import React from 'react';
import i18n from '../../i18n';
import { ICheckoutCoupon, ICheckoutCouponProps, ICheckoutCouponState, ICheckoutOrderDetailShowedPageNames } from '../../interfaces';
import { IconHeadBox } from './checkout-icon-head-box';
import { BsTagFill } from 'react-icons/bs';
import CouponItem from '../coupon/coupon-item.component';
import _ from 'lodash';
import './scss/checkout-coupon.styles.scss';
import { InsertCode } from '../insert-code-dialog/insert-code-dialog.component';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { MdKeyboardArrowRight } from 'react-icons/md';
import BScroll from 'better-scroll';

class CheckoutCouponComponent extends React.Component<Props, State> implements ICheckoutCoupon {

    public state: State = {
        isShowTipBox: false,
        isShowCouponCode: false

    }
    // 鼠标拖拽事件 滚动插件
    public SlideScroll: BScroll | undefined;

    // *********************
    // Life Cycle Function
    // *********************

    async componentDidMount() {
        // 初始化滚动插件
        this.initBetterScroll();
    }

    componentWillUnmount() {
        if (this.SlideScroll) {
            this.SlideScroll.destroy()
        }
    }

    // *********************
    // Default Function
    // *********************
    /**
     * 设置滚动插件初始化
     */
    initBetterScroll() {
        // 使用插件解决滑动问题
        try {
            // 区域 滑动
            const SlideWrapper: any = document.querySelector('#scrollBox')
            this.SlideScroll = new BScroll(SlideWrapper, {
                scrollX: true,
                scrollY: false,
                mouseWheel: true,
                click: true
            });
        } catch (error) {
            console.log(error)
        }
    }
    // *********************
    // View
    // *********************

    render() {
        const { isShowCouponCode } = this.state;
        const { updateShowedPage, allCoupons, selectedCoupons, analysisAndAdjustCoupon, onInsertCodeCoupon, newlyReceivedCouponCodes } = this.props;
        return (
            <div className={'checkoutCouponComponent'}>
                <IconHeadBox
                    iCon={() => <BsTagFill />}
                    title={i18n.t('coupon_text_use_my_coupon')}
                    actionLabel={() => <>{i18n.t('coupon_text_view_coupons')} <MdKeyboardArrowRight /></>}
                    action={() => { updateShowedPage?.(ICheckoutOrderDetailShowedPageNames.COUPON_LIST_PAGE) }}
                />
                <div className='scrollBox' id='scrollBox'>
                    <div className={`allCouponsBox ${Array.isArray(allCoupons) && allCoupons.length === 0 ? 'noCouponBox' : ''}`}>
                        {
                            Array.isArray(allCoupons) && allCoupons.length > 0
                            && allCoupons.map((item, index) => {
                                return (
                                    <CouponItem
                                        key={index}
                                        couponItem={item}
                                        onClick={() => { analysisAndAdjustCoupon?.(item) }}
                                        isCheck={_.findIndex(selectedCoupons, (n) => n.coupon_id === item.coupon_id) !== -1}
                                        hideRuleText={true}
                                        showNewTag={_.includes(newlyReceivedCouponCodes, item.code)}
                                        type='payment'
                                        className={item.showCantUseText ? 'showCantUseText' : ''}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <div className='redeemCouponBox' >
                    <span className='redeemCoupon'
                        onClick={() => { this.setState({ isShowCouponCode: true }) }}>
                        {i18n.t('coupon_text_enter_coupon_code_to_redeem')}
                    </span>
                </div>
                {isShowCouponCode &&
                    <InsertCode
                        // className={`${restaurantTheme === 'SECOND_THEME' ? 'blackGoldInsertCode' : ''}`}
                        restaurantStringId={LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID)}
                        handleClose={() => { this.setState({ isShowCouponCode: false }) }}
                        onConfirm={(insertCode) => {
                            this.setState({ isShowCouponCode: false })
                            onInsertCodeCoupon(insertCode)
                        }} />}
            </div>
        )
    }
}
export default CheckoutCouponComponent

// *********************
// Props & State
// *********************

interface State extends ICheckoutCouponState {
}

interface Props extends ICheckoutCouponProps {
    // 输入编码点击确认
    onInsertCodeCoupon: (insertCode: string) => void;
    // 新领取的优惠券code数组
    newlyReceivedCouponCodes: string[];
}