import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, Checkbox, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
export default class ScrollDialogComponent extends Component<DialogComponentProps, DialogComponentState> {

    constructor(props: DialogComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // state
        this.state = {
            checkBoxValue: false
        }
    }

    // *********************
    // View
    // *********************

    render(this: any) {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={false}
                open={this.props.visible}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                onClose={(e) => {
                    if (this.props.onCancelFn && this.props.maskClose) this.props.onCancelFn(e);
                }}
                onClick={(e) => { e.stopPropagation(); }}
                classes={{ paper: 'scrollOrderDialog' }}
            >
                <DialogTitle id='alert-dialog-title' className='orderDialogTitle'>
                    <div>
                        {this.props.title}
                    </div>
                    <IconButton className='closeBtn'
                        onClick={(e) => {
                            if (this.props.onCancelFn) this.props.onCancelFn(e);
                        }}
                    >
                        <CloseIcon></CloseIcon>
                    </IconButton>
                </DialogTitle>
                <DialogContent classes={{ root: 'dialogContent' }}>
                    {
                        this.props.content ?
                            <DialogContentText className='contentText' id='alert-dialog-description'>
                                {this.props.content}
                            </DialogContentText> : null
                    }
                    {typeof this.props.contentFunction === 'function' ? this.props.contentFunction() : null}
                    <FormControlLabel
                        style={this.props.checkboxText ? { opacity: 0.65, marginTop: 20 } : { display: 'none' }}
                        control={
                            <Checkbox
                                checked={!this.state.checkBoxValue}
                                onChange={() => {
                                    this.setState({
                                        checkBoxValue: !this.state.checkBoxValue
                                    })
                                }}
                                value='checkBoxValue'
                                style={{ color: '#ffdc33' }}
                            />
                        }
                        label={this.props.checkboxText}
                    />
                </DialogContent>
                <DialogActions style={{
                    justifyContent: 'space-between'
                }} className='dialogFooter'>
                    {
                        typeof this.props.actionsFunction === 'function' ?
                            this.props.actionsFunction() : null
                    }
                    {
                        typeof this.props.actionsFunction !== 'function' ?
                            <Button
                                onClick={(e) => {
                                    if (this.props.onConfirmFn) this.props.onConfirmFn(e);
                                }}
                                size='large'
                                className='dialogButton'
                                variant='contained'
                                style={{ background: '#ffdc33' }}
                            >
                                {this.props.confirmButtonText}
                            </Button> : null
                    }
                    {
                        typeof this.props.actionsFunction !== 'function' ?
                            <Button
                                onClick={(e) => {
                                    if (this.props.onCancelFn) this.props.onCancelFn(e);
                                }}
                                size='large'
                                variant='contained'
                                className='dialogButton'
                                style={{ background: '#5867B4' }}
                                autoFocus
                            >
                                {this.props.cancelButtonText}
                            </Button> : null
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

// *********************
// States
// *********************

type DialogComponentProps = {
    visible: boolean;
    title: string;
    content?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    checkboxText?: string;
    checkBoxSettingValue?: string;
    contentFunction?: () => React.ReactNode;
    onCancelFn?: (e: any) => void;
    onConfirmFn?: (e: any) => void;
    actionsFunction?: () => React.ReactNode;
    // 是否点击遮罩层关闭
    maskClose?: boolean
}

type DialogComponentState = {
    checkBoxValue: boolean;
}
