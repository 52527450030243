// 过敏原 图片
import ALLERGEN_CELERY from './ALLERGEN_CELERY.png';
import ALLERGEN_CRUSTACEANS from './ALLERGEN_CRUSTACEANS.png';
import ALLERGEN_EGGS from './ALLERGEN_EGGS.png';
import ALLERGEN_FISH from './ALLERGEN_FISH.png';
import ALLERGEN_GLUTEN from './ALLERGEN_GLUTEN.png';
import ALLERGEN_LUPIN from './ALLERGEN_LUPIN.png';
import ALLERGEN_MILK from './ALLERGEN_MILK.png';
import ALLERGEN_MOLLUSCE from './ALLERGEN_MOLLUSCE.png';
import ALLERGEN_MUSTARD from './ALLERGEN_MUSTARD.png';
import ALLERGEN_PEANUTS from './ALLERGEN_PEANUTS.png';
import ALLERGEN_SESAME from './ALLERGEN_SESAME.png';
import ALLERGEN_SOYA from './ALLERGEN_SOYA.png';
import ALLERGEN_SULPHITES from './ALLERGEN_SULPHITES.png';
import ALLERGEN_TREE_NUTS from './ALLERGEN_TREE_NUTS.png';

// 其他菜品标识 图片
import INGREDIENTS_ADDITIVES from './INGREDIENTS_ADDITIVES.png';
import INGREDIENTS_BLAST_FREEZING from './INGREDIENTS_BLAST_FREEZING.png';
import INGREDIENTS_COLOURANTS from './INGREDIENTS_COLOURANTS.png';
import INGREDIENTS_COOKED from './INGREDIENTS_COOKED.png';
import INGREDIENTS_FROZEN_ORIGIN from './INGREDIENTS_FROZEN_ORIGIN.png';
import INGREDIENTS_GLUTEN_FREE from './INGREDIENTS_GLUTEN_FREE.png';
import INGREDIENTS_MEDIUM_SPICY from './INGREDIENTS_MEDIUM_SPICY.png';
import INGREDIENTS_MILD_SPICY from './INGREDIENTS_MILD_SPICY.png';
import INGREDIENTS_RAW from './INGREDIENTS_RAW.png';
import INGREDIENTS_VEGAN from './INGREDIENTS_VEGAN.png';
import INGREDIENTS_VEGETARIAN from './INGREDIENTS_VEGETARIAN.png';
import INGREDIENTS_VERY_SPICY from './INGREDIENTS_VERY_SPICY.png';
import INGREDIENTS_PORK from './INGREDIENTS_PORK.png';

export const allDishImages = {
    ALLERGEN_CELERY, ALLERGEN_CRUSTACEANS, ALLERGEN_EGGS,
    ALLERGEN_FISH, ALLERGEN_GLUTEN, ALLERGEN_LUPIN, ALLERGEN_MILK,
    ALLERGEN_MOLLUSCE, ALLERGEN_MUSTARD, ALLERGEN_PEANUTS, ALLERGEN_SESAME,
    ALLERGEN_SOYA, ALLERGEN_SULPHITES, ALLERGEN_TREE_NUTS, INGREDIENTS_ADDITIVES,
    INGREDIENTS_BLAST_FREEZING, INGREDIENTS_COLOURANTS, INGREDIENTS_COOKED,
    INGREDIENTS_FROZEN_ORIGIN, INGREDIENTS_GLUTEN_FREE, INGREDIENTS_MEDIUM_SPICY,
    INGREDIENTS_MILD_SPICY, INGREDIENTS_RAW, INGREDIENTS_VEGAN, INGREDIENTS_VEGETARIAN,
    INGREDIENTS_VERY_SPICY, INGREDIENTS_PORK
}