import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent, DialogActions, Dialog, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { MdKeyboardArrowDown } from 'react-icons/md';
import './account-information.component.scss';
import i18n from '../../i18n';

const options = ['account_page_text_change_gender_male', 'account_page_text_change_gender_female'];

export function ConfirmationDialogRaw(props: {
	classes: Record<'paper', string>;
	id: string;
	keepMounted: boolean;
	value: string;
	open: boolean;
	onClose: (value?: string) => void;
}) {
	const { onClose, value: valueProp, open, ...other } = props;
	const [value, setValue] = React.useState(`${i18n.t(valueProp)}` || '');
	const radioGroupRef = React.useRef<HTMLElement>(null);

	React.useEffect(() => {
		if (!open) {
			setValue(`${i18n.t(valueProp)}` || '');
		}
	}, [valueProp, open]);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = (e) => {
		e.stopPropagation();
		onClose();
	};

	const handleOk = (e) => {
		e.stopPropagation();
		onClose(value);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth='xs'
			onEntering={handleEntering}
			aria-labelledby='confirmation-dialog-title'
			open={open}
			{...other}
		>
			<DialogTitle id='confirmation-dialog-title'>{i18n.t('signup_page_text_gender')}</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					ref={radioGroupRef}
					aria-label='ringtone'
					name='ringtone'
					value={`${i18n.t(value)}`}
					onChange={handleChange}
				>
					{options.map((option, index) => (
						<FormControlLabel
							value={`${i18n.t(option)}`}
							key={index}
							control={<Radio style={{ color: '#5867B4' }}/>}
							label={`${i18n.t(option)}`}
						/>
					))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel} style={{ color: '#ffdc33' }}>
					{i18n.t('general_text_cancel')}
				</Button>
				<Button onClick={handleOk} style={{ color: '#ffdc33' }}>
					{i18n.t('general_text_ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper
		},
		paper: {
			width: '80%',
			maxHeight: 435
		}
	})
);

export default function ConfirmationDialog(props: {
	className: string;
	defaultValue?: string;
	changeState: (val: number) => void;
}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const gender = props.defaultValue ? options[props.defaultValue] : ''
	const [value, setValue] = React.useState(gender ? `${i18n.t(gender)}` : '');

	const handleClose = (newValue?: string) => {
		setOpen(false);
		if (newValue) {
			const FIND_INDEX = options.findIndex((n) => {
				return `${i18n.t(n)}` === newValue;
			});
			props.changeState(FIND_INDEX);
			if (newValue) setValue(newValue);
		}
	};

	return (
		<div
			className={props.className}
			onClick={(e) => {
				e.stopPropagation();
				setOpen(true);
			}}
		>
			<div>{value}</div>
			<div className='icon-left'>
				<MdKeyboardArrowDown />
			</div>
			<ConfirmationDialogRaw
				classes={{
					paper: classes.paper
				}}
				id='ringtone-menu'
				keepMounted
				open={open}
				onClose={handleClose}
				value={value}
			/>
		</div>
	);
}
