
// 防抖装饰器
export const debounce = (wait) => {
    let timer;
    return (target, name, descriptor) => {
        const func = descriptor.value;
        if (typeof func === 'function') {
            descriptor.value = function (...args) {
                if (timer) clearTimeout(timer)
                timer = setTimeout(() => {
                    func.apply(this, args)
                }, wait)
            }
        }
    }
}

// 节流装饰器
export const throttle = (wait) => {
    let prev = new Date().valueOf();
    return (target, name, descriptor) => {
        const func = descriptor.value;
        if (typeof func === 'function') {
            descriptor.value = function (...args) {
                const now = new Date().valueOf();
                if (now - prev > wait) {
                    func.apply(this, args);
                    prev = new Date().valueOf();
                }
            }
        }
    }
}

/**
 * Interface for classes with `new` operator and static properties/methods
 */
export interface Class<T> {
    // new MyClass(...args) ==> T
    new(...args: any[]): T;
    // Other static properties/operations
    [property: string]: any;
}

// 防止内存泄漏 组件销毁之后不再setState
export function inject_unount(target: Class<React.Component>) {
    // 改装componentWillUnmount，销毁的时候记录一下
    const next = target.prototype.componentWillUnmount;
    target.prototype.componentWillUnmount = function () {
        // eslint-disable-next-line prefer-rest-params
        if (next) next.call(this, ...arguments);
        this.unmount = true
    }
    // 对setState的改装，setState查看目前是否已经销毁
    const setState = target.prototype.setState
    target.prototype.setState = function () {
        if (this.unmount) return;
        // eslint-disable-next-line prefer-rest-params
        setState.call(this, ...arguments)
    }
}