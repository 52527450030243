
/**
 * 所有缓存的键值枚举
 */
export enum LOCAL_STORAGE_KEYS {
    // 语言
    'LANGUAGE' = 'LANGUAGE',
    // 账号信息
    'ACCOUNT' = 'ACCOUNT',
    // TOKEN信息
    'TOKEN' = 'TOKEN',
    // 当前集团string_id
    'CURRENT_GROUP_STRING_ID' = 'CURRENT_GROUP_STRING_ID',
    // 当前餐馆string_id
    'CURRENT_RES_STRING_ID' = 'CURRENT_RES_STRING_ID',
    // 当前餐馆名称
    'CURRENT_RES_NICK_NAME' = 'CURRENT_RES_NICK_NAME',
    // 最后登录的账号
    'LAST_LOGIN_ACCOUNT' = 'LAST_LOGIN_ACCOUNT',
    // 浏览过的多个餐馆信息
    'RESTAURANTS' = 'RESTAURANTS',
    // 多个餐馆的购物车详情
    'SHOP_CAR' = 'SHOP_CAR',
    // 搜索历史餐馆
    'SEARCH_HISTORY' = 'SEARCH_HISTORY',
    // 停业时间
    'CLOSED_TIME' = 'CLOSED_TIME',
    // 配送费
    'DELIVERY_FEE' = 'DELIVERY_FEE',
    // 在配送区域内的配送费数组
    'DELIVERY_FEE_ARR' = 'DELIVERY_FEE_ARR',
    // 结算托盘数据
    'CHECKOUT_DATA' = 'CHECKOUT_DATA',
    // 餐厅当前时间是否临时停业
    'CURRENT_CLOSED_STATUS' = 'CURRENT_CLOSED_STATUS',
    // 谷歌预定token
    'RWG_TOKEN' = 'RWG_TOKEN',
    // 验证码的pending状态来源
    'VERIFY_CODE_PENDING_SOURCE' = 'VERIFY_CODE_PENDING_SOURCE',
    // 当前推荐码
    'CURRENT_REFERRAL_CODE' = 'CURRENT_REFERRAL_CODE',
    // 第三方token
    'THIRD_PARTY_TOKEN' = 'THIRD_PARTY_TOKEN'
}