import React from 'react';

import { ArrowBackIos } from '@material-ui/icons';
import '../static/css/sign-up.scss';
import ButtonComponent from '../component/button.component';
import AccountInput from '../component/account-input.component';
import PasswordInput from '../component/password-input.component';
import VerificationComponent from '../component/verification-input.component';
import { createHashHistory } from 'history';
import apiManage from '../request';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IS_PHONE, IS_PASSWORD, IS_VCODE } from '../constants/base.constant';
import _ from 'lodash';
import { showApiMessage } from '../messenger/messageManager';
import { LocalStorageManager } from '../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../constants/local-storage.constant';
import { getBrowserPhoneCode } from '../constants/tool-get-browser-language';
import i18n from '../i18n';
import Notification from '../component/snackbar/snackbar';
import { inject_unount } from '../component/decorators/helper.decorator';

@inject_unount
class ForgetPasswordPage extends React.Component<Props, State> {
    public state: State = {
        // 手机
        phone: '',
        // 验证码
        verificationCode: '',
        // 点击
        isConfirm: true,
        // 重复密码
        newPassword: '',
        // 密码
        password: '',
        // 上次登录的账号
        lastLogin: LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LAST_LOGIN_ACCOUNT),
        phone_prefix_code: getBrowserPhoneCode() || '39',
        showSuccessPhone: false
    }

    // ************
    // 生命周期
    // ************

    // ************
    // 方法区
    // ************
    public back() {
        if (this.state.isConfirm) {
            createHashHistory().replace('/');
        } else {
            this.setState({
                isConfirm: true,
                verificationCode: '',
                password: '',
                newPassword: ''
            });
        }
    }

    public async confirm() {
        if (this.state.isConfirm) {
            if (this.state.phone === '' || !IS_PHONE(this.state.phone_prefix_code, this.state.phone)) {
                Notification.notification(i18n.t('reset_password_page_text_phone_number_format_invalid'));
                return;
            }
            const isPhoneExistResult = await apiManage.isPhoneExist({ phone_prefix: this.state.phone_prefix_code, phone: this.state.phone })
            if (_.get(isPhoneExistResult, 'error') || !isPhoneExistResult) {
                Notification.notification(i18n.t('账户不存在'));
            } else {
                this.setState({
                    isConfirm: false
                });
            }
        } else {
            if (this.state.phone === '' || !IS_PHONE(this.state.phone_prefix_code, this.state.phone)) {
                Notification.notification(i18n.t('reset_password_page_text_phone_number_format_invalid'));
                return;
            } else if (this.state.verificationCode === '' || !IS_VCODE.test(this.state.verificationCode)) {
                Notification.notification(i18n.t('reset_password_page_text_vcode_mismatched'));
                return;
            } else if (!(this.state.password && this.state.newPassword
                && IS_PASSWORD.test(this.state.password))) {
                Notification.notification(i18n.t('signup_page_text_invalid_password_format'));
                return;
            } else if (this.state.password !== this.state.newPassword) {
                Notification.notification(i18n.t('signup_page_text_invalid_password_confirm'));
            } else {
                // 修改密码
                const params = {
                    vcode: {
                        value: this.state.verificationCode,
                        type: 'RESET_PASSWORD_BY_PHONE'
                    },
                    password: this.state.password,
                    phone: this.state.phone,
                    phone_prefix: this.state.phone_prefix_code
                }
                try {
                    const result = await apiManage.patch_customer_password(params, {
                        headers: {
                            Authorization: undefined
                        }
                    });
                    if (result.error) {
                        showApiMessage(result, 'forgetPassword')
                        return;
                    }
                    Notification.notification(i18n.t('delivery_address_page_text_success'));
                    createHashHistory().replace('/');
                } catch (error) {
                    //
                }
            }
        }
    }
    // ************
    // ViEW
    // ************
    public render() {
        const { t } = this.props
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    backgroundColor: '#F8F8F8',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: '#F8F8F8',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        height: '80%'
                    }}
                >
                    <div
                        className='sign-up-title'
                    >
                        <ArrowBackIos className='sign-left-icon' onClick={() => {
                            this.setState({
                                showSuccessPhone: false
                            })
                            this.back()
                        }} />
                        <span className='sign-up-title-text'>{t('signin_page_text_forget_password')}</span>
                        <ArrowBackIos style={{ color: '#F8F8F8' }} />
                    </div>
                    {this.state.isConfirm ?
                        <div
                            style={{
                                display: 'flex',
                                backgroundColor: '#F8F8F8',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            <span className='sign-up-phone-text'>{t('general_text_phone_number')}</span>
                            <div className='sing-up-phone-container'>
                                <AccountInput
                                    className='sing-up-phone'
                                    defaultValue={this.state.phone}
                                    phonePreFixValue={this.state.phone_prefix_code}
                                    phone_prefix={(val: string) => {
                                        // 手机前缀
                                        this.setState({
                                            phone_prefix_code: val
                                        });
                                    }}
                                    onChange={(val: string) => {
                                        this.setState({
                                            phone: val
                                        });
                                    }}
                                />
                            </div>
                            <div
                                className='forget-password-text-div'
                            >
                                <span className='forget-password-text' style={{ textAlign: 'left' }}>{t('reset_password_page_text_last_logged_in_successfully_account')}</span>
                                <span className='forget-password-text' style={{ fontWeight: 'bold' }}>
                                    {_.get(this.state.lastLogin, 'phone')}
                                </span>
                                <ButtonComponent
                                    className='forget-password-button'
                                    value={t('reset_password_page_button_use_last_logged_in_successfully_account')}
                                    onClick={() => {
                                        const phone = _.get(this.state.lastLogin, 'phone')
                                        // 有数据的情况下才能覆盖
                                        if (phone) {
                                            this.setState({
                                                phone,
                                                phone_prefix_code: _.get(this.state.lastLogin, 'phonePrefix')
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        :
                        <div
                            style={{
                                display: 'flex',
                                backgroundColor: '#F8F8F8',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    visibility: this.state.showSuccessPhone ? 'visible' : 'hidden',
                                    marginLeft: '10%',
                                    width: '80%',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span className='forget-password-get-code'>
                                    {t('reset_password_page_text_vcode_has_been_sent_to')}
                                </span>
                                <span className='forget-password-get-phone'>{this.state.phone}</span>
                            </div>
                            <span className='sign-up-verification-text'>{t('signup_page_text_vcode')}</span>
                            <div className='sing-up-phone-container'>
                                <VerificationComponent
                                    className='sign-up-verification'
                                    defaultValue={this.state.verificationCode}
                                    value={this.state.phone}
                                    phone_prefix={this.state.phone_prefix_code}
                                    type={'resetPassword'}
                                    onChange={(val: string) => {
                                        this.setState({
                                            verificationCode: val
                                        });
                                    }}
                                    onSendSuccess={() => {
                                        this.setState({
                                            showSuccessPhone: true
                                        })
                                    }}
                                />
                            </div>
                            <span className='forget-password-text-first'>{t('reset_password_page_text_insert_new_password')}</span>
                            <div className='sing-up-phone-container'>
                                <PasswordInput
                                    className='sign-up-password'
                                    defaultValue={this.state.password}
                                    isSame={true}
                                    onChange={(val: string) => {
                                        this.setState({
                                            password: val
                                        });
                                    }}
                                />
                            </div>
                            <span className='sign-up-verification-text'>{t('reset_password_page_text_confirm_new_password')}</span>
                            <div className='sing-up-phone-container'>
                                <PasswordInput
                                    className='sign-up-password'
                                    defaultValue={this.state.newPassword}
                                    isSame={this.state.password === this.state.newPassword}
                                    onChange={(val: string) => {
                                        this.setState({
                                            newPassword: val
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: '#F8F8F8',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginTop: 100,
                        marginBottom: 300,
                        justifyContent: 'center'
                    }}
                >
                    <ButtonComponent
                        className='signup-button-div'
                        value={t('signup_page_button_confirm')}
                        onClick={() => {
                            this.confirm();
                        }}
                    />
                </div>
            </div>
        )
    }

}

export default withTranslation()<Props>(ForgetPasswordPage as any);

// *********
// TYPE
// *********

type Props = {
    enqueueSnackbar?: any
} & WithTranslation;

type State = {
    // 手机
    phone: string;
    // 验证码
    verificationCode: string;
    // 点击
    isConfirm: boolean;
    // 密码
    password: string;
    // 重复密码
    newPassword: string;
    // 上次登录的密码
    lastLogin: string;
    // 手机前缀
    phone_prefix_code: string;
    // 显示发送成功后的号码
    showSuccessPhone: boolean;
};