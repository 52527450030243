import './order.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import BScroll from 'better-scroll';
import { createHashHistory } from 'history';
import _ from 'lodash';
import React from 'react';
import Notification from '../../component/snackbar/snackbar';

import {
    OrderFloatDialog,
    OrderHeader,
    OrderSearch,
    OrderSlide,
    RemarksDialog, analysisCart,    // TipsDialog,
    checkSetDishOrMeal
} from '../../component/order/order.component';
import { SidebarComponet } from '../../component/sidebar/sidebar.component';
import TopBar from '../../component/top-bar/top-bar.component';
import i18n from '../../i18n';
import { Coupon, Customer, Dish, Menu, Restaurant, Schedule } from '../../models';
import apiManage from '../../request';
import { ALLERGEN, INGREDIENTS } from '../../static/dish-base-data';
import errorCode from '../../static/error-code';
import tuneBlack from '../../static/images/baseline_tune_black_48dp.png';
import DishDetail from './dish.details.page';
import Store from '../../rudex/store';
import { checkVersion } from '../../constants/tool-check-resource';
import { checkToken } from '../../constants/tool-check-token';
import { getRestaurantTheme } from '../../constants/tool-restaurant-theme';
import moment from 'moment';
import { DefaultWebsocketClient } from '../../modules/websocket/module';
import { convertDateByTimeZoneName } from '../../constants/tool-constant';
import momentTimeZone from 'moment-timezone';
import OrderPackageMeal from '../../component/order/default-theme-packageMeal/order-package-meal.component';
import Checkout from '../../component/checkout/checkout';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import SwipeableTemporaryClosedDialog from '../../component/restaurant/swipeable -temporary-closed-dialog';
import { LocalOffer } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { debounce, inject_unount } from '../../component/decorators/helper.decorator';
import axios, { Canceler, CancelToken } from 'axios';
import { MenuFixed } from '../../models/fixed/menu.entity';
import { ShopCar } from '../../models/fixed/shop-car.entity';
import { LevelDeliveryFee } from '../../models/abstract/level-delivery-fee.entity';
import SetDishDetailItem from '../../component/order/packageMeal/set-dish-detail-item.conponent';
import { CheckoutDetailCartDish } from '../../interfaces';
import MenuList from '../../component/order/menu-list/menu-list.component';
import { DishFixed } from '../../models/fixed/dish.entity';
import { ScrollParams } from 'react-virtualized';
import { ConfirmClearCartModal } from '../../component/order/confirm-clear-cart.modal';
import { isMobileOrTablet } from '../../component/checkout/checkout-order-detail-page';
import { PM } from '../../component/order/tool-points';
import { getFulfillmentTime, getMenuDishAvailableSchedule, ScheduleDialog } from '../../component/restaurant/schedule-dialog.component';

const history = createHashHistory();
@inject_unount
class OrderPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.handelWebsocketFunRestaurant = this.handelWebsocketFunRestaurant.bind(this)
        this.handleWebsocketBlacklistedUser = this.handleWebsocketBlacklistedUser.bind(this)
        this.cancelToken = new axios.CancelToken(() => {
            this.cancel = undefined;
        });
        this.onScroll = this.onScroll.bind(this);

    }
    // 菜品列表ref
    public menuListRef: MenuList | null = null;
    // 订单类型1：自取 2：外送 3：堂食
    public orderType = this.getQueryString('orderway');
    // 头部ref
    public mainHeaderRef: HTMLDivElement | null | undefined;
    public scroll: BScroll | undefined;
    public SlideScroll: BScroll | undefined;
    // 临时停业倒计时
    public countdownInterval!: NodeJS.Timeout;
    // 一级菜单事件 取消异步请求
    public cancel: Canceler | undefined;
    public cancelToken: CancelToken;
    scrollIndex: number = 0;

    // 数据初始化
    public state: State = {
        mainHeaderData: {
            headerClassName: 'mainHeaderStyle',
            leftIco: (className?: string) => <SearchIcon className={className} />,
            rightIco: () => <div className='tune-tool'>
                {this.state.filterTagsNumber !== 0 && <div className='cue-bubble'>{this.state.filterTagsNumber}</div>}
                <img src={tuneBlack} className='tool-icon' alt='tuneBlack' width='72' />
            </div>,
            title: i18n.t('restaurant_order_page_text_memu')
            // renderFunction: (className?: string) => <KeyboardArrowDownIcon className={className} />
        },
        slideData: [],
        // 搜索值
        menuSearchValue: '',
        // 原始菜单数据
        originDishListData: [],
        // 菜单数据总数(菜品过滤器过滤后的数据)
        dishListData: [],
        // 点击一级菜单后的菜单（在过滤器的菜单中进行筛选）
        clickFirstdishListData: [],
        // 菜单数据总数 (选择一级菜单会改变，默认全部)
        totalShopPrice: 0,
        // 总积分
        totalShopPoints: 0,
        // 保存需要购买的订单数据
        saveShopCatDate: [],
        showFilterDialog: false,
        // 菜品的过敏原
        allAllergen: [],
        // 菜品的配料
        allIngredients: [],
        // 刷选器选中的标签-过敏原
        selectFilterLabeldAllergen: [],
        // 刷选器选中的标签-配料
        selectFilterLabeldIngredients: [],
        // 当前筛选标签数
        filterTagsNumber: 0,
        // 搜索框是否显示
        isSearchShow: false,
        historyList: [],
        // 控制购物车弹窗事件
        isShopCatShow: false,
        touchTop: 0,
        // 节流开关
        throttleFlag: true,
        // 滑动的起始位置
        startClientY: 0,
        // 搜索过滤的数据
        filterList: [],
        // 控制显示数据还是历史记录
        showSearchList: false,
        // 侧边栏控制
        isShowSidebar: false,
        // 显示搜索的暂无相关数据开关
        isShowNoDateTip: false,
        // 自取最低订单价格或派送最高订单价格
        OrderPrice: 0,
        nickname: '', // 餐馆名字
        // 餐厅电话号码
        restaurantsPhone: '',
        // 当前餐馆id
        currentResStringID: '',
        // 集团id
        groupStringID: '',
        // 菜品备注
        remarksDialogvisible: false,
        // 正在备注的菜品
        remarkItem: {},
        // 提示弹窗visible
        tipsDialogvisible: false,
        // 是否显示菜品详情
        isshowDishDetails: false,
        // 某个菜品详情
        dishesDetail: {},
        // 购物车的的提示box
        isShowTipBox: true,
        // 动画-配送最低价格没达到时
        showleastPriceAnimation: false,
        // 上次缓存中的菜单数据
        oldmenuDishInfo: [],
        // 主题色
        RestaurantTheme: 'FIRST_THEME',
        // 主题颜色
        RestaurantThemeColor: 'FIRST_THEME_COLOR',
        // 更换主题时 是否显示样式 0：当前餐馆找不到 1：显示餐馆 2：加载中
        isShowOrder: 2,
        // 免注册下单模式
        loginFreeMode: false,
        // 餐馆临时停业状态
        temporaryClosureStatus: false,
        // 倒计时时分秒
        countdownText: '',
        // 倒计时初始值
        countdownValue: 0,
        // 餐馆时区
        restaurantsTimeZoneName: '',
        // 包餐套餐区域
        isShowPackageMeal: 0,
        // 菜品类型 0：菜品 1：包餐 2：套餐
        dishType: '',
        // 包餐套餐菜品ID
        dishId: '',
        // 包餐套餐所在区域id
        setMenuId: '',
        // 结算托盘显示
        checkoutDrawerVisible: false,
        // 停业弹窗
        isVisibleClosedInfo: false,
        // 停业时间
        resClosedTime: 0,
        start_time: new Date(),
        end_time: new Date(),
        coupons: [],
        // 当前点击菜单名字
        menuName: '',
        // 是否开启whatsApp
        whatsAppEnable: false,
        // whatsApp网址
        whatsAppURL: '',
        // 菜品列表title高度数组
        listHeight: [],
        // 菜品列表可视高度
        dishListClientHeight: 0,
        // 正在请求菜品图片
        requesting: false,
        // 是否编辑套餐、包餐
        isEditSetMeal: false,
        // 是否显示清空购物车弹窗
        isVisibleClearCartModal: false,
        // 打开弹窗
        isOpenSchedulesModel: false,
        // 菜品可售时间表
        dishSchedules: [],
        // 原始时间表
        originSchedules: []
    }

    // *********************
    // Life Cycle Function
    // *********************
    UNSAFE_componentWillMount() {
        try {
            // 检查token是否失效
            const isTokenInvalid = checkToken();
            if (isTokenInvalid) {
                // 消息提示
                Notification.notification(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'), { autoHideDuration: 5000 });
                // 是否是商家网站
                const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
                // 跳转到餐馆主页
                history.push(`/restaurantPage?string_id=${this.getQueryString('string_id')}${merchantWebsite ? '&merchant_website=true' : ''}`);
            }
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID,
                value: this.getQueryString('string_id')
            });
        } catch (error) { }
    }
    async componentDidMount() {
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        if (!token) {
            Store.dispatch({
                type: 'SET_STATE',
                path: 'globalLoading',
                value: true
            })
        }
        // 获取餐馆信息
        await this.getRestaurantsApi();
        // 获取菜单数据
        this.getRestaurant();
        // 初始化滚动插件
        // this.getHeightList();
        // this.initBetterScroll();
        // 获取优惠劵信息
        this.getCoupons();
        // 监听餐馆临时休业开关
        DefaultWebsocketClient.addListener('RESTAURANT_RESOURCE_UPDATED_EVENT', this.handelWebsocketFunRestaurant);
        // 监听用户是否被屏蔽
        DefaultWebsocketClient.addListener('BLOCKED_USERS_CREATE_EVENT', this.handleWebsocketBlacklistedUser);
        // 监听用户是否解除屏蔽
        DefaultWebsocketClient.addListener('BLOCKED_USERS_RELIEVE_EVENT', this.handleWebsocketBlacklistedUser);
        if (!token) {
            Store.dispatch({
                type: 'SET_STATE',
                path: 'globalLoading',
                value: false
            })
        }
    }

    componentWillUnmount() {
        if (this.scroll) {
            this.scroll.destroy()
        }
        if (this.SlideScroll) {
            this.SlideScroll.destroy()
        }
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval)
        }
        this.setState({
            isShowOrder: 0
        })
        DefaultWebsocketClient.removeListener('RESTAURANT_RESOURCE_UPDATED_EVENT', this.handelWebsocketFunRestaurant);
        DefaultWebsocketClient.removeListener('BLOCKED_USERS_CREATE_EVENT', this.handleWebsocketBlacklistedUser);
        DefaultWebsocketClient.removeListener('BLOCKED_USERS_RELIEVE_EVENT', this.handleWebsocketBlacklistedUser)
    }

    // *********************
    // Default Function
    // *********************

    handelWebsocketFunRestaurant(data) {
        // 缓存的餐厅string id
        const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        // 已经展示过的停业时间
        let res_closed_time = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CLOSED_TIME) || {};
        // 是否已登录， token
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        // 当前时间转成相同时区格式
        let currentTime = moment(new Date()).format(moment.defaultFormatUtc);
        currentTime = convertDateByTimeZoneName(currentTime, momentTimeZone.tz.guess(), this.state.restaurantsTimeZoneName)

        const start_time = _.get(data, 'newValue.setting_temporarily_closed_start_data', '');
        const end_time = _.get(data, 'newValue.setting_temporarily_closed_end_data', '');
        const closed_status = _.get(data, 'newValue.setting_temporarily_closed_enable', false);
        const closed_res_id = _.get(data, 'id', '');

        // 现在时间与结束时间的差（结束时间-现在时间）
        const countdownValue = moment(end_time).diff(currentTime, 'seconds');

        // 现在时间与开始时间的差（结束时间-现在时间）
        const startTimeValue = moment(currentTime).diff(start_time, 'seconds');
        // 临时停业时间
        res_closed_time = _.get(res_closed_time, `[${closed_res_id}]`);
        // 判断是否同一家餐厅
        if (closed_res_id === res_string_id && token) {
            // 判断是否显示过弹窗 或者 已经停业了 或者 不在当前时间范围内
            if (moment(_.get(res_closed_time, 'startTime', '')).format('YYYY-MM-DD HH:mm:ss') === moment(start_time).format('YYYY-MM-DD HH:mm:ss')
                || start_time === end_time || !closed_status || startTimeValue < -5 || countdownValue < 0) {
                // 判断是否暂停停业
                if (start_time === '' || end_time === '' || !closed_status) {
                    this.setState({
                        isVisibleClosedInfo: false,
                        resClosedTime: 0,
                        countdownValue: 0,
                        temporaryClosureStatus: false
                    })
                    // 餐厅当前时间是否临时停业
                    LocalStorageManager.setLocalStorage({
                        'path': LOCAL_STORAGE_KEYS.CURRENT_CLOSED_STATUS,
                        'value': false
                    })
                    return
                } else {
                    // 已经显示过
                    this.setTemporaryClosure(closed_status, start_time, end_time);
                }
                return;
            }
        } else {
            return;
        }
        // 没有展示过的直接缓存
        const closedTime = {
            startTime: start_time,
            endTime: end_time
        }

        const cacheClosedTime: { [key: string]: { startTime: string, endTime: string } } = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CLOSED_TIME) || {};
        _.set(cacheClosedTime, `${closed_res_id}`, closedTime);

        LocalStorageManager.setLocalStorage({
            'path': LOCAL_STORAGE_KEYS.CLOSED_TIME,
            'value': cacheClosedTime
        })

        // 显示弹窗
        this.setState({
            resClosedTime: countdownValue,
            isVisibleClosedInfo: true,
            start_time,
            end_time,
            temporaryClosureStatus: true,
            countdownValue
        }, async () => {
            this.startCountdown()
        })
    }

    /**
     * 用户被纳入黑名单或恢复正常
     */
    handleWebsocketBlacklistedUser(data) {
        try {
            const { currentResStringID, groupStringID } = this.state;
            const groupId = _.get(data, 'group_string_id', '')
            const resStringIdArr = _.get(data, 'value', [])
            let isBlackUser = false
            // 该用户是否为所属集团下被禁用或解禁的餐馆
            if (groupStringID === groupId && _.isArray(resStringIdArr)) isBlackUser = resStringIdArr.includes(currentResStringID)
            if (isBlackUser) {
                if (_.get(data, 'type', '') === 'BLOCKED_USERS_CREATE_EVENT') {
                    Notification.notification(i18n.t('general_text_you_have_been_banned_by_this_restaurant'))
                } else if (_.get(data, 'type', '') === 'BLOCKED_USERS_RELIEVE_EVENT') {
                    Notification.notification(i18n.t('general_text_unbanned'))
                }
            }
        } catch (error) {

        }
    }

    /**
     * 关闭停业弹窗
     */
    onCloseDialog() {
        this.setState({
            isVisibleClosedInfo: false,
            resClosedTime: 0
        })
    }
    // 获取餐馆信息
    async getRestaurantsApi() {
        try {
            let stringId: string | null = this.getQueryString('string_id')
            if (stringId) {
                stringId = stringId.toUpperCase();
                const cacheAccount: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                // 餐馆信息
                const RestaurantInfo = await apiManage.getRestaurantInfo(stringId, { notNeedLoading: true }, cacheAccount._id);
                // 获取餐馆 配送区域
                const settinglevelDelivery: { levelDelivery: LevelDeliveryFee[] } = await apiManage.get_restaurant_level_delivery(stringId);
                if (_.get(RestaurantInfo, 'error')) {
                    // 标识后台返回的错误
                    const errorTip = errorCode.filter(item => (item.code === _.get(RestaurantInfo, 'error.code')))
                    Notification.notification(i18n.t(errorTip[0].message));
                    this.setState({
                        isShowOrder: 0
                    })
                    return
                }
                if (RestaurantInfo.dbResults && RestaurantInfo.dbResults[0]) {
                    const RestaurantInfoData = RestaurantInfo.dbResults[0];
                    // $ 设置最新的餐馆配送区域
                    RestaurantInfoData.setting_level_delivery = settinglevelDelivery?.levelDelivery || [];
                    // $ 更新餐馆缓存数据
                    const cacheRestaurants: { [key: string]: { menuDish: Dish, restaurant: Restaurant } } = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
                    _.set(cacheRestaurants, `${stringId}.restaurant`, RestaurantInfoData);
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.RESTAURANTS,
                        value: cacheRestaurants
                    });
                    // $ 获取餐馆布局和主题色
                    const { restaurantTheme, restaurantThemeColor } = await getRestaurantTheme(RestaurantInfoData.string_id || '', _.get(RestaurantInfoData,
                        'settings_layout', []), true)
                    // $ 获取餐馆是否开启'免注册下单模式'
                    const loginFreeMode = _.get(RestaurantInfoData,
                        'setting_registration_free_mode_enable', false);
                    // $ 是否在临时停业时间内
                    // 餐馆临时停业开关
                    const temporaryClosureStatus = _.get(RestaurantInfoData,
                        'setting_temporarily_closed_enable', false);
                    // 餐馆临时停业开始时间
                    const temporaryClosureStartTime = _.get(RestaurantInfoData,
                        'setting_temporarily_closed_start_data', '');
                    // 餐馆临时停业结束时间
                    const temporaryClosureEndTime = _.get(RestaurantInfoData,
                        'setting_temporarily_closed_end_data', '');
                    // 餐馆临时停业结束时间
                    const restaurantsTimeZoneName = _.get(RestaurantInfoData,
                        'time_zone', momentTimeZone.tz.guess());
                    // 是否顾客是否转跳到 WhatsApp app
                    const whatsAppEnable = _.get(RestaurantInfoData,
                        'setting_whats_app_enable', false);
                    // 商家 WhatsApp app 手机前缀
                    const whatsAppPhonePrefix = _.get(RestaurantInfoData,
                        'setting_whats_app_phone_prefix', '');
                    // 商家 WhatsApp app 手机号
                    const whatsAppPhone = _.get(RestaurantInfoData,
                        'setting_whats_app_phone', '');
                    // WhatsApp聊天网站
                    const totalPhone = whatsAppPhonePrefix + whatsAppPhone;
                    const whatsAppURL = `https://${(isMobileOrTablet() ? 'api' : 'web')}.whatsapp.com/send?phone=${totalPhone}`;
                    this.setState({
                        RestaurantTheme: restaurantTheme,
                        RestaurantThemeColor: restaurantThemeColor,
                        isShowOrder: 1,
                        loginFreeMode,
                        restaurantsTimeZoneName,
                        whatsAppEnable,
                        whatsAppURL
                    }, () => {
                        const data = {
                            id: stringId,
                            newValue: {
                                setting_temporarily_closed_start_data: temporaryClosureStartTime,
                                setting_temporarily_closed_end_data: temporaryClosureEndTime,
                                setting_temporarily_closed_enable: temporaryClosureStatus
                            }
                        }
                        // 临时停业弹窗
                        this.handelWebsocketFunRestaurant(data)
                        // // 是否在临时停业时间内
                        // this.setTemporaryClosure(temporaryClosureStatus, temporaryClosureStartTime, temporaryClosureEndTime)
                    })
                }
            }
        } catch (error) {
            this.setState({
                isShowOrder: 0
            })
        }
    }

    /**
     * 获取所有优惠劵
     */

    public async getCoupons() {
        const stringId = this.getQueryString('string_id') || '';
        try {
            const coupons = await apiManage.getRestaurantCouponPackage(stringId.toUpperCase(), { notNeedLoading: true });

            if (coupons.error) {
                Notification.notification(i18n.t('delivery_address_page_get_coupons_info_failed'));
            } else {
                this.setState({
                    coupons
                })
            }

        } catch (error) {
        }
    }

    // 获取菜单信息
    async getRestaurant() {
        // 餐馆菜单信息
        try {
            let stringId: string | null = this.getQueryString('string_id');
            if (stringId) {
                stringId = stringId.toUpperCase();
                let restaurantsMag = {}
                // ! 特色菜暂时禁用
                const Dishparams = { stringId, pr_dish_pic: false, special_dish_status: false, special_dish: false, pr_is_hidden: false }
                const menuDish = await apiManage.getMenuDish(Dishparams, { notNeedLoading: true });

                if (_.get(menuDish, 'error')) {
                    // 标识后台返回的错误
                    const errorTip = errorCode.filter(item => (item.code === _.get(menuDish, 'error.code')));
                    Notification.notification(i18n.t(errorTip[0].message));
                } else if (menuDish.dbResults) {
                    // 给特色菜菜单赋予_id  -specialMenu
                    _.set(_.find(menuDish.dbResults, ['name', 'special_dish']) || {}, '_id', '-specialMenu')
                    const cacheRestaurants = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
                    restaurantsMag = { ...cacheRestaurants }
                    // ! 过滤掉没有_id菜单
                    const newMenuDishDbResults = _.filter(menuDish.dbResults, (n) => { return n._id });
                    const restaurantInfo: Restaurant = _.get(restaurantsMag, `${stringId}.restaurant`);

                    const oldmenuDishInfo = _.get(restaurantsMag, `${stringId}.menuDish`) || [];
                    // _.set(restaurantsMag, `${stringId}.restaurant`, restaurantInfo);
                    _.set(restaurantsMag, `${stringId}.menuDish`, newMenuDishDbResults)

                    this.setState({
                        oldmenuDishInfo
                    }, async () => {
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.RESTAURANTS,
                            value: restaurantsMag
                        });
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID,
                            value: stringId
                        });
                        // 保存当前集团stringID
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.CURRENT_GROUP_STRING_ID,
                            value: restaurantInfo.belonged_group
                        });
                        await this.getStoreData(restaurantsMag, stringId);
                    })

                }
            }
        } catch (err) {
        }
    }

    /**
     * 过滤时间表菜单
     */
    getScheduleMenu(menu: Menu[], schedule: Schedule[], date?: string) {
        const nowTime = moment(date).format('YYYY-MM-DD HH:mm:ss');
        const cloneMenu = _.cloneDeep(menu).map(item => {
            if (item.canceled_schedule_time_ranges) {
                const result = getMenuDishAvailableSchedule(item.canceled_schedule_time_ranges, schedule, nowTime, this.orderType || '1');
                item.unavailableTime = !result.available;
                item.dishs = item.dishs?.map((dishItem) => {
                    dishItem.availableSchedule = result.schedule;
                    dishItem.unavailableTime = !result.available;
                    return dishItem
                }) || []
            } else {
                item.unavailableTime = false;
            }
            return item;
        });

        return cloneMenu
    }

    // 获取到store里面的数据
    async getStoreData(restaurantsMag, stringId) {
        try {
            // 获取到订单配送 最低订单价格
            let minOrderPrice;
            // 获取到订单自取 最高订单价格
            // let maxOrderPrice;
            // 定义一个共用字段orderPrice
            let OrderPrice;
            // 获取餐馆名字
            let nickname;
            // 获取集团id
            let groupStringID;
            // 直接从缓存里面拿数据(获取到餐厅信息)
            const cacheRestaurants = restaurantsMag || {};
            const currentResStringID = stringId;
            const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
            const orderway = this.getQueryString('orderway');
            let menuDish: MenuFixed[] = []
            if (Object.keys(cacheRestaurants).length > 0) {
                let restaurant = cacheRestaurants[`${currentResStringID}`].restaurant
                // 如果没有restaurant的信息，重新获取
                if (!restaurant) {
                    // 餐馆信息
                    const restaurantInfo = await apiManage.getRestaurantInfo(currentResStringID, { notNeedLoading: true });
                    if (restaurantInfo && !_.get(restaurantInfo, 'error')) {
                        // 存储用户餐馆信息
                        const menuDishInfo = _.get(cacheRestaurants, `${currentResStringID}.menuDish`) || [];
                        _.set(cacheRestaurants, `${currentResStringID}.menuDish`, menuDishInfo);
                        _.set(cacheRestaurants, `${currentResStringID}.restaurant`, restaurantInfo.dbResults[0]);
                        restaurant = restaurantInfo.dbResults[0];
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.RESTAURANTS,
                            value: cacheRestaurants
                        });
                    }
                }
                // 获取到餐馆电话号码
                const restaurantsPhone = restaurant.tel_prefix && restaurant.tel_prefix.length > 0
                    ? `+${restaurant.tel_prefix} ${restaurant.tel}`
                    : ``;
                minOrderPrice = restaurant.setting_order_delivery_minimum_fee;
                // maxOrderPrice = restaurant.setting_order_selftake_maximum_fee;
                nickname = restaurant.nickname;
                menuDish = cacheRestaurants[`${currentResStringID}`].menuDish;
                menuDish = _.isArray(menuDish) ? menuDish : [];

                groupStringID = restaurant.belonged_group;
                // 获取集团id 判断是否为集团下餐馆的黑名单

                //  $ 需要判断餐馆的版本号是否是1.2之后。1.2之后才有圆环
                const currentVersion = _.get(restaurant, 'version') || '0';
                const isUpdate = checkVersion(currentVersion, '1.2.0');
                if (isUpdate) {
                    const cacheDeliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE) || {};
                    const cacheValue = cacheDeliveryFee.delivery_minimum_fee;
                    minOrderPrice = (cacheValue || cacheValue === 0) ? cacheValue : minOrderPrice;
                }
                // $ orderway为 自取 时， OrderPrice 为 0，
                OrderPrice = orderway === '1' ? 0 : orderway === '2' ? minOrderPrice : 0;
                // 过滤不符合配送方式的菜品
                menuDish = menuDish.map((menuDishItem) => {
                    if (menuDishItem && typeof _.head(menuDishItem.dishs) === 'object') {
                        menuDishItem.dishs = _.filter(menuDishItem.dishs, (dishsItem) => {
                            /**
                             * delivery_type支持配送类型
                             * 0: 外送
                             * 1: 自取
                             * 2: 外送+自取
                             * 3: 堂食
                             * 4：堂食+自取
                             * 5：堂食+外送
                             * 6：堂食+自取+外送
                             * undefind: 堂食+自取+外送
                             * 默认为全支持
                             */
                            if (!dishsItem.delivery_type) {
                                return true;
                            }
                            // 外送
                            else if (['0', '2', '5', '6'].includes(dishsItem.delivery_type) && orderway === '2') {
                                return true;
                            }
                            // 自取
                            else if (['1', '2', '4', '6'].includes(dishsItem.delivery_type) && orderway === '1') {
                                return true;
                            } else {
                                return false;
                            }
                        })
                    }
                    return menuDishItem;
                })
                // 解决重复菜品id
                menuDish = menuDish.map((series) => {
                    if (series && typeof _.head(series.dishs) === 'object') {
                        if (Array.isArray(series.dishs)) {
                            series.dishs.map((dishitem) => {
                                dishitem.firstId = `${dishitem._id}${series._id}`;
                                dishitem.realpics = dishitem.realpics || undefined;
                                dishitem.menuId = series._id || '';
                                dishitem.menuName = series.name || '';
                                return dishitem
                            })
                        }
                    }
                    return series
                })

                // 处理可用时间菜单
                menuDish = this.getScheduleMenu(menuDish, restaurant?.setting_app_takeaway_service_schedule || [], moment(getFulfillmentTime())?.format('YYYY-MM-DD HH:mm'));
                this.handleGetOrderMenuData(menuDish)
                this.setState({
                    restaurantsPhone,
                    originSchedules: restaurant?.setting_app_takeaway_service_schedule || []
                })
            }
            // 拿到当前餐馆的购物车信息
            let shopCar: ShopCar;
            if (cacheShopCar[currentResStringID]) {
                shopCar = _.cloneDeep(cacheShopCar[currentResStringID] || []);
                // $ 后台更新菜馆菜品后，对应餐馆已添加购物车菜品的更新
                let newShopCarList: Dish[] = [];
                if (_.isArray(shopCar.shopCarList)) {
                    shopCar.shopCarList.forEach((shopCaritem) => {
                        // 对应的菜单
                        const targetMenu = _.find(menuDish, (n) => { return shopCaritem.menuId === n._id })
                        if (targetMenu) {
                            // 对应菜单的对应菜品
                            const targetDish = _.find(targetMenu.dishs || [], (m) => { return shopCaritem.firstId === m.firstId })
                            if (targetDish) {
                                // 保留状态正常的菜品(包括 套餐、包餐内有售罄不符合规则)
                                if (targetDish.status === '0') {
                                    // 普通菜品
                                    if (shopCaritem.dish_type === '0') {
                                        newShopCarList.push({
                                            ...targetDish, buyNumber: shopCaritem.buyNumber,
                                            remark: shopCaritem.remark,
                                            realpics: shopCaritem.realpics
                                        })
                                    }
                                    // 套餐或包餐
                                    else if (shopCaritem.dish_type === '1' || shopCaritem.dish_type === '2') {
                                        newShopCarList.push({ ...shopCaritem, availableSchedule: targetDish.availableSchedule })
                                    }
                                }
                            }
                        }
                    })
                    newShopCarList = _.compact(newShopCarList)
                }
                shopCar = analysisCart({ shopCarList: newShopCarList })
            } else {
                shopCar = {
                    shopCarList: [],
                    totalPrice: 0,
                    totalPoints: 0
                }
            }
            this.getCartDishPic(currentResStringID, shopCar, cacheShopCar,);

            this.setState({
                saveShopCatDate: shopCar.shopCarList || [],
                totalShopPrice: shopCar.totalPrice || 0,
                totalShopPoints: shopCar.totalPoints || 0,
                nickname,
                currentResStringID,
                groupStringID,
                OrderPrice
            })
            // 更新缓存购物车数据
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                value: {
                    ...cacheShopCar,
                    [currentResStringID]: {
                        ...shopCar
                    }
                }
            });
        } catch (err) {
        }
    }

    /**
     * 处理请求的数据
     * @param result 菜品所有数量
     * @param status 是否持久化操作
     */
    handleGetOrderMenuData(dbResults: MenuFixed[]) {
        // 一级菜单数据
        const newfirstLevelMenu: Array<SlideTypeObj> = []
        // 过滤无菜品的菜单
        dbResults = dbResults.filter((item) => { return item.dishs && item.dishs.length > 0 && !_.isArray(item.dishs[0]) });
        dbResults.map(item => {
            if (item.dishs && item.dishs.length > 0 && !_.isArray(item.dishs[0])) {
                // 一级菜单
                newfirstLevelMenu.push({
                    title: item.name!,
                    id: item._id!
                })
                item.dishs.forEach(dishItem => {
                    // 菜单列表的套餐、包餐，是否可选
                    if (dishItem.status === '0' && dishItem.dish_type !== '0' && checkSetDishOrMeal(dishItem, [])?.soldout) {
                        dishItem.status = '2';
                    }
                });
            }
            return item
        })
        // 更新数据
        this.setState({
            slideData: newfirstLevelMenu,
            dishListData: dbResults,
            originDishListData: dbResults,
            allAllergen: ALLERGEN,
            allIngredients: INGREDIENTS,
            menuSearchValue: newfirstLevelMenu?.[0]?.id
        }, async () => {
            if (newfirstLevelMenu.length > 0) {
                // 初始化滚动插件
                this.getHeightList();
            }
        })
    }
    /**
     * 购物车菜品图片补充
     * @param shopCar
     */
    public async getCartDishPic(stringId: string, shopCar: ShopCar, cacheShopCar: { [key: string]: ShopCar }) {
        // $ 购物车菜品图片 没有图片的菜品
        let dishIds: string[] = [];
        const shopCarList = shopCar?.shopCarList;
        shopCarList?.forEach((item) => {
            if (!item.realpics && item._id) {
                dishIds.push(item._id);
            }
        });
        dishIds = [...new Set(dishIds)];
        if (dishIds?.length) {
            // 拆成20张图为一组，并发请求
            const request = _.chunk(dishIds, 20)?.map((ids) => {
                return apiManage.getSetDishImg(stringId, ids, { notNeedLoading: true },)
            });
            if (request && request.length) {
                const dishImgRes = await Promise.all(request);
                dishImgRes.forEach((item) => {
                    if (item && !_.get(item, 'error')) {
                        item?.forEach((dishItem) => {
                            shopCarList?.forEach((listItem) => {
                                if (!listItem.realpics && listItem._id) {
                                    listItem.realpics = [dishItem.picUrl];
                                }
                            });
                        })
                    }
                })
            }
            this.setState({
                saveShopCatDate: shopCarList || [],
                totalShopPrice: shopCar.totalPrice || 0,
                totalShopPoints: shopCar.totalPoints || 0,
            })
            // 更新缓存购物车数据
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                value: {
                    ...cacheShopCar,
                    [stringId]: {
                        shopCarList: shopCarList,
                        totalPrice: shopCar.totalPrice,
                        totalShopPoints: shopCar.totalPoints || 0,
                    }
                }
            });
        }
    }
    /**
     * 获取URL问号后的参数值
     * @param name 传递过来的参数
     */
    public getQueryString(name) {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        const r = createHashHistory() && createHashHistory().location.search.substring(1).match(reg);
        if (r != null) {
            return decodeURI(r[2]);
        }
        return null;
    }


    /**
     * 更新购物车数据
     * @param updateItem 点击的商品项数据
     * @param status 为true表示加法  为false表示减法
     * @param saveShopCatDate 保存购物车数组
     */
    public updateBuyData(updateItem: Dish, status: boolean, saveShopCatDate: Array<Dish>) {
        const currentResStringID = this.state.currentResStringID;

        // 将加号的数据 保存起来  buyNumber表示数量
        const originSaveShopCatDate = _.cloneDeep(saveShopCatDate);
        let shopCatList = _.cloneDeep(saveShopCatDate);

        if (status) {
            // 执行加号操作
            if (shopCatList.length === 0) {
                // 没有数据的时候
                shopCatList.push({
                    ...updateItem,
                    buyNumber: 1
                })
            } else {
                // 判断之前是否点击过 购买数据量的变动 找到下单搜索
                const buyObj = shopCatList.find(item => (updateItem.firstId === item.firstId) && item.buyNumber);
                if (_.isObject(buyObj) && buyObj.buyNumber) {
                    // 单个菜品数量不得超于99
                    if (buyObj.buyNumber < 99) {
                        buyObj.buyNumber++;
                    } else {
                        Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
                    }
                } else {
                    shopCatList.push({
                        ...updateItem,
                        buyNumber: 1
                    })

                }
            }
        } else {
            // 减号操作 (又数量-1 当数量为0的时候过滤掉)
            shopCatList = shopCatList.map(item => {
                if (updateItem.firstId === item.firstId && item.buyNumber) {
                    item.buyNumber--;
                }
                return item
            }).filter(item => item.buyNumber !== 0)
        }

        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        // 是否已登录， token
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        let cart = analysisCart({ shopCarList: shopCatList });
        const pass = PM.updatePoints({ key: 'dishUsedPoints', value: cart.totalPoints || 0 }, !token);
        if (!pass) {
            // 加法操作
            if (status) {
                cart = analysisCart({ shopCarList: originSaveShopCatDate });
            }
            Notification.notification(`${i18n.t('order_page_text_not_enough_for_points')}`);
        }
        // 更新数据
        this.setState({
            // 购物车没有数据，如果显示购物车那么改变为隐藏
            isShopCatShow: shopCatList.length > 0 ? this.state.isShopCatShow : false,
            // isShowTipBox: totalPrice ? true : false,
            saveShopCatDate: cart.shopCarList || [],
            totalShopPrice: cart.totalPrice || 0,
            totalShopPoints: cart.totalPoints || 0,
        })
        // 更新缓存购物车数据
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.SHOP_CAR,
            value: {
                ...cacheShopCar,
                [currentResStringID]: cart
            }
        });
    }

    /**
     * 选择套餐/包餐
     */
    public choosePackageMeal(itemData: Dish) {
        const firstId = `${itemData._id || ''}${itemData.menuId || ''}`
        // 获取到保存购买数量的对应数据
        let buyNumber = 0
        this.state.saveShopCatDate.forEach(i => {
            if (firstId === i.firstId) {
                buyNumber += i.buyNumber ?? 0
            }
        })
        // 菜品最大可选择99
        if (buyNumber >= 99) {
            Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
            return;
        }
        this.setState({
            isShowPackageMeal: itemData.dish_type === '1' ? 1 : 2,
            isSearchShow: false,
            dishesDetail: itemData,
            dishType: itemData.dish_type ?? '',
            dishId: itemData._id || '',
            setMenuId: itemData.menuId ?? '',
            menuName: itemData.menuName ?? ''
        })
    }

    /**
     * 删除历史记录
     * @param index
     */
    public handleHistory(index: number) {
        const copyHistoryList = _.cloneDeep(this.state.historyList);
        copyHistoryList.splice(index, 1);
        this.setState({
            historyList: copyHistoryList
        })
    }

    /**
     * 处理搜索
     * @param inputValue inpit框的值
     */
    public async handleSearch(inputValue: string, status?: boolean) {
        if (inputValue !== '') {
            try {
                // 对筛选后的数据进行过滤
                let dishList = _.cloneDeep(this.state.dishListData);
                dishList = dishList.filter(i => { return i.dishs && i.dishs.length !== 0 })
                // 获取到过滤后的数据
                let filterList = dishList.map(item => {
                    const filterDate = item.dishs && item.dishs.map(i => {
                        const regular = new RegExp(inputValue, 'gi')
                        if (regular.test(i.name ? i.name : '')) {
                            return i
                        }
                        return {}
                    }).filter(i => i !== undefined).filter(i => { return Object.keys(i).length !== 0 })
                    if (item.dishs) {
                        item.dishs = filterDate
                    }
                    return item
                })
                filterList = filterList.filter(i => { return i.dishs && i.dishs.length !== 0 })

                // 深度copy 头部添加（顺序） 去重
                let copyHistoryList = _.cloneDeep(this.state.historyList);
                copyHistoryList.unshift(inputValue);
                copyHistoryList = Array.from(new Set(copyHistoryList));

                // 设置没有数据的时候提示框 先获取到dishs字段  打平  过滤掉{} 判断是否为空数组
                const isShowNoDateTip =
                    filterList.map(item => (item.dishs)).flat()
                        .filter(iitem => { return !iitem || (iitem && Object.keys(iitem).length > 0) }).length > 0
                        ? true : false

                // 更新数据
                this.setState({
                    historyList: copyHistoryList,
                    filterList,
                    showSearchList: true,
                    isShowNoDateTip
                })
                if (!status) {
                    // 没有图片的菜单系列ID组
                    let menuItemIds: Array<string> = [];
                    filterList.map((item) => {
                        if (item.dishs) {
                            item.dishs.map((iitem) => {
                                if (!iitem.realpics && item._id) {
                                    menuItemIds.push(item._id)
                                }
                                return iitem
                            })
                        }
                        return item
                    })
                    menuItemIds = Array.from(new Set(menuItemIds));
                    if (menuItemIds && menuItemIds.length > 0) {
                        await this.getPicAndUpdateDishList(menuItemIds, inputValue)
                    }
                }
            } catch (err) {
            }
        } else {
            this.setState({
                showSearchList: false
            })
        }
    }

    /**
     * 获取菜品图片并且更新菜单
     * menuItemIds 需要更新的菜单id数组
     */
    public async getPicAndUpdateDishList(menuItemIds: Array<string>, inputValue: string) {
        try {
            let dishList = this.state.dishListData;
            let stringId: string = this.getQueryString('string_id') || ''

            if (stringId) {
                stringId = stringId.toUpperCase();
                // 获取当前选中的菜单分类的菜品
                if (menuItemIds) {
                    menuItemIds.map(async (item) => {
                        let dishPics: DishPic[] = []
                        const Dishparams = { stringId, menus: [item] }
                        if (item === '-specialMenu') {
                            const res = await apiManage.getMenuSpecialDishPic(Dishparams, { notNeedLoading: true });
                            const newExample: Array<{ pic: string, _id: string }> = [];
                            if (res && _.isArray(res)) {
                                res.map((v) => {
                                    newExample.push({ pic: v.picUrl, _id: v._id });
                                    return v
                                })
                                dishPics = [{ dishs: newExample, _id: item }]
                            }
                        } else {
                            const res: DishPic[] = await apiManage.getMenuDishPics(Dishparams, { notNeedLoading: true });
                            if (res && !_.get(res, 'error')) {
                                dishPics = res
                            }
                        }
                        dishList = dishList.map((dishListitem) => {
                            dishPics.map((dishPicsiitem) => {
                                if (dishListitem._id === dishPicsiitem._id) {
                                    if (dishListitem.dishs) {
                                        dishListitem.dishs.map((dishsItem) => {
                                            dishPicsiitem.dishs.map((dishPicsItem) => {
                                                if (dishsItem._id === dishPicsItem._id) {
                                                    dishsItem.realpics = [dishPicsItem.pic]
                                                }
                                                return dishPicsItem
                                            })
                                            return dishsItem
                                        })
                                    }
                                }
                                return dishPicsiitem
                            })
                            return dishListitem
                        })
                        // 更新购物车的图片
                        const { saveShopCatDate } = this.state
                        const newCatDate = saveShopCatDate.map((catDateItem) => {
                            dishPics.map((dishPicsitem) => {
                                if (dishPicsitem._id === catDateItem.menuId) {
                                    if (dishPicsitem.dishs) {
                                        dishPicsitem.dishs.map((iitem) => {
                                            if ((iitem._id === catDateItem._id) && !catDateItem.realpics) {
                                                catDateItem.realpics = [iitem.pic]
                                            }
                                            return iitem
                                        })
                                    }
                                }
                                return dishPicsitem
                            })
                            return catDateItem
                        })
                        // 更新点击大图、套餐详情的图片
                        this.updateDishesDetailPic(dishPics);
                        this.setState({
                            saveShopCatDate: newCatDate,
                            originDishListData: dishList || this.state.originDishListData,
                            dishListData: dishList || this.state.originDishListData
                        }, () => {
                            this.state.filterList?.length > 0 && this.handleSearch(inputValue, true)
                        })
                    })
                }
            }
        } catch (error) {
            return false
        }
    }


    /**
     * 关闭购物车
     */
    public handleCloseCatShow() {
        // 更新数据
        this.setState({
            isShopCatShow: false,
            isShowTipBox: false
        })
    }

    /**
     * 打开购物车
     */
    public handleShopCat() {
        // 打开结算托盘
        const { temporaryClosureStatus } = this.state
        // 临时停业
        if (temporaryClosureStatus) {
            Notification.notification(i18n.t('request_failure_text_restaurant_temporarily_business'))
            return
        }
        if (this.state.saveShopCatDate.length > 0) {
            this.setState({
                checkoutDrawerVisible: true
            })
        }
    }

    /**
     * 关闭结算托盘(重新获取缓存的购物车)
     */
    public closeCheckoutComponent() {
        this.setState({ checkoutDrawerVisible: false })
        this.updateOrderPageState();
    }

    /**
     * 结算托盘更新点餐页State
     */
    public updateOrderPageState(action?: { fulfillmentTime: Date }) {
        if (action?.fulfillmentTime) {
            // 更改履约时间，更新菜单菜品可售状态
            const menuDish = this.getScheduleMenu(this.state.dishListData, this.state.originSchedules, moment(action?.fulfillmentTime)?.format('YYYY-MM-DD HH:mm'))
            this.setState({ dishListData: menuDish, originDishListData: menuDish });
        }
        // $ 重新获取缓存的购物车
        const { currentResStringID } = this.state;
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        let shopCar;
        if (cacheShopCar[currentResStringID]) {
            shopCar = _.cloneDeep(cacheShopCar[currentResStringID]);
            // 后台更新菜馆菜品后，对应餐馆已添加购物车菜品的更新

            const cart = analysisCart({ shopCarList: shopCar.shopCarList })
            this.setState({
                saveShopCatDate: cart.shopCarList || [],
                totalShopPrice: cart.totalPrice || 0,
                totalShopPoints: cart.totalPoints || 0
            });
        }
        // $ 更新起送价
        const cacheDeliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE) || {};
        const minOrderPrice = cacheDeliveryFee.delivery_minimum_fee || 0;
        // orderway为 自取 时， OrderPrice 为 0，
        const orderway = this.getQueryString('orderway');
        const OrderPrice = orderway === '1' ? 0 : orderway === '2' ? minOrderPrice : 0;
        this.setState({ OrderPrice });


    }

    /**
     * 一键清除购物车
     */
    public handleClearShopCat() {
        const currentResStringID = this.state.currentResStringID;
        this.setState({
            saveShopCatDate: [],
            isShopCatShow: false,
            totalShopPrice: 0,
            totalShopPoints: 0,
            isShowTipBox: false
        })

        // 更新缓存购物车数据
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.SHOP_CAR,
            value: {
                ...cacheShopCar,
                [currentResStringID]: {
                    shopCarList: [],
                    totalPrice: 0,
                    totalPoints: 0
                }
            }
        });
    }
    public onClose() {
        this.setState({
            isShowTipBox: false
        })
    }
    /**
     * 显示菜品的备注弹窗
     * @param item 菜品单条数据
     */
    public onshowRemarksDialog(item?: Dish) {
        this.setState({
            remarksDialogvisible: true,
            remarkItem: item!,
            isShowTipBox: false
        })
    }
    /**
     * 显示菜品详细图
     * @param item 菜品单条数据
     */
    public onshowDishDetails(item?: Dish) {
        this.setState({
            isshowDishDetails: true,
            dishesDetail: item!
        })
    }
    /**
     * 设置道菜的备注
     * @param value 输入的文本
     * @param isCheck 检查是否是添加备注
     * @param saveShopCatDate 获取在购物车的菜品数据，便于插入或更新字段值
     */
    public onCheackRemarks(value: string, isCheck: boolean, saveShopCatDate: Array<Dish>) {
        const { remarkItem, currentResStringID } = this.state;
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        const totalPrice = _.get(cacheShopCar[currentResStringID], 'totalPrice');
        const totalPoints = _.get(cacheShopCar[currentResStringID], 'totalPoints');
        const shopCatList = _.cloneDeep(saveShopCatDate);
        if (isCheck) {
            const buyObj = shopCatList.find(item => (remarkItem.firstId === item.firstId));
            if (_.isObject(buyObj) && buyObj.buyNumber) {
                buyObj.remark = value;
            }
            // 更新数据
            this.setState({
                saveShopCatDate: shopCatList,
                totalShopPrice: totalPrice,
                totalShopPoints: totalPoints,
                remarksDialogvisible: false
            })

            // 更新缓存购物车数据
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                value: {
                    ...cacheShopCar,
                    [currentResStringID]: {
                        ...cacheShopCar[currentResStringID],
                        shopCarList: shopCatList,
                    }
                }
            });
        } else {
            this.setState({
                remarksDialogvisible: false
            })
        }
    }

    // 一级菜单事件
    public async handleFirstLevel(id: string) {
        try {
            const { dishListData, originDishListData } = this.state
            const selectedId = id;
            const dishList = dishListData;
            let origindishList = _.cloneDeep(originDishListData);
            // 选中一级菜单时用菜品过滤器过滤的数据，去找出对应的id的菜品。没有选中则用菜品过滤器过滤的全部菜单菜品
            let filterdishList = selectedId.length > 0 ? dishList.filter(item => selectedId === item._id) : dishList;
            // 取消上一个点击的请求
            // this.cancel?.();
            if (selectedId.length === 0) {
                if (filterdishList.length === 0 || !_.has(filterdishList[0].dishs, `0._id`)) {
                    // 当没有一级菜单没有数据的时候
                    filterdishList = [];
                }
            } else {
                // 更新菜品的图片
                let stringId: string = this.getQueryString('string_id') || ''
                if (stringId) {
                    stringId = stringId.toUpperCase();
                    if (!_.get(filterdishList[0].dishs, `0.realpics`)) {
                        try {
                            // 每次请求前都先重置axios.CancelToken
                            // this.cancelToken = new axios.CancelToken(c => {
                            //     this.cancel = c;
                            // });
                            const Dishparams = { stringId, menus: [selectedId] };
                            let dishPics: DishPic[] = [];
                            if (selectedId === '-specialMenu') {
                                const res = await apiManage.getMenuSpecialDishPic(Dishparams, { notNeedLoading: true }, this.cancelToken);
                                if (_.get(res, 'error', false)) return
                                const newExample: Array<{ pic: string, _id: string }> = [];
                                if (res && _.isArray(res)) {
                                    res.map((v) => {
                                        newExample.push({ pic: v.picUrl, _id: v._id })
                                        return v
                                    })
                                    dishPics = [{ dishs: newExample, _id: selectedId }]
                                }
                            } else {
                                dishPics = await apiManage.getMenuDishPics(Dishparams, { notNeedLoading: true }, this.cancelToken);
                                if (_.get(dishPics, 'error', false)) return
                            }
                            if (_.isArray(dishPics) && dishPics.length > 0) {
                                // 更新选中菜单分类的数组
                                filterdishList = filterdishList.map((v) => {
                                    dishPics.map((iitem) => {
                                        if (v._id === iitem._id) {
                                            if (v.dishs) {
                                                v.dishs.map((dishsItem) => {
                                                    iitem.dishs.map((dishPicsItem) => {
                                                        if (dishsItem._id === dishPicsItem._id) {
                                                            dishsItem.realpics = [dishPicsItem.pic]
                                                        }
                                                        return dishPicsItem
                                                    })
                                                    return dishsItem
                                                })
                                            }
                                        }
                                        return iitem
                                    })
                                    return v
                                })
                                // 更新原始菜单的数组
                                origindishList = origindishList.map((v) => {
                                    dishPics.map((iitem) => {
                                        if (v._id === iitem._id) {
                                            if (v.dishs) {
                                                v.dishs.map((dishsItem) => {
                                                    dishPics[0].dishs.map((dishPicsItem) => {
                                                        if (dishsItem._id === dishPicsItem._id) {
                                                            dishsItem.realpics = [dishPicsItem.pic]
                                                        }
                                                        return dishPicsItem
                                                    })
                                                    return dishsItem
                                                })
                                            }
                                        }
                                        return iitem
                                    })
                                    return v
                                })
                                // 更新点击大图、套餐详情的图片
                                this.updateDishesDetailPic(dishPics);
                            }
                        } catch (err) { }
                    }
                }
                // 直接给state赋值，不使用setState更新导致卡顿，在外层有更新视图
                this.state.clickFirstdishListData = filterdishList;
                this.state.originDishListData = origindishList;
            }
        } catch (error) { }
    }

    // 过滤器
    public onSelect(item: 'Allergen' | 'Ingredients' | string, type: 'Allergen' | 'Ingredients' | 'clear') {
        const { selectFilterLabeldAllergen, selectFilterLabeldIngredients } = this.state;
        // selectFilterLabeldAllergen:过敏原
        // selectFilterLabeldIngredients:配料
        let selectArr: string[] = [];
        let pendingSelectArr: string[] = [];
        if (type === 'clear') {
            pendingSelectArr = item === 'Allergen' ? selectFilterLabeldIngredients : selectFilterLabeldAllergen;
        } else {
            selectArr = type === 'Allergen' ? selectFilterLabeldAllergen : selectFilterLabeldIngredients;
            pendingSelectArr = type === 'Allergen' ? selectFilterLabeldIngredients : selectFilterLabeldAllergen;
            if (selectArr.includes(item)) {
                _.remove(selectArr, (v) => { return item === v; });
            } else {
                selectArr.push(item);
            }
        }
        const newDishListData = this.state.dishListData;
        const allSelectArr = [...selectArr, ...pendingSelectArr];
        newDishListData.map((dishSeries) => {
            if (_.has(dishSeries.dishs, `0.firstId`)) {
                (dishSeries.dishs || []).forEach((dishsItem) => {
                    // 设置初始状态
                    dishsItem.filter_status = false;
                    const allergenAndIngredients = (dishsItem.allergen || []).concat(dishsItem.ingredients || [])
                    if (_.difference(allergenAndIngredients, allSelectArr).length !== allergenAndIngredients.length) {
                        dishsItem.filter_status = true;
                    } else {
                        // 套餐、包餐
                        if (dishsItem.dish_type !== '0' && checkSetDishOrMeal(dishsItem, allSelectArr)?.filterStatus) {
                            dishsItem.filter_status = true;
                        }
                    }
                })
            }
            return dishSeries
        })

        this.setState({
            dishListData: newDishListData,
            selectFilterLabeldAllergen: type === 'clear' && item === 'Allergen' ? [] : type === 'Allergen' ? selectArr : this.state.selectFilterLabeldAllergen,
            selectFilterLabeldIngredients: type === 'clear' && item === 'Ingredients' ? [] : type === 'Ingredients' ? selectArr : this.state.selectFilterLabeldIngredients,
            filterTagsNumber: allSelectArr.length
        });
    }
    /**
     * 过滤器-判断是否选中
     * @param val 选中的值
     * @param type 区分选中的类型 Allergen | Ingredients
     */
    public isSelected(val: string, type: string) {
        let result: boolean = false
        if (type === 'Allergen') {
            for (const value of this.state.selectFilterLabeldAllergen) {
                if (value === val) {
                    result = true;
                }
            }
        } else if (type === 'Ingredients') {
            for (const value of this.state.selectFilterLabeldIngredients) {
                if (value === val) {
                    result = true;
                }
            }
        }
        return result;
    }

    handleClearCartModal(state: boolean) {
        this.setState({
            isVisibleClearCartModal: state
        })
    }

    /**
     * 关闭菜品大图详情
     * @param shopCar 有添加菜品进入购物车
     */
    onhandleClose = (shopCar?: ShopCar) => {
        const currentResStringID = this.state.currentResStringID;
        this.setState({
            isshowDishDetails: false
        })
        if (shopCar) {
            this.setState({
                saveShopCatDate: shopCar.shopCarList || [],
                totalShopPrice: shopCar.totalPrice || 0,
                totalShopPoints: shopCar.totalPoints || 0
            })
            const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                value: {
                    ...cacheShopCar,
                    [currentResStringID]: shopCar
                }
            });
        }

    }

    /**
     * 获取菜品分类title的高度,并初始化滚动插件
     */
    getHeightList() {
        const newlistHeight: Array<number> = [];
        let height = 0;
        newlistHeight.push(height);
        const wrapper: Element | null = document.querySelector('#main_container_dish_list');
        const innerWidth = window.innerWidth;
        this.state.dishListData?.forEach((item) => {
            height += (item?.dishs?.length || 0) * (300 / 1080 * innerWidth) + 42;
            newlistHeight.push(Number(height.toFixed(2)));
        })

        this.setState({
            listHeight: newlistHeight,
            dishListClientHeight: wrapper?.clientHeight || 0
        }, () => {
            // 滚到最左
            this.changeMenuValue(0);
            // 初始化
            this.initBetterScroll();
            // 滚到最顶部
            this.scrollToDishElement(0);
            // 加载第一可视区域的菜品图片
            this.getCurrentIndex(0);
        })
    }

    /**
     * 设置滚动插件初始化
     */
    initBetterScroll() {
        // 使用插件解决滑动问题
        try {
            // const wrapper: any = document.querySelector('#main_container_dish_list')
            // this.scroll = new BScroll(wrapper, {
            //     scrollY: true,
            //     mouseWheel: true,
            //     click: true,
            //     probeType: 3,
            //     // useTransition: false,
            //     HWCompositing: false
            // })
            // // 滚动结束之后，获取滚动的值
            // this.scroll.on('scroll', pos => {
            //     this.changeMenuValue(Math.abs(Math.round(pos.y)));
            // });
            // // 滚动结束之后，获取滚动的值
            // this.scroll.on('scrollEnd', pos => {
            //     this.getCurrentIndex(Math.abs(Math.round(pos.y)));
            // });

            // 一级菜单 滑动
            const slideWrapper: Element | null = document.querySelector('#main_container_slide');
            if (slideWrapper) {
                this.SlideScroll = new BScroll(slideWrapper, {
                    scrollX: true,
                    scrollY: false,
                    mouseWheel: true,
                    click: true
                })
            }
        } catch (error) { }
    }

    /**
     * 改变选中菜单
     */
    changeMenuValue(scrollY: number) {
        try {
            let scrollIndex: number;
            const { listHeight } = this.state;
            scrollIndex = _.sortedIndex(listHeight, scrollY) - 1;
            scrollIndex = scrollIndex < 0 ? 0 : scrollIndex;
            // if (this.scrollIndex === scrollIndex) {
            //     return
            // }
            // this.scrollIndex = scrollIndex;
            const wrapper: Element | null = document.querySelector('#main_container_slide');
            const contentList = wrapper?.getElementsByClassName('slideChip');
            if (contentList) {
                // scrollToElement 需要HTMLElement，这里获取到的是Element数组
                const el: any = contentList[scrollIndex];
                for (let i = 0; i < contentList.length; i++) {
                    if (contentList[i]) {
                        const item = contentList[i];
                        if (i === scrollIndex) {
                            item.classList.add('activeBg');
                        } else {
                            item.classList.remove('activeBg');
                        }
                    }
                }
                this.SlideScroll?.scrollToElement(el, 300, -20);
            }
        } catch (error) { }
    }

    /**
     * 滑动菜品，自动选中一级菜单
     */
    @debounce(1000)
    async getCurrentIndex(scrollY: number) {
        try {
            if (this.state.requesting) return
            let scrollIndex: number;
            const viewableAreaIndex: number[] = [];
            const { listHeight, slideData, dishListClientHeight } = this.state;
            // 可视范围，看到的所有菜品title
            listHeight.forEach((height, hIndex) => {
                if ((height > scrollY) && (height < (dishListClientHeight + scrollY))) {
                    viewableAreaIndex.push(hIndex);
                }
            })
            scrollIndex = _.sortedIndex(listHeight, scrollY) - 1;
            scrollIndex = scrollIndex < 0 ? 0 : scrollIndex;
            viewableAreaIndex.unshift(scrollIndex);
            this.state.requesting = true
            // this.setState({
            //     requesting: true
            // }, async () => {
            // 可视区域的菜品获取图片

            for (const item in viewableAreaIndex) {
                if (slideData[viewableAreaIndex[item]]?.id) {
                    await this.handleFirstLevel(slideData[viewableAreaIndex[item]].id);
                    continue;
                }
            }
            // this.state.requesting = false

            this.setState({ requesting: false })
            // });
        } catch (error) { }
    }

    /**
     * 聚焦菜品列表的对应title
     * @param index
     */
    scrollToDishElement(index: number) {
        try {
            // const wrapper: Element | null = document.querySelector('#main_container_dish_list');
            // const contentList = wrapper?.getElementsByClassName('DishList');
            // if (contentList) {
            //     // scrollToElement 需要HTMLElement，这里获取到的是Element数组
            //     const el: any = contentList[index];
            //     // scrollToElement：滚动到目标元素
            //     this.scroll?.scrollToElement(el, 300, 0, 5);
            // }

            this.menuListRef?.recomputeGridSize();
            this.menuListRef?.scrollToPosition(this.state.listHeight[index || 0] + 3);
        } catch (error) { }
    }

    /**
     * 是否在临时停业时间内
     * @param temporaryClosureStatus 餐馆临时停业开关
     * @param temporaryClosureStartTime 餐馆临时停业开始时间
     * @param temporaryClosureEndTime 餐馆临时停业结束时间
     */
    setTemporaryClosure(temporaryClosureStatus: boolean, temporaryClosureStartTime: Date, temporaryClosureEndTime: Date) {
        try {
            if (this.countdownInterval) clearInterval(this.countdownInterval);
            // 是否在临时停业时间内
            if (temporaryClosureStatus && temporaryClosureStartTime && temporaryClosureEndTime) {
                // $ 将本地当前时间转化为餐馆时区的时间
                let currentTime = moment(new Date()).format(moment.defaultFormatUtc);
                currentTime = convertDateByTimeZoneName(currentTime, momentTimeZone.tz.guess(), this.state.restaurantsTimeZoneName)
                // 现在时间于结束时间的差（结束时间-现在时间）
                const countdownValue = moment(temporaryClosureEndTime).diff(currentTime, 'seconds');
                if (countdownValue > 0) {
                    this.setState({
                        countdownValue,
                        temporaryClosureStatus: true
                    }, () => {
                        this.startCountdown();
                    })
                } else {
                    this.setState({
                        temporaryClosureStatus: false
                    })
                }
            } else {
                this.setState({
                    temporaryClosureStatus: false
                })
            }
        } catch (error) {
            this.setState({
                temporaryClosureStatus: false
            })
        }
    }
    /**
     * 临时停业开始倒计时
     * @param initValue 倒计时的初始值（秒）
     */
    startCountdown() {
        try {
            if (this.state.temporaryClosureStatus) {
                if (this.countdownInterval) clearInterval(this.countdownInterval);
                this.countdownInterval = setInterval(() => {
                    let countdownValue = this.state.countdownValue;
                    countdownValue = countdownValue - 1;
                    // 秒转成时分秒
                    const countdownText = moment('00:00:00', 'HH:mm:ss').add(countdownValue, 's').format('HH:mm:ss')
                    this.setState({ countdownText, countdownValue });
                    if (countdownValue <= 0) {
                        if (this.countdownInterval) clearInterval(this.countdownInterval);
                        this.setState({
                            temporaryClosureStatus: false
                        })
                        // 餐厅当前时间是否临时停业
                        LocalStorageManager.setLocalStorage({
                            'path': LOCAL_STORAGE_KEYS.CURRENT_CLOSED_STATUS,
                            'value': false
                        })
                    }
                }, 1000);
            } else {
                if (this.countdownInterval) clearInterval(this.countdownInterval);
                // 餐厅当前时间是否临时停业
                LocalStorageManager.setLocalStorage({
                    'path': LOCAL_STORAGE_KEYS.CURRENT_CLOSED_STATUS,
                    'value': false
                })
            }
        } catch (error) { }
    }
    /**
     * 更新点击大图、套餐详情的图片
     */
    updateDishesDetailPic(dishPics: DishPic[]) {
        const { dishesDetail } = this.state;
        if (dishesDetail) {
            const pic = dishPics.find((item) => item._id === dishesDetail.menuId)?.dishs?.find((item) => item._id === dishesDetail._id)?.pic
            if (pic) {
                this.setState({ dishesDetail: { ...dishesDetail, realpics: [pic] } })
            }
        }
    }

    /**
     * 结算托盘，点击编辑掏套餐、包餐
     */
    editSetMeal(checkoutDetailCartDish: CheckoutDetailCartDish) {
        const dishItem = checkoutDetailCartDish.dish
        this.setState({
            isShowPackageMeal: dishItem.dish_type === '1' ? 1 : 2,
            isSearchShow: false,
            dishesDetail: dishItem,
            dishType: dishItem.dish_type ?? '',
            dishId: dishItem._id || '',
            setMenuId: dishItem.menuId ?? '',
            menuName: dishItem.menuName ?? '',
            isEditSetMeal: true
        })

    }

    /**
     * 拍平菜单
     */
    flatMenu() {
        const menu = this.state.dishListData;
        return _.flatten((menu || []).map((n) => { return [{ title_name: n.name || '' }, ...(n.dishs || [])] }));
    }
    /**
     * 列表滚动时触发
     */
    onScroll(position: ScrollParams) {
        const scrollTop = Math.abs(Math.round(position.scrollTop + 3))
        this.changeMenuValue(scrollTop);
        this.getCurrentIndex(scrollTop);
    }
    // *********************
    // View
    // *********************
    render() {
        const {
            mainHeaderData, slideData, dishListData, OrderPrice,
            restaurantsPhone, saveShopCatDate, showFilterDialog, isSearchShow,
            historyList, totalShopPrice, isShopCatShow, touchTop, filterList,
            showSearchList, isShowSidebar, isShowNoDateTip, nickname, remarksDialogvisible,
            remarkItem, allAllergen, allIngredients, isshowDishDetails, dishesDetail,
            showleastPriceAnimation, RestaurantTheme, isShowOrder, countdownText,
            temporaryClosureStatus, isShowPackageMeal, dishType, dishId, checkoutDrawerVisible,
            isVisibleClosedInfo, coupons, menuName, whatsAppEnable, whatsAppURL, setMenuId,
            isEditSetMeal, selectFilterLabeldAllergen, selectFilterLabeldIngredients, totalShopPoints, isOpenSchedulesModel, dishSchedules
        } = this.state
        // const ItemColor = RestaurantThemeColor === 'FIRST_THEME_COLOR' ? 'gold' : 'unknown';
        const merchantWebsite = _.get(Store.getState(), 'merchantWebsite')
        return (
            <div className='orderPage' style={{
                background: '#fff'
            }}>
                {isShowOrder === 0 || isShowOrder === 1 ?
                    !isshowDishDetails && <TopBar
                        closedTime={countdownText}
                        isShowClosed={temporaryClosureStatus}
                        isHideMenu={false}
                        title={nickname}
                        isGoBack={() => history.push(`/restaurantPage?string_id=${this.getQueryString('string_id')}${merchantWebsite ? '&merchant_website=true' : ''}`)}
                    /> : null}

                {isShowOrder === 0 || isShowOrder === 1 ?
                    !isshowDishDetails && coupons.length > 0 && <div className={'coupons-com'} >
                        <div className='coupons'>
                            <div className='coupons-container' onClick={() => { this.setState({ isVisibleClosedInfo: true }) }}>
                                {
                                    (coupons || []).map((item: Coupon, index: number) => (
                                        <div key={`${index}-coupon`} className='coupon-area' >
                                            <LocalOffer className='icon' />
                                            <div className='text-point' />
                                            <Typography className='text' variant='subtitle1'>
                                                {item.name}
                                            </Typography>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div> : null}
                {/* 订餐主页 */}
                <div className={`order_main_container ${coupons.length > 0 ? 'showCouponHeight' : ''} `}
                    style={{
                        visibility: isShowOrder === 1 ? 'visible' : 'hidden',
                        transform: `translate3d(0px, ${touchTop}px, 0px)`
                    }}>
                    <div className='main_container_header'>
                        <OrderHeader
                            orderHeaderData={mainHeaderData}
                            handleRightBtn={() => {
                                this.setState({
                                    showFilterDialog: !showFilterDialog
                                })
                            }}
                            handleLeftBtn={() => {
                                this.setState({
                                    isSearchShow: !isSearchShow
                                })
                            }} />
                    </div>
                    {/* 这里更换主题背景样式时需要改相应的背景色 否则默认白色背景 */}
                    <div className='main_container_slide' id='main_container_slide'  >
                        <OrderSlide slideData={slideData}
                            handleChangeSlideIndex={(id) => {
                                const scrollIndex = _.findIndex(dishListData, (item) => { return item._id === id; });
                                this.scrollToDishElement(scrollIndex);
                            }}
                            RestaurantTheme={RestaurantTheme} />
                    </div>
                    <div id='main_container_dish_list'
                        className={`main_container_dish_list  `}>
                        {/* 菜品列表 */}
                        {dishListData.length ? <MenuList
                            ref={(refs) => this.menuListRef = refs}
                            dishList={this.flatMenu() as DishFixed[]}
                            updateShopCatList={(updateItem, status) => {
                                this.updateBuyData(updateItem, status, saveShopCatDate)
                            }}
                            saveShopCatDate={saveShopCatDate}
                            onshowDishDetails={(dishes) => this.onshowDishDetails(dishes)}
                            choosePackageMeal={(itemData: Dish) => this.choosePackageMeal(itemData)}
                            onScroll={this.onScroll}
                            // orderInfo={orderInfo}
                            orderType={this.orderType || '1'}
                            openSchedulesModel={(dishSchedules) => {
                                this.setState({ isOpenSchedulesModel: true, dishSchedules })
                            }}
                        /> : null}
                        {/* 暂无数据 */}
                        {(dishListData.length === 0 &&
                            <div className='no_related_date'>
                                {i18n.t('general_text_no_relevant_data')}
                            </div>)}
                        {/* <div className='all_dish_contrainer'>
                            {
                                dishListData.length > 0 && dishListData.map(item => {
                                    const dishes = item.dishs
                                    return (
                                        item.dishs && item.dishs.length > 0 && !_.isArray(item.dishs[0]) ? (
                                            <DishList
                                                key={item._id}
                                                dishListData={dishes}
                                                dishTitle={`${item.name}`}
                                                updateShopCatList={(updateItem, status) => {
                                                    this.updateBuyData(updateItem, status, saveShopCatDate)
                                                }}
                                                saveShopCatDate={saveShopCatDate}
                                                isRemarksDialog={false}
                                                onshowDishDetails={(dishes) => this.onshowDishDetails(dishes)}
                                                RestaurantTheme={RestaurantTheme || ''}
                                                choosePackageMeal={(itemData: Dish) => this.choosePackageMeal(itemData)}
                                            />
                                        ) : null
                                    )
                                })
                            }
                            {
                                (dishListData.length === 0 &&
                                    <div className='no_related_date'>
                                        {i18n.t('general_text_no_relevant_data')}
                                    </div>)
                            }
                        </div> */}
                    </div>
                </div>

                {/* 这里改变了 底部电话和购物车 */}
                {isShowOrder === 1 ? !isshowDishDetails && !isShowPackageMeal && <div className={'main_container_flex_footer'}>
                    <OrderFloatDialog
                        totalShopPrice={totalShopPrice}
                        totalShopPoints={totalShopPoints}
                        saveShopCatDate={saveShopCatDate}
                        OrderPrice={OrderPrice}
                        isShopCatShow={isShopCatShow}
                        handlePhoneCall={() => {
                            if (whatsAppEnable) {
                                window.open(whatsAppURL)
                            } else {
                                window.location.href = 'tel:' + restaurantsPhone;
                            }
                        }}
                        handleShopCat={this.handleShopCat.bind(this)}
                        orderway={this.getQueryString('orderway')}
                        showleastPriceAnimation={showleastPriceAnimation}
                        temporaryClosureStatus={temporaryClosureStatus}
                        whatsAppEnable={whatsAppEnable}
                    />
                </div> : ''}

                {/* 筛选弹窗 */}
                {
                    <Dialog
                        open={showFilterDialog}
                        onClose={() => {
                            this.setState({
                                showFilterDialog: false
                            })
                        }}
                        className='orderFilterDialog'
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                    >
                        <DialogContent>
                            <div className='dialogContent'>
                                <div className='dialogName'>
                                    <div className='fontStyle'>
                                        {i18n.t('restaurant_order_page_text_dishes_filter')}
                                        <div className='close' onClick={() => {
                                            this.setState({
                                                showFilterDialog: false
                                            })
                                        }}>
                                            <ClearIcon className='clearIcon' />
                                        </div>
                                    </div>

                                </div>
                                <div className='clear-filter-box'>
                                    <span className='title'>
                                        {i18n.t('restaurant_order_page_text_allergen')}
                                    </span>
                                    <span className='clear-filter'
                                        onClick={() => this.onSelect('Allergen', 'clear')}>
                                        {i18n.t('general_text_clear_all')}
                                    </span>
                                </div>
                                <div className='subtitle'>
                                    <span>
                                        {i18n.t('restaurant_order_page_text_hide_dishes_selected_allergen')}
                                    </span>
                                </div>
                                <div className='filter'>
                                    <div className='filter-content'>
                                        {
                                            allAllergen.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='filter-labeld'
                                                        onClick={() => this.onSelect(item.key, 'Allergen')}
                                                        style={this.isSelected(item.key, 'Allergen') ? {
                                                            borderColor: '#5867B4',
                                                            color: '#5867B4',
                                                            backgroundColor: '#5867b426'
                                                        } : {}}
                                                    >{i18n.t(item.text)}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='clear-filter-box second-clear-filter-box'>
                                    <span className='title'>
                                        {i18n.t('restaurant_order_page_text_other_dish_marks')}
                                    </span>
                                    <span className='clear-filter'
                                        onClick={() => this.onSelect('Ingredients', 'clear')}>
                                        {i18n.t('general_text_clear_all')}
                                    </span>
                                </div>
                                <div className='subtitle'>
                                    <span>
                                        {i18n.t('restaurant_order_page_text_hide_dishes_with_selected_types')}
                                    </span>
                                </div>
                                <div className='filter'>
                                    <div className='filter-content'>
                                        {
                                            allIngredients.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='filter-labeld'
                                                        onClick={() => this.onSelect(item.key, 'Ingredients')}
                                                        style={this.isSelected(item.key, 'Ingredients') ? {
                                                            borderColor: '#5867B4',
                                                            color: '#5867B4',
                                                            backgroundColor: '#5867b426'
                                                        } : {}}
                                                    >{i18n.t(item.text)}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
                {/* 搜索弹窗 */}
                {
                    isSearchShow ? (
                        <div className='order_search_container' style={{
                            visibility: isshowDishDetails ? 'hidden' : 'visible'
                        }}>
                            <OrderSearch historyList={historyList}
                                saveShopCatDate={saveShopCatDate}
                                showSearchList={showSearchList}
                                filterList={filterList}
                                handleRemoveHistory={(index) => {
                                    this.handleHistory(index)
                                }}
                                handleSearchInput={
                                    (inputValue) => {
                                        this.handleSearch(inputValue)
                                    }
                                }
                                handleCloseSearch={() => {
                                    this.setState({
                                        isSearchShow: !isSearchShow,
                                        filterList: []
                                    })
                                }} updateShopCatList={(updateItem, status) => {
                                    this.updateBuyData(updateItem, status, saveShopCatDate)
                                }}
                                choosePackageMeal={(itemData: Dish) => this.choosePackageMeal(itemData)}
                                isShowNoDateTip={isShowNoDateTip}
                                onshowDishDetails={(dishes) => this.onshowDishDetails(dishes)}
                                RestaurantTheme={RestaurantTheme}
                                openSchedulesModel={(dishSchedules) => {
                                    this.setState({ isOpenSchedulesModel: true, dishSchedules })
                                }}
                            />
                        </div>
                    ) : null
                }
                {
                    (remarksDialogvisible ?
                        <RemarksDialog className='RemarksDialog'
                            open={remarksDialogvisible}
                            handleClose={() => this.setState({ remarksDialogvisible: false })}
                            onbutton={(value, ischeck) => this.onCheackRemarks(value, ischeck, saveShopCatDate)}
                            inputValue={remarkItem.remark ? remarkItem.remark : ''}
                            title={remarkItem.name}
                        /> : null)
                }
                {/* 侧边栏 */}
                {
                    isShowSidebar ? <SidebarComponet /> : null
                }
                {/* 菜品详情 */}
                {
                    isshowDishDetails ?
                        <DishDetail
                            onhandleClose={(shopCar) => this.onhandleClose(shopCar)}
                            dishesDetail={dishesDetail}
                            isCollectiveData={false}
                            openPackageMeal={(itemData: Dish) => { this.choosePackageMeal(itemData) }}
                        />
                        : null
                }
                {/* 包餐 */}
                {
                    isShowPackageMeal === 1 ?
                        <OrderPackageMeal
                            menuName={menuName}
                            menuSearchValue={setMenuId}
                            dishId={dishId}
                            dishType={dishType}
                            saveShopCatDate={saveShopCatDate}
                            stringId={this.getQueryString('string_id')?.toUpperCase() || ''}
                            updateOrderState={(value) => { this.setState(value) }}
                            goBack={() => { this.setState({ isShowPackageMeal: 0, isEditSetMeal: false }) }}
                            isEdit={isEditSetMeal}
                            details={dishesDetail}
                            selectFilterLabeldAllergen={selectFilterLabeldAllergen}
                            selectFilterLabeldIngredients={selectFilterLabeldIngredients}
                        /> : null
                }
                {/* 套餐 */}
                {isShowPackageMeal === 2 ?
                    <SetDishDetailItem
                        details={dishesDetail}
                        stringId={this.getQueryString('string_id')?.toUpperCase() || ''}
                        updateOrderState={(value) => { this.setState(value); }}
                        goBack={() => { this.setState({ isShowPackageMeal: 0, isEditSetMeal: false }); }}
                        isEdit={isEditSetMeal}
                        selectFilterLabeldAllergen={selectFilterLabeldAllergen}
                        selectFilterLabeldIngredients={selectFilterLabeldIngredients}
                    /> : null}
                {/* 结算托盘 */}
                <Checkout
                    visible={checkoutDrawerVisible}
                    closeCheckoutComponent={() => this.closeCheckoutComponent()}
                    updateOrderPageState={(action) => { this.updateOrderPageState(action) }}
                    componentType='DELIVERY'
                    editSetMeal={(checkoutDetailCartDish) => this.editSetMeal(checkoutDetailCartDish)}
                    handleClearCartModal={(state: boolean) => { this.handleClearCartModal(state) }}
                />
                {/* 停业弹窗显示 */}
                {
                    isVisibleClosedInfo ?
                        <SwipeableTemporaryClosedDialog
                            showPage={this.state.countdownText ? ['TemporaryClosed', 'coupon'] : ['coupon']}
                            visible={isVisibleClosedInfo}
                            countdownText={this.state.countdownText}
                            coupons={coupons}
                            onClose={() => {
                                this.onCloseDialog()
                            }}
                        /> : null
                }
                {/* 是否清空购物车弹窗显示 */}
                <ConfirmClearCartModal
                    isVisibleClearCartModal={this.state.isVisibleClearCartModal}
                    handleClearCartModal={(b: boolean) => this.handleClearCartModal(b)}
                    currentResStringID={this.state.currentResStringID}
                    restaurantTheme={RestaurantTheme}
                    updateOrderPageState={() => { this.updateOrderPageState() }}
                />
                {/* 菜品可用时间表 */}
                <ScheduleDialog
                    componentType='dish'
                    serviceAllBtn={true}
                    className='scheduleDialog'
                    isPickUp={this.orderType === '1'}
                    scheduledata={dishSchedules}
                    open={isOpenSchedulesModel}
                    handleClose={() => this.setState({ isOpenSchedulesModel: false })} />
            </div>
        )
    }
}

export default OrderPage;


// *********************
// Types
// *********************


type Props = {
};

type State = {
    mainHeaderData: MainHeaderData;
    slideData: Array<SlideTypeObj>;
    menuSearchValue: string;
    totalShopPrice: number;
    totalShopPoints: number,
    originDishListData: Array<MenuFixed>;
    dishListData: Array<MenuFixed>;
    clickFirstdishListData: Array<MenuFixed>,
    saveShopCatDate: Array<Dish>;
    allAllergen: Array<{ key: string, text: string }>,
    allIngredients: Array<{ key: string, text: string }>,
    selectFilterLabeldAllergen: Array<string>,
    selectFilterLabeldIngredients: Array<string>,
    filterTagsNumber: number,
    showFilterDialog: boolean;
    isSearchShow: boolean;
    historyList: Array<string>;
    isShopCatShow: boolean;
    touchTop: number;
    throttleFlag: boolean;
    startClientY: number;
    filterList: Array<MenuFixed>;
    showSearchList: boolean;
    isShowSidebar: boolean;
    isShowNoDateTip: boolean;
    OrderPrice: number;
    nickname: string
    restaurantsPhone: string;
    currentResStringID: string;
    groupStringID: string;
    remarksDialogvisible: boolean;
    remarkItem: Dish;
    tipsDialogvisible: boolean;
    isshowDishDetails: boolean;
    dishesDetail: Dish;
    isShowTipBox: boolean;
    showleastPriceAnimation: boolean;
    oldmenuDishInfo: Array<Dish>;
    RestaurantTheme: 'FIRST_THEME' | 'SECOND_THEME' | 'THIRD_THEME';
    RestaurantThemeColor: 'FIRST_THEME_COLOR' | 'SECOND_THEME_COLOR' | 'THIRD_THEME_COLOR';
    isShowOrder: 0 | 1 | 2;
    loginFreeMode: boolean;
    temporaryClosureStatus: boolean;
    countdownText: string;
    countdownValue: number;
    restaurantsTimeZoneName: string;
    // 0:不显示 1:包餐  2:套餐
    isShowPackageMeal: 0 | 1 | 2;
    dishType: string;
    dishId: string;
    setMenuId: string;
    checkoutDrawerVisible: boolean;
    // 停业弹窗
    isVisibleClosedInfo: boolean;
    // 停业时间
    resClosedTime: number;
    start_time: Date;
    end_time: Date;
    coupons: Array<Coupon>;
    menuName: string;
    whatsAppEnable: boolean;
    whatsAppURL: string;
    listHeight: Array<number>;
    dishListClientHeight: number;
    requesting: boolean;
    isEditSetMeal: boolean;
    isVisibleClearCartModal: boolean;
    isOpenSchedulesModel: boolean;
    dishSchedules: Schedule[];
    originSchedules: Schedule[];
};


export type SlideTypeObj = {
    id: string
    title: string
    select?: boolean
}

export type DishesFileterItem = {
    [key: string]: Array<SlideTypeObj>
}

export type MainHeaderData = {
    headerClassName: string
    leftIco: (className?: string) => JSX.Element
    rightIco: (className?: string) => JSX.Element
    renderFunction?: (className?: string) => JSX.Element
    title?: string
}
type DishPic = {
    dishs: { pic: string, _id: string }[],
    _id: string
}