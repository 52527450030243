import React from 'react';
import { Button } from '@material-ui/core';
import apiManage from '../request';
import i18n from '../i18n';
import { showApiMessage } from '../messenger/messageManager';
import { IS_PHONE } from '../constants/base.constant';
import _ from 'lodash';
import Store from '../rudex/store';

import ReCaptChaDialog from './recaptcha-dialog/recaptcha-dialog';
import Notification from '../component/snackbar/snackbar';
import { GOOGLE_ROBOT_SCORE } from '../gconfig';
import { FeatGoogleReCaptcha } from '../page/sign-in/sign-in.page';

export default function ButtonComponent(props: {
    className: string;
    value: string;
    onClick?: () => void;
    phone?: string;
    phone_prefix?: string;
    type?: string;
    getVcodeType?: string;
    onSendSuccess?: () => void;
    disabled?: boolean;
}) {
    const CHECK = /^\d{5,15}$/;
    const [text, setText] = React.useState(props.value);
    // 谷歌人机校验获取token
    const [showGoogleReCaptcha, onShowGoogleReCaptcha] = React.useState(false);
    // 谷歌人机校验图像弹窗
    const [showReCaptChaDialog, onShowReCaptChaDialog] = React.useState(false);
    // 是否通过人机校验
    const [isPassReCaptcha, onIsPassReCaptcha] = React.useState(false);
    // 倒计时
    let leftSecond = 90;
    let time: NodeJS.Timeout;
    React.useEffect(() => {
        setText(props.value)
    }, [props.value])

    /**
     * 发送验证码
     */
    async function timer() {
        try {
            // 发送验证码类型
            let codeType = '';
            if (props.type === 'sign-in' || props.type === 'sign-up' || props.type === 'autoComplete') {
                // 登录/注册
                codeType = 'INIT_BY_PHONE'
                if (!(props.phone && IS_PHONE(props.phone_prefix || '', props.phone))) {
                    return
                }
            } else if (props.type === 'resetPassword') {
                // 修改密码
                codeType = 'RESET_PASSWORD_BY_PHONE'
            } else if (props.type === 'bindPhone') {
                // 绑定手机
                codeType = 'BIND_PHONE'
            } else if (props.type === 'bindEMail') {
                // 绑定邮箱
                codeType = 'BIND_EMAIL'
            }
            const postInfo = {
                account_type: 'CUSTOMER',
                type: codeType,
                phone_prefix: `${props.phone_prefix}`,
                phone: props.phone || ''
            }
            //  请求验证码
            const VCODE: any = await apiManage.post_admin_code(postInfo)
            if (VCODE.error) {
                showApiMessage(VCODE, 'vcode')
                return
            }
            if (typeof props.onSendSuccess === 'function') props.onSendSuccess()
            setText(i18n.t('signup_page_button_resend', { 1: leftSecond }));
            time = setInterval(() => {
                leftSecond = leftSecond - 1;
                setText(i18n.t('signup_page_button_resend', { 1: leftSecond }));
                if (leftSecond <= 0) {
                    if (time) clearInterval(time);
                    setText(i18n.t(`signup_page_text_send_vcode`));
                }
            }, 1000)
        } catch (error) {
            console.log('error', error)
        }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    function handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            onIsPassReCaptcha(true)
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        Notification.notification(i18n.t(message));
    }

    /**
     * 通过reCaptchaToken解析分值
     */
    async function checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        onShowReCaptChaDialog(true)
                    } else {
                        // 评分高，已验证不是机器人
                        onIsPassReCaptcha(true)
                        timer()
                    }
                }
            }
        } catch (error) { }
        // 关闭全局loading
        Store.dispatch({
            type: 'SET_STATE',
            path: 'globalLoading',
            value: false
        })
    }
    return (
        <div
            className={props.className}
        >
            {/* 谷歌人机校验获取token*/}
            {<FeatGoogleReCaptcha
                showGoogleReCaptcha={showGoogleReCaptcha}
                onVerify={async (token) => {
                    onShowGoogleReCaptcha(false);
                    checkReCaptchaToken(token);
                }} />}
            {/* 谷歌人机选择图像校验*/}
            {showReCaptChaDialog &&
                <ReCaptChaDialog
                    handleClose={() => { onShowReCaptChaDialog(false) }}
                    handelReCaptchaCallBack={(result) => { handelReCaptchaCallBack(result) }}
                />}
            <Button
                className='signin-button'
                disableRipple={true}
                disabled={props.disabled}
                onClick={async () => {
                    if (typeof props.onClick === 'function') {
                        props.onClick();
                    } else if (text === i18n.t('signup_page_text_send_vcode') && CHECK.test(props.phone || '')) {
                        // 是否通过校验
                        if (isPassReCaptcha) {
                            timer();
                        } else {
                            Store.dispatch({
                                type: 'SET_STATE',
                                path: 'globalLoading',
                                value: true
                            })
                            onShowGoogleReCaptcha(true);
                        }
                    }
                }}
            >
                {i18n.t(text)}
            </Button>

        </div >
    )

}