import React from 'react';
import './scss/checkout-payment-button.styles.scss';
import { ICheckoutOrderRemarks, ICheckoutOrderRemarksProps, ICheckoutOrderRemarksState } from '../../interfaces';
import i18n from '../../i18n';
import { Collapse } from '@material-ui/core';
import { IconHeadBox } from './checkout-icon-head-box';
import { MdAssignment, MdKeyboardArrowRight } from 'react-icons/md';
import './scss/checkout-order-remarks.styles.scss';
import { IoIosCloseCircle } from 'react-icons/io';

class CheckoutOrderRemarks extends React.Component<Props, State> implements ICheckoutOrderRemarks {

    // 多行文本框ref
    textareaRefs: HTMLTextAreaElement | null;

    constructor(props: Props) {
        super(props)
        this.textareaRefs = null;
        this.state.remarks = this.props.orderRemark;
    }

    state: State = {
        isShowCollapse: true,
        remarks: '',
        showActionBtn: false
    }

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        const { isShowCollapse, remarks, showActionBtn } = this.state;
        const { orderRemark, onRderRemarks } = this.props;
        return (
            <div className='checkoutOrderRemarks'>
                <IconHeadBox
                    iCon={() => <MdAssignment />}
                    title={i18n.t('restaurant_order_page_text_dish_remarks')}
                    clickHead={() => { if (!isShowCollapse) this.setState({ isShowCollapse: true, remarks: orderRemark }) }}
                    actionLabel={() => isShowCollapse ?
                        ((!orderRemark || showActionBtn) ?
                            <>
                                <IoIosCloseCircle className='close' onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ isShowCollapse: false, remarks: '', showActionBtn: false });
                                    onRderRemarks('');
                                }} />
                            </> : <></>)
                        : <MdKeyboardArrowRight onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ isShowCollapse: true, remarks: orderRemark })
                        }} />}
                // action={() => { }}
                />
                <Collapse in={isShowCollapse}>
                    <div className={'textareaBox'}>
                        <textarea
                            ref={(ref) => this.textareaRefs = ref}
                            style={{ height: this.textareaRefs?.scrollHeight || 0 + 10 + 'px' }}
                            value={remarks}
                            onChange={(evt) => {
                                this.setState({ remarks: evt.target.value }, () => {
                                    onRderRemarks(this.state.remarks.trim());
                                });
                            }}
                        >
                        </textarea>
                    </div>
                </Collapse>
            </div >
        )
    }
}

export default CheckoutOrderRemarks

// *********************
// Props & State
// *********************

interface State extends ICheckoutOrderRemarksState {
    isShowCollapse: boolean;
    remarks: string;
    showActionBtn: boolean;
}

interface Props extends ICheckoutOrderRemarksProps {
    onRderRemarks: (value: string) => void;
}