import _ from 'lodash';
import { Bag, OrderDish } from '../models';
import { OrderPackageMeal } from '../models/abstract/order-package-meal.entity';
import { OrderSetMeal } from '../models/abstract/order-set-meal.entity';

/**
 * 字典排序方法
 * @param a
 * @param b
 * @returns
 */
// 字典排序方法
export function strSort(a: string, b: string) {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();

    // 提取数字部分
    const regex = /^(\d+)/;
    const aMatch = nameA.match(regex) || [];
    const bMatch = nameB.match(regex) || [];

    const aNum = parseInt(aMatch[1], 10);
    const bNum = parseInt(bMatch[1], 10);

    // 去除非数字部分
    const aStr = nameA.replace(regex, '');
    const bStr = nameB.replace(regex, '');

    // 比较数字部分
    if (aNum < bNum) {
        return -1;
    } else if (aNum > bNum) {
        return 1;
    } else {
        // 数字相同，比较字典序
        if (aStr < bStr) {
            return -1;
        } else if (aStr > bStr) {
            return 1;
        } else {
            // 防止出现返回 0 的情况，导致排序不稳定
            return nameA.localeCompare(nameB);
        }
        // return nameA.localeCompare(nameB);
    }
}

/**
 * 套餐里的菜品字典排序
 * @param setMeal
 * @returns
 */
export function getSetMealSort(setMeal: OrderSetMeal) {
    // 排序套餐区域
    (setMeal.origin_set_meal_areas || []).sort((a: any, b: any) => {
        return strSort(a.origin_name, b.origin_name);
    });
    // 排序套餐区域里的菜品
    (setMeal.origin_set_meal_areas || []).forEach((area: any) => {
        (area.dishes || []).sort((a: any, b: any) => {
            return strSort(a.origin_name, b.origin_name);
        });
    })
    return setMeal;
}

/**
 * 字典排序订单菜品
 * @param bag
 * @returns
 */
export function getOrderAllDishSort(bag: Bag) {
    // 获取订单里的所有菜品
    const orderAllDish: Array<OrderDish | OrderPackageMeal | OrderSetMeal> = _.concat([], bag.dishs || [], bag.set_meals || [], bag.package_meals || []) as Array<OrderDish | OrderPackageMeal | OrderSetMeal>;
    // 先排序普通菜品，套餐，包餐的名字（不排序套餐包餐里的菜品）
    orderAllDish.sort((a: any, b: any) => {
        return strSort(a.origin_name, b.origin_name);
    });
    for (const dishItem of orderAllDish) {
        // 套餐
        if (dishItem.dish_type === '2') {
            let dish = dishItem as OrderSetMeal;
            dish = getSetMealSort(dish);
        }
        // 包餐
        if (dishItem.dish_type === '1') {
            const dish = dishItem as OrderPackageMeal;
            // 排序套餐区域
            (dish.origin_package_meal_areas || []).sort((a: any, b: any) => {
                return strSort(a.origin_name, b.origin_name);
            });
            // 排序包餐区域里的菜品
            (dish.origin_package_meal_areas || []).forEach((area: any) => {
                // 排序包餐区域里的套餐
                (area.set_meals).forEach((set_meal: any) => {
                    getSetMealSort(set_meal);
                });
                // 套餐区域里的普通菜品
                const areaAllDish = _.concat([], area.dishes || []);
                (areaAllDish).sort((a: any, b: any) => {
                    return strSort(a.origin_name, b.origin_name);
                });
                area.dishes = areaAllDish;
            })
        }
    }

    return orderAllDish;

}
