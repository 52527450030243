import React from 'react';
import { CheckCircle, Cancel, Check } from '@material-ui/icons';
import '../static/css/sign-up.scss';
import ButtonComponent from './button.component';
import { IS_VCODE } from '../constants/base.constant';
import i18n from '../i18n';
import { CircularProgress } from '@material-ui/core';

export default function VerificationComponent(props: {
    className: any;
    defaultValue: string;
    placeholderTxt?: string;
    onChange?: (val: string) => void;
    value?: string;
    phone_prefix?: string;
    type?: string;
    getVcodeType?: string;
    onSendSuccess?: () => void;
    vCodeLoading?: string;
    disabled?: boolean;
    reqError?: boolean;
    textColor?: 'black' | 'purple';
}) {
    const [isCode, setIsPhone] = React.useState(IS_VCODE.test(props.defaultValue));
    return (
        <div
            className={props.className}
        >
            <input
                style={props.textColor ? { color: props.textColor === 'black' ? '#333' : '#9892c0' } : { color: '#9892c0' }}
                className='signup-password-input'
                value={props.defaultValue}
                placeholder={props.placeholderTxt || i18n.t('reset_password_page_text_insert_vcode')}
                type='tel'
                pattern='[0-9]'
                maxLength={6}
                onChange={(val: any) => {
                    const EXP = /^[0-9]*$/;
                    if (EXP.test(val.target.value)) {
                        if (typeof props.onChange === 'function') props.onChange(val.target.value);
                        setIsPhone(IS_VCODE.test(val.target.value))
                    }
                }}
                disabled={props.disabled}
            />
            {props.type === 'autoComplete' ?
                (
                    (props.vCodeLoading === 'pending' ?
                        <div style={{ width: '15%' }}>
                            <CircularProgress style={{ width: '50%', height: '50%', color: '#ffdc33' }} />
                        </div>
                        : props.vCodeLoading === 'success' ?
                            <Check style={{
                                color: '#fff', right: 10, width: 20, height: 20, padding: 1,
                                backgroundColor: 'green', borderRadius: 50, marginRight: '1vw'
                            }} className='signin-label' />
                            : props.vCodeLoading === 'error' ?
                                <Cancel style={{
                                    color: 'red', width: 20, padding: 1, height: 20,
                                    marginRight: '1vw'
                                }} className='signin-label' />
                                : <div style={{ width: 20, opacity: 0, marginRight: '1vw' }}></div>)
                ) :
                (props.defaultValue.length > 0 ? (
                    isCode ?
                        // 当请求错误时-
                        !props.reqError ?
                            <CheckCircle
                                style={{
                                    color: 'green', width: '15%'
                                }}
                                className='signin-label'
                            /> :   // - 显示这个XX
                            <Cancel style={{ color: 'red', width: '15%', padding: 1 }} className='signin-label' /> :
                        <Cancel style={{ color: 'red', width: '15%' }} className='signin-label' />) :
                    <Cancel style={{ color: '#FFF', opacity: 0, fontSize: 18, width: '15%' }} />)
            }
            <ButtonComponent
                className='sign-up-button'
                value={i18n.t('signup_page_text_send_vcode')}
                phone={props.value}
                phone_prefix={props.phone_prefix}
                type={props.type}
                getVcodeType={props.getVcodeType}
                disabled={props.disabled}
                onSendSuccess={() => {
                    if (typeof props.onSendSuccess === 'function') props.onSendSuccess()
                }}
            />

        </div >
    )
}