
import React from 'react';
import './share-box.style.scss';
import i18n from '../../i18n';
import { MdClear } from 'react-icons/md';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton } from 'react-share';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';

export function ShareBox(
    props: {
        className?: string;
        onhandleClose: () => void;
        QRcodePic?: string;
    }
) {
    const { onhandleClose, className, QRcodePic } = props
    const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
    const currentResStringNickName = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_NICK_NAME);
    const origin = 'https://go.ordelivery.shop'
    const shareUrl = `${origin}/#/restaurantPage?string_id=${currentResStringID}`;
    const title = `${currentResStringNickName} ${i18n.t('general_text_share_Info')}`;
    return (
        <div className={`shareBoxBackgorund ${className || ''}`}
            onClick={(e) => {
                e.stopPropagation()
                onhandleClose()
            }}>
            <div className='shareContent' onClick={(e) => { e.stopPropagation() }}>
                <div className='QRpic QRpicbox'>
                    <img className='QRpic' src={QRcodePic} alt='' />
                </div>
                <div className='shareText'>
                    <p>{i18n.t('general_text_share')}</p>
                </div>
                <div>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className='shareItemPic'
                    >
                        <FacebookIcon />
                    </FacebookShareButton>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator={` `}
                        className='shareItemPic'
                    >
                        <WhatsappIcon />
                    </WhatsappShareButton>
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className='shareItemPic'
                    >
                        <svg viewBox="0 0 64 64" width="40" height="40" fill="#ffffff">
                            <rect width="64" height="64" rx="0" ry="0" fill="#000000"></rect>
                            <path
                                d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
                                fill="white"
                            ></path>
                        </svg>
                    </TwitterShareButton>
                </div>
                <div className='closeBox'>
                    <div className='closeButton' onClick={(e) => {
                        e.stopPropagation()
                        onhandleClose()
                    }}>
                        <MdClear className='colseicon' />
                    </div>
                </div>
            </div>
        </div>
    )
}


// // Regex expression to remove all characters which are NOT alphanumeric
// let number = mobileNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");

// // Appending the phone number to the URL
//   let url = `https://web.whatsapp.com/send?phone=${number}`;

// // Appending the message to the URL by encoding it
//   url += `&text=${encodeURI(message)}&app_absent=0`;
// Open our newly created URL in a new tab to send the message
//  window.open(url);

// maybe
// https://www.npmjs.com/package/react-whatsapp