import { Dish } from '../models';
import { PackageMealArea } from '../models/abstract/package-meal-area.entity';
import { PackageMeal } from '../models/abstract/package-meal.entity';
import { SetMealArea } from '../models/abstract/set-meal-area.entity';
import { SetMeal } from '../models/abstract/set-meal.entity';
import { v4 as uuidv4 } from 'uuid';

export interface ICollectiveDishPanel {

    state: ICollectiveDishPanelState;

    props: ICollectiveDishPanelProps;

    /**
     * 批量执行购物车修改事件
     *
     * 步骤：
     * - 批量执行修改事件
     * - 调用rebuildCollectiveArea()重新重置视图
     *
     * @param areaCloned 克隆的CollectiveItemArea对象
     * @param actions 购物车事件数组
     */
    excuteCartActions(
        areaCloned: CollectiveItemArea,
        actions: Array<CartActions>
    ): CollectiveItemArea;

    /**
     * 更新集合性商品区域视图
     *
     * 步骤：
     * - 根据购物篮id和区域id找到对应的区域对象
     * - 根据区域对象的规则和区域对象内部选择的菜品情况，更新区域和内部菜品的视图帮助性字段
     * - 返回更新后的区域对象
     *
     * 细节：
     * - 如果菜品数量为0，则减少按钮不用
     * - 如果达到区域菜品数量达到上限，则增加按钮不可用
     *
     * @param areaCloned 克隆的CollectiveItemArea对象
     * @param actions 购物车事件数组
     */
    rebuildCollectiveArea(
        areaCloned: CollectiveItemArea
    ): CollectiveItemArea;

    /**
     * 显示新增集合性商品视图
     *
     * 步骤：
     * - 显示视图
     *
     * @param areaCloned 克隆的父级区域对象
     * @param itemCloned 克隆的新增的集合性商品对象
     */
    showNewCollectiveItemView(
        areaCloned: CollectiveItemArea,
        itemCloned: CollectiveItem
    ): void;

    /**
     * 新增集合性商品
     *
     * 步骤：
     * - 在整体state添加菜品
     * - 在父级区域添加菜品
     *
     * @param areaCloned 克隆的父级区域对象
     * @param itemCloned 克隆的新增的集合性商品对象
     */
    // addNewCollectiveItem(
    //     areaCloned: CollectiveItemArea,
    //     itemCloned: CollectiveItem
    // ): CollectiveItemArea;

}

export interface ICollectiveDishPanelState {
    // 当前购物篮id
    currentBasketId: string;
    // 页面购物篮层级
    currentBasketLevel: number;
    // 当前购物篮的类型
    currentBasketType: ICollectiveDishPanelType;
    // 总购物篮
    basket: Array<ICollectiveDishPanelBasket>;
}

export interface ICollectiveDishPanelProps {

}

/**
 * SET - 套餐
 * BUFFET - 包餐
 */
export enum ICollectiveDishPanelType {
    SET_MEAL = 'SET_MEAL',
    PACKAGE_MEAL = 'PACKAGE_MEAL'
}

/**
 * 不同层级的包餐或套餐对象
 */
export interface ICollectiveDishPanelBasket {
    // 唯一标识符
    id: string;
    // 标识是包餐或套餐
    type: ICollectiveDishPanelType;
    // 当前所处层级
    level: number;
    // 内部的菜品
    item: CollectiveItem;
}

// tslint:disable-next-line: max-classes-per-file
export class BasketDish extends Dish {

    constructor() {
        super();
        this.id = uuidv4();
    }

    // 前端用唯一标识码
    public id?: string = '';
    // 数量
    public num: number = 0;
    // 是否是集合性商品
    public isICollectiveDish?: boolean = false;
    // 从属的购物篮的唯一标识码
    public belongedBasketId?: string;
    // $ 视图帮助性字段
    // 数量减少可用状态
    reduceButtonStatus?: boolean = false;
    // 数量增加可用状态
    addButtonStatus?: boolean = false;
    // 不同菜品的套餐或包餐id
    differentSetDishId?: string = uuidv4();
    // 集合性商品总价格
    CollectiveDishTotalPrice?: number
    // 是否前端克隆的集合性菜品
    isCloneDish?: boolean
    // 从属区域id
    belongedAreaId?: string;
}

// tslint:disable-next-line: max-classes-per-file
export class BasketSetMealArea extends SetMealArea {
    // 内部菜品
    public dishes: Array<BasketDish> = [];
    // $ 视图帮助性字段
    // 区域内菜品总数量
    public totalDishes: number = 0;
    // 区域内菜品总价格
    public totalDishesPrice: number = 0;
    // 是否此区域条件已符合
    public isAchievedCriteria: boolean = false;
}

// tslint:disable-next-line: max-classes-per-file
export class BasketSetMeal extends SetMeal {
    // 内部菜品区域
    public set_meal_areas?: Array<BasketSetMealArea> = [];
    // 所属的点餐页菜单
    menuName?: string;
    // 区分不同菜单的相同菜品ID
    firstId?: string
    // 加入到购物篮时的套餐唯一id(套餐)
    differentSetDishId?: string;
    // 菜品图片
    realpics?: string[]
}

// tslint:disable-next-line: max-classes-per-file
export class BasketPackageMealArea extends PackageMealArea {
    // 内部菜品
    public dishes: Array<BasketDish> = [];
    // 内部套餐
    public set_meals: Array<BasketDish & BasketSetMeal> = []
    // $ 视图帮助性字段
    // 区域内菜品总数量
    public totalDishes: number = 0;
    // 区域内菜品总价格
    public totalDishesPrice: number = 0;
    // 是否此区域条件已符合
    public isAchievedCriteria: boolean = false;
}

// tslint:disable-next-line: max-classes-per-file
export class BasketPackageMeal extends PackageMeal {
    // 内部菜品区域
    public package_meal_areas?: Array<BasketPackageMealArea> = [];
    // 所属的点餐页菜单
    menuName?: string
    // 区分不同菜单的相同菜品ID
    firstId?: string
    // 加入到购物篮时的套餐唯一id(包餐)
    differentSetDishId?: string;
}

export type CollectiveItem = BasketSetMeal | BasketPackageMeal;

export type CollectiveItemArea = BasketSetMealArea | BasketPackageMealArea;

// *********************
// 购物车事件
// *********************

export type CartActions = CartAddAction | CartReduceAction | CartRefreshAction;

/**
 * 购物车菜品添加类操作
 *
 * @property type 操作类型
 * @property id 菜品的唯一标识码
 * @property 是否是集合性商品
 * @property 不同菜品的套餐或包餐id
 */
export type CartAddAction = {
    type: 'ADD';
    id: string;
    num: number;
    isICollectiveDish: boolean;
    differentSetDishId?: string;
};

/**
 * 购物车菜品减少类操作
 *
 * @property type 操作类型
 * @property id 菜品的唯一标识码
 * @property num 菜品的操作的数量
 * @property 是否是集合性商品
 * @property 不同菜品的套餐或包餐id
 * @property 将当前菜品置为0
 */
export type CartReduceAction = {
    type: 'REDUCE';
    id: string;
    num: number;
    isICollectiveDish: boolean;
    differentSetDishId?: string;
    clearAll?: boolean
};

/**
 * 购物车刷新类操作（重置菜品优惠券信息等）
 *
 * @property type 操作类型
 */
export type CartRefreshAction = {
    type: 'REFRESH';
};
