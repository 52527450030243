

// 过敏原
export const ALLERGEN = [{
    key: 'ALLERGEN_GLUTEN',
    text: 'restaurant_order_page_text_gluten'
}, {
    key: 'ALLERGEN_CRUSTACEANS',
    text: 'restaurant_order_page_text_crustaceans'
}, {
    key: 'ALLERGEN_EGGS',
    text: 'restaurant_order_page_text_eggs'
}, {
    key: 'ALLERGEN_FISH',
    text: 'restaurant_order_page_text_fish'
}, {
    key: 'ALLERGEN_PEANUTS',
    text: 'restaurant_order_page_text_peanuts'
}, {
    key: 'ALLERGEN_SOYA',
    text: 'restaurant_order_page_text_soya'
}, {
    key: 'ALLERGEN_MILK',
    text: 'restaurant_order_page_text_milk'
}, {
    key: 'ALLERGEN_TREE_NUTS',
    text: 'restaurant_order_page_text_tree_nuts'
}, {
    key: 'ALLERGEN_CELERY',
    text: 'restaurant_order_page_text_celery'
}, {
    key: 'ALLERGEN_MUSTARD',
    text: 'restaurant_order_page_text_mustard'
}, {
    key: 'ALLERGEN_SESAME',
    text: 'restaurant_order_page_text_sesame'
}, {
    key: 'ALLERGEN_SULPHITES',
    text: 'restaurant_order_page_text_sulphite'
}, {
    key: 'ALLERGEN_LUPIN',
    text: 'restaurant_order_page_text_lupin'
}, {
    key: 'ALLERGEN_MOLLUSCE',
    text: 'restaurant_order_page_text_molluscs'
}]

//   配料(其他菜品标识)
export const INGREDIENTS = [
    {
        key: 'INGREDIENTS_MILD_SPICY',
        text: 'restaurant_order_page_text_mild_spicy'
    },
    {
        key: 'INGREDIENTS_MEDIUM_SPICY',
        text: 'restaurant_order_page_text_medium_spicy'
    },
    {
        key: 'INGREDIENTS_VERY_SPICY',
        text: 'restaurant_order_page_text_heavy_spicy'
    },
    {
        key: 'INGREDIENTS_FROZEN_ORIGIN',
        text: 'restaurant_order_page_text_frozen_origin'
    },
    {
        key: 'INGREDIENTS_BLAST_FREEZING',
        text: 'restaurant_order_page_text_blast_freezing'
    },
    {
        key: 'INGREDIENTS_VEGETARIAN',
        text: 'restaurant_order_page_text_vegetarian'
    },
    {
        key: 'INGREDIENTS_VEGAN',
        text: 'restaurant_order_page_text_vegan'
    },
    {
        key: 'INGREDIENTS_GLUTEN_FREE',
        text: 'restaurant_order_page_text_gulten_free'
    },
    {
        key: 'INGREDIENTS_RAW',
        text: 'restaurant_order_page_text_raw_food'
    },
    {
        key: 'INGREDIENTS_COOKED',
        text: 'restaurant_order_page_text_cooked_food'
    },
    {
        key: 'INGREDIENTS_ADDITIVES',
        text: 'restaurant_order_page_text_food_additives'
    },
    {
        key: 'INGREDIENTS_COLOURANTS',
        text: 'restaurant_order_page_text_food_coloring'
    },
    {
        key: 'INGREDIENTS_PORK',
        text: 'restaurant_order_page_text_pork'
    }
]