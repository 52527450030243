import React from 'react';
import './scss/checkout-icon-head-box.styles.scss'

export function IconHeadBox(props: {
    iCon: () => JSX.Element;
    title: string;
    subTitle?: string;
    actionLabel?: string | (() => JSX.Element);
    action?: () => void;
    clickHead?: () => void;
    containerStyle?: React.CSSProperties;
}) {
    const { iCon, title, subTitle, actionLabel, action, clickHead, containerStyle } = props;
    return (
        <div className='iconHeadBox' style={containerStyle} onClick={(e) => { e.stopPropagation(); clickHead?.() }}>
            <div className='icon'>{iCon()}</div>
            <div className='titleBox'>
                <span className='title'>{title}</span>
                <span className='subTitle'>{subTitle}</span>
            </div>
            <div className='action' onClick={() => { action?.() }}>
                {typeof actionLabel === 'string'
                    ? actionLabel
                    : typeof actionLabel === 'function'
                        ? actionLabel()
                        : null}
            </div>
        </div>
    )
}