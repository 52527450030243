import _ from 'lodash'
import { Address } from '../models'
import { LevelDeliveryFee } from '../models/abstract/level-delivery-fee.entity'
import apiManage from '../request'
import { LOCAL_STORAGE_KEYS } from './local-storage.constant'
import { matchingAreaDeliveryFee } from './tool-googlemap'
import { LocalStorageManager } from './tool-local-storage'

/**
 * 检查圆环配送费的圆环圈数
 * @param stringId 餐馆stringID
 * @param levelDelivery 餐馆原型配送费数组
 * @param notNeedLoading 是否需要加载动画
 *
 * @deprecated 前端不需要校验授权点
 */
export async function checkLevelDelivery(stringId: string, levelDelivery: any[], notNeedLoading?: boolean) {
    try {
        let newLevelDelivery = levelDelivery
        // AU1003 检查环形配送费的权限
        const checkCustomerVoucher = await apiManage.checkCustomerVoucher(stringId,
            'AU1003', true, { notNeedLoading })
        // 是否有通过的授权点
        // let hasPassedWarrant = _.get(checkCustomerVoucher, 'passedWarrant')
        // 最大环数(至少为1)
        const max_num = _.get(checkCustomerVoucher, 'passedWarrant[0].num') || 1
        console.log('授权环形配送费最大环数:', `${max_num}`)

        if (max_num) {
            newLevelDelivery = _.filter(levelDelivery,
                (n, index) => Number(index) < Number(max_num))
        } else {
            newLevelDelivery = []
        }
        return newLevelDelivery
    } catch (error) {
        return []
    }
}

/**
 * 缓存选中的地址的配送费
 */

export async function CachedAddressDeliveryFee(addressInfo: Address) {
    if (JSON.stringify(addressInfo) === '{}' || !addressInfo) {
        LocalStorageManager.setLocalStorage(
            {
                path: LOCAL_STORAGE_KEYS.DELIVERY_FEE,
                value: {}
            }
        )
        LocalStorageManager.setLocalStorage(
            {
                path: LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR,
                value: []
            }
        )
        return;
    }
    try {
        const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res_all = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const res_info = _.get(res_all, `[${res_string_id}].restaurant`);
        const setting_level_delivery: LevelDeliveryFee[] = _.get(res_info, 'setting_level_delivery', []);

        // let line = 0;

        // const { google_map_lat, google_map_lng } = res_info;
        // let restaurantsLatLng = new google.maps.LatLng(Number(google_map_lat), Number(google_map_lng));

        // 地址的经纬度
        const addressGoogleMapLat = Number(addressInfo.google_map_lat);
        const addressGoogleMapLng = Number(addressInfo.google_map_lng);
        // let customerLatLng = new google.maps.LatLng(addressGoogleMapLat, addressGoogleMapLng);
        // 餐厅与地址的距离转成公里
        // line = google.maps.geometry.spherical.computeDistanceBetween(restaurantsLatLng, customerLatLng) / 1000;
        // 匹配区域配送费
        const getDeliveryLevel = matchingAreaDeliveryFee(setting_level_delivery, { lat: addressGoogleMapLat, lng: addressGoogleMapLng });
        if (getDeliveryLevel) {
            LocalStorageManager.setLocalStorage(
                {
                    path: LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR,
                    value: getDeliveryLevel
                }
            )
        }
    } catch (error) {
        console.log(error)
    }
}