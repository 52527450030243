import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { loadStripe } from '@stripe/stripe-js';
import { createHashHistory } from 'history';
import _ from 'lodash';
import momentTimeZone from 'moment-timezone';
import React from 'react';
import { MdError } from 'react-icons/md';
import Notification from '../../component/snackbar/snackbar';
import TopBar from '../../component/top-bar/top-bar.component';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { DT } from '../../constants/tool-constant';
import { getQueryString } from '../../constants/tool-get-parms';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import i18n from '../../i18n';
import { ICheckoutOrderDetailOrderDescribeStatusNames } from '../../interfaces';
import { IPaymentMethod, IPaymentMethodProps, IPaymentMethodState } from '../../interfaces/payment-method.interface';
import apiManage, { VIVA_PAYMENT_URL } from '../../request';
import errorCode from '../../static/error-code';
// import TowPayNowLogo from '../../static/payment-images/2paynow_logo.png';
// import cardLogo from '../../static/payment-images/card_logo.png';
import PayPalLogo from '../../static/payment-images/PayPal_logo.png';
import stripeLogo from '../../static/payment-images/stripe_logo.png';
import VivawalletLogo from '../../static/payment-images/vivawallet.png';
// import weixinLogo from '../../static/payment-images/weixin_logo.png';
// import zhifubaoLogo from '../../static/payment-images/zhifubao_logo.png';
import postofsale from '../../static/payment-images/cash_logo.png'
import './paymentMethod.page.styles.scss';
import CloseIcon from '@material-ui/icons/Close';
import Store from '../../rudex/store';
import { Restaurant } from '../../models';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { CheckoutData } from '../../component/checkout/checkout-order-detail-page';
import { AiOutlineStop } from 'react-icons/ai';
import { checkToken } from '../../constants/tool-check-token';
import { AirBubbles } from '../../component/payment/payment.componet';
import { IoMdCash } from 'react-icons/io';
import orderCompleteLogo from '../../static/images/order_complete.png';
@inject_unount
class PaymentMethod extends React.Component<Props, State> implements IPaymentMethod {

    public state: State = {
        // 判断支付方式 0:未选择 1:paypal支付 2:线下支付 3:stripe支付 4:2PAYNOW支付 5:viva wallet
        paymenMethod: 0,
        // 用户选择2payNow支付渠道 '-1':未选择 '0':支付宝 '1':微信 '5':银行卡
        twoPayNowWay: '-1',
        // 用户选择线下支付渠道 '-1':未选择 '0':现金 '1':POS支付
        paymentCodType: '-1',
        // paypal开启状态
        paypalStatus: false,
        // 2paynow开启状态
        twoPayNowStatus: false,
        // stripe开启状态
        stripeStatus: false,
        // stripe的PkKey
        stripePkKey: '',
        // 线下付款开启状态
        cashStatus: false,
        // 显示无付款方式文本
        showNoPaymentMethod: false,
        // 餐厅string_id
        restaurantStringId: getQueryString('string_id')?.toUpperCase() || '',
        // 餐厅手机号
        restaurantPhone: '',
        // 餐厅手机前缀
        restaurantPhonePrefix: '',
        // 线下付款，最大金额限制（只限制配送）
        maxOfflineFee: 0,
        // 订单id
        orderId: getQueryString('order_id')?.toUpperCase() || '',
        // 操作后详情提示弹窗
        paymentMethodDialogVisible: false,
        // 支付状态
        paymentStatus: 'CREATING',
        // 错误提示
        errorDetails: {},
        // 加载动画
        loadingStatus: false,
        // 结算托盘信息
        checkoutData: {},
        // 订单自取或配送 '0':配送 '1':自取
        orderType: '0',
        // 订单原始购物车价格
        orderToTalPrice: 0,
        // 气泡提示（支持信用卡付款）
        showAirBubbles: true,
        // 后台设置 2paynow
        // '5':银行卡
        setting2paynowCradEnable: false,
        // '5':支付宝
        setting2paynowAlipayEnable: false,
        // '5':微信
        setting2paynowWechatEnable: false,
        // stripe 支付宝
        settingStripeAlipayEnable: false,
        // stripe 银行卡
        settingStripeCradEnable: false,
        // 货到付款现金
        settingCodCashEnable: false,
        // 货到付款pos
        settingCodPosEnable: false,
        // viva商家的Merchant ID
        vivaStatus: false,
        // viva商家的API Key
        vivaMerchantId: '',
        // 是否允许viva支付
        vivaApiKey: ''
    }

    // *********************
    // Life Cycle Function
    // *********************

    UNSAFE_componentWillMount() {
        try {
            if (LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) && LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT)) {
                checkToken()
            } else {
                this.hendletRestaurantError('/restaurantPage', `${i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again')}`);
            }
        } catch (error) { }
    }

    async componentDidMount() {
        await this.CheckSource();
    }

    // *********************
    // Default Function
    // *********************

    /**
     * 检查来源
     * - 从点餐页面跳转过来
     * - 从我的订单跳转过来
     */
    public async CheckSource() {
        try {
            const { restaurantStringId, orderId } = this.state;
            if (restaurantStringId) {
                // 获取到餐馆数据
                await this.getRestaurantInfo(restaurantStringId);
                // 从我的订单跳转过来
                if (orderId) {
                    // 获取到订单详情
                    await this.getOrderDetail(restaurantStringId, orderId);
                }
                // 从结算托盘过来
                else {
                    const checkoutData = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CHECKOUT_DATA) || {};
                    // 托盘数据不存在
                    if (Object.keys(checkoutData).length === 0 || (checkoutData.restaurantStringId !== restaurantStringId)) {
                        this.hendletRestaurantError('/restaurantPage');
                    } else {
                        this.setState({
                            checkoutData
                        })
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 获取餐馆信息
     */
    public async getRestaurantInfo(restaurantStringId: string) {
        const result: any = await apiManage.getRestaurantInfo(restaurantStringId);
        if (_.get(result, 'error')) {
            const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
            const text = errorTip.length > 0 ? errorTip[0].message : 'payment_page_text_Restaurant_no_exist_reselect_restaurant';
            this.hendletRestaurantError('/restaurantPage', `${i18n.t(text)}`)
        } else {
            const { dbResults } = result;
            if (dbResults && dbResults.length > 0) {
                // 拿到最新的餐馆信息，并存入缓存
                const restaurantsInfo: Restaurant = dbResults[0] || {};
                const cacheRestaurants = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
                const restaurantsMag = { ...cacheRestaurants };
                const string_id = _.get(restaurantsInfo, 'string_id') || '';
                _.set(restaurantsMag, `${string_id}.restaurant`, restaurantsInfo);
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.RESTAURANTS,
                    value: restaurantsMag
                });
                // 线下支付最大金额限制
                // 付款方式按钮状态
                this.setState({
                    paypalStatus: restaurantsInfo.setting_app_takeaway_service_payment_paypal_status || false,
                    twoPayNowStatus: restaurantsInfo.setting_app_takeaway_service_payment_2paynow_status || false,
                    stripeStatus: restaurantsInfo.setting_app_takeaway_service_payment_stripe_status || false,
                    stripePkKey: restaurantsInfo.setting_stripe_pk_key || '',
                    cashStatus: restaurantsInfo.setting_app_takeaway_service_payment_cash_status || false,
                    restaurantPhone: restaurantsInfo.tel || '',
                    restaurantPhonePrefix: restaurantsInfo.tel_prefix || '',
                    maxOfflineFee: restaurantsInfo.restaurant_cod_maximum_amount || 0,
                    showNoPaymentMethod: true,
                    setting2paynowCradEnable: restaurantsInfo.setting_2paynow_crad_enable || false,
                    setting2paynowAlipayEnable: restaurantsInfo.setting_2paynow_alipay_enable || false,
                    setting2paynowWechatEnable: restaurantsInfo.setting_2paynow_wechat_enable || false,
                    settingStripeAlipayEnable: restaurantsInfo.setting_stripe_alipay_enable || false,
                    settingStripeCradEnable: restaurantsInfo.setting_stripe_crad_enable || false,
                    settingCodCashEnable: restaurantsInfo.setting_cod_cash_enable || false,
                    settingCodPosEnable: restaurantsInfo.setting_cod_pos_enable || false,
                    vivaStatus: !!restaurantsInfo.setting_app_takeaway_service_payment_viva_status,
                    vivaMerchantId: restaurantsInfo.viva_merchant_id || '',
                    vivaApiKey: restaurantsInfo.viva_api_key || ''
                })
            } else {
                this.hendletRestaurantError(
                    '/restaurantPage',
                    `${i18n.t('payment_page_text_Restaurant_no_exist_reselect_restaurant')}`
                )
            }
        }
    }

    /**
     * 获取订单详情
     * - 检查订单状态是否已付款
     */
    public async getOrderDetail(restaurantStringId: string, orderId: string) {
        const result = await apiManage.getRestaurantOrder({ string_id: restaurantStringId, id: orderId });

        if (_.get(result, 'error')) {
            // 创建失败弹窗 关闭加载框、打开失败窗口
            const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
            if (errorTip.length > 0) {
                // 有对应错误编码
                Notification.notification(i18n.t(errorTip[0].message));
            } else {
                Notification.notification(i18n.t('payment_page_text_order_error_order_not_exist'));
            }
        } else {
            // 订单支付情况 0未付款 1已付款 2  已申请退款 3 已退款
            const orderPaymentStatus = result.payment_status;
            if (orderPaymentStatus !== '0') {
                Notification.notification(i18n.t('payment_method_page_the_order_has_been_paid'));
                this.setState({
                    paymentStatus: 'PAID_SUCCESS'
                })
            } else {
                this.setState({
                    orderType: result.type,
                    orderToTalPrice: result.total_price || 0
                })
            }

        }
    }

    /**
     * 获取套餐或包餐的区域菜品
     */
    public getMealAreas(mealAreas, type: 'packageMeal' | 'setMeal') {
        try {
            if (_.isArray(mealAreas)) {
                return mealAreas.map(areasItem => {

                    return {
                        _id: areasItem._id,
                        dishes: areasItem.dishes && areasItem.dishes.map((areasDishesItem) => {
                            return {
                                _id: areasDishesItem._id,
                                num: areasDishesItem.num
                            }
                        }),
                        set_meals: type === 'packageMeal' && _.isArray(areasItem.set_meals) && areasItem.set_meals.length > 0 ?
                            areasItem.set_meals.map((setMealsItem) => {
                                return {
                                    _id: setMealsItem._id,
                                    num: setMealsItem.num,
                                    set_meal_areas: this.getMealAreas(setMealsItem.set_meal_areas, 'setMeal')
                                }
                            })
                            : undefined,
                        set_meal_areas: type === 'setMeal' && _.isArray(areasItem.set_meals) && areasItem.set_meals.length > 0 ?
                            areasItem.set_meals.map((setMealsItem) => {
                                return {
                                    _id: setMealsItem._id,
                                    num: setMealsItem.num
                                    // 暂无套餐中含有套餐，字段未定义
                                    // set_meals: this.getMealAreas(setMealsItem.set_meal_areas, 'setMeal')
                                }
                            })
                            : undefined
                    }
                })
            } else {
                return undefined
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 创建订单
     */
    public async createOrder() {
        try {
            // 选择的付款方式
            const paymenMethod = this.state.paymenMethod;
            // 2paynow选择的支付渠道
            const twoPayNowWay = this.state.twoPayNowWay;
            // 线下支付选择的渠道
            const paymentCodType = this.state.paymentCodType;
            // 未选择付款方式
            if (paymenMethod === 0 || (paymenMethod === 4 && twoPayNowWay === '-1') || (paymenMethod === 2 && paymentCodType === '-1')) {
                return
            }
            // 结算托盘数据
            const checkoutData = this.state.checkoutData;
            // 订单的描述状态 1:自取 0:配送
            const type = checkoutData.orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_PICKUP ? '1' : '0';
            // 购物车有积分菜品
            let hasPointDish = false;
            // 购物车(数据筛选出需要的字段)
            const bagItemDish = _.isArray(checkoutData.cartDishes) && checkoutData.cartDishes.map(item => {
                if (item.dish.is_points_redemption) {
                    hasPointDish = true;
                }
                return {
                    _id: item.dish._id,
                    num: item.num,
                    remark: item.dish.remark || undefined,
                    menu_name: item.dish.menuName,
                    menu_id: item.dish.menuId,
                    // '0':菜品;'1':包餐;'2':套餐;
                    dish_type: item.dish.dish_type || '0',
                    // 包餐
                    package_meal_areas: item.dish.dish_type === '1' ? this.getMealAreas(item.dish.package_meal_areas, 'packageMeal') : undefined,
                    // 套餐
                    set_meal_areas: item.dish.dish_type === '2' ? this.getMealAreas(item.dish.set_meal_areas, 'setMeal') : undefined,
                    // 启用积分
                    is_points_redemption: item.dish.is_points_redemption
                }
            })
            // 购物袋
            const bags = [bagItemDish];
            // 优惠券(数据筛选出需要的字段)
            const coupons = _.isArray(checkoutData.selectedCoupons) && checkoutData.selectedCoupons.map(item => {
                return {
                    coupon_code: (item.ignore_get_enable && !item.is_customer_coupon) ? item.code : item.coupon_code,
                    _id: item._id,
                    is_ignore_get: (item.ignore_get_enable && !item.is_customer_coupon) || undefined
                }
            })
            // 订单备注
            const remark = checkoutData.orderRemark;
            // '0':线上 '1'：线下
            const payment_type = paymenMethod === 2 ? '1' : '0';
            // 付款方式详情
            let payment_detail = {};
            switch (paymenMethod) {
                case 1:
                    payment_detail = {
                        type: 'PAYPAL'
                    }
                    break;
                case 2:
                    payment_detail = {
                        type: 'COD',
                        paymentCODType: paymentCodType
                    }
                    break;
                case 3:
                    payment_detail = {
                        type: 'STRIPE'
                    }
                    break;
                case 4:
                    payment_detail = {
                        type: '2PAYNOW',
                        payment2PayNowType: twoPayNowWay
                    }
                    break;
                case 5:
                    payment_detail = {
                        type: 'VIVA'
                    }
                    break;
                default:
                    break;
            }

            // 自取时需要传的字段
            const pickupPostData = {
                // 自取address传空
                address: {},
                // 履约时间-自取时间
                selftake_time: DT(checkoutData.fulfillmentTime),
                // 时区
                selftake_time_zone_name: momentTimeZone.tz.guess()
            }
            // 配送时需要传的字段
            const address = _.cloneDeep(checkoutData.currentAddress || {});
            // 去掉没必要字段
            _.set(address, 'line', undefined)
            _.set(address, 'isWithin', undefined)
            _.set(address, 'deliveryLine', undefined)
            const deliveryPostData = {
                // 履约地址-配送地址
                address,
                // 履约时间-配送
                delivery_time: DT(checkoutData.fulfillmentTime),
                // 时区
                delivery_time_zone_name: momentTimeZone.tz.guess(),
                // 直线距离
                delivery_kilometer: checkoutData?.currentAddress?.line || 0,
                // 路径距离
                route_kilometer: checkoutData?.currentAddress?.deliveryLine || 0
            }
            const postData = type === '1' ? pickupPostData : deliveryPostData
            /**
             * 创建订单提交的参数
             *
             * belonged_restaurant
             * - OD_APP：OD移动程序的订单
             * - OD_WEB：OD网页版的订单
             * - OD_TEMP：OD后台创建的临时订单
             */
            const allPostData: any = {
                order_creation_origin: 'OD_WEB',
                belonged_restaurant: checkoutData.restaurantStringId || '',
                belonged_customer: _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT), '_id'),
                type,
                bags,
                remark,
                coupons,
                payment_type,
                payment_detail,
                customer_language: this.getVivaLanguage(),
                is_points_redemption: hasPointDish,
                ...postData
            }
            // 请求创建订单接口
            this.setState({ paymentMethodDialogVisible: true, paymentStatus: 'CREATING' })
            const orderPaymentResult: any = await apiManage.postOrderPayment(allPostData, { notNeedLoading: true });
            // 这里需要做一个错误代码提示
            if (_.get(orderPaymentResult, 'error')) {
                // 创建失败弹窗 关闭加载框、打开失败窗口
                console.log('创建订单失败：', orderPaymentResult)
                if (_.get(orderPaymentResult, 'result.code') === 'ORDER_1005' && _.get(orderPaymentResult, 'result.statusCode') === 400) {
                    // $ 以下优惠券当前不能使用
                    const details = _.get(orderPaymentResult, 'result.details', []);
                    const failedCouponsText = _.map(details, 'name');
                    this.setState({
                        paymentMethodDialogVisible: true,
                        paymentStatus: 'CREATED_ERROR',
                        errorDetails: {
                            message: `${i18n.t('payment_page_text_the_following_coupons_cannot_currently_be_used')}`,
                            subMessage: `${_.join(failedCouponsText, ',')}`,
                            showCloseBtn: true
                        }
                    })
                    return
                } else if (
                    (_.get(orderPaymentResult, 'result.code') === 'ORDER_1031' && _.get(orderPaymentResult, 'result.statusCode') === 403)
                    ||
                    (_.get(orderPaymentResult, 'result.code') === 'ORDER_1038' && _.get(orderPaymentResult, 'result.statusCode') === 400)
                ) {
                    // 线上支付不能为0元
                    let message = '';
                    // 1:PayPal支付  (直接调用接口获取到PayPal支付接口)
                    if (paymenMethod === 1) {
                        message = 'payment_page_text_PayPals_total_payment_amount_cannot_be_zero';
                    }
                    // 3:Stripe
                    else if (paymenMethod === 3) {
                        message = 'payment_page_text_Stripe_total_payment_amount_cannot_be_zero';
                    }
                    // 4:2paynow
                    else if (paymenMethod === 4) {
                        message = 'payment_page_text_2PayNow_total_payment_amount_cannot_be_zero';
                    }
                    // 5:viva
                    else if (paymenMethod === 5) {
                        message = i18n.t('payment_page_text_viva_total_payment_amount_must_be_greater_than_x', { x: 0.3 });
                    }
                    this.setState({
                        paymentMethodDialogVisible: true,
                        paymentStatus: 'CREATED_ERROR',
                        errorDetails: {
                            message: i18n.t(message),
                            showPhone: true,
                            showCloseBtn: true
                        }
                    })
                    return
                }
                const errorTip = errorCode.filter(item => (item.code === _.get(orderPaymentResult, 'result.code')));
                this.setState({
                    paymentMethodDialogVisible: true,
                    paymentStatus: 'CREATED_ERROR',
                    errorDetails: {
                        message: errorTip.length > 0 ? `${i18n.t(errorTip[0].message)}` : '',
                        showPhone: true,
                        showCloseBtn: true
                    }
                })
            } else {
                // 没有错误的情况
                const { createOrderInCustomerResult, createOrderInRestaurantResult } = orderPaymentResult;
                // 判断顾客和餐厅都是ok才行
                if (createOrderInCustomerResult.ok === 1
                    && createOrderInCustomerResult.n === 1
                    && createOrderInCustomerResult.modifiedCount === 1
                    && createOrderInRestaurantResult.ok === 1
                    && createOrderInRestaurantResult.n === 1
                    && createOrderInRestaurantResult.insertedCount === 1) {
                    this.setState({
                        paymentMethodDialogVisible: true,
                        paymentStatus: 'CREATED_SUCCESS'
                    })
                    // 购物车清除
                    const initShop = {
                        shopCarList: [],
                        totalPrice: 0
                    }
                    // 更新存储里面的购物车数据
                    const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                        value: {
                            ...cacheShopCar,
                            [this.state.restaurantStringId]: initShop
                        }
                    });
                    const cacheAccount = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                    if (cacheAccount._id) {
                        // 加入历史餐馆
                        const historyRestaurant = { _id: cacheAccount._id, restaurant_string_ids: [this.state.restaurantStringId] };
                        await apiManage.updateHistoryRestaurants(historyRestaurant, { notNeedLoading: true });
                    }
                    // 1:PayPal支付  (直接调用接口获取到PayPal支付接口)
                    if (paymenMethod === 1) {
                        this.toPayMent({ payPalUrl: _.get(orderPaymentResult, 'createPaymentResult.approval_url.href', '') })
                    }
                    // 2:线下支付
                    else if (paymenMethod === 2) {
                        this.setState({
                            paymentMethodDialogVisible: false,
                            paymentStatus: 'PAID_SUCCESS'
                        })
                    }
                    // 3:Stripe
                    else if (paymenMethod === 3) {
                        this.toPayMent({ stripeId: _.get(orderPaymentResult, 'createPaymentResult.id', '') })
                    }
                    // 4:2paynow
                    else if (paymenMethod === 4) {
                        this.toPayMent({ twoPayNowUrl: _.get(orderPaymentResult, 'createPaymentResult.url', '') })
                    }
                    // 5:viva
                    else if (paymenMethod === 5) {
                        this.toPayMent({ vivaOrderCode: _.get(orderPaymentResult, 'vivaOrder_code.vivaOrder_code', '') })
                    }
                    // 创建成功后移除托盘数据缓存
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.CHECKOUT_DATA)
                } else {
                    this.setState({
                        paymentMethodDialogVisible: true,
                        paymentStatus: 'CREATED_ERROR',
                        errorDetails: {
                            showPhone: true,
                            showCloseBtn: true
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 从我的订单过来再次付款
     */
    async payAgain() {
        const { paymenMethod, twoPayNowWay, paymentCodType } = this.state;
        if (paymenMethod === 1) {
            // $ paypal付款
            await this.handleSuccessPayPal();
        } else if (paymenMethod === 2 && paymentCodType !== '-1') {
            // $ 线下 货到付款
            this.toPayMent({})
        } else if (paymenMethod === 3) {
            // $ Stripe支付
            await this.handleSuccessStripe();
        } else if (paymenMethod === 4 && twoPayNowWay !== '-1') {
            // $ 2PayNow支付
            await this.handleSuccess2PayNow();
        } else if (paymenMethod === 5) {
            // $ viva支付
            await this.handleSuccessViva();
        }
    }


    /**
     * 线下支付创建订单成功调用链接
     */
    public async handleSuccessOffline() {
        try {
            this.setState({ paymentMethodDialogVisible: true, paymentStatus: 'PAYING' })
            const { restaurantStringId, orderId, paymentCodType } = this.state;
            // 二次付款
            const result = await apiManage.postOrderOffline(restaurantStringId, orderId, paymentCodType, { notNeedLoading: true });
            if (_.get(result, 'error')) {
                // 创建失败弹窗 关闭加载框、打开失败窗口
                const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
                this.setState({
                    paymentMethodDialogVisible: true,
                    paymentStatus: 'PAID_ERROR',
                    errorDetails: {
                        message: errorTip.length > 0 ? `${i18n.t(errorTip[0].message)}` : '',
                        showPhone: true,
                        showCloseBtn: true
                    }
                })
            } else {
                // 线下支付成功
                this.setState({
                    paymentMethodDialogVisible: false,
                    paymentStatus: 'PAID_SUCCESS'
                })
            }
        } catch (err) { }
    }

    /**
     * PayPal创建订单成功调用链接
     * @param orderId 订单id
     */
    public async handleSuccessPayPal() {
        this.setState({
            paymentMethodDialogVisible: true,
            paymentStatus: 'PAYING',
            errorDetails: {
                message: i18n.t('payment_page_text_paypal_payment_error')
            }
        })
        const { restaurantStringId, orderId } = this.state;
        const result = await apiManage.postOrderPaypal(restaurantStringId, orderId, { notNeedLoading: true });

        if (_.get(result, 'error')) {
            // 创建失败弹窗 关闭加载框、打开失败窗口
            const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
            // 有对应错误编码
            if (errorTip.length > 0 && errorTip[0].code === 1008) {
                this.setState({
                    paymentMethodDialogVisible: true,
                    paymentStatus: 'PAID_ERROR',
                    errorDetails: {
                        message: i18n.t('payment_page_text_paypal_payment_error'),
                        subMessage: i18n.t('payment_page_text_PayPals_total_payment_amount_cannot_be_zero'),
                        showPhone: true,
                        showCloseBtn: true
                    }
                })
            } else {
                this.setState({
                    paymentMethodDialogVisible: true,
                    paymentStatus: 'PAID_ERROR',
                    errorDetails: {
                        message: errorTip.length > 0 ? `${i18n.t(errorTip[0].message)}` : '',
                        showPhone: true,
                        showCloseBtn: true
                    }
                })
            }
        } else {
            if (result.emitMessageToCustomerResult && result.emitMessageToRestaurantResult) {
                // 跳转PayPal第三方网址
                this.toPayMent({ payPalUrl: result.createPaymentResult.approval_url.href })
            }
        }
    }

    /**
     * Stripe创建订单成功调用链接
     */
    public async handleSuccessStripe() {
        try {
            // 二次付款
            const { restaurantStringId, orderId } = this.state;
            const result = await apiManage.postOrderStripe(restaurantStringId, orderId, { notNeedLoading: true });
            if (_.get(result, 'error')) {
                this.setState({
                    paymentMethodDialogVisible: true,
                    paymentStatus: 'PAID_ERROR',
                    errorDetails: {
                        message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                        showPhone: true,
                        showCloseBtn: true
                    }
                })
            } else {
                // 去Stripe付款
                this.toPayMent({ stripeId: _.get(result, 'createStripeResult.id', '') })
            }
        } catch (error) {
            this.setState({
                paymentMethodDialogVisible: true,
                paymentStatus: 'PAID_ERROR',
                errorDetails: {
                    message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                    showPhone: true,
                    showCloseBtn: true
                }
            })
        }
    }

    /**
     * 2PayNow创建订单成功调用链接
     */
    public async handleSuccess2PayNow() {
        try {
            // 二次付款
            const { restaurantStringId, orderId, twoPayNowWay } = this.state;
            // 0:微信 1:支付宝 5:银行卡
            const result = await apiManage.postOrder2PayNow(restaurantStringId, orderId, twoPayNowWay, { notNeedLoading: true });
            if (_.get(result, 'error')) {
                this.setState({
                    paymentMethodDialogVisible: true,
                    paymentStatus: 'PAID_ERROR',
                    errorDetails: {
                        message: `2PayNow ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                        showPhone: true,
                        showCloseBtn: true
                    }
                })
            } else {
                // 去2PayNow付款
                this.toPayMent({ twoPayNowUrl: _.get(result, 'payment_url', '') })
            }
        } catch (error) {
            this.setState({
                paymentMethodDialogVisible: true,
                paymentStatus: 'PAID_ERROR',
                errorDetails: {
                    message: `2PayNow ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                    showPhone: true,
                    showCloseBtn: true
                }
            })
        }
    }

    /**
     * Viva
     */
    public async handleSuccessViva() {
        // 二次付款
        const { restaurantStringId, orderId } = this.state;
        const result = await apiManage.postOrderViva(restaurantStringId, orderId,
            { customer_language: this.getVivaLanguage() },
            { notNeedLoading: true });
        if (_.get(result, 'error')) {
            this.setState({
                paymentMethodDialogVisible: true,
                paymentStatus: 'PAID_ERROR',
                errorDetails: {
                    message: `Viva ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                    showPhone: true,
                    showCloseBtn: true
                }
            })
        } else {
            // 去Viva付款
            this.toPayMent({ vivaOrderCode: _.get(result, 'vivaOrder_code.vivaOrder_code', '') })
        }
    }

    /**
     * 去支付
     * @param params
     * - payPalUrl 付款地址
     * - stripeId 付款地址
     * - twoPayNowUrl 付款地址
     */
    public async toPayMent(params: { payPalUrl?: string, stripeId?: string, twoPayNowUrl?: string, vivaOrderCode?: number }) {
        try {
            /**
             * 1、PayPal支付
             * 2、确认线下支付
             * 3、点击控件之后隐藏控件
             *   点击支付之后  改变控件文字为支付中..  加载框按钮消失
             */
            this.setState({
                paymentMethodDialogVisible: true,
                paymentStatus: 'PAYING'
            })
            const { paymenMethod } = this.state;
            // $ 1：PayPal支付
            if (paymenMethod === 1) {
                // 在Safari浏览器，有检测到非用户操作 window.open 是广告会被屏蔽
                if (params.payPalUrl) {
                    window.location.replace(params?.payPalUrl);
                }
                // handleTiming('/my-order-page', 3000)
            }
            // $ 2：确认线下支付  (直接确认弹出成功窗口)
            else if (paymenMethod === 2) {
                this.handleSuccessOffline();
            }
            // $ 3：stripe支付
            else if (paymenMethod === 3) {
                try {
                    if (this.state.stripePkKey && params.stripeId) {
                        const stripePromise = loadStripe(this.state.stripePkKey);
                        const stripe = await stripePromise;
                        const result = await stripe!.redirectToCheckout({
                            sessionId: params.stripeId
                        });
                        if (result.error) {
                            this.setState({
                                paymentMethodDialogVisible: true,
                                paymentStatus: 'PAID_ERROR',
                                errorDetails: {
                                    message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                                    showPhone: true,
                                    showCloseBtn: true
                                }
                            })
                        }
                    }
                } catch (error) {
                    this.setState({
                        paymentMethodDialogVisible: true,
                        paymentStatus: 'PAID_ERROR',
                        errorDetails: {
                            message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                            showPhone: true,
                            showCloseBtn: true
                        }
                    })
                }
            }
            // $ 4:2PAYNOW支付
            else if (paymenMethod === 4) {
                // 在Safari浏览器，有检测到非用户操作 window.open 是广告会被屏蔽
                if (params.twoPayNowUrl) {
                    window.location.replace(params.twoPayNowUrl);
                }
                // handleTiming('/my-order-page', 3000)
            }
            // $ 5:viva
            else if (paymenMethod === 5) {
                // 在Safari浏览器，有检测到非用户操作 window.open 是广告会被屏蔽
                if (params.vivaOrderCode) {
                    window.location.replace(VIVA_PAYMENT_URL + `${params.vivaOrderCode}`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * 获取Viva支付语言
     */
    getVivaLanguage() {
        let language = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE);
        // en-US、it-IT、es-ES
        language = language === 'ES' ? 'es-ES' : language === 'IT' ? 'it-IT' : 'en-US';
        return language
    }
    /**
     * 错误退出方法
     * @param path 跳转地址
     * @param text 提示信息
     */
    hendletRestaurantError(path: string, text?: string) {
        // 消息提示
        if (text) {
            Notification.notification(i18n.t(text), { autoHideDuration: 5000 });
        }
        const history = createHashHistory();
        const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');

        if (path === '/restaurantPage') {
            history.replace(`/restaurantPage?string_id=${this.state.restaurantStringId}${merchantWebsite ? '&merchant_website=true' : ''}`)
        } else {
            history.replace(`${path}`);
        }
    }

    /**
     * 返回到我的订单页面
     */
    public handleReturn() {
        const history = createHashHistory();
        // 直接将当前地址退还掉，防止用户二次支付
        history.replace('/my-order-page');
    }

    // *********************
    // View
    // *********************

    render() {
        const { paymenMethod, twoPayNowWay, paypalStatus, twoPayNowStatus, stripeStatus,
            stripePkKey, cashStatus, paymentMethodDialogVisible, paymentStatus, errorDetails,
            restaurantPhone, restaurantPhonePrefix, orderId, maxOfflineFee, showNoPaymentMethod,
            checkoutData, orderType, orderToTalPrice, showAirBubbles,
            settingStripeAlipayEnable,
            settingStripeCradEnable, settingCodCashEnable, settingCodPosEnable, paymentCodType, vivaStatus,
            vivaMerchantId, vivaApiKey
        } = this.state;
        // 禁用线下支付按钮-线下支付最大限制（购物车或订单菜品原始总价）
        let cashPayDisabled = false;
        if (orderId) {
            if ((orderType === '0' && (orderToTalPrice || 0) >= maxOfflineFee)) {
                cashPayDisabled = true
            }
        } else {
            if (checkoutData.orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY &&
                ((checkoutData.originalTotalPrice || 0) >= maxOfflineFee)) {
                cashPayDisabled = true
            }
        }
        return (
            <div className='paymentMethod'>
                <TopBar
                    backgroundstyle={`grayBackground`}
                    title={i18n.t('payment_method_page')}
                    isGoBack={() => {
                        if (paymentStatus === 'PAID_SUCCESS') {
                            this.handleReturn()
                        } else {
                            createHashHistory().goBack()
                        }
                    }}
                />
                {/* 未支付成功 */}
                {paymentStatus !== 'PAID_SUCCESS' &&
                    <div className='choosePaymentMethodBox'>
                        <div className='choosePaymentMethodText'>
                            {i18n.t('payment_method_page_choose_your_payment_Method')}
                        </div>
                        {showNoPaymentMethod && !paypalStatus && !twoPayNowStatus && !(stripeStatus && stripePkKey) && !cashStatus &&
                            <div className='noPaymentMethodSet'>{i18n.t('payment_page_text_The_restaurant_has_not_set_a_payment_method')}</div>
                        }
                        <div className='paymentMethodList'>
                            {/* PayPAL */}
                            {paypalStatus &&
                                <PaymentLabel
                                    content={() => <img src={PayPalLogo} alt='paypal' />}
                                    selected={paymenMethod === 1}
                                    allUnselected={paymenMethod === 0}
                                    onSelect={() => { this.setState({ paymenMethod: 1, showAirBubbles: false }) }}
                                    showAirBubbles={showAirBubbles}
                                    airBubblesText={i18n.t('payment_method_page_support_credit_card_payment')}
                                />}
                            {/* 2paynow */}
                            {/* {twoPayNowStatus && (setting2paynowCradEnable || setting2paynowAlipayEnable || setting2paynowWechatEnable) &&
                                <PaymentLabel
                                    content={() => <img src={TowPayNowLogo} alt='2paynow' />}
                                    selected={paymenMethod === 4}
                                    allUnselected={paymenMethod === 0}
                                    onSelect={() => { this.setState({ paymenMethod: 4, showAirBubbles: false }) }}
                                />} */}
                            {/* 2paynow支付渠道 */}
                            {/* {twoPayNowStatus && (setting2paynowCradEnable || setting2paynowAlipayEnable || setting2paynowWechatEnable) &&
                                < div className='twoPaynowList' style={{ display: paymenMethod === 4 ? 'flex' : 'none' }}>
                                    {setting2paynowAlipayEnable && <PaymentLabel
                                        content={() => <img src={zhifubaoLogo} alt='zhifubaoLogo' />}
                                        selected={twoPayNowWay === '0'}
                                        allUnselected={twoPayNowWay === '-1'}
                                        onSelect={() => { this.setState({ twoPayNowWay: '0' }) }}
                                    />}
                                    {setting2paynowWechatEnable && <PaymentLabel
                                        content={() => <img src={weixinLogo} alt='weixinLogo' />}
                                        selected={twoPayNowWay === '1'}
                                        allUnselected={twoPayNowWay === '-1'}
                                        onSelect={() => { this.setState({ twoPayNowWay: '1' }) }}
                                    />}
                                    {setting2paynowCradEnable && <PaymentLabel
                                        content={() =>
                                            <>
                                                <img className='cardLogo' src={cardLogo} alt='card' />
                                                <div className='cardLogoText'>
                                                    <span>{i18n.t('payment_page_text_bank_card')}</span>
                                                    <span>/{i18n.t('payment_page_text_credit_card')}</span>
                                                </div>
                                            </>
                                        }
                                        selected={twoPayNowWay === '5'}
                                        allUnselected={twoPayNowWay === '-1'}
                                        onSelect={() => { this.setState({ twoPayNowWay: '5' }) }}
                                    />}
                                </div>} */}
                            {/* stripe支付 */}
                            {stripeStatus && stripePkKey && (settingStripeAlipayEnable ||
                                settingStripeCradEnable) &&
                                <PaymentLabel
                                    content={() => <img src={stripeLogo} alt='stripe' />}
                                    selected={paymenMethod === 3}
                                    allUnselected={paymenMethod === 0}
                                    onSelect={() => { this.setState({ paymenMethod: 3, showAirBubbles: false }) }}
                                    showAirBubbles={showAirBubbles}
                                    airBubblesText={i18n.t('payment_method_page_support_credit_card_payment')}
                                />}
                            {/* viva wallet */}
                            {vivaStatus && vivaMerchantId && vivaApiKey &&
                                <PaymentLabel
                                    content={() => <img src={VivawalletLogo} alt='vivawallet' className='VivawalletLogo' />}
                                    selected={paymenMethod === 5}
                                    allUnselected={paymenMethod === 0}
                                    onSelect={() => { this.setState({ paymenMethod: 5, showAirBubbles: false }) }}
                                    showAirBubbles={showAirBubbles}
                                    airBubblesText={i18n.t('payment_method_page_support_credit_card_payment')}
                                />}
                            {/* 线下支付 */}
                            {cashStatus && (settingCodCashEnable || settingCodPosEnable) &&
                                <PaymentLabel
                                    content={() =>
                                        <div className='cashText'>
                                            {i18n.t('payment_page_button_cash_on_delivery')}
                                            {((!orderId && checkoutData.orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY) ||
                                                (orderId && orderType === '0'))
                                                &&
                                                <span className='maxOfflineFee'>
                                                    ({i18n.t('payment_page_button_at_most')} €{`${maxOfflineFee.toFixed(2)}`})
                                                </span>}
                                        </div>}
                                    selected={paymenMethod === 2}
                                    allUnselected={paymenMethod === 0}
                                    onSelect={() => { this.setState({ paymenMethod: 2, showAirBubbles: false }) }}
                                    disabled={cashPayDisabled}
                                />}
                            {/* 线下支付渠道 */}
                            {cashStatus && (settingCodCashEnable || settingCodPosEnable) &&
                                < div className='twoPaynowList' style={{ display: paymenMethod === 2 ? 'flex' : 'none' }}>
                                    {settingCodCashEnable && <PaymentLabel
                                        content={() =>
                                            <>
                                                <IoMdCash className='cashLogo' />
                                                <div className='cardLogoText'>
                                                    <span>{i18n.t('payment_page_text_cash_payment')}</span>
                                                </div>
                                            </>}
                                        selected={paymentCodType === '0'}
                                        allUnselected={paymentCodType === '-1'}
                                        onSelect={() => { this.setState({ paymentCodType: '0' }) }}
                                    />}
                                    {settingCodPosEnable && <PaymentLabel
                                        content={() =>
                                            <>
                                                <img className='posLoge' src={postofsale} alt='card' />
                                                <div className='cardLogoText'>
                                                    <span>{i18n.t('payment_page_text_pos_payment')}</span>
                                                </div>
                                            </>}
                                        selected={paymentCodType === '1'}
                                        allUnselected={paymentCodType === '-1'}
                                        onSelect={() => { this.setState({ paymentCodType: '1' }) }}
                                    />}
                                </div>}
                        </div>
                        {(paypalStatus || twoPayNowStatus || (stripeStatus && stripePkKey) || cashStatus) &&
                            <div className='createOrderBtnBox'>
                                <Button
                                    className={`createOrderBtn ${(paymenMethod === 0 || (paymenMethod === 4 && twoPayNowWay === '-1') || (paymenMethod === 2 && paymentCodType === '-1')) ? 'disabledCreateOrderBtn' : ''}`}
                                    onClick={async () => {
                                        if (orderId) {
                                            await this.payAgain();
                                        } else {
                                            await this.createOrder();
                                        }
                                    }}
                                >
                                    {i18n.t('payment_page_text_checkout')}
                                </Button>
                            </div>}
                    </div>}
                {/* 支付成功 */}
                {
                    paymentStatus === 'PAID_SUCCESS' &&
                    <div className='paidSuccessView'>
                        <div className='paidSuccessViewContent'>
                            <div className='paidText'>{i18n.t('payment_method_page_thank_you_and_good_appetite')}</div>
                            <div>
                                <img alt='' src={orderCompleteLogo} className='paidSuccessImg' />
                            </div>
                            <Button className='myOrderBtn' onClick={() => { this.handleReturn() }}>
                                {i18n.t('sidebar_page_text_my_order')}
                            </Button>
                        </div>
                    </div>
                }
                {/* 付款状态详情弹窗 */}
                <Dialog
                    open={paymentMethodDialogVisible}
                    onClose={() => {
                        this.setState({ paymentMethodDialogVisible: false })
                    }}
                    className={`paymentMethodDialog`}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                    disableBackdropClick={true}
                >
                    {errorDetails.showCloseBtn &&
                        <DialogTitle className='paymentMethodDialogTitle'>
                            <CloseIcon onClick={() => { this.setState({ paymentMethodDialogVisible: false }) }} />
                        </DialogTitle>}
                    <DialogContent className='paymentMethodDialogContent'>
                        <div>
                            {(paymentStatus === 'CREATING' || paymentStatus === 'PAYING') &&
                                <CircularProgress style={{ color: '#ffdc33' }} />}
                            <div className='tipsText'>
                                {!(paymentStatus === 'CREATED_ERROR' || paymentStatus === 'PAID_ERROR') &&
                                    <p>
                                        {paymentStatus === 'CREATING' ?
                                            i18n.t('payment_page_text_create_orde')
                                            : paymentStatus === 'CREATED_SUCCESS' ?
                                                i18n.t('payment_page_text_create_order_successfully_to_pay')
                                                : paymentStatus === 'PAYING' ?
                                                    i18n.t('payment_page_text_to_pay_wait')
                                                    : ''
                                        }
                                    </p>}
                                {(paymentStatus === 'CREATED_ERROR' || paymentStatus === 'PAID_ERROR') &&
                                    <div className='errorDetailsBox'>
                                        <MdError className='errorIco' />
                                        {errorDetails.message && <p>{errorDetails.message}</p>}
                                        {errorDetails.subMessage && <p>{errorDetails.subMessage}</p>}
                                        {errorDetails.showPhone &&
                                            <div>
                                                <p>{i18n.t('payment_page_button_failed_create_order_contact_restaurant')}</p>
                                                <br />
                                                {/* 区号和电话号码要同时存在 */}
                                                {
                                                    restaurantPhonePrefix && restaurantPhone &&
                                                    <a href={`tel: ${restaurantPhonePrefix} ${restaurantPhone}`}>
                                                        {'+'}{restaurantPhonePrefix} {restaurantPhone}
                                                    </a>
                                                }
                                            </div>}
                                    </div>
                                }
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}
export default PaymentMethod

// *********************
// State & Props
// *********************

interface State extends IPaymentMethodState {
    // 判断支付方式 0:未选择 1:paypal支付 2:线下支付 3:stripe支付 4:2PAYNOW支付 5:viva wallet
    paymenMethod: 0 | 1 | 2 | 3 | 4 | 5,
    twoPayNowWay: '-1' | '0' | '1' | '5',
    paymentCodType: '-1' | '0' | '1',
    paypalStatus: boolean,
    twoPayNowStatus: boolean,
    stripeStatus: boolean,
    stripePkKey: string,
    cashStatus: boolean;
    showNoPaymentMethod: boolean;
    restaurantStringId: string;
    restaurantPhone: string;
    restaurantPhonePrefix: string;
    maxOfflineFee: number;
    orderId: string;
    paymentMethodDialogVisible: boolean;
    paymentStatus: 'CREATING' | 'CREATED_SUCCESS' | 'CREATED_ERROR' | 'PAYING' | 'PAID_SUCCESS' | 'PAID_ERROR';
    errorDetails: {
        message?: string;
        subMessage?: string;
        showPhone?: boolean;
        showReturnBtn?: boolean;
        showCloseBtn?: boolean;
    }
    loadingStatus: boolean;
    checkoutData: CheckoutData;
    orderType: string | undefined;
    orderToTalPrice: number;
    showAirBubbles: boolean;
    setting2paynowCradEnable: boolean;
    setting2paynowAlipayEnable: boolean;
    setting2paynowWechatEnable: boolean;
    settingStripeAlipayEnable: boolean;
    settingStripeCradEnable: boolean;
    settingCodCashEnable: boolean;
    settingCodPosEnable: boolean;
    vivaStatus: boolean;
    vivaMerchantId: string;
    vivaApiKey: string;
}

interface Props extends IPaymentMethodProps {

}

function PaymentLabel(props: {
    content: () => JSX.Element;
    selected: boolean;
    allUnselected: boolean;
    onSelect: () => void;
    disabled?: boolean;
    airBubblesText?: string;
    showAirBubbles?: boolean;
}) {
    const [isShowAirBubbles, onShowAirBubbles] = React.useState(props.showAirBubbles);
    React.useEffect(() => {
        onShowAirBubbles(props.showAirBubbles);
    }, [props.showAirBubbles])
    return (
        <div className={`paymentLabel ${props.allUnselected || props.selected ? 'allUnselected' : ''}`}
            onClick={() => { if (!props.disabled) props.onSelect() }
            }>
            {isShowAirBubbles ?
                <AirBubbles
                    title={props.airBubblesText || ''}
                    attachClass='paymentLabelAirBubbles'
                    onClick={() => { onShowAirBubbles(false) }}
                /> : null}
            <div className='content'>
                {props.content()}
            </div>
            <div className='selectedBox' >
                <span className={`circleBox ${props.disabled ? 'disabledCircleBox' : props.selected ? 'selectedCircleBox' : ''}`}>
                    {props.disabled ? <AiOutlineStop /> : props.selected && <Done />}
                </span>
            </div>
        </div >
    )
}