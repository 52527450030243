

import React from 'react';
import './privacy-apgreement.style.scss';
import TopBar from '../top-bar/top-bar.component';
import i18n from '../../i18n';
import PrivacyApgreementText from './privacy-apgreement-text';

export function PrivacyApgreement(props: {
    handleClose: () => void;
}) {
    return (
        <div className='PrivacyApgreement'>
            <TopBar
                title={i18n.t('privacy_agreement_text')}
                isGoBack={() => {
                    if (props?.handleClose) {
                        props.handleClose?.()
                    }
                }}
                isHideMenu={true}
                backgroundstyle={'backgroundTransparent'}
                isHideGoback={!props?.handleClose}
            />
            <div className='PrivacyApgreementText'>
                <PrivacyApgreementText />
            </div>
        </div>
    )
}