import { Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';

import { MdChevronLeft } from 'react-icons/md';
import { IS_PHONE, IS_VCODE } from '../../constants/base.constant';
import { GOOGLE_ROBOT_SCORE } from '../../gconfig';
import i18n from '../../i18n';
import { ICheckoutShowedPageNames } from '../../interfaces/checkout.interface';
import apiManage from '../../request';
import ButtonComponent from '../button.component';
import { PhoneInput } from '../payment/payment.componet';
import { PrivacyApgreementDialog } from '../privacy-apgreement/privacy-apgreement-dialog.componet';
import ReCaptChaDialog from '../recaptcha-dialog/recaptcha-dialog';
import Notification from '../../component/snackbar/snackbar';
import _ from 'lodash';
import VerificationCode from './verification-code.component';
import Drawer from '@material-ui/core/Drawer/Drawer';
import { signIn_param } from './checkout-sign-in-password-page';
import { FeatGoogleReCaptcha, TimeoutAtCodeDelay } from '../../page/sign-in/sign-in.page';


class CheckoutThirdPartySignInPhone extends React.Component<Props, State> {
    public state: State = {
        // 谷歌人机校验获取token
        showGoogleReCaptcha: false,
        // 谷歌人机校验图像弹窗
        showReCaptChaDialog: false,
        // 是否通过人机校验
        isPassReCaptcha: false,
        isShowPrivacyApgreementDialog: false,
        // 显示验证码页面
        isVerificationCodePage: false,
        // 验证码
        vcode: '',
        // 托盘的状态
        bottomPanelVisible: false,
        // 同意协议政策
        isAgree: false
    }
    signInBtnAvailable: boolean = true;


    /**
     * 通过reCaptchaToken解析分值
     */
    public async checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        this.setState({
                            showReCaptChaDialog: true
                        })
                    } else {
                        // 评分高，已验证不是机器人
                        this.setState({
                            isPassReCaptcha: true,
                            isVerificationCodePage: false
                        })
                        // 发送验证码
                        this.props.sendPhoneGetVerificationCode();
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    public handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            this.setState({
                isPassReCaptcha: true,
                isVerificationCodePage: false
            })
            // 发送验证码
            this.props.sendPhoneGetVerificationCode();
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        // 提示
        Notification.notification(i18n.t(message));
    }

    /**
     * #### 第三方登录,
     * 判断顾客信息是否完善, 不完善跳入信息补全页面
     */
    public async signIn() {
        const { phone, phonePrefix } = this.props;
        const params = {
            phone,
            phone_prefix: phonePrefix,
            code_value: this.state.vcode
            // third_party: {
            //     type: this.props.thirdPartyType?.toLocaleUpperCase(),
            //     token: this.props.thirdPartyToken
            // }
        }
        this.props.signIn(params);
    }

    render() {
        const { showGoogleReCaptcha, showReCaptChaDialog, isPassReCaptcha, isShowPrivacyApgreementDialog, isVerificationCodePage, vcode, bottomPanelVisible, isAgree } = this.state;
        const { phone, phonePrefix, vcodeTimeMS } = this.props;
        return (
            <div>
                {isVerificationCodePage ?
                    <div className='checkout-sign-in-phone'>
                        <div className='headBox'>
                            <span className='actionIcon' onClick={() => { this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE) }}>
                                <MdChevronLeft />
                            </span>
                            <span className='title'>
                                {i18n.t('signin_page_text_Bind_your_phone_number')}
                            </span>
                        </div >
                        <PhoneInput
                            className={'signUpPhoneInput'}
                            hideLabel={true}
                            selectItem={phonePrefix}
                            defaultInput={phone}
                            handleChange={(val) => {
                                this.props.updateState({
                                    phone: val?.trim()
                                });
                            }}
                            handleSelect={(val) => {
                                this.props.updateState({
                                    phonePrefix: val
                                });
                            }}
                            type='autoComplete'
                        />
                        <Typography className='tip-text'>
                            {i18n.t('delivery_address_page_signin_prompt_message')}
                        </Typography>
                        {/* <Typography className='change-button' onClick={() => {
                            if (!IS_PHONE(phonePrefix, phone)) return;
                            this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PASSWORD_PAGE)
                        }}>
                            Log in with password
                        </Typography> */}
                        <ButtonComponent
                            className={!(IS_PHONE(phonePrefix, phone) && isAgree) ? 'signup-button-not-phone' : 'signup-button-div'}
                            value={i18n.t('payment_page_text_next')}
                            onClick={async () => {
                                if (!(IS_PHONE(phonePrefix, phone) && isAgree)) return;
                                if (this.signInBtnAvailable === false) return;
                                this.signInBtnAvailable = false;
                                try {
                                    if (isPassReCaptcha) {
                                        this.setState({
                                            isVerificationCodePage: false
                                        })
                                        // 发送验证码
                                        await this.props.sendPhoneGetVerificationCode();
                                    } else {
                                        this.props.checkoutClearInterval();
                                        this.setState({ showGoogleReCaptcha: true });
                                    }
                                } finally {
                                    this.signInBtnAvailable = true;
                                }
                            }}
                        />

                        <div className='agreePolicy'  >
                            <FormControlLabel
                                className='agreePolicyCheckBox'
                                control={
                                    <Checkbox
                                        checked={this.state.isAgree}
                                        onChange={() => {
                                            this.setState({
                                                isAgree: !this.state.isAgree
                                            })
                                        }}
                                        value='checkBoxValue'
                                        style={{ color: '#ffdc33' }}
                                    />
                                }
                                label={<>
                                    <span className='agreeText'>
                                        {i18n.t('privacy_agreement_text_by_logging_in_registering_you_agree_to')}
                                    </span>
                                    <span className='clickPolicyText'
                                        onClick={(e) => { e.stopPropagation(); this.setState({ isShowPrivacyApgreementDialog: true }) }}
                                    >
                                        {i18n.t('privacy_agreement_text')}
                                    </span>
                                </>}
                            />
                        </div>
                        {/* 谷歌人机校验获取token*/}
                        {<FeatGoogleReCaptcha
                            showGoogleReCaptcha={showGoogleReCaptcha}
                            onVerify={async (token) => {
                                this.setState({
                                    showGoogleReCaptcha: false
                                }, () => {
                                    this.checkReCaptchaToken(token);
                                })
                            }} />}
                        {/* 谷歌人机选择图像校验*/}
                        {showReCaptChaDialog &&
                            <ReCaptChaDialog
                                handleClose={() => { this.setState({ showReCaptChaDialog: false }) }}
                                handelReCaptchaCallBack={(result) => { this.handelReCaptchaCallBack(result) }}
                            />}
                        {isShowPrivacyApgreementDialog
                            ? <PrivacyApgreementDialog
                                // onConfirm={() => { this.isAgree() }}
                                handleClose={() => { this.setState({ isShowPrivacyApgreementDialog: false }) }}
                            />
                            : null}
                    </div> :
                    <div className='checkout-sign-in-phone'>
                        <div className='headBox'>
                            <span className='actionIcon' onClick={() => { this.props.updateState({ phone: '' }); this.setState({ isVerificationCodePage: true }) }}>
                                <MdChevronLeft />
                            </span>
                            <span className='title'>
                                {i18n.t('reset_password_page_text_insert_vcode')}
                            </span>
                        </div >
                        <Typography className='tip-text'>
                            {i18n.t('delivery_address_page_please_your_verification_code', { phone: `${phonePrefix} ${phone}` })}
                        </Typography>
                        <VerificationCode
                            defaultValue={vcode}
                            onChange={(vCode) => this.setState({
                                vcode: vCode
                            })}
                        />
                        <div onClick={() => {
                            this.setState({
                                bottomPanelVisible: true
                            })
                        }}>
                            {
                                vcodeTimeMS !== TimeoutAtCodeDelay ?
                                    <Typography className='change-button'>
                                        {i18n.t('delivery_address_page_resend_verification_code', { number: vcodeTimeMS })}
                                    </Typography>
                                    :
                                    <Typography className='change-button' >
                                        {i18n.t('delivery_address_page_not_receive_verification_code')}
                                    </Typography>
                            }
                        </div>
                        <ButtonComponent
                            className={!IS_VCODE.test(vcode) ? 'signup-button-not-phone' : 'signup-button-div'}
                            value={i18n.t('payment_page_text_next')}
                            onClick={() => {
                                if (!IS_VCODE.test(vcode)) return;
                                this.signIn()
                            }}
                        />
                        <Drawer
                            anchor='bottom'
                            onClose={() => {
                                this.setState({
                                    bottomPanelVisible: false
                                })
                            }}
                            open={bottomPanelVisible}>
                            <div className='bottomPanel'>
                                <Button
                                    fullWidth
                                    className={this.props.vcodeTimeMS === TimeoutAtCodeDelay ? 'bottomPanelButton' : 'bottomPanelButton-active'}
                                    variant='contained'
                                    onClick={() => {
                                        if (this.props.vcodeTimeMS !== TimeoutAtCodeDelay) return;
                                        if (isPassReCaptcha) {
                                            this.props.sendPhoneGetVerificationCode();
                                        } else {
                                            this.setState({ showGoogleReCaptcha: true })
                                        }
                                    }}
                                >
                                    {this.props.vcodeTimeMS !== TimeoutAtCodeDelay ?
                                        i18n.t('delivery_address_page_resend_verification_code', { number: vcodeTimeMS })
                                        : i18n.t('signup_page_text_send_vcode')}
                                </Button>
                                <Button
                                    fullWidth
                                    className='bottomPanelButton cancelPanelButton'
                                    variant='contained'
                                    onClick={() => this.setState({
                                        bottomPanelVisible: false
                                    })}>
                                    {i18n.t('general_text_cancel')}
                                </Button>
                            </div>
                        </Drawer>
                        {/* 谷歌人机校验获取token*/}
                        {<FeatGoogleReCaptcha
                            showGoogleReCaptcha={showGoogleReCaptcha}
                            onVerify={async (token) => {
                                this.setState({
                                    showGoogleReCaptcha: false
                                })
                                this.checkReCaptchaToken(token)
                            }} />}
                        {/* 谷歌人机选择图像校验*/}
                        {showReCaptChaDialog &&
                            <ReCaptChaDialog
                                handleClose={() => { this.setState({ showReCaptChaDialog: false }) }}
                                handelReCaptchaCallBack={(result) => { this.handelReCaptchaCallBack(result) }}
                            />}
                    </div>
                }
            </div>
        )
    }
}

export default CheckoutThirdPartySignInPhone;


// **************
// TYPE
// **************

type State = {
    showGoogleReCaptcha: boolean;
    showReCaptChaDialog: boolean;
    isPassReCaptcha: boolean;
    isShowPrivacyApgreementDialog: boolean;
    isVerificationCodePage: boolean;
    // 验证码
    vcode: string;
    bottomPanelVisible: boolean;
    isAgree: boolean;
};

type Props = {
    checkoutClearInterval: () => void;
    updateState: (update: any) => void;
    // 发送验证码
    sendPhoneGetVerificationCode: () => void;
    // 跳转页面
    updateShowedPage: (targetPage: ICheckoutShowedPageNames) => void;
    // 第三方手机号密码
    phone: string;
    phonePrefix: string;
    vcodeTimeMS: number;
    signIn: (param: signIn_param) => void;
};
