import React from 'react';
export default class GridLayoutComponent extends React.Component<GridLayoutComponentProps, GridLayoutComponentState> {

    constructor(props: GridLayoutComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // state
        this.state = {
        }
    }

    // *********************
    // View
    // *********************

    render(this: any) {
        const { columns, leftText, leftClass, rightText, rightClass, centerText, centerClass, gridClass, isShowMargin, isShowDot, isShowLine, discount } = this.props
        return (
            columns === 1 ?
                <div className={`gridShow-1 ${gridClass ? gridClass : ''}`}>
                    <span className={`left ${leftClass ? leftClass : ''}`}>{leftText}</span>
                </div> :
                columns === 2 ?
                    <div className={`gridShow-2 ${gridClass ? gridClass : ''}  ${discount ? 'discount' : ''}`}>
                        <span className={`left ${leftClass ? leftClass : ''}`}>{leftText}</span>
                        <span className={`right ${rightClass ? rightClass : ''}`}>{rightText}</span>
                    </div>
                    :
                    <div className={`gridShow-3 ${gridClass}`}>
                        <span className={`left ${leftClass ? leftClass : ''} ${isShowMargin ? 'paddingLeft40' : ''}`}>
                            {isShowDot && <span className='titleDot'>·</span>}
                            {isShowLine && <span className='titleDot'>-</span>}
                            <span>
                                {leftText}
                            </span>
                        </span>
                        <span className={`center ${centerClass ? centerClass : ''}`}>{centerText}</span>
                        <span className={`right ${rightClass ? rightClass : ''}`}>{rightText}</span>
                    </div>
        )
    }
}

// *********************
// States
// *********************

type GridLayoutComponentProps = {
    columns: 1 | 2 | 3;
    leftText?: string;
    leftClass?: string;
    centerText?: string;
    centerClass?: string;
    rightText?: string | JSX.Element;
    rightClass?: string;
    gridClass?: string;
    isShowMargin?: boolean;
    isShowDot?: boolean;
    isShowLine?: boolean;
    discount?: boolean;
}

type GridLayoutComponentState = {
}
