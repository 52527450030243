import _ from 'lodash';

/**
 * 根据浏览器当前语言，自动识别（若识别出错，则默认IT 39）
 * @returns 手机国家区号
 */
export function getBrowserPhoneCode() {
    try {
        // 根据浏览器当前语言，自动识别（若识别出错，则默认IT）
        const browserLanguage = navigator.language.substring(0, 2).toLocaleUpperCase() || 'IT';
        // 中国86 意大利 39 英国 44 西班牙 34
        const appLanguage = { ZH: '86', IT: '39', ES: '34', LU: '352' };
        const browserPhonePrefix: string = _.get(appLanguage, browserLanguage, '39');
        return browserPhonePrefix
    } catch (error) {
        return '39'
    }
}