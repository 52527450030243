import { LOCAL_STORAGE_KEYS } from './local-storage.constant';

export class LocalStorageManager {

    /**
     * 设置本地存储
     * @param path 本地存储的key
     */
    public static setLocalStorage(action: { path: LOCAL_STORAGE_KEYS, value: unknown }) {
        try {
            localStorage.setItem(action.path, JSON.stringify(action.value));
        } catch (error) { }
    }

    /**
     * 获取本地存储
     * @param path 本地存储的key
     */
    public static getLocalStorage(path: LOCAL_STORAGE_KEYS) {
        try {
            let value: any = localStorage.getItem(path);
            if (value) {
                value = JSON.parse(value);
            }
            return value;
        } catch (error) { }
    }
}
