import './shopping-basket.scss'

import { Drawer } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { PackageMealItem } from './package-meal-item.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MdAdd } from 'react-icons/md';
import i18n from '../../../i18n';
import _ from 'lodash';
import { BasketDish, CartActions, CollectiveItemArea } from '../../../interfaces/collective-dish-panel.interface';
import { PM } from '../tool-points';

// 购物篮组件
export function ShoppingBasket(
    props: {
        isEdit: boolean;
        setDishType: string;
        basketPackagePrice: number;
        isShowBasket: boolean;
        onClose: () => void;
        drawerStyles?: string;
        basketDishData: Array<CollectiveItemArea>;
        updataShoppingBasket: (actions: Array<CartActions>, itemData: CollectiveItemArea) => void;
        updateState: (val) => void;
        isRepeated?: boolean;
        maxNum: number;
        dishNum: number;
        isDisableAdd: boolean;
        addToShoppingCar: () => void;
        currentBasketLevel: number;
        handleCollectiveData?: (itemData: BasketDish, basketAreasId: string) => void;
        isPoints?: boolean;
    }
) {
    const { isShowBasket, onClose, drawerStyles, basketDishData, updataShoppingBasket, isRepeated, maxNum, dishNum, isDisableAdd,
        addToShoppingCar, setDishType, updateState, currentBasketLevel, handleCollectiveData, isEdit, isPoints } = props
    // 包餐总价
    let dishesTotalPrice: number = 0
    // 添加到购物篮或购物车按钮是否可点 false不可点
    let diableIcomButton: boolean = true
    props.basketDishData.forEach(item => {
        if (diableIcomButton) {
            // 当区域必选 但未选择菜品或未满足条件时按钮不可点
            if (item.is_required) {
                if (item.customer_min_num_dishes) {
                    if (item.customer_min_num_dishes > item.totalDishes) diableIcomButton = false
                } else if (!item.totalDishes) {
                    diableIcomButton = false
                }
            } else {
                // 当区域非必选有必选满 但选择的菜品未满足时按钮不可点
                if (item.customer_min_num_dishes && item.totalDishes) {
                    if (item.customer_min_num_dishes > item.totalDishes) diableIcomButton = false
                }
            }
        }
        if (item.totalDishes) {
            dishesTotalPrice += item.totalDishesPrice
        }
    })
    if (!dishNum) diableIcomButton = false
    dishesTotalPrice += props.basketPackagePrice ?? 0
    return (
        <Drawer
            classes={{ root: drawerStyles }}
            anchor='right'
            open={isShowBasket}
            onClose={() => { onClose() }}>
            <div className='shoppingBasket'>
                <div className='price'>
                    <div className='menuPrice'>
                        {setDishType === '1' ?
                            `${i18n.t('restaurant_order_page_text_Menu')} ${PM.showPointsOrPrice(isPoints, dishesTotalPrice || 0)}`
                            : i18n.t('restaurant_order_page_text_set_meal')}
                    </div>
                    <div className='close'>
                        <IconButton onClick={() => { onClose() }}>
                            <CloseIcon className='closeIcon' />
                        </IconButton>
                        <div>
                            <span>{dishNum}</span>
                            {maxNum ? <span>/{maxNum}</span> : null}
                        </div>
                    </div>
                </div>
                <div className='basketDishs'>
                    {_.isArray(basketDishData) && basketDishData.length > 0 ?
                        basketDishData.map((item, itemIndex) => {
                            return (
                                <div key={itemIndex}>
                                    {setDishType === '2' ? item?.totalDishes === 0 ? null :
                                        <div className={`${item?.totalDishes === item.customer_max_num_dishes ? 'handleGreen' : ''} categoryTitle`}>
                                            <h6>{item.name}</h6>
                                            <span className={`${item.is_required && !item.totalDishes && !item.customer_max_num_dishes ? 'handleRed' : ''}`}>{item.totalDishes}{item.customer_max_num_dishes ? '/' : ''}</span>
                                            {item.customer_max_num_dishes ?
                                                <span className={`${!item.isAchievedCriteria ? 'handleRed' : ''}`}>{item.customer_max_num_dishes}</span> : null}
                                            {item.totalDishes && item.isAchievedCriteria ? <CheckCircleIcon className='checkIcon' /> : null}
                                        </div> : null}
                                    <ul>
                                        {_.isArray(_.get(item, 'set_meals', [])) && _.get(item, 'set_meals', []).length > 0 ?
                                            _.get(item, 'set_meals', []).map((iitem, index) => {
                                                return (
                                                    <li key={index}>
                                                        {iitem.num ?
                                                            <PackageMealItem
                                                                basketAreasId={item._id}
                                                                handleCollectiveData={(itemData, basketAreasId) => {
                                                                    handleCollectiveData?.(itemData, basketAreasId);
                                                                }}
                                                                updateState={(val) => { updateState(val); }}
                                                                isDisableAdd={isDisableAdd}
                                                                isRepeated={isRepeated}
                                                                itemData={iitem}
                                                                type='shoppingBasket'
                                                                updataShoppingBasket={(actions) => {
                                                                    updataShoppingBasket(actions, item);
                                                                }}
                                                                allselectAllergen={[]}
                                                                isPoints={isPoints}
                                                            />
                                                            : null}
                                                    </li>
                                                )
                                            }) : null}

                                        {_.isArray(item?.dishes) && item?.dishes.length > 0 ?
                                            item?.dishes.map((iitem, index) => {
                                                return (
                                                    <li key={index}>
                                                        {iitem.num ?
                                                            <PackageMealItem
                                                                basketAreasId={item._id}
                                                                updateState={(val) => { updateState(val) }}
                                                                isDisableAdd={isDisableAdd}
                                                                isRepeated={isRepeated}
                                                                itemData={iitem}
                                                                updataShoppingBasket={(actions) => {
                                                                    updataShoppingBasket(actions, item)
                                                                }}
                                                                type='shoppingBasket'
                                                                allselectAllergen={[]}
                                                                isPoints={isPoints}
                                                            />
                                                            : null}

                                                    </li>
                                                )
                                            }) : null}

                                    </ul>
                                </div>
                            )
                        }) : null}
                </div>
                <div className='footer'>
                    <button className={`addCart ${diableIcomButton ? '' : 'disAddCart'}`} onClick={() => { addToShoppingCar() }}>
                        <MdAdd className='mdAddIco' />
                        <span>
                            {
                                currentBasketLevel === 0 ?
                                    isEdit ?
                                        i18n.t('restaurant_order_page_text_confirm_the_changes') :
                                        i18n.t('restaurant_order_page_button_add_to_cart') :
                                    i18n.t('restaurant_order_page_text_add_to_shopping_basket')
                            }
                        </span>
                    </button>
                </div>
            </div>
        </Drawer>
    )
}