import _ from 'lodash';
import React from 'react';
import { MdShoppingBasket, MdKeyboardArrowRight } from 'react-icons/md';
import i18n from '../../i18n';
import { CartActions, CartRemarkAction, CheckoutDetailCartDish, ICheckoutCart, ICheckoutCartProps, ICheckoutCartState } from '../../interfaces';
import { DishFixed } from '../../models/fixed/dish.entity';
import { debounce } from '../decorators/helper.decorator';
import { CheckoutCartItem } from './checkout-cart-item';
import { IconHeadBox } from './checkout-icon-head-box';
import './scss/checkout-cart.styles.scss';
import { Schedule } from '../../models';
import { ScheduleDialog } from '../restaurant/schedule-dialog.component';

class CheckoutCart extends React.Component<Props, State> implements ICheckoutCart {

    // 多行文本框ref
    textareaRefs: HTMLTextAreaElement | null;

    constructor(props: Props) {
        super(props)
        this.textareaRefs = null
    }

    public state: State = {
        // 气泡提示对应key
        airBubblesKey: '',
        // 显示
        showCollapseKey: '',
        // 备注
        remark: '',
        // 弹窗
        isOpenSchedulesModel: false,
        // 菜品营业时间表
        dishSchedules: []
    }

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    /**
     * 更新购物车
     */
    @debounce(300)
    analysisAndAdjustCart(actions: CartActions[]) {
        if (this.props.analysisAndAdjustCart && actions) {
            this.props.analysisAndAdjustCart(actions);
        }
    }

    /**
     * 整理套餐组内的菜品
     */
    renderSetMenuDetail(dishItem: CheckoutDetailCartDish): DishFixed[] {
        let setMealDishes: DishFixed[] = [];
        try {
            const dish = dishItem.dish || {};
            let areas: any = [];
            // 包餐
            if (dish.dish_type === '1') {
                areas = _.get(dish, 'package_meal_areas', []);
            }
            // 套餐
            else if (dish.dish_type === '2') {
                areas = _.get(dish, 'set_meal_areas', []);
            }
            if (Array.isArray(areas)) {
                setMealDishes = _.flatMap(areas?.map((areasItem: any) => {
                    return _.concat(areasItem?.set_meals || [], areasItem?.dishes || [])
                }))
            }
        } catch (error) { }
        return setMealDishes as DishFixed[];
    }


    // *********************
    // View
    // *********************

    render() {
        const { airBubblesKey, showCollapseKey, isOpenSchedulesModel, dishSchedules } = this.state;
        const { closeCheckoutComponent, cartDishes, totalCartDishes, showCartErrorText } = this.props;
        return (
            <div className={'checkoutCartBox'} id='checkoutCartBox'>
                <IconHeadBox
                    iCon={() => <MdShoppingBasket />}
                    title={i18n.t('restaurant_order_page_text_cart')}
                    subTitle={`${totalCartDishes} ${i18n.t('general_text_items')}`}
                    actionLabel={() => <>{i18n.t('checkout_page_text_see_menu')} <MdKeyboardArrowRight /></>}
                    action={() => { closeCheckoutComponent() }}
                />
                <div className='dishesList'>
                    {_.isArray(cartDishes) && cartDishes.map((dishItem, index) => {
                        // 相同一个套餐、包餐数量不能大于99
                        const dishTotalNum = _.sumBy(_.filter(cartDishes, (n) => n.dish.firstId === dishItem.dish.firstId), (m) => m.num)
                        // 优惠券气泡
                        let airBubblesText = '';
                        if (dishItem.coupons.length > 0) {
                            dishItem.coupons.forEach((n => {
                                if (airBubblesText) {
                                    airBubblesText = airBubblesText + '<br/>';
                                }
                                airBubblesText += n.name
                            }))
                        }
                        return (
                            <CheckoutCartItem
                                key={index}
                                dishItem={dishItem}
                                dishTotalNum={dishTotalNum}
                                airBubblesKey={airBubblesKey}
                                showCollapseKey={showCollapseKey}
                                clickAirBubles={(key) => {
                                    this.setState({ airBubblesKey: key })
                                }}
                                clickRow={() => {
                                    this.setState({
                                        showCollapseKey: '',
                                        remark: dishItem.dish.remark || '',
                                        airBubblesKey: ''
                                    })
                                    // 点击套餐可编辑套餐内容
                                    if (dishItem.dish.dish_type === '1' || dishItem.dish.dish_type === '2') {
                                        this.props.editSetMeal(dishItem);
                                    }
                                }}
                                handleDish={(isAdd: boolean, num: number = 1) => {
                                    if (this.props.analysisAndAdjustCart && dishItem.helpfulId) {
                                        if (isAdd) {
                                            this.props.analysisAndAdjustCart([{ id: dishItem.helpfulId, type: 'ADD', num, dishTotalNum }]);
                                        } else {
                                            this.props.analysisAndAdjustCart([{ id: dishItem.helpfulId, type: 'REDUCE', num }]);
                                        }
                                    }
                                }}
                                changeRemark={
                                    (action: CartRemarkAction | string) => {
                                        if (typeof action === 'string') {
                                            this.setState({ showCollapseKey: action })
                                        } else {
                                            if (this.props.analysisAndAdjustCart && dishItem.helpfulId) {
                                                this.props.analysisAndAdjustCart([action]);
                                            }
                                        }
                                    }
                                }
                                openDishScheduleDialog={() => { this.setState({ dishSchedules: dishItem.dish.availableSchedule || [], isOpenSchedulesModel: true }) }}
                            />
                        )
                    })}
                </div>
                {showCartErrorText.show &&
                    <div className='errorText'>
                        {i18n.t('checkout_page_text_the_original_price_did_not_reach_the_minimum_delivery_amount', { 1: showCartErrorText.deliveryMinimumFee })}
                    </div>
                }
                {/* 菜品可用时间表 */}
                <ScheduleDialog
                    serviceAllBtn={this.props.takeawayServiceStatus}
                    className='scheduleDialog'
                    isPickUp={this.props.orderDescribeStatus === 'TAKEAWAY_ORDER_PICKUP'}
                    scheduledata={dishSchedules}
                    open={isOpenSchedulesModel}
                    handleClose={() => this.setState({ isOpenSchedulesModel: false })}
                    componentType='dish'
                />
            </div >
        )
    }
}
export default CheckoutCart

// *********************
// Props & State
// *********************

interface State extends ICheckoutCartState {
    airBubblesKey: string;
    showCollapseKey: string;
    remark: string;
    isOpenSchedulesModel: boolean;
    dishSchedules: Schedule[]
}

interface Props extends ICheckoutCartProps {
    showCartErrorText: {
        show: boolean;
        deliveryMinimumFee: number;
    };
    // 编辑套餐、包餐
    editSetMeal: (CheckoutDetailCartDish: CheckoutDetailCartDish) => void;
}