/**
 * @CE AKclown
 */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import _ from 'lodash';
import Store from '../rudex/store';
import { DefaultWebsocketClient } from '../modules/websocket/module';
import {
    Admin, Menu, Dish, Customer, Address, Order,
    Restaurant, CustomerConditionParams, TokenMetadata, CouponPackage
} from '../models'
import i18n, { fillbackWhatsappLngSetting } from '../i18n';
import { LOCAL_STORAGE_KEYS } from '../constants/local-storage.constant';
import { LocalStorageManager } from '../constants/tool-local-storage';
import { cleanAccountCache } from '../constants/tool-check-token';
import Notification from '../component/snackbar/snackbar';
import { createHashHistory } from 'history';
import { GOOGLE_RESERVATIONS_SANDBOX_RWG_TOKEN, RELEASE_VIVA_PAYMENT_URL, SANDBOX_VIVA_PAYMENT_URL } from '../gconfig';
import { ReOrderReport } from '../page/my-order/my-order-detail.page';

const VERSION = 'v1';
const VERSION2 = 'v2';

//  $ 基础url (三种不同的环境：dev、stable、release)
const PREURI = process.env.REACT_APP_BASE_URL ?
    process.env.REACT_APP_BASE_URL : 'https://dev-test-qftech-yennefer-be.evophotic.com';

// const PREURI = 'http://192.168.3.160:9000';

// $ 支付模式 (三种不同的环境：dev、stable、release)
const PayPalMode = process.env.REACT_APP_PAYPAL_MODE ?
    process.env.REACT_APP_PAYPAL_MODE : 'sandbox';

// $ socket链接路径 (三种不同的环境：dev、stable、release)
const socketUrl = process.env.REACT_APP_SOCKET ?
    process.env.REACT_APP_SOCKET : 'https://dev-test-qftech-yennefer-be.evophotic.com:2053/system';

// $ 谷歌预定模式
export const GoogleReservationsMode = process.env.REACT_APP_GOOGLE_RESERVATIONS_MODE ?? 'sandbox';

// $ VIVA 环境
export const VIVA_PAYMENT_URL = process.env.REACT_APP_VIVA_MODE === 'live' ? RELEASE_VIVA_PAYMENT_URL : SANDBOX_VIVA_PAYMENT_URL;

const URI = `${PREURI}/${VERSION}`;
const URI2 = `${PREURI}/${VERSION2}`;
class ApiManage {
    private _axios: AxiosInstance;
    private notNeedLoading?: boolean;
    constructor() {

        // *********************
        // 不要随意修改axios全局配置
        // *********************

        this._axios = axios;
        this.setRequestInterceptors();
        this.setResponseInterceptors();
    }

    /**
     * axios 请求拦截
     */
    public setRequestInterceptors() {
        if (!this._axios) throw new Error('axios 未初始化');
        this._axios.interceptors.request.use((config: any) => {
            const result: AxiosRequestConfig & { notNeedLoading?: boolean } = {
                ...config
            };
            const auth = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN), 'access_token');
            // $ 统一配置auth权限头部信息
            result.headers = Object.assign(
                {},
                {
                    'authorization-type': 'BASIC_AUTH',
                    // 'Content-Type': 'application/json',// 这个字段不能默认添加
                    Authorization: `Bearer ${auth || ''}`
                },
                result.headers
            );
            // 判断是否有不展示loading的参数
            if (!result.notNeedLoading) {
                Store.dispatch({
                    type: 'SET_STATE',
                    path: 'globalLoading',
                    value: true
                })
            }
            return result;
        });
    }

    /**
     * axios 返回拦截
     */
    public setResponseInterceptors() {
        if (!this._axios) throw new Error('axios 未初始化');
        this._axios.interceptors.response.use(
            (resp: any) => {
                const result: AxiosResponse<any> = { ...resp };
                const config: AxiosRequestConfig & { notNeedLoading?: boolean } = result.config;
                if (!config?.notNeedLoading) {
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'globalLoading',
                        value: false
                    })
                }
                return result.data;
            },
            (error: any) => {
                try {
                    // 当服务器发生错误时，直接弹出窗口
                    if (`${_.get(error, 'response.status')}`.startsWith('5')) {
                        console.error(error);
                        Store.dispatch({
                            type: 'SHOW_MESSAGE',
                            message: i18n.t('request_failure_text_service_is_not_available'),
                            details: i18n.t('request_failure_text_remote_server_maintenance')
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
                const config: AxiosRequestConfig & { notNeedLoading?: boolean } = error.config;
                if (!config?.notNeedLoading) {
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'globalLoading',
                        value: false
                    })
                }
                // 接口处理，版本号不对应，就刷新页面
                if (_.get(error, 'response.data.error.code') === 1037 && _.get(error, 'response.data.error.statusCode') === 400) {
                    Notification.notification(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'), { autoHideDuration: 5000 });
                    cleanAccountCache(true);
                    // 当前地址
                    const pathname = createHashHistory().location.pathname;
                    const resStringId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
                    // 餐馆主页
                    if (resStringId) {
                        // 是否是商家网站
                        const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
                        if (pathname === '/orderpage' || pathname === '/paymentMethodPage') {
                            createHashHistory().push(`/restaurantPage?string_id=${resStringId}${merchantWebsite ? '&merchant_website=true' : ''}`)
                        } else {
                            createHashHistory().push(`/`)
                        }
                    }
                    const time = setTimeout(() => {
                        window.location.reload()
                        clearTimeout(time)
                    }, 1000)
                }
                // return
                return {
                    error: true,
                    status: _.get(error, 'response.status'),
                    result: _.get(error, 'response.data.error') || _.get(error, 'response.data')
                };
            }
        );
    }

    // *********************
    // Staff
    // *********************

    /**
     * 根据ID获取staff
     * @param staffId
     * @param config
     */
    public async get_staff_id(
        staffId: string | number,
        type: string,
        config?: AxiosRequestConfig
    ): Promise<any> {
        return await this._axios.get(`${URI}/${type}/${staffId}`, {
            ...config
        });
    }

    /**
     *  获取用户头像
     * @param id 账号id
     */
    public async get_staff_id_avatar(id: string, config?: any) {
        return this._axios.get(
            `${URI}/customer/${id}/avatar`, {
            ...config
        });
    }

    /**
     *  修改用户头像
     * @param id 账号id
     * @param file 文件对象 （formData）
     */
    public async patch_staff_id_avatar(
        id: string,
        file: any
    ): Promise<any> {
        return this._axios.patch(
            `${URI}/customer/${id}/avatar`,
            file,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    /**
     *  绑定邮箱
     * @param id 账号id
     * @param email 邮箱
     * @param verificationCode  验证码
     * @param type 登录的类型 staff / admin
     */
    public async post_bind_email(id: string, _email: string, verificationCode: string, type: string) {
        return this._axios.post(
            `${URI}/${type}/bind/email`, {
            _id: id,
            email: _email,
            vcode: {
                type: 'BIND_EMAIL',
                value: verificationCode
            }
        });
    }

    /**
     *  绑定手机
     * @param id 账号id
     * @param phone 手机号
     * @param verificationCode  验证码
     * @param type 登录的类型 staff / admin
     * @param language 手机前缀
     */
    public async post_bind_phone(id: string, phone: string, verificationCode: string,
        type: string, language: string, merge_customer?: '1', vCodeType?: 'SMS' | 'WHATSAPP', params?: any) {
        // 若有参数，则按照参数来提交。没有的话用回原来的
        if (params) {
            return this._axios.post(
                `${URI}/${type}/bind/phone`, params);
        } else {
            return this._axios.post(
                `${URI}/${type}/bind/phone`, {
                phone,
                _id: id,
                phone_prefix: language,
                vcode: {
                    type: vCodeType === 'SMS' ? 'BIND_PHONE' : 'INIT_BY_WHATS',
                    value: verificationCode
                },
                merge_customer
            });
        }
    }

    // *********************
    // Admin
    // *********************

    /**
     *  $ 获取验证码(发送验证码)
     *  @param account_type 手机前缀
     *  @param type 验证码类型（
     *  RESET_PASSWORD_BY_PHONE 验证码修改密码
     *  INIT_BY_PHONE 登录/注册
     *  BIND_PHONE 绑定手机
     *  BIND_EMAIL 绑定邮箱）
     *  @param phone_prefix 手机前缀
     *  @param phone 手机号码
     *  @param email 邮箱
     *  @param subject 邮箱主题
     */
    public async post_admin_code(
        params: {
            account_type: string;
            type: string;
            phone_prefix?: string;
            phone?: string;
            subject?: string;
            email?: string;
        }
    ): Promise<any> {
        return await this._axios.post(
            `${URI}/code`,
            params
        );
    }

    /**
     * $ 登录请求token
     * @param account 用户名
     * @param password 密码
     * @param phonePrefix 手机号前缀
     * @param type 用户输入的类型  phone | nickname | email 目前用不着
     */

    public async post_auth_token(
        account: string,
        password: string,
        phonePrefix: string
    ): Promise<any> {
        // 已字符串的格式传入，而不是json
        const formData = new URLSearchParams();
        formData.append('phone', account);
        formData.append('phone_prefix', phonePrefix);
        formData.append('password', password);
        formData.append('type', 'CUSTOMER');

        return await this._axios.post(`${URI}/auth/token`, formData);
    }

    /**
     * $ 验证码登入/免登录下单 请求token
     * @param phone 用户名
     * @param password 密码
     * @param phonePrefix 手机号前缀
     * @param vcode 验证码
     * @param third_party_type 第三方登录类型GOOGLE FACEBOOK
     * @param third_party_token 第三方token
     */
    public async post_customer_init(param: {
        phone?: string;
        phone_prefix?: string;
        code_value?: string;
        password?: string;
        third_party_token?: string;
        third_party_type?: string;
    }, config?: any
    ): Promise<any> {
        const res: any = await this._axios.post(`${URI}/customer/init`, param, { ...config });
        // 登陆完成后将目前的语言设置存入本地并提交到后台
        if (!res.error && res._id) {
            LocalStorageManager.setLocalStorage({
                path: LOCAL_STORAGE_KEYS.TOKEN,
                value: { access_token: res.accessToken }
            });
            this.patch_customer_info({ language: fillbackWhatsappLngSetting(i18n.language) }, res._id)
        }
        return res
    }

    /**
     * $ 根据令牌获取令牌元信息
     * @param config axios的配置
     */

    public async get_auth_token_metadata(config?: any): Promise<TokenMetadata> {
        return await this._axios.get(`${URI}/auth/token/metadata`, {
            ...config
        });
    }

    /**
     *  修改密码
     * @param type  手机号 | 邮箱
     * @param value  手机号或者邮箱
     * @param verificationCode 验证码
     * @param newPassword 输入的新密码
     */
    public async patch_admin_password(params: Admin, type: string):
        Promise<any> {
        return await this._axios.patch(
            `${URI}/${type}/password`,
            {
                ...params
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    // /**
    //  * $ 通过facebook登录
    //  * @param token 登录facebook后返回的token
    //  * @param config axios的配置
    //  */
    // public async loginByFaceBook(token: string, config?: any) {
    //     return await this._axios.post(`${URI}/auth/facebook`,
    //         {
    //             clientToken: token,
    //             accountType: 'CUSTOMER'
    //         },
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             ...config
    //         }
    //     );
    // }

    // /**
    //  * 通过google登录
    //  * @param token
    //  */
    // public async loginByGoogle(token: string, config?: any) {
    //     return await this._axios.post(`${URI}/auth/google`,
    //         {
    //             clientToken: token,
    //             accountType: 'CUSTOMER'
    //         },
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             ...config
    //         }
    //     );
    // }

    /**
     * 把账号绑定到fackbook中
     * @param auth
     */
    public async bindFackBook(auth: {
        _id: string;
        clientToken: string;
        merge_customer?: '1';
    }) {
        return await this._axios.patch(`${URI}/customer/${auth._id}/bind/facebook`,
            {
                clientToken: auth.clientToken,
                merge_customer: auth.merge_customer
            }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    /**
     * 把账号绑定到google中
     * @param auth
     */
    public async bindGoogle(auth: {
        _id: string;
        clientToken: string;
        merge_customer?: '1';
    }) {
        return await this._axios.patch(`${URI}/customer/${auth._id}/bind/google`, {
            clientToken: auth.clientToken,
            merge_customer: auth.merge_customer
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    /**
     * 解绑谷歌账号
     * @param auth
     */
    public async unBindGoogle(auth: { _id: string }) {
        return await this._axios.delete(`${URI}/customer/${auth._id}/unlock/google`, {
            data: {},
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    /**
     * 解绑facebook账号
     * @param auth
     */
    public async unBindFaceBook(auth: { _id: string }) {
        return await this._axios.delete(`${URI}/customer/${auth._id}/unlock/facebook`, {
            data: {},
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    // *********************
    // Restaurant
    // *********************

    /**
     * 获取到历史餐厅的logo
     * @param params  restaurantIds:[]
     * @param config 是否开启loading这些配置项
     */


    public async get_history_restaurant_logo(params: any, config?: any) {
        return await this._axios.get(`${URI}/restaurant/logo`, {
            params: {
                ...params
            },
            ...config
        })
    }

    /**
     * 获取到餐厅的logo
     * @param id 餐厅的id
     */
    public async get_restaurant_logo(id: string, config?: any): Promise<string> {
        return await this._axios.get(`${URI}/restaurant/${id}/logo`, {
            ...config
        })
    }

    /**
     * 获取到餐厅的宣传图
     * @param id 餐馆id
     * @param pr_exist_pic 为true,只返回有效图片
     */
    public async get_restaurant_pics(id: string, pr_exist_pic: boolean, config?: any) {
        return await this._axios.get(`${URI}/restaurant/album/${id}/pics`, {
            params: { pr_exist_pic },
            ...config
        })
    }

    // *********************
    // Customer
    // *********************

    /**
     * $ 注册一个新的cusmoter
     * @param params
     * 1.first_name 名字
     * 2.last_name 姓氏
     * 3.password 密码
     * 4.phone 手机号
     * 5.phone_prefix 手机前缀
     * 6.vcode object {type: 验证码的类型，value:验证码}
     */
    public async post_customer(params): Promise<any> {
        return await this._axios.post(`${URI}/customer`, {
            ...params
        });
    }

    /**
     * 根据_id查找到对应的客户
     * // $不拿头像图片，单独有接口拿
     * @param id 用户id
     * @param blocked_group_string_id 集团id(检验用户是否为集团下黑名单)
     */
    public async get_customer_info(id: string, config?: { notNeedLoading: boolean }, params?: { blocked_group_string_id?: string, is_recommendation_integral?: boolean }):
        Promise<Customer> {
        return await this._axios.get(`${URI}/customer/${id}`, {
            params: {
                pr_res_logo: false,
                pr_coupon: true,
                ...params,
            },
            ...config
        });
    }

    /**
     * 根据条件查找多个Customer资源
     */
    public async get_customer_condition(
        params: CustomerConditionParams
    ): Promise<{ dbResults: Array<Customer>; totalCount: number }> {
        return await this._axios.get(`${URI}/customer/condition`, {
            params: {
                ...params
            }
        });
    }

    /**
     * $ 修改Customer密码
     * @param params
     * 1.password 修改的新密码
     * 2.phone 手机号码
     * 3.phone_prefix 手机号码前缀
     * 4.vcode object {type: 验证码的类型，value:验证码}
     * @param config axios的配置
     */
    public async patch_customer_password(params, config?: AxiosRequestConfig): Promise<any> {
        return await this._axios.patch(`${URI}/customer/password`, {
            ...params
        }, config);
    }

    /**
     * 确认用户是否显示过填写密码(是否注册过)
     * @param id 用户id
     * @param config axios的配置
     */
    public async patch_customer_is_password_page(
        id: string,
        config?: AxiosRequestConfig
    ): Promise<{ dbResults: Array<Customer>; totalCount: number }> {
        return await this._axios.patch(`${URI}/customer/${id}/is_password_page`, config);
    }

    /**
     * 创建/修改 地址
     * @param address 修改的地址信息
     * @param id 用户id
     */
    public async patch_customer_address(
        params: { address: Address },
        id: string
    ): Promise<{ dbResults: Array<Customer>; totalCount: number }> {
        return await this._axios.patch(`${URI}/customer/${id}/address`, {
            ...params
        });
    }

    /**
     * 更新用户信息
     * @param params 用户修改的信息
     * @param id 用户ID
     */
    public async patch_customer_info(
        params: { [key: string]: any },
        id: string
    ): Promise<{ dbResults: Array<Customer>; totalCount: number }> {
        return await this._axios.patch(`${URI}/customer/${id}`, {
            ...params
        });
    }



    /**
     * $ 获取客户订单
     * @param params
     * 1.pag_per_page 每页显示的条数
     * 2.pag_page 当前页数
     * 3.origin_status 订单状态  0: 未确认  1: 已确认  2: 已冻结 3: 已取消  4: 已完成
     * 4.sort_value 订单排序，1: 正序  -1: 反序
     * @param config
     * axios的配置
     */

    public async getCustomerOrder(params?: Order, config?: any) {
        const accountId = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT), '_id');
        return await this._axios.get(`${URI}/customer/${accountId}/order`, {
            params,
            ...config
        });
    }

    // *********************
    // Dish
    // *********************

    /**
     * 更新菜品数据
     * @param dishId 菜品id
     * @param params 菜品更新的参数
     */
    public async updateRestaurantDish(
        dishId: string,
        params: Dish,
        config?: AxiosRequestConfig
    ): Promise<{ result: any }> {
        return await this._axios.patch(
            `${URI}/restaurant/${'aaaaa'}/dish/${dishId}`,
            {
                ...params
            },
            { ...config }
        );
    }

    /**
     * 创建菜品
     * @param params 菜品数据
     * @param config axios配置
     */
    public async createRestaurantDish(params: Dish, config?: AxiosRequestConfig) {
        return await this._axios.post(
            `${URI}/restaurant/${'aaaaa'}/dish`,
            {
                ...params
            },
            { ...config }
        );
    }

    /**
     * 上传菜品的图片
     * @param dishId 菜品id
     * @param index 菜品图片索引
     * @param params 上传文件内容
     * @param config axios配置
     */
    public async uploadDishImage(
        dishId: string,
        index: number,
        params: any,
        config?: AxiosRequestConfig
    ): Promise<{ result: any }> {
        const formData = new FormData();
        formData.append('', params);
        return await this._axios.patch(
            `${URI}/restaurant/${'aaaaa'}/dish/${dishId}/pic/${index}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ...config
            }
        );
    }

    /**
     * 获取菜品图片
     * @param dishId 菜品id
     * @param index 菜品图片索引
     */
    public async getDishImage(dishId: string, index: number): Promise<any> {
        return await this._axios.get(
            `${URI}/restaurant/${'aaaaa'}/dish/${dishId}/pic/${index}`
        );
    }

    /**
     * 获取单个套餐的信息
     * @param dishId 菜品id
     * @param stringId 餐馆ID
     * @param isPackageMeal true包餐下套餐的标识
     * @param packageMealAreaId 包餐区域id
     */
    public async getSetMeal(stringId: string, dishId: string): Promise<any> {
        return await this._axios.get(
            `${URI}/restaurant/${stringId}/set_meal/${dishId}?project_is_od_order=true`
        );
    }


    /**
     * 获取单个包餐的信息
     * @param dishId 菜品id
     * @param stringId 餐馆ID
     */
    public async getPackageMeal(stringId: string, dishId: string): Promise<any> {
        return await this._axios.get(
            `${URI}/restaurant/${stringId}/package_meal/${dishId}?project_is_od_order=true`
        );
    }

    /**
     * 获取包餐套餐菜品图片
     * @param stringId 餐馆id
     * @param ids 要获取的图片ids
     * @param config axios配置
     * @param cancelToken axios取消请求
     */
    public async getSetDishImg(stringId: string, ids: Array<string>, config?: any, cancelToken?: CancelToken): Promise<any> {
        return await this._axios.get(
            `${URI}/restaurant/${stringId}/dishs/pics`, {
            params: {
                ids
            },
            ...config,
            cancelToken
        }
        );
    }

    // **************
    // ORDER
    // **************

    /**
     * 创建订单
     * @param belonged_restaurant 餐馆id
     * @param belonged_customer   顾客id
     * @param type                配送类型
     * @param bags                订单数据
     * @param address             地址
     * @param remark              订单备注
     */
    public async postOrderPayment(params: {
        belonged_restaurant: string, belonged_customer: string,
        type: string, bags: Array<Array<Dish>>, address: Address,
        remark: string, delivery_time?: Date,
        selftake_time?: Date, payment_type?: string, payment_detail?: { type?: string, mode?: string }
    }, config?: any): Promise<any> {
        if (params.payment_detail && params.payment_detail.type === 'PAYPAL') {
            _.set(params, 'payment_detail.mode', PayPalMode)
        }
        return await this._axios.post(
            `${URI}/order_payment`,
            {
                ...params
            },
            config
        );
    }

    /**
     * 线下支付订单
     * @param string_id 餐馆id
     * @param _id 订单id
     * @param config 是否开启loading
     * @param paymentCODType 货到付款子方式
     */
    public async postOrderOffline(string_id: string, _id: string, paymentCODType: string, config?: any): Promise<any> {
        return await this._axios.post(
            `${URI}/restaurant/${string_id}/order/${_id}/payment/cash`,
            {
                paymentCODType
            },
            config
        );
    }

    /**
     * paypal支付订单
     * @param string_id 餐馆id
     * @param _id 订单id
     * @param config 是否开启loading
     */
    public async postOrderPaypal(string_id: string, _id: string, config?: any): Promise<any> {
        // $ mode这里两个值，live 线上，sandbox 线下的
        const mode = PayPalMode
        return await this._axios.post(
            `${URI}/restaurant/${string_id}/order/${_id}/payment/paypal/${mode}`,
            {},
            config
        );
    }

    /**
     * Stripe支付订单
     * @param string_id 餐馆id
     * @param _id 订单id
     * @param config 是否开启loading
     */
    public async postOrderStripe(string_id: string, _id: string, config?: any): Promise<any> {
        return await this._axios.post(
            `${URI}/restaurant/${string_id}/order/${_id}/payment/stripe`,
            {},
            config
        );
    }

    /**
     * Viva支付订单
     * @param string_id 餐馆id
     * @param _id 订单id
     * @param config 是否开启loading
     */
    public async postOrderViva(string_id: string, _id: string, params: any, config?: any): Promise<any> {
        return await this._axios.post(
            `${URI}/restaurant/${string_id}/order/${_id}/payment/viva`,
            { ...params },
            config
        );
    }
    /**
     * 2PayNow支付订单
     * @param string_id 餐馆id
     * @param _id 订单id
     * @param type 0:微信 1:支付宝 5:银行卡
     * @param config 是否开启loading
     */
    public async postOrder2PayNow(string_id: string, _id: string, type: string, config?: any): Promise<any> {
        return await this._axios.post(
            `${URI}/restaurant/${string_id}/order/${_id}/payment/2paynow?type=${type}`,
            {},
            config
        );
    }

    /**
     * 2PayNow付款状态
     * @param string_id 餐馆id
     * @param _id 订单id
     * @param config 是否开启loading
     */
    public async getOrder2PayNowStatus(string_id: string, _id: string, config?: any): Promise<any> {
        return await this._axios.get(
            `${URI}/restaurant/${string_id}/order/${_id}/payment/2paynow/query_2paynow_order`,
            { ...config }
        );
    }

    /**
     * $ 获取订单详情
     * @param params
     * 1.string_id 餐馆的id
     * 2.id 订单的id
     * 3.range HISTORY_ORDER:已完成的历史订单
     */
    public async getRestaurantOrder(params?: any, config?: any): Promise<Order> {
        return await this._axios.get(`${URI}/restaurant/${params.string_id}/order/${params.id}`, {
            params: {
                range: params.range ? params.range : undefined
            },
            ...config
        });
    }
    /**
     * 扫码请求
     * @param Id 临时id
     * @param qrcodeUrl 二维码携带信息
     */
    public async getQrcode(id: string, qrcodeUrl: {
        qrcode_url: string,
        qrcode_dx: number, // x轴起始
        qrcode_dy: number, // y轴起始
        qrcode_dw: number, // 二维码高
        qrcode_dh: number, // 二维码宽
        background_dx: number, // x轴起始
        background_dy: number, // y轴起始
        background_dw: number, // 背景宽
        background_dh: number // 背景高
    }): Promise<any> {
        return await this._axios.get(`${URI}/restaurant/${id}/index_qrcode`, {
            params: qrcodeUrl
        });
    }
    /**
     * 根据id查找餐馆信息
     * @param Id 餐馆id
     * @param check_customer_id 游客检测id
     */
    public async getRestaurantInfo(string_id: string, config?: any, check_customer_id?: string): Promise<{
        dbResults: Array<Partial<Restaurant>>
    }> {
        return await this._axios.get(`${URI}/restaurant/condition`, {
            params: {
                string_id,
                check_customer_id,
                // 返回有效环形配送费
                is_delivery_order: true,
                // 注册信息
                pr_registry: false,
                // 日志
                pr_record: false,
            },
            ...config
        });
    }

    /**
     * 根据id查找餐馆菜单信息
     * @param stringId 餐馆id
     * @param pr_dish_pic ture 只拿菜品第一张的图片
     * @param _id 菜单id
     */
    public async getMenuDish(params: {
        stringId: string;
        pr_dish_pic: boolean;
        special_dish: boolean;
        special_dish_status: boolean;
        pr_is_hidden: boolean;
        _id?: string;
    }, config?: any): Promise<{
        dbResults: Array<Partial<Menu>>
    }> {
        return await this._axios.get(`${URI}/restaurant/${params.stringId}/menu/${params._id || ''}`, {
            params: {
                pr_dish_pic: params.pr_dish_pic,
                special_dish: params.special_dish,
                special_dish_status: params.special_dish_status,
                pr_is_hidden: params.pr_is_hidden,
                // 检验套餐授权点
                client_project_source: 'OD_WEB_ORDER',
                pr_record: false
            },
            ...config
        });
    }

    /**
     * 查找餐馆菜单信息（特色菜）
     * @param stringId 餐馆id
     */
    public async getMenuSpecialDish(params: {
        stringId: string;
    }): Promise<Dish[]> {
        return await this._axios.get(`${URI}/restaurant/${params.stringId}/special/dish`);
    }
    /**
     * 查找餐馆菜单信息（特色菜图片）
     * @param stringId 餐馆id
     * @param cancelToken axios取消请求
     */
    public async getMenuSpecialDishPic(params: {
        stringId: string;
    }, config?: any, cancelToken?: CancelToken) {
        return await this._axios.get(`${URI}/restaurant/${params.stringId}/special/pics`, {
            params: {
            },
            ...config,
            cancelToken
        });
    }
    /**
     * 根据id查找餐馆菜单的图片
     * @param stringId 餐馆id
     * @param menus 需要获取菜单id数组JSON
     * @param config
     * @param cancelToken axios取消请求
     */
    public async getMenuDishPics(params: {
        stringId: string;
        menus: Array<string>;
    }, config?: any, cancelToken?: CancelToken): Promise<any> {
        return await this._axios.get(`${URI}/restaurant/${params.stringId}/menu/dish-pics`, {
            params: {
                menus: params.menus
            },
            ...config,
            cancelToken
        });
    }
    /**
     * 增加历史餐馆
     * @param _id 用户id
     * @param restaurant_string_ids 需要执行的餐馆
     */
    public async updateHistoryRestaurants(params: {
        _id: string;
        restaurant_string_ids: Array<string>;
    }, config?: any) {
        return await this._axios.patch(`${URI}/customer/${params._id}/restaurant_string_ids `, {
            restaurant_string_ids: params.restaurant_string_ids
        }, config);
    }

    /**
     * 获取餐馆优惠券包
     * @param stringId 餐馆stringId
     */
    public async getRestaurantCouponPackage(stringId: string, config?: any): Promise<any> {
        return await this._axios.get(`${URI}/restaurant/${stringId}/coupon_package`, {
            params: {
                // 隐藏免领取的优惠券
                // hide_ignore_coupon: true
            },
            ...config
        });
    }

    /**
     * 领取优惠券
     * @param stringId 餐馆stringId
     * @param _cpid 优惠包id
     */
    public async getCoupon(stringId: string, _cpid: string, _cid: string, config?: any) {
        return await this._axios.post(`${URI}/restaurant/${stringId}/coupon_package/${_cpid}/get`, {
            _cid
        }, { ...config });
    }
    /**
     * 通过二维码添加剂优惠券
     * @param info 值
     */
    public async getCouponByScan(info) {
        return await this._axios.get(`${URI}/restaurant/coupon/${info}`);
    }
    /**
     * 通过编码添加剂优惠券
     * @param _id 用户id
     * @param code 值
     */
    public async onInsertCodeCoupon(_id, code): Promise<any> {
        return await this._axios.post(`${URI}/customer/${_id}/coupon_package/${code}`);
    }

    /**
     * 检查是否是当前餐馆的老顾客
     * @param stringId 餐馆stringId
     * @param _id 用户id
     * @returns
     * -值返回true:老用户 false:新用户
     */
    public async checkCustomerIsOldUsers(string_id, _id) {
        return await this._axios.get(`${URI}/restarant/${string_id}/customer/${_id}/is_old_users`);
    }

    /**
     * 检查餐馆 AU1003-是否有动态配送费凭证 AU1004-预定服务 AU1017-主题色 AU1012-餐厅基础权限
     * @param string_id 餐馆string_id
     * @param code 码
     * @param is_order_mode 只查授权点有多少个环
     */
    public async checkCustomerVoucher(string_id, code: string, is_order_mode?: boolean, config?: any) {
        return await this._axios.get(`${URI}/restaurant/${string_id}/check_warrant/${code}?${is_order_mode ? 'is_order_mode=true' : ''}`
            , {
                ...config
            });
    }

    /**
     * 获取餐馆的二维码图片
     * @param _id 餐馆id
     * @param string_id 餐馆string_id
     */
    public async getRestaurantQRcodePic(_id, string_id, config) {
        return await this._axios.get(`${URI}/restaurant/${_id}/qrcode/${string_id}`, {
            ...config
        });
    }
    /**
     * 提交预约
     */
    public async bookingOrder(param): Promise<any> {
        return await this._axios.post(`${URI}/booking_order`, {
            ...param
        });
    }
    /**
     * 提交预约 免登录
     */
    public async bookingOrderNoSignIn(param): Promise<any> {
        return await this._axios.post(`${URI}/booking_order_no_auth`, {
            ...param
        });
    }
    /**
     * 我的预定
     * @param params
     * 1.pag_per_page 每页显示的条数
     * 2.pag_page 当前页数
     * 3.origin_status 订单状态  0: 未确认  1: 已确认  2: 已取消 3: 未赴约  4: 已完成 5:已过期
     * 4.sort_value 订单排序，1: 正序  -1: 反序
     * @param config
     * axios的配置
     */

    public async getCustomerBookOrder(params?: Order, config?: any) {
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        return await this._axios.get(`${URI}/customer/${account._id}/booking_order`, {
            params,
            ...config
        });
    }

    /**
     * 获取集团下的餐馆地址
     * @param string_id 集团string_id
     * @param config
     */
    public async getGroupRestaurantAddress(string_id?: string, config?: any) {
        return await this._axios.get(`${URI}/group/${string_id}/restaurant_address`, {
            params: {},
            ...config
        });
    }

    /**
     * 获取集团下的餐馆
     * @param string_id 集团string_id
     * @param config
     */
    public async getGroupRestaurant(string_id?: string, config?: any) {
        return await this._axios.get(`${URI}/restaurant/condition?belonged_group=${string_id}`, {
            ...config
        });
    }

    /**
     * 获取到集团下的餐厅的logo
     * @param params  restaurantIds:[]
     * @param config 是否开启loading这些配置项
     */


    public async get_restaurant_List_logo(params: any, config?: any) {
        return await this._axios.get(`${URI}/restaurant/logo/restaurantIds`, {
            params: {
                ...params
            },
            ...config
        })
    }
    /**
     *  发送验证码(免登录模式)
     *  @param params
     */
    public async postRegistrationFreeVcode(params: {
        account_type: string;
        type: string;
        phone: string;
        phone_prefix: string;
    }): Promise<any> {
        return await this._axios.post(
            `${URI}/code`,
            params
        );
    }

    /**
     * 检查目标时间，是否是商家忙碌时间
     * @param
     */
    public async checkRestaurantBusyHours(params, config?: any) {
        return await this._axios.get(`${URI}/restaurant/${params.string_id}/check/delivery_busy?start_time=${params.start_time}&time_zone_name=${params.time_zone_name}`, {
            params: {},
            ...config
        });
    }

    /**
     * 获取餐馆免领取的优惠券
     * @param
     */
    public async getExemptCoupon(params, config?: any): Promise<CouponPackage[]> {
        return await this._axios.get(`${URI}/restaurant/${params.string_id}/coupon_package?pr_ignore_get=true`, {
            ...params,
            ...config
        });
    }

    /**
     * 谷歌人机校验
     * @param token
     */
    public async googleReCaptchaSiteverify(params, config?: any):
        Promise<{
            challenge_ts: string;
            hostname: string;
            score: number;
            success: boolean
        }> {
        return await this._axios.post(`${URI}/google/recaptcha/siteverify`,
            { ...params },
            { ...config }
        );
    }

    /**
     * 手机账户是否存在
     */
    public async isPhoneExist(params: { phone_prefix: string, phone: string }, config?: any) {
        return await this._axios.post(`${URI}/customer/is_phone_exist`,
            { ...params },
            { ...config }
        );
    }

    /**
     * 解绑邮箱
     */
    public async customer_unlock_email(id: string): Promise<any> {
        return await this._axios.delete(`${URI}/customer/${id}/unlock/email`);
    }

    /**
     * 获取消息
     */
    public async get_customer_message(id: string): Promise<any> {
        return await this._axios.get(`${URI}/customer/${id}/messages`);
    }

    /**
     * 获取餐馆配送区域
     * app=true 返回区域限制时间详情
     */
    public async get_restaurant_level_delivery(string_id: string): Promise<any> {
        return await this._axios.get(`${URI}/restaurant/${string_id}/setting_level_delivery?app=true`);
    }

    /**
     * 记录google api 调用
     * group_string_id 集团
     */
    public async post_record_google_api(group_string_id: string, params: {
        data: {
            // 调用的api或组件名
            url: string;
            // 调用的次数
            num: number;
            // 记录时间
            record_time: string;
            // 状态'0':成功，'1'：失败
            status: string;
            // 餐馆
            res_string_id: string;
            // ‘0’：路径规划 ‘1’：APP   ‘2’：地图订单
            business: string;
        }[]
    }, config: any = { notNeedLoading: true }): Promise<any> {
        return await this._axios.post(`${URI}/group/${group_string_id}/record/google-api`, params, { ...config });
    }
    /**
     * 删除账户
     * - 14天后硬删除
     */
    public async delect_account(_id: string): Promise<any> {
        return await this._axios.delete(`${URI2}/customer/${_id}`);
    }

    /**
     * 加入会员
     */
    public async join_member(string_id: string, _id: string, config?: any): Promise<any> {
        return await this._axios.patch(`${URI}/${string_id}/customer/${_id}`, undefined, { ...config });
    }
    /**
     * 谷歌预定记录
     */
    public async googleConversionCollect(rwg_token: string): Promise<any> {
        // 沙盒模式 & rwg_token是空
        if (GoogleReservationsMode === 'sandbox' && !rwg_token) {
            return await this._axios.post(`${URI}/google/conversion/collect`, { rwg_token: GOOGLE_RESERVATIONS_SANDBOX_RWG_TOKEN });
        }
        // 生产模式 & rwg_token有值
        else if (GoogleReservationsMode === 'live' && rwg_token) {
            return await this._axios.post(`${URI}/google/conversion/collect`, { rwg_token });
        }
    }

    /**
     * 获取预定时间点的订单数量
     */
    public async getOrderQuantity(string_id: string): Promise<any> {
        return await this._axios.get(`${URI}/restaurant/${string_id}/booking_order/reception`)
    }

    /**
     * 再次订单 检查菜品状态【下架/估清 /价格变动】
     */
    public async checkDishStatus(string_id: string, shopCarList: any[], orderType: string): Promise<ReOrderReport & { error: boolean }> {
        return await this._axios.post(`${URI}/${string_id}/order_again/${orderType}`, shopCarList)
    }

    /**
     * 身份验证 审核
     */
    public async checkCodeShark(params: {
        type: string,
        verify_id: string,
        string_id: string,
        phone_prefix: string,
        phone: string,
        status: string
    }): Promise<any> {
        return await this._axios.post(`${URI}/qr_code/code_shark`, params)
    }

    /**
     * 身份验证 审核
     */
    public async getVerify(params: {
        verify_id: string,
    }): Promise<any> {
        return await this._axios.get(`${URI}/check/code_shark`, { params })
    }
    /**
     * 通过手机是否存在有效审核
     */
    public async getVerifyByPhone(params: {
        phone_prefix: string,
        phone: string,
    }): Promise<any> {
        return await this._axios.get(`${URI}/check/code_phone`, { params })
    }

    /**
     * 第三方登录
     */
    public async loginByThirdParty(params: {
        third_party_token: string;
        third_party_type: string;
    }): Promise<any> {
        return await this._axios.post(`${URI}/customer/third_party/init`, { ...params })
    }
    /**
     * 记录google api 调用 详情
     * group_string_id 集团
     */
    public async post_record_google_api_info(params: {
        data: {
            // 调用的google api类型 (Places API || Distance Matrix API || Geocoding API)
            url: string;
            // 调用的次数
            num: number;
            // 记录时间
            record_time: string;
            // 状态'0':成功，'1'：失败
            status: string;
            // 餐馆
            res_string_id?: string;
            // ‘0’：路径规划 ‘1’：APP   ‘2’：地图订单
            // business: string;
            source: 'OD_WEB',
        }[]
    }, config: any = { notNeedLoading: true }): Promise<any> {
        return await this._axios.post(`${URI}/record/google_api/info`, params, { ...config });
    }


    // *********************
    // Websocket
    // *********************

    /**
     * socket.io初始化
     * @param websocketServerUrl 服务器地址
     * @param token 用户的token
     */
    public websocket_init(
        websocketServerUrl?: string,
        token?: string
    ): void {
        // init
        if (!token) token = (LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) || { access_token: '' }).access_token;
        if (!websocketServerUrl) websocketServerUrl = socketUrl;
        // try to connect
        DefaultWebsocketClient.connect(
            websocketServerUrl,
            `${token}`,
            'BASIC_AUTH'
        );
    }
}

const apiManage = new ApiManage();

export default apiManage;
