import _ from 'lodash';
import moment from 'moment';
import { Customer, Restaurant } from '../models';
import { convertDateByTimeZoneName, DT } from './tool-constant';
import momentTimeZone from 'moment-timezone';
import { CheckoutCouponPackage } from '../models/fixed/checkout-coupon-package.entity';
import { PM } from '../component/order/tool-points';

/**
 * 分析免领取的优惠券
 * @param customer 顾客信息
 * @param ignoreGetCoupon 免领取的优惠券
 * @param restaurant 餐厅信息
 *
 * @returns pass 是否通过
 * @returns receive_fail_rule 不符合的规则
 */
export function ignoreGetCouponByCustomer(
    customer: Customer,
    ignoreGetCoupon: CheckoutCouponPackage,
    restaurant: Restaurant
): {
    pass: boolean;
    receive_fail_rule?: string[];
} {
    try {
        // 不符合的规则数组
        const receive_fail_rule: string[] = [];

        // $ 1. 顾客下单次数领取规则
        if (ignoreGetCoupon.user_obtain_strategy && ignoreGetCoupon.user_obtain_strategy === '0') {
            // 查询订单
            const orders = _.filter(customer.orders ? customer.orders : [], function (o) {
                return o.origin_belonged_restaurant === restaurant.string_id;
            });
            // 在此餐馆未下过订单的用户可以领取:下过单的不能领取
            if (orders.length > 0) {
                // console.log('顾客下单次数领取规则0');
                receive_fail_rule.push('user_obtain_strategy');
            }
        } else if (
            ignoreGetCoupon.user_obtain_strategy &&
            ignoreGetCoupon.user_obtain_strategy === '1'
        ) {
            // 查询订单
            const orders = _.filter(customer.orders ? customer.orders : [], function (o) {
                return o.origin_belonged_restaurant === restaurant.string_id;
            });

            // 在此餐馆下过订单的用户可以领取 : 没有下过单的不可以领取
            if (orders.length === 0) {
                // console.log('顾客下单次数领取规则1');
                receive_fail_rule.push('user_obtain_strategy');
            }
        }

        // $ 2.满足积分领取规则
        const groupStrId = restaurant.belonged_group;
        if (ignoreGetCoupon.integral_full_enable) {
            if (Array.isArray(customer.integrals) && groupStrId) {
                const integral = _.find(customer.integrals, { group_string_id: groupStrId });
                if (integral) {
                    if (Number(integral.value) < Number(ignoreGetCoupon.integral_full_value)) {
                        // console.log('满足积分领取规则');
                        receive_fail_rule.push('integral_full_enable');
                    }
                } else {
                    // console.log('满足积分领取规则');
                    receive_fail_rule.push('integral_full_enable');
                }
            } else {
                // console.log('满足积分领取规则');
                receive_fail_rule.push('integral_full_enable');
            }
        }

        // $ 3.消耗积分领取优惠券
        if (ignoreGetCoupon.integral_deduction_enable) {
            if (Array.isArray(customer.integrals) && groupStrId) {
                // 可用积分
                const integral = PM.getAvailablePoints();
                // const integral = _.find(customer.integrals, { group_string_id: groupStrId });
                if (integral) {
                    const num = Number(integral) - Number(ignoreGetCoupon.integral_deduction_value);
                    if (num < 0) {
                        // console.log('消耗积分领取优惠券');
                        receive_fail_rule.push('integral_deduction_enable');
                    }
                } else {
                    // console.log('消耗积分领取优惠券');
                    receive_fail_rule.push('integral_deduction_enable');
                }
            } else {
                // console.log('消耗积分领取优惠券');
                receive_fail_rule.push('integral_deduction_enable');
            }
        }

        // $ 4.总共消费多少领取规则
        if (ignoreGetCoupon.total_consumption_fee_enable) {
            if (Array.isArray(customer.integrals) && groupStrId) {
                const total_amount = _.find(customer.integrals, (integral) =>
                    integral.group_string_id === groupStrId &&
                    integral.total_amount! >= ignoreGetCoupon.total_consumption_fee_value!
                );
                if (_.isEmpty(total_amount)) {
                    // console.log('总共消费多少领取规则');
                    receive_fail_rule.push('total_consumption_fee_enable');
                }
            } else {
                // console.log('总共消费多少领取规则');
                receive_fail_rule.push('total_consumption_fee_enable');
            }
        }

        // $ 5.下单次数领取规则
        if (ignoreGetCoupon.total_times_limit_enable) {
            if (Array.isArray(customer.integrals) && groupStrId) {
                const order_num = _.find(
                    customer.integrals,
                    (integral) =>
                        integral.group_string_id === groupStrId &&
                        integral.order_num! >= ignoreGetCoupon.total_times_limit_value!
                );
                if (_.isEmpty(order_num)) {
                    // console.log('下单次数领取规则');
                    receive_fail_rule.push('total_times_limit_enable');
                }
            } else {
                // console.log('下单次数领取规则');
                receive_fail_rule.push('total_times_limit_enable');
            }
        }

        // $ 6.顾客下单频率领取优惠券
        if (ignoreGetCoupon.order_frequency_enable) {
            // 当前餐馆的时间
            const resDate = convertDateByTimeZoneName(DT(),
                momentTimeZone.tz.guess(),
                restaurant.time_zone!
            );

            let is_pass = false;
            // 错误提示
            const errorDetail: {
                order_frequency_type?: string,
                order_frequency_value?: number
            } = {
                order_frequency_type: '0',
                order_frequency_value: 1
            };

            // 可以使用此优惠券的餐馆
            let stringIdArray = _.map(ignoreGetCoupon.string_ids, (n) => { return n.string_id })

            stringIdArray = _.compact(
                _.uniq(_.concat([restaurant.string_id], stringIdArray))
            );

            if (customer.orders && Array.isArray(customer.orders)) {
                let latest_date = '';
                // 获取用户所关联的餐厅所有的订单
                _.filter(customer.orders, (order1) => {
                    if (
                        _.includes(stringIdArray, order1.origin_belonged_restaurant)
                        &&
                        order1.origin_status === '4'
                    ) {
                        // 并拿到最早下单时间
                        if (latest_date === '') {
                            latest_date = order1.origin_create_time + '';
                        } else if (moment(order1.origin_create_time).isAfter(latest_date)) {
                            latest_date = order1.origin_create_time + '';
                        }
                        return true;
                    }
                });

                // 当前的系统时间与最新的下单时间相差多少个月
                const diffMonthNum = moment(resDate).diff(moment(latest_date), 'months');
                // 是否满足下单频率的设置
                if (ignoreGetCoupon.order_frequencies &&
                    Array.isArray(ignoreGetCoupon.order_frequencies)
                ) {
                    _.forEach(ignoreGetCoupon.order_frequencies, (frequency) => {
                        // 下过单
                        if (frequency.order_frequency_type === '0' &&
                            frequency.order_frequency_value! >= Number(diffMonthNum)
                        ) {
                            is_pass = true;
                        }
                        // 相隔时间
                        if (frequency.order_frequency_type === '1' &&
                            frequency.order_frequency_value! <= Number(diffMonthNum)
                        ) {
                            is_pass = true;
                        }
                        // 未通过记录错误信息
                        if (!is_pass) {
                            errorDetail['order_frequency_type'] = frequency.order_frequency_type;
                            errorDetail['order_frequency_value'] = frequency.order_frequency_value;
                        }
                    });
                }
            }
            if (!is_pass) {
                // console.log('顾客下单频率领取优惠券');
                receive_fail_rule.push('order_frequency_enable');
            }
        }
        // $ 7.只能领取一次(不选择的话使用完才能再次领取).
        const couponsItem = _.find(ignoreGetCoupon.coupons, (o) => { return o.is_collected === customer._id; });
        if (ignoreGetCoupon.count_obtain_strategy && ignoreGetCoupon.count_obtain_strategy === '0') {
            // 只能领取一张不可重复领取
            if (couponsItem) {
                // console.log('只能领取一次');
                receive_fail_rule.push('count_obtain_strategy');
            }
        } else {
            if (couponsItem) {
                // 领取使用完之后才能领取 // 可以无限领取，所以为true
                // if (customer.coupons && customer.coupons.length > 0) {
                //     for (let couponp of customer.coupons) {
                //         if (_.isEqual(customer._id, couponp._id) && !couponp.expire_date) {
                //
                //         }
                //     }
                // }
            }
        }
        // $ 8.XX天内下过超过XX欧元订单
        if (ignoreGetCoupon.achieve_order_money_enable) {

            // 可以使用此优惠券的餐馆
            let stringIdArray = _.map(ignoreGetCoupon.string_ids, (n) => { return n.string_id })

            stringIdArray = _.compact(
                _.uniq(_.concat([restaurant.string_id], stringIdArray))
            );
            // 开始时间
            const startDate = moment().subtract(ignoreGetCoupon.achieve_order_money_day, 'days').format('YYYY-MM-DD');

            const totalPrice = customer?.orders?.reduce((pre, cur) => {
                if (stringIdArray.includes(cur.origin_belonged_restaurant) && moment(startDate, 'YYYY-MM-DD').isBefore(cur.origin_create_time, 'day')
                    && cur.origin_status === '4'
                ) {
                    return pre + (cur.origin_total_price_with_tax || 0);
                }
                return pre;
            }, 0) || 0;
            if (totalPrice <= (ignoreGetCoupon.achieve_order_money || 0)) {
                // console.log(startDate, totalPrice, restaurant.string_id, 'XX天内下过超过XX欧元订单');
                receive_fail_rule.push('achieve_order_money_enable');
            }
        }

        if (receive_fail_rule.length > 0) {
            return { pass: false, receive_fail_rule }
        } else {
            return { pass: true };
        }
    } catch (error) {
        return { pass: false, receive_fail_rule: [] };
    }
}