import Store from '../rudex/store';
import _ from 'lodash';
import i18n from '../i18n';
import { AxiosResponse } from 'axios';

/**
 * @param RESLUTE API返回值
 * @param type 国际化字段
 */
export function errorCatching(RESLUTE: any & AxiosResponse<any>, type?: string) {
    const STATUS_CODE = new RegExp('^4.*$').test(`${_.get(RESLUTE, 'status')}`)
    if (_.has(RESLUTE, 'error') && STATUS_CODE) {
        // 默认返回的错误信息
        const resultErrMessage = _.get(RESLUTE, 'result.message')
        const resultErrDetails = _.get(RESLUTE, 'result.details')
        let i18Msg = (typeof resultErrDetails === 'string') ? resultErrDetails : resultErrMessage;
        if (type) i18Msg = type

        Store.dispatch({
            type: 'SHOW_MESSAGE',
            message: `${i18n.t(i18Msg)}`
        });
        return true;
    }
    return false;
}