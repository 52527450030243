import './black-gold-theme-delivery-address.component.scss'

import React, { Component } from 'react'
import _ from 'lodash';
import TopBar from '../../top-bar/top-bar.component';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Customer, Address } from '../../../models';
import apiManage from '../../../request';
import { CreateAddress } from '../../payment/payment.componet';
import i18n from '../../../i18n';
import { RestaurantTheme } from '../../../constants/tool-restaurant-theme';
import Notification from '../../../component/snackbar/snackbar';
import { LocalStorageManager } from '../../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../../constants/local-storage.constant';
import { CachedAddressDeliveryFee } from '../../../constants/tool-check-level-delivery';
import { checkAddress } from '../../../constants/tool-googlemap';

export default class BlackGoldThemeDeliceryAddress extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            language: 'EN',
            addressCardInfo: [],
            isEdiAddressModal: false,
            editAddress: {
                street: 'Enter street name with number',
                homeNumber: 'Enter your home number',
                city: '',
                zipCode: '',
                details: 'Describe details about the address'
            },
            // 修改地址数据
            modiftAddress: {},
            // 是否有选择地址
            isChoose: true

        }
    }

    // ***************
    //  生命周期
    // ***************
    componentDidMount() {
        const { customerInfo } = this.props;
        const addressCardInfo = customerInfo.address || [];
        addressCardInfo.sort(this.compare)
        this.setState({ addressCardInfo }, () => {
            // this.isChoosed(addressCardInfo);
            // 判断地址是否在配送范围
            const timer = setTimeout(async () => {
                this.isWithinRange(addressCardInfo);
                clearTimeout(timer);
            }, 1000);
        })
    }

    // ***************
    // 方法区
    // ***************

    /**
     * 判断是否在配送范围内
     */

    public async isWithinRange(address: Array<Address>) {
        const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const res_info = _.get(res, `[${res_string_id}].restaurant`);
        const allAdress = await checkAddress(res_info, address);

        allAdress.forEach((item) => {
            if (item.default) {
                if (item.isWithin) {
                    CachedAddressDeliveryFee(item);
                } else {
                    item.default = false
                }
            }
        })
        this.setState({
            addressCardInfo: allAdress
        })
    }

    // public isChoosed(addressCardInfo: Array<Address>) {
    //     // 确认按钮是否灰掉
    //     let isChoose = addressCardInfo.findIndex((item) => {
    //         return item.default === true
    //     })
    //     this.setState({ isChoose: isChoose === -1 ? false : true })
    // }

    public isSelected(itemAddress: any, index) {
        const setCardInfo = _.cloneDeep(this.state.addressCardInfo);
        setCardInfo.forEach((item, idx) => {
            if (index === idx) {
                item.default = true;
                CachedAddressDeliveryFee(item)
            } else {
                item.default = false
            }
        });
        this.setState({
            addressCardInfo: setCardInfo
        })
    }

    // 修改用户信息
    public async patchCustomerInfo() {
        try {
            const { customerInfo, customerId } = this.props;
            const { addressCardInfo } = this.state;

            _.set(customerInfo, `address`, addressCardInfo)
            // 此接口，是修改整个用户信息列表
            await apiManage.patch_customer_info(
                { address: customerInfo.address },
                customerId
            )
        } catch (error) {
        }
    }

    // 请求获取到用户的所有地址
    public async getCustomerAddress(id: string) {
        try {
            const customerInfo = await apiManage.get_customer_info(id);
            const addressCardInfo = customerInfo !== null ? customerInfo.address : [];
            // 所有地址需要排序,要把default放在最上面

            if (addressCardInfo) addressCardInfo.sort(this.compare);
            this.props.changeStatus(customerInfo, true);
            this.isWithinRange(addressCardInfo || []);
        } catch (err) {
            console.log(err)
        }
    }

    // 数组对象排序处理方法
    public compare(preAddress: Address) {
        if (preAddress.default) return -1;
        return 1;
    }

    updateState = (value: any) => {
        this.setState(value)
    }

    // ***************
    // VIEW
    // ***************

    public render() {
        const { addressCardInfo, isEdiAddressModal, modiftAddress, isChoose } = this.state;
        const { customerInfo, type, restaurantTheme } = this.props;
        const title = type === 'restaurant' ? `delivery_address_page_Confirm_address` : 'delivery_address_page_text_client_my_delivery_address'
        return (
            <div className='blackGoldThemeAddress'>
                <div className='delivery-address-main overall-center'>

                    {/* Top bar */}
                    <TopBar
                        backgroundstyle={'backgroundTransparent'}
                        isHideMenu={type === 'restaurant' ? false : true}
                        title={i18n.t(title)}
                        isGoBack={() => {
                            if (type === 'restaurant' || type === 'scanCodePage') {
                                if (this.props.isGoBack) {
                                    this.props.isGoBack()
                                }
                            } else {
                                this.props.changeStatus({ isShowAddress: false })
                            }
                        }}
                    // rightIcon={() => (
                    //     <Button className='confrimicon' style={{ opacity: type === 'restaurant' ? 1 : 0 }}>
                    //         {i18n.t('delivery_address_page_skip')}
                    //         <KeyboardArrowRightIcon className='bar-menu tool-icon' />
                    //     </Button>
                    // )}
                    // isConfirm={() => {
                    //     if (type === 'restaurant') {
                    //         this.props.changeStatus({ isShowAddress: false })
                    //     }
                    // }}
                    />
                    {/* 编辑我的地址 */}
                    {/* <EditAddressModal
                    isEdiAddressModal={isEdiAddressModal}
                    editAddress={editAddress}
                    updateState={(upDataVal: any) => {
                        this.updateState(upDataVal);
                    }} /> */}

                    {/* 新建地址 */}
                    {
                        isEdiAddressModal ? <CreateAddress
                            addressList={addressCardInfo}
                            customerInfo={customerInfo}
                            modiftAddress={modiftAddress}
                            handleClose={() => {
                                this.updateState({ isEdiAddressModal: false });
                            }}
                            refreshAddress={() => { // 重新做一次请求
                                this.setState({ isEdiAddressModal: false }, async () => {
                                    this.getCustomerAddress(customerInfo._id || '');
                                })
                            }}
                            restaurantTheme={restaurantTheme}
                            restaurantPosition={this.props.restaurantPosition}
                            handleChoose={() => {
                                this.setState({ isEdiAddressModal: false })
                            }} /> : null
                    }
                    <div className='delivery-address-content'>
                        <div className='main-body'>
                            <div className='delivery-address-list'>
                                <div className='address-main-bg'>
                                    <div className='flex-center-between'>
                                        <div className='address-tit'>
                                            {i18n.t('delivery_address_page_text_client_add_address')}
                                        </div>
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                modiftAddress: {},
                                                isEdiAddressModal: true
                                            })
                                        }}><AddIcon style={{ color: '#bababd' }} /></div>
                                    </div>
                                    <div className='address-msg flex-center-start'>
                                        {
                                            i18n.t('delivery_address_page_text_client_choose_your_default_address')
                                        }
                                    </div>
                                    <div className='address-main'>
                                        {
                                            addressCardInfo && addressCardInfo.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='address-card'
                                                        style={item.default ? {
                                                            backgroundColor: '#323161'
                                                        } : {}}
                                                        onClick={() => {
                                                            if (!item.isWithin) return;
                                                            this.isSelected(item, index)
                                                        }}>
                                                        <div className='left' style={item.default ? { background: '#CAA376' } : {}}>
                                                            <div className='country'>
                                                                <div style={{ marginBottom: '8px' }}>{item.street}{item.home_number}</div>
                                                                {/* <div>{item.country}</div> */}
                                                            </div>
                                                            <div className='name'>{item.first_name}{item.last_name}</div>
                                                            <div className='phone'>{item.phone}</div>
                                                            {
                                                                !item.isWithin &&
                                                                <div className='within'>{i18n.t('payment_page_text_this_address_is_not_within_the_scope_of_shipping')}</div>
                                                            }
                                                        </div>
                                                        <div className='right' style={item.default ? { background: '#B07C4D' } : {}}>
                                                            <KeyboardArrowRightIcon onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    modiftAddress: { ...item, index },
                                                                    isEdiAddressModal: true
                                                                })
                                                            }} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <Button className={`${isChoose ? 'confirm' : 'notChoose'}`} onClick={async () => {
                                const customerID = customerInfo._id
                                const checkAddressCard: any = _.find(addressCardInfo, (val) => {
                                    return val.default
                                }) || {};
                                if (Object.values(checkAddressCard).length) {
                                    const { google_map_lat, google_map_lng } = checkAddressCard
                                    if (!(google_map_lat && google_map_lng)) {
                                        // 请重新定位谷歌地址地址
                                        Notification.notification(i18n.t('payment_page_text_the_address_selected_needs_to_be_repositioned'));
                                        return
                                    }
                                    if (checkAddressCard && customerID) {
                                        const selectedAddress = checkAddressCard as Address
                                        await this.patchCustomerInfo()
                                        if (type === 'scanCodePage') {
                                            this.props.changeStatus({ address: addressCardInfo, _id: customerID })
                                        } else {
                                            this.props.changeStatus({
                                                addressModifiedInfo: {
                                                    country: selectedAddress.city,
                                                    address: `${selectedAddress.street} ${selectedAddress.code} ${selectedAddress.city} ${selectedAddress.state} ${selectedAddress.country}`,
                                                    username: `${selectedAddress.first_name} ${selectedAddress.last_name}`,
                                                    tel: selectedAddress.phone
                                                }
                                            })
                                            this.props.changeStatus({
                                                isShowAddress: false,
                                                isSelectedAddress: true
                                            })
                                        }
                                    }
                                } else {
                                    // 请选择默认地址
                                    Notification.notification(i18n.t('delivery_address_page_text_client_choose_your_default_address'));
                                }
                            }}>{i18n.t('delivery_address_page_text_address_confirmed')}</Button>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
}

// **********
// Type && State
// **********

type Props = {
    changeStatus: (val, status?: boolean) => void;
    customerInfo: Customer;
    customerId: string;
    type?: string;
    isGoBack?: () => void;
    restaurantTheme: RestaurantTheme;
    restaurantPosition?: { lat: number, lng: number };
};

type State = {
    language: string;
    addressCardInfo: Array<Address>;
    isEdiAddressModal: boolean;
    editAddress: {
        street: string;
        homeNumber: string;
        city: string;
        zipCode: string;
        details: string;
    }
    modiftAddress: Address,
    // 是否有选择地址
    isChoose: boolean;
}
