import _ from 'lodash';
import { LOCAL_STORAGE_KEYS } from './local-storage.constant';
import { LocalStorageManager } from './tool-local-storage';

/**
 * 检查资源的更新状态，且更新缓存(图片资源)
 * @param type 资源类型
 * @param nextVersion 需要检查的版本号
 *
 * @deprecated
 */
export function checkResource(type: string, nextVersion: any) {
    const resourceVersion = {}
    const cachingValue: any = _.get(resourceVersion, type)
    const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
    // ! 暂时把return true
    // $ 检查用户头像资源版本
    if (type === 'avatar') {
        // 头像资源更新状态
        // let updateStatus: boolean = false
        if (!cachingValue || (cachingValue.version !== (nextVersion ? nextVersion : '-1'))) {
            // updateStatus = true
            _.set(resourceVersion, type, { version: nextVersion })
        }
        return { updateStatus: true, resourceVersion }
    }

    // $ 检查历史餐馆logo资源版本
    if (type === 'historyRestaurantsLogo') {
        // 历史餐馆资源是否需要更新
        // let updateStatus: boolean = false
        const newNextVersion: Array<object> = []

        if (Array.isArray(nextVersion) && nextVersion.length > 0) {

            if (!cachingValue) {
                // updateStatus = true

            } else if (cachingValue) {
                if (Array.isArray(cachingValue) && cachingValue.length > 0) {
                    // 旧的历史餐馆的logo组
                    const oldVersion = cachingValue.map((n) => n.logo ? n.logo[0].version : '-1')
                    // 新的历史餐馆的logo组
                    const newVersion = nextVersion.map((n) => n.logo ? n.logo[0].version : '-1')

                    if ((_.join(oldVersion) === _.join(newVersion))) {
                        // updateStatus = false
                    } else {
                        // updateStatus = true
                    }
                } else if (cachingValue.length === 0) {
                    // updateStatus = true
                }
            }
            nextVersion.forEach((item) => {
                if (item.logo) {
                    newNextVersion.push(item.logo)
                } else {
                    newNextVersion.push({ _id: item._id, version: '-1' })
                }
            })
            _.set(resourceVersion, type, { version: newNextVersion })
        }
        return { updateStatus: true, resourceVersion }
    }

    // $ 餐馆宣传图
    if (type === 'restaurantAlbum') {
        // 资源更新状态
        // let albumStatus: boolean = false
        const album = _.get(cachingValue, currentResStringID)

        if (!album) {
            // albumStatus = true
        } else if (album) {
            if (album !== (nextVersion ? nextVersion[0].version : '-1')) {
                // albumStatus = true
            }
        }
        _.set(resourceVersion, `${type}.${currentResStringID}`, nextVersion ? nextVersion[0].version : '-1')
        return { albumStatus: true, resourceVersion }
    }

    // $ 餐馆logo资源版本
    if (type === 'restaurantsLogo') {
        // 餐馆logo是否需要
        // let updateStatus: boolean = false
        const logoVersion = _.get(cachingValue, currentResStringID)
        if (!cachingValue) {
            // updateStatus = true
        } else if (cachingValue) {
            if (logoVersion !== (_.get(nextVersion, 'version') ? _.get(nextVersion, 'version') : '-1')) {
                // updateStatus = true
            } else {
                // updateStatus = false
            }
        }
        _.set(resourceVersion, `${type}.${currentResStringID}`, nextVersion ? nextVersion['version'] : '-1')

        return { updateStatus: true, resourceVersion }
    }
}
/**
 * 判断当前版本是否在目标版本之后
 * @param currentversion 当前版本
 * @param targetVerison 目标版本
 */
export function checkVersion(currentversion: string, targetVerison: string) {
    const newCurrentVersion = currentversion.replace(/\./g, '')
    const newtargetVerison = targetVerison.replace(/\./g, '')
    // 是否要更新
    let isUpdate = false
    if (Number(newCurrentVersion) > Number(newtargetVerison)) {
        isUpdate = true
    }
    return isUpdate
}