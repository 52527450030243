import React from 'react'
import './booking-box.style.scss';
import i18n from '../../i18n';
import { MdDateRange } from 'react-icons/md';
import { FiClock } from 'react-icons/fi';
import { BsFillPeopleFill, BsPlus } from 'react-icons/bs';
import { FaClipboardList } from 'react-icons/fa';
import { GrFormSubtract } from 'react-icons/gr';
import { MdKeyboardArrowDown } from 'react-icons/md';
import _ from 'lodash';
import moment from 'moment';
import PhoneInput from '../account/phone.component';
import { Button } from '@material-ui/core';
import { DateCalendar } from '../date-calendar/date-calendar';
import { BookingMessageDialog } from './booking-message-dialog';
import BScroll from 'better-scroll';
import { createHashHistory } from 'history';
import { IS_PHONE } from '../../constants/base.constant';
import apiManage from '../../request';
import { BookingServiceSchedule, SettingClosedSchedule, BookingServiceScheduleTimeRange, Customer } from '../../models';
import { showApiMessage } from '../../messenger/messageManager';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import Notification from '../snackbar/snackbar';
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';
import Store from '../../rudex/store';
import { AirBubbles } from '../payment/payment.componet';
// import getRelatedCode from '../../constants/international-code';
import momentTimeZone from 'moment-timezone';
import { convertDateByTimeZoneName } from '../../constants/tool-constant';
import { SMARTWAY_WEBSITE } from '../../gconfig';
import { OrderQuantity } from '../../models/abstract/order-quantity.entity';
import companyLogo from '../../static/images/company_logo.png';
import { ICheckoutShowedPageNames } from '../../interfaces';

export default class BookingBox extends React.Component<Props, State> {


    public state: State = {
        // 选择日期
        selectDate: null,
        // 预约人数
        peopleNumber: 1,
        // 就餐时间
        selectedTimes: [],
        // 预定人的firstName
        inputFirstName: '',
        // 预定人的lastName
        inputLastName: '',
        // 手机区号
        phonePrefix: '',
        // 手机号码
        insertPhone: '',
        // 特殊要求
        remark: '',
        // 预定成功弹窗
        isShowDialog: false,
        // 某一天的时间段
        bookingTimeInterval: [],
        // 周几不可预约
        disabledWeeks: [],
        // 不可预约的日期（具体哪一天）,组
        disabledDates: this.props.bookingNoServiceSchedule,
        // 预定订单时间点订单数量
        orderQuantity: []
    }

    // *********************
    // Life Cycle Function
    // *********************

    async componentDidMount() {
        this.init();
        // 设置滑动插件
        this.setBetterScroll();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.visible !== prevProps.visible) {
            if (this.props.visible) {
                this.init()
            }
        }
    }

    // *********************
    // Service Function
    // *********************

    async init() {
        // 获取暂存的预约订单数据
        const bookingInfo: BookingInfo = _.get(Store.getState(), 'bookingInfo', {});
        // 自动填充账户信息
        const account: Customer = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        // 找出不可以预定的周几
        const disabledWeeks = this.onCheckDisabledWeek();
        // 订单数量
        const orderQuantity = await this.getOrderQuantity();

        this.setState({
            disabledWeeks,
            inputFirstName: bookingInfo.first_name || account.first_name || '',
            inputLastName: bookingInfo.last_name || account.last_name || '',
            phonePrefix: bookingInfo.phone_prefix || account.phone_prefix || getBrowserPhoneCode(),
            insertPhone: bookingInfo.phone || account.phone || '',
            peopleNumber: bookingInfo.customer_num || 1,
            remark: bookingInfo.remark || '',
            selectedTimes: bookingInfo.selectedTimes || [],
            selectDate: bookingInfo.selectDate || null,
            bookingTimeInterval: bookingInfo.bookingTimeInterval || [],
            orderQuantity,
            disabledDates: this.props.bookingNoServiceSchedule
        }, async () => {
            // 完善登录信息后，需要执行创建预定订单
            if (bookingInfo.shouldAction) {
                await this.onBooking();
            }
        })
        // 清除预定的暂存数据
        Store.dispatch({
            type: 'SET_STATE',
            path: 'bookingInfo',
            value: undefined
        })
    }

    /**
     * 设置滑动插件
     */
    setBetterScroll() {
        // 使用插件解决滑动问题(booking弹窗-连续点击问题、双击放大问题)
        const bookingWrapper1: Element | null = document.querySelector('#bookingButton1');
        const bookingWrapper2: Element | null = document.querySelector('#bookingButton2');
        if (bookingWrapper1 && bookingWrapper2) {
            new BScroll(bookingWrapper1, {
                mouseWheel: true,
                click: true
            });
            new BScroll(bookingWrapper2, {
                mouseWheel: true,
                click: true
            })
        }
    }

    /**
     * 找出不可以预定的周几
     */
    onCheckDisabledWeek() {
        try {
            const { bookingServiceSchedule } = this.props;
            const cloneBookingServiceSchedule = _.cloneDeep(bookingServiceSchedule);
            const disabledWeeks: string[] = [];
            cloneBookingServiceSchedule.forEach((item) => {
                if (item.booking_time_interval && item.booking_time_interval.length === 0) {
                    disabledWeeks.push(`${item.week}`)
                }
            })
            return disabledWeeks;
        } catch (error) {
            return []
        }
    }


    /**
     * 选择就餐时间
     */
    async onChooseTime(timeId: string) {
        try {
            let newSelectedTimes: string[] = _.cloneDeep(this.state.selectedTimes);
            // $ 单选
            if (_.includes(this.state.selectedTimes, timeId)) {
                newSelectedTimes = [];
            } else {
                newSelectedTimes = [timeId];
            }
            this.setState({ selectedTimes: newSelectedTimes });
        }
        catch (error) { }
    }

    /**
     * 预定按钮
     */
    async onBooking() {
        try {
            const { selectedTimes, peopleNumber, inputFirstName, inputLastName, phonePrefix, insertPhone,
                remark, selectDate, bookingTimeInterval } = this.state
            const string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
            const customer_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT)?._id;
            if (selectedTimes.length === 0) {
                Notification.notification(i18n.t('reservation_page_text_please_select_the_booking_time'));
                return
            }
            // 检查必填
            if (!this.onCheckRequired()) {
                Notification.notification(i18n.t('payment_page_text_please_complete_customer_information'));
                return
            }
            const { start_time, end_time } = this.getSelectReachTime();
            if (moment(start_time).isBefore(new Date())) {
                Notification.notification(i18n.t('reservation_page_text_the_appointment_time_needs_to_be_later_than_the_present_time'));
                return
            }
            const bookingInfo: BookingInfo = {
                belonged_res_string_id: string_id,
                belonged_customer: customer_id,
                customer_num: peopleNumber,
                first_name: inputFirstName,
                last_name: inputLastName,
                phone_prefix: phonePrefix,
                phone: insertPhone,
                remark,
                actual_start_time: start_time,
                actual_end_time: end_time
            }

            // 通知托盘，检查是否登录
            // 根据餐厅设置是否校验登录状态
            const isLogin = await this.props.checkLogin();
            const record = {
                ...bookingInfo,
                selectedTimes,
                selectDate,
                bookingTimeInterval
            }
            if (!isLogin) {
                if (this.props.restaurantsNeedSignIn) {
                    // 用于登录后显示的已填内容
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'bookingInfo',
                        value: record
                    })
                    this.props.updateState({
                        phonePrefix: bookingInfo.phone_prefix || getBrowserPhoneCode(),
                        phone: bookingInfo.phone || ''
                    })
                    return
                }
            } else {
                const account: Customer = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                // 判断信息是否缺失（姓名）
                if (!account.first_name || !account.last_name || !account.phone) {
                    // 用于登录后显示的已填内容
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'bookingInfo',
                        value: record
                    })
                    this.props.updateState({
                        showedPage: ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE,
                        isFirstName: !account.first_name,
                        isLastName: !account.last_name,
                        isAddress: false,
                        isReferralsCode: false,
                        isVisibleAccount: !account.phone
                    })
                    return
                }
            }

            Store.dispatch({
                type: 'SET_STATE',
                path: 'bookingInfo',
                value: undefined
            })

            const result = isLogin ? await apiManage.bookingOrder(bookingInfo) : await apiManage.bookingOrderNoSignIn(bookingInfo);

            if (_.get(result, 'error')) {
                showApiMessage(result, 'booking')
            } else {
                // 自动填充账户信息
                const account: Customer = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                this.setState({
                    isShowDialog: true,
                    selectDate: null,
                    selectedTimes: [],
                    bookingTimeInterval: [],
                    peopleNumber: 1,
                    inputFirstName: account.first_name || '',
                    inputLastName: account.last_name || '',
                    phonePrefix: account.phone_prefix || getBrowserPhoneCode(),
                    insertPhone: account.phone || '',
                    remark: ''
                })

                const rwg_token = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RWG_TOKEN);

                const googleConversionCollect = await apiManage.googleConversionCollect(rwg_token);

                if (!googleConversionCollect?.error) {
                    // 暂未操作
                }
            }
        } catch (error) {

        }
    }
    // 检验必填
    onCheckRequired() {
        const { inputFirstName, inputLastName, insertPhone, phonePrefix } = this.state
        const RequiredArr: string[] = []
        if (inputFirstName.trim() === '') {
            RequiredArr.push('1')
        }
        if (inputLastName.trim() === '') {
            RequiredArr.push('2')
        }
        if (insertPhone.trim() === '' || !(IS_PHONE(phonePrefix, insertPhone))) {
            RequiredArr.push('3')
        }
        if (RequiredArr.length > 0) {
            return false
        } else {
            return true
        }
    }

    // 跳转我的预定页面
    onHere() {
        createHashHistory().push('/my-reservations-page')
    }
    // 设置并显示所选日期的可预约时间段
    setTimePeriod(week: number, selectDateFormat: string) {
        try {
            const { disabledDates, orderQuantity } = this.state;
            const { bookingServiceSchedule, restaurantsTimeZoneName } = this.props;
            // 当前时区
            const originTimeZoneName = momentTimeZone.tz.guess();
            // $ 将本地当前时间转化为餐馆时区的时间
            let currentTime = moment(new Date()).format(moment.defaultFormatUtc);
            currentTime = convertDateByTimeZoneName(currentTime, originTimeZoneName, restaurantsTimeZoneName);
            currentTime = moment(currentTime, moment.defaultFormatUtc).format('YYYY-MM-DD HH:mm');

            // 这一天不可以预定的开始时间
            let closeStartTime = '';
            // 这一天不可以预定的结束时间
            let closeEndTime = '';
            // 闭店时间范围：开始结束不是同一天
            if (disabledDates) {
                const cloneDisabledDates = _.cloneDeep(disabledDates)
                const selectCloseDate = _.filter(cloneDisabledDates, (item) => {
                    const start_date = item.start_date && this.formateDate(item.start_date, 'YYYY-MM-DD')
                    const end_date = this.formateDate(item?.end_date, 'YYYY-MM-DD')
                    return (moment(selectDateFormat).isBetween(start_date, end_date, 'date', '[]')
                        && (start_date !== end_date));
                })
                if (selectCloseDate) {
                    let newCloseStartTime = '';
                    let newCloseEndTime = '';
                    selectCloseDate.forEach((item) => {
                        const newStartDate = moment(item.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format('YYYY-MM-DD')
                        const newEndDate = moment(item.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format('YYYY-MM-DD')

                        if (moment(selectDateFormat).isSame(newStartDate)) {
                            if (newCloseStartTime) {
                                const start_time = moment(item.start_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                                if (moment(newCloseStartTime).isAfter(start_time)) {
                                    newCloseStartTime = moment(item.start_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') || ''
                                }
                            } else {
                                newCloseStartTime = moment(item.start_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') || ''
                            }
                        }
                        if (moment(selectDateFormat).isSame(newEndDate)) {
                            if (newCloseEndTime) {
                                const start_time = moment(item.start_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                                if (moment(newCloseEndTime).isBefore(start_time)) {
                                    newCloseEndTime = moment(item.end_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') || ''
                                }
                            } else {
                                newCloseEndTime = moment(item.end_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') || ''
                            }
                        }
                    })
                    closeStartTime = newCloseStartTime;
                    closeEndTime = newCloseEndTime;
                }
            }
            // 闭店时间范围：开始结束都是同一天的
            let sameCloseDate: SettingClosedSchedule[] = []
            if (disabledDates) {
                const cloneDisabledDates = _.cloneDeep(disabledDates)
                sameCloseDate = _.filter(cloneDisabledDates, (item) => {
                    const start_date = item.start_date && this.formateDate(item.start_date, 'YYYY-MM-DD')
                    const end_date = this.formateDate(item?.end_date, 'YYYY-MM-DD')
                    return (moment(selectDateFormat).isBetween(start_date, end_date, 'date', '[]')
                        && (start_date === end_date));
                })
            }
            // 赋予预约详细时间段按钮 selectKey、disabled
            const cloneBookingServiceSchedule = _.cloneDeep(bookingServiceSchedule)
            // 找出选择的日期的所对应的周几
            const scheduleItem = _.find(cloneBookingServiceSchedule, (n) => { return n.week === week })
            let booking_time_interval = scheduleItem?.booking_time_interval || []
            if (booking_time_interval && booking_time_interval.length > 0) {
                booking_time_interval = booking_time_interval.map((n, nameIndex) => {
                    if (n.time_interval_detail && n.time_interval_detail.length > 0) {
                        n.time_interval_detail.map((v, timeIndex) => {
                            // 设置时间段按钮的key、disabled
                            _.set(v, 'selectKey', `${nameIndex}-${timeIndex}`)
                            _.set(v, 'disabled', false)
                            const start_time = moment(v.start_time, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                            const end_time = moment(v.end_time, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                            // 将闭店开始时间之后的时间段设置不可点击
                            if (closeStartTime && (moment(closeStartTime).isSameOrBefore(start_time))) {
                                _.set(v, 'disabled', true)
                            }
                            // 将闭店结束时间之前的时间段设置不可点击
                            if (closeEndTime && (moment(closeEndTime).isSameOrAfter(end_time))) {
                                _.set(v, 'disabled', true)
                            }
                            // 设置选择的日期的闭店时间段设置不可点击
                            if (sameCloseDate.length > 0) {
                                sameCloseDate.forEach((item) => {
                                    const endTimeInterval = moment(item.start_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                                    const startTimeInterval = moment(item.end_time_interval, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                                    if (
                                        moment(start_time).isBetween(endTimeInterval, startTimeInterval,
                                            'minutes', '[]')
                                        && moment(end_time).isBetween(endTimeInterval, startTimeInterval, 'minutes', '[]')
                                    ) {
                                        _.set(v, 'disabled', true)
                                    }
                                })
                            }
                            // 开始时间在现在时间之前设置不可点击
                            if (moment(`${selectDateFormat} ${v.start_time}`).isBefore(currentTime)) {
                                _.set(v, 'disabled', true);
                            }
                            // 预定订单最大额度
                            const orderQuantityObj = orderQuantity.find((orderQuantityItem) => (
                                orderQuantityItem.date === selectDateFormat
                            ));
                            if (orderQuantityObj) {
                                orderQuantityObj?.booking_time_interval?.forEach(intervalItem => {
                                    if ((v.start_time?.toString() === intervalItem.start_time) && (v.end_time?.toString() === intervalItem.end_time)) {
                                        if (intervalItem.num <= 0) {
                                            _.set(v, 'disabled', true);
                                        }
                                    }
                                });
                            }
                            return v
                        })
                    }
                    return n
                })
            }
            return booking_time_interval

        } catch (error) {
            return []
        }
    }
    // 点击日历日期
    onSelectDateBefore(date) {
        try {
            const week = moment(date).isoWeekday();
            const selectDateFormat = moment(date).format('YYYY-MM-DD');
            const bookingTimeInterval = this.setTimePeriod(week, selectDateFormat);

            this.setState({
                selectDate: date,
                selectedTimes: [],
                bookingTimeInterval
            })
        } catch (error) {

        }
    }

    // 获取选择时间段的具体时间 并return出来
    getSelectReachTime(realSelectedTimes?: string[]) {
        const { bookingTimeInterval, selectedTimes, selectDate } = this.state;
        const newBookingTimeInterval: BookingTimeInterval[] = _.cloneDeep(bookingTimeInterval);
        // 拿到所选择的时间段
        const times = realSelectedTimes ?? selectedTimes;
        const selectTimeNameIndex = times.join().split('-')[0];
        const selectTimeKey = times.join().split('-')[1];
        const findTimeItem = _.find(newBookingTimeInterval, (n, index) => { return `${index}` === selectTimeNameIndex });
        const newSelectedTimes = _.find(findTimeItem?.time_interval_detail, (n) => {
            return n.selectKey?.split('-')[1] === selectTimeKey
        });
        const newSelectDate = moment(selectDate || '').format('YYYY-MM-DD');
        const newStartTime = moment(newSelectedTimes?.start_time, 'HH:mm:ss').format('HH:mm:ss');
        const newEndTime = moment(newSelectedTimes?.end_time, 'HH:mm:ss').format('HH:mm:ss');
        // 将字符串格式化为 ISO8601 标准  2020-02-04T22:44:30.652Z
        const start_time = moment(`${newSelectDate} ${newStartTime}`).format();
        const end_time = moment(`${newSelectDate} ${newEndTime}`).format();
        return { start_time, end_time };
    }


    /**
     * 格式化日期
     * @param date 日期
     * @param format 要转换的格式
     */
    formateDate(date: string | undefined, format: string) {
        return date ? moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format) : ''
    }

    /**
     * 获取时间点的订单数量checking order quantity
     */
    async getOrderQuantity(): Promise<OrderQuantity[]> {
        const string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res: OrderQuantity[] & { error: true } = await apiManage.getOrderQuantity(string_id);
        if (!res?.error) {
            return res
        }
        return []
    }

    render() {
        const {
            selectDate, peopleNumber, selectedTimes, inputFirstName, inputLastName, insertPhone, remark,
            isShowDialog, bookingTimeInterval, disabledDates, disabledWeeks, phonePrefix
        } = this.state;
        const { onHandleClose, className, isBlacklistedUser, settingMaxBookingDay, showProvidedTip } = this.props;
        return (
            <div id={'bookingBoxMain'} className={`bookingBoxMain ${className || ''}`}
                onClick={(e) => { e.stopPropagation(); }}
            >
                <div className='downIcon' onClick={() => { onHandleClose() }}>
                    <MdKeyboardArrowDown />
                </div>
                {showProvidedTip && <div className='provided-box'>
                    <div className='provided-text-box'>
                        <div className='project-logo-box'>
                            <img
                                className='project-logo'
                                src={companyLogo} alt=''
                            />
                        </div>
                        <span className='text'>{'Booking time information provided by Smartway'}</span>
                    </div>
                    <div className='privacy-box'>
                        <a href={SMARTWAY_WEBSITE} target='_blank' rel='noopener noreferrer' className='clickPolicyText'>{'Terms of Use'}</a>
                        {' / '}
                        <a href={'/#/ordelivery-privacy-policy'} target='_blank' rel='noopener noreferrer' className='clickPolicyText'>{'Privacy Policy'}</a>
                    </div>
                </div>}
                <div className='bookingContent' onClick={() => { }}>
                    <div>
                        <IconTitle titleName={i18n.t('reservation_page_text_choose_a_date')} icon={() => <MdDateRange />} />
                        <div className='bookingDate' id={'bookingDate'}>
                            <DateCalendar
                                onChange={(date) => this.onSelectDateBefore(date)}
                                value={selectDate}
                                disabledWeeks={disabledWeeks}
                                disabledDates={disabledDates}
                                settingMaxBookingDay={settingMaxBookingDay}
                            />
                        </div>
                    </div>
                    <div>
                        <IconTitle titleName={`${i18n.t('reservation_page_text_time')} (${i18n.t('reservation_page_based_on_restaurant_local_time')})`} icon={() => <FiClock />} />
                        <div className='chooseTime boxShadow'>
                            {bookingTimeInterval.length > 0 ? bookingTimeInterval.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className='name'>{item.name}</div>
                                        {_.isArray(item.time_interval_detail) && item.time_interval_detail.length > 0 ?
                                            _.chunk(item.time_interval_detail, 3).map((timeItem, timeIndex) => (
                                                <div key={timeIndex} className='flexRow'>
                                                    {timeItem.map(
                                                        (value, iindex) => (
                                                            <span key={iindex} className={value.disabled ? 'disabledBg timeItem' :
                                                                _.includes(selectedTimes, value.selectKey)
                                                                    ? 'activeBg timeItem' : 'timeItem'}
                                                                onClick={async () => {
                                                                    if (!value.disabled && value.selectKey) {
                                                                        await this.onChooseTime(value.selectKey)
                                                                    }
                                                                }}
                                                            >
                                                                {/* {`${moment(value.start_time, 'HH:mm').format('HH:mm')}-${moment(value.end_time, 'HH:mm').format('HH:mm')}`} */}
                                                                {`${moment(value.start_time, 'HH:mm').format('HH:mm')}`}
                                                            </span>
                                                        ))}
                                                </div>
                                            ))
                                            : <div className='noOrderTime'>
                                                {i18n.t('reservation_page_text_there_is_no_time_to_book')}
                                            </div>}
                                    </div>
                                )
                            }) : <div className='noOrderTime'>
                                {i18n.t('reservation_page_text_there_is_no_time_to_book')}
                            </div>}
                        </div>
                    </div>
                    <div >
                        <IconTitle titleName={i18n.t('reservation_page_text_number_of_people')}
                            icon={() => <BsFillPeopleFill />} />
                        <div className='chooseNumberOfPeople boxShadow' >
                            <div className='button' id={'bookingButton1'} onClick={() => {
                                if (peopleNumber > 1) {
                                    this.setState({ peopleNumber: peopleNumber - 1 });
                                }
                            }}>
                                <GrFormSubtract />
                            </div>
                            <div className='peopleNumber'>{peopleNumber}</div>
                            <div className='button' id={'bookingButton2'} onClick={() => {
                                this.setState({ peopleNumber: peopleNumber + 1 });
                            }}>
                                <BsPlus />
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconTitle titleName={i18n.t('reservation_page_text_information_details')}
                            icon={() => <FaClipboardList />} />
                        <div className='details boxShadow'>
                            <div className={`item-style`}>
                                <div className='title'>{i18n.t('reservation_page_text_first_name')}<em>*</em></div>
                                <div className='radiusInputBox'>
                                    <input
                                        className='radiusInput'
                                        value={inputFirstName}
                                        onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                                            this.setState({ inputFirstName: val.target.value })
                                        }}
                                    /></div>
                            </div>
                            <div className={`item-style`}>
                                <div className='title'>{i18n.t('reservation_page_text_last_name')}<em>*</em></div>
                                <div className='radiusInputBox'>
                                    <input
                                        className='radiusInput'
                                        value={inputLastName}
                                        onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                                            this.setState({ inputLastName: val.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='title'>{i18n.t('reservation_page_text_phone')}<em>*</em></div>
                                <div className='phoneInput'>
                                    <PhoneInput
                                        className='phone-main radiusInputBox'
                                        defaultValue={insertPhone}
                                        phonePrefix={phonePrefix}
                                        onChangePhonePrefix={(val: string) => {
                                            this.setState({ phonePrefix: val })
                                        }}
                                        onChange={(val: string) => {
                                            this.setState({ insertPhone: val })
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='title special'>{i18n.t('restaurant_order_page_text_dish_remarks')}</div>
                                <div className='radiusInputBox areaBox'>
                                    <textarea
                                        className={'area'}
                                        // placeholder={''}
                                        value={remark}
                                        onChange={(evt) => {
                                            this.setState({ remark: evt.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='confirmBox'>
                                <div className='bubblesBox'>
                                    {isBlacklistedUser && <AirBubbles title={i18n.t('general_text_you_have_been_banned_by_this_restaurant')} attachClass='bubbles' />}
                                </div>
                                <Button className={`confirm boxShadow-btn ${isBlacklistedUser ? 'disabled' : ''}`}
                                    onClick={async () => { if (!isBlacklistedUser) await this.onBooking() }}>
                                    {i18n.t('reservation_page_button_Book')}
                                </Button>
                            </div>
                            {/* 预约成功框 */}
                            {isShowDialog ? <BookingMessageDialog
                                handleClose={() => {
                                    onHandleClose();
                                    this.setState({ isShowDialog: false })
                                }}
                                onConfirm={() => {
                                    onHandleClose();
                                    this.setState({ isShowDialog: false })
                                }}
                                onHere={() => { this.onHere() }}
                            /> : null}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


// 带icon的头部标题
function IconTitle(
    props: {
        icon: () => JSX.Element
        titleName: string
    }
) {
    const { titleName, icon } = props
    return (
        <div className='IconTitle'>
            <div className='icon'>{icon()}</div>
            <div className='name'>{titleName}</div>
        </div>
    )
}


// *********************
// Types
// *********************

interface Props {
    className?: string;
    onHandleClose: () => void;
    bookingServiceSchedule: Array<BookingServiceSchedule>;
    bookingNoServiceSchedule: Array<SettingClosedSchedule>;
    isBlacklistedUser: boolean;
    checkLogin: () => Promise<boolean | undefined>;
    updateState: (update: any) => void;
    settingMaxBookingDay?: number;
    restaurantsTimeZoneName: string;
    showProvidedTip?: boolean;
    visible: boolean;
    restaurantsNeedSignIn?: boolean;
}
interface State {
    // 选择日期
    selectDate: Date | null;
    // 预约人数
    peopleNumber: number;
    // 就餐时间
    selectedTimes: string[];
    // 预定人的firstName
    inputFirstName: string;
    // 预定人的lastName
    inputLastName: string;
    // 手机区号
    phonePrefix: string;
    // 手机号码
    insertPhone: string;
    // 特殊要求
    remark: string;
    // 预定成功弹窗
    isShowDialog: boolean;
    // 某一天的时间段
    bookingTimeInterval: BookingTimeInterval[];
    // 周几不可预约
    disabledWeeks: string[];
    // 不可预约的日期（具体哪一天）数组
    disabledDates: SettingClosedSchedule[];
    // 预定订单时间点订单数量
    orderQuantity: OrderQuantity[];
}
interface BookingTimeInterval extends BookingServiceScheduleTimeRange {
    time_interval_detail?: Array<
        {
            start_time?: Date;
            end_time?: Date;
            disabled?: boolean;
            selectKey?: string;
        }>;
}
export type BookingInfo = {
    belonged_res_string_id: string;
    belonged_customer: string;
    customer_num: number;
    first_name: string;
    last_name: string;
    phone_prefix: string;
    phone: string;
    remark: string;
    actual_start_time: string;
    actual_end_time: string;
    selectedTimes?: string[];
    selectDate?: Date | null;
    bookingTimeInterval?: BookingTimeInterval[];
    shouldAction?: boolean;
}