// 手机号正则 - 中国 11位数字
export const IS_PHONE_CN = /^[0-9]{11}$/;
// 手机号正则 - 意大利 9-10位数字
export const IS_PHONE_IT = /^[0-9]{9,10}$/;
// 手机号正则 - 西班牙 9位数字
export const IS_PHONE_ES = /^[0-9]{9}$/;
// 手机号正则 - 卢森堡 8-9位数字
export const IS_PHONE_LU = /^[0-9]{8,9}$/;

// 手机号正则
/**
 * 校验手机号码位数
 * @param code 'cn'-86  'it'-39  'es'-34  'lu'-352
 * @returns
 */
export const IS_PHONE = (code: string, phone: string) => {
    let pass = false;
    switch (code) {
        case '86':
            pass = IS_PHONE_CN.test(phone);
            break;
        case '39':
            pass = IS_PHONE_IT.test(phone);
            break;
        case '34':
            pass = IS_PHONE_ES.test(phone);
            break;
        case '352':
            pass = IS_PHONE_LU.test(phone);
            break;
        default:
            pass = false;
            break;
    }
    return pass
}

// 密码正则
export const IS_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/;
// 验证码正则
export const IS_VCODE = /^\d{6}$/;
// 邮箱验证正则
// 不需要eslint校验
// eslint-disable-next-line no-useless-escape
export const IS_EMAIL = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
// 姓氏或名称正则
export const IS_CUSTOMER_NAME = /^[^\s].{0,30}$/;

export declare type PropType<T, P extends keyof T> = T[P];