import BScroll from 'better-scroll';
import _ from 'lodash';
import React from 'react';
import { MdAdd, MdRoom } from 'react-icons/md';
import { getQueryString } from '../../constants/tool-get-parms';
import i18n from '../../i18n';
import { ICheckoutFulfillmentAddress, ICheckoutFulfillmentAddressProps, ICheckoutFulfillmentAddressState, ICheckoutOrderDetailShowedPageNames } from '../../interfaces';
import { Address } from '../../models/abstract/address.entity';
import { AddressCars } from '../payment/payment.componet';
import { IconHeadBox } from './checkout-icon-head-box';
import './scss/checkout-fulfillment-address.styles.scss';

class CheckoutFulfillmentAddress extends React.Component<Props, State> implements ICheckoutFulfillmentAddress {

    // 鼠标拖拽事件 滚动插件
    public SlideScroll: BScroll | undefined;

    // *********************
    // Life Cycle Function
    // *********************

    async componentDidMount() {
        // 初始化滚动插件
        this.initBetterScroll();
    }

    componentWillUnmount() {
        if (this.SlideScroll) {
            this.SlideScroll.destroy()
        }
    }

    // *********************
    // Default Function
    // *********************

    showNewAddressModal(): void {
        this.props?.updateShowedPage?.(ICheckoutOrderDetailShowedPageNames.NEW_ADDRESS_PAGE)
    }

    /**
     * 设置滚动插件初始化
     */
    initBetterScroll() {
        // 使用插件解决滑动问题
        try {
            // 区域 滑动
            const SlideWrapper: any = document.querySelector('#customerAddressBox')
            if (SlideWrapper) {
                this.SlideScroll = new BScroll(SlideWrapper, {
                    scrollX: true,
                    scrollY: false,
                    mouseWheel: true,
                    click: true
                });
            }
        } catch (error) {
            console.log(error)
        }
    }


    // *********************
    // View
    // *********************

    render() {
        const { restaurantLogo, restaurantAddress, allAddresses } = this.props;
        return (
            <div className='FulfillmentAddress' id='FulfillmentAddress'>
                <IconHeadBox
                    iCon={() => <MdRoom />}
                    title={
                        getQueryString('orderway') === '1'
                            ? i18n.t('restaurant_info_page_text_restaurant_address')
                            : i18n.t('payment_page_text_delivery_information')
                    }
                    actionLabel={() => {
                        if (getQueryString('orderway') === '2') {
                            return <MdAdd />
                        } else {
                            return <div></div>
                        }
                    }}
                    action={() => {
                        if (getQueryString('orderway') === '2') {
                            this.showNewAddressModal()
                        }
                    }}
                />
                {/* 餐厅地址 */}
                {getQueryString('orderway') === '1'
                    &&
                    <div className='restaurantAddress'>
                        <div className='logo'>
                            <img src={restaurantLogo} alt='' />
                        </div>
                        <div className='content'>
                            <p className='name'>{restaurantAddress.nickname}</p>
                            <p className='address'>{restaurantAddress.address}</p>
                        </div>
                    </div>}
                {/* 顾客地址 */}
                {getQueryString('orderway') === '2'
                    &&
                    <div className='customerAddressBox' id='customerAddressBox'>
                        <div className='customerAddress'>
                            {_.isArray(allAddresses) && allAddresses.length > 0 ? allAddresses.map((item, index) => {
                                return (
                                    <div className='addressItem' key={index}
                                        onClick={() => {
                                            this.props.onChangeSelectedAddress(item, index)
                                        }}>
                                        <AddressCars
                                            isSelected={(item.default || false) && (item.isWithin || false) && (!!item.door_number)}
                                            addressItem={item}
                                            handleModiftFn={(address, e) => {
                                                e.stopPropagation();
                                                const all = _.cloneDeep(allAddresses);
                                                all.forEach((element) => {
                                                    delete element['isEdit']
                                                })
                                                this.props.onChangeAddress(item, all, index)
                                            }}
                                        />
                                    </div>
                                )
                            }) :
                                <div className='noAddressText'>
                                    <span className='text'>{i18n.t('payment_page_text_enter_delivery_address')}</span>
                                </div>
                            }
                        </div>
                    </div>}
            </div>
        )
    }
}
export default CheckoutFulfillmentAddress

// *********************
// Props & State
// *********************

interface State extends ICheckoutFulfillmentAddressState {

}

interface Props extends ICheckoutFulfillmentAddressProps {
    onChangeSelectedAddress: (address: Address, index: number) => void;
    onChangeAddress: (address: Address, allAddresses: Array<Address>, index: number) => void;
}
