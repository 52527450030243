import { INIT_STATE, State, Actions } from './type';
import _ from 'lodash';

export const Reducer = (state: State = INIT_STATE, action: Actions) => {
    // init
    const newState = _.cloneDeep([state])[0];
    // switch actions
    switch (action.type) {
        case 'RESET_STATE': {
            _.set(newState, action.path, _.get(INIT_STATE, action.path));
            break;
        }
        case 'SET_STATE': {
            _.set(newState, action.path, _.get(action, 'value'));
            break;
        }
        default: { break; }
    }
    // return
    return newState;
};