import React from 'react';
import './coupon-item.style.scss';
import { Done } from '@material-ui/icons';
import moment from 'moment';
import i18n from '../../i18n';
import _ from 'lodash';
import { CheckoutCoupon } from '../../models';
import { AirBubbles } from '../payment/payment.componet';
import PhotoView from '../photoView/photo-view.component';
import { ImQrcode } from 'react-icons/im';
import { CircularProgress } from '@material-ui/core';

export default function CouponItem(props: {
    couponItem: CheckoutCoupon;
    isCheck?: boolean;
    onClick: () => void | Promise<void>;
    type?: 'restaurant' | 'payment' | 'myCoupon';
    className?: string;
    hideRuleText?: boolean;
    showNewTag?: boolean;
    accountID?: string;
}) {
    const { couponItem, onClick, isCheck, className, type, hideRuleText, showNewTag } = props
    const [isShowRuleView, onShowRuleView] = React.useState(false);
    const [isShowNewTag, onShowNewTag] = React.useState(showNewTag);
    const [isShowQRcode, onShowQRcode] = React.useState(false);
    const [isLoading, setIsLoaidng] = React.useState(false);
    const { name, type_rule_name, date_rule_effective_date, date_rule_expiration_date,
        discount_value, status, disClickstatus, discount_type, date_rule_enable, ignore_get_enable,
        is_customer_coupon, mealPreparationStatus, showCantUseText, coupon_data
    } = couponItem
    React.useEffect(() => { onShowNewTag(showNewTag) }, [showNewTag])

    // 用于是否是禁用状态
    let itemclass = ''
    if (type !== 'restaurant') {
        if (disClickstatus || status === '1' || mealPreparationStatus === true) {
            itemclass = 'disabledItem'
        } else if (isCheck) {
            itemclass = 'checkedItem'
        }
    }
    // 2:16vw; 3:12vw; 4:8vw; 5:6vw 6:5.5vw;
    // 2:23vw; 3:23vw; 4:17vw; 5:14vw 6:11vw;
    // 设置文字大小（文字越多字体越小）
    function setFontSize(value: string, originSize: number) {
        const length = `${value}`.length;
        if (originSize === 16) {
            if (length <= 2) {
                return `10vw`;
            } else if (length <= 4) {
                return `${originSize - ((length - 2) * 4)}vw`
            } else if (length === 5) {
                return `6vw`;
            } else {
                return `5.5vw`;
            }
        } else if (originSize === 23) {
            if (length <= 3) {
                return `30vw`
            } else if (length === 4) {
                return `23vw`;
            } else if (length === 5) {
                return `20vw`;
            } else {
                return `20vw`;
            }
        } else if (originSize === 25) {
            if (length <= 2) {
                return `30vw`
            } else if (length <= 4) {
                return `22vw`;
            } else if (length === 5) {
                return `17vw`;
            } else {
                return `15vw`;
            }
        }

    }
    // 免领取规则文本
    const analysisRulesOfReceiveText = analysisRulesOfReceive(couponItem).trim();
    // 规则文本组件
    const RulesText = () => {
        return (
            <>
                {/* 使用规则 */}
                <span dangerouslySetInnerHTML={{ __html: analysisRulesOfUse(couponItem, type) }}></span>
                {/* 免领取的规则 */}
                {ignore_get_enable && !is_customer_coupon && analysisRulesOfReceiveText && <span className='exemptionRulesText'>
                    <span className='title'>{i18n.t('coupon_Text_rules_exemption_rules')}:</span>
                    <span dangerouslySetInnerHTML={{ __html: analysisRulesOfReceiveText }}></span>
                </span>}
            </>
        )
    }

    return (
        <div className={`couponItemBox ${className || ''} ${(isCheck && type !== 'payment') ? 'isCheckCoupon' : ''}`}>
            <div className={`couponItem ${itemclass || ''} `}
                onClick={() => {
                    !isShowQRcode && onShowRuleView(true);
                    onShowNewTag(false);
                }}>
                {showCantUseText && <AirBubbles attachClass='couponItemBubbless' title={i18n.t('checkout_page_text_This_coupon_cannot_be_used_for_items_in_the_current_shopping_cart')} />}
                {isShowNewTag && <span className='showNewTag'>New</span>}
                {isShowRuleView ?
                    <div className='fullScreenRules' onClick={(e) => { e.stopPropagation(); onShowRuleView(false) }}>
                        {RulesText()}
                    </div>
                    : <>
                        <div className={`backGroundVal`} style={
                            type
                                ? { fontSize: setFontSize(`${discount_value}${discount_type === '0' ? '%' : ''}`, 23) }
                                : {
                                    paddingBottom: '5px', fontSize: setFontSize(`${discount_value}${discount_type === '0' ? '%' : ''}`, 25)
                                }
                        }>
                            {discount_value}{discount_type === '0' ? '%' : '€'}
                        </div>
                        <div className={`ruleText ${type === 'restaurant' ?
                            'restaurantCouponItem'
                            : (type === 'myCoupon') ? 'myCouponItem' : ''}`
                        }>
                            <div className='row1'>
                                <div className='col1' style={{ fontSize: setFontSize(`${discount_value}${discount_type === '0' ? '%' : ''}`, 16) }}>
                                    {discount_value}{discount_type === '0' ? '%' : '€'}
                                </div>
                                <div className='col2'><span>{name}</span></div>
                            </div>
                            <div className='row2'>
                                <div className='col1'>{type_rule_name}</div>
                                <div className='col2'>
                                    {date_rule_enable
                                        ? `${formateDate(date_rule_effective_date, 'DD.MM.YYYY')} - ${formateDate(date_rule_expiration_date, 'DD.MM.YYYY')}`
                                        : ''}
                                </div>
                            </div>
                        </div>
                        {(type === 'restaurant' || type === 'payment') ?
                            <div className='getBox'>
                                <span onClick={async (e) => {
                                    e.stopPropagation();
                                    setIsLoaidng(true);
                                    await onClick();
                                    setIsLoaidng(false);
                                }}>
                                    {
                                        isLoading ? <CircularProgress style={{ color: '#ffdc33', width: 25, height: 25 }} />
                                            : type === 'restaurant' ? i18n.t('coupon_text_receive')
                                                : (isCheck ?
                                                    <div className='CheckedBox'>
                                                        <Done className='doneicon' />
                                                    </div>
                                                    : i18n.t('coupon_text_use'))
                                    }
                                </span>
                            </div>
                            :
                            <div className='getBox'>
                                <div
                                    className='qrCodeBox'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onShowQRcode(true);
                                    }}>
                                    <div className='CheckedBox'>
                                        <ImQrcode className='IoQrCode' />
                                        <div className='IoQrCodeText'>{i18n.t('general_text_QR_code')}</div>
                                    </div>
                                    <div className='coupoCodeTextBox'>
                                        <div className='coupoCodeText'>{couponItem?.coupon_code}</div>
                                    </div>
                                </div>
                                <PhotoView
                                    value={JSON.stringify({ package_code: coupon_data?.package_code, coupon_code: coupon_data?.coupon_code }) || ''}
                                    visible={isShowQRcode}
                                    onClose={() => { onShowQRcode(false) }}
                                />
                            </div>
                        }
                    </>}
            </div>
            {
                (!hideRuleText && type !== 'restaurant') &&
                <div className={`description ${(isCheck && type !== 'payment') ? 'checkedDescription' : ''} `}>
                    {RulesText()}
                </div>
            }
        </div >
    )
}
/**
 * 格式化日期
 * @param date 日期
 * @param format 要转换的格式
 * Example// formateDate(orderTime, 'DD/MM/YYYY')
 */
export function formateDate(date: Date | undefined, format: string) {
    return date ? moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format) : '';
}

/**
 * 分析'使用规则'并国际化
 * @param coupon 每张优惠券
 */
export function analysisRulesOfUse(coupon: CheckoutCoupon, type?: 'restaurant' | 'payment' | 'myCoupon') {
    const { date_rule_enable, date_rule_effective_date,
        date_rule_expiration_date, count_rule_total_count,
        count_rule_remaining_count, type_rule_enable, type_rule_name, type_rule_num,
        exclusion_rule_enable, order_price_rule_enable, order_price_rule_value,
        order_type_rule_enable, order_type_rule_types, meal_preparation_date_rule_enable,
        meal_preparation_date_rule_value, string_ids, use_fail_rule, effectiveTargetsRestaurants
    } = coupon;

    // $ 时间规则: `仅限于{1}至{2}使用;`
    let timeRulesText = date_rule_enable ? i18n.t('coupon_Text_rules_time',
        {
            1: formateDate(date_rule_effective_date, 'DD.MM.YYYY'),
            2: formateDate(date_rule_expiration_date, 'DD.MM.YYYY')
        }) : '';
    if (_.includes(use_fail_rule, 'date_rule_enable') && timeRulesText) {
        timeRulesText = failRuleText(timeRulesText);
    }
    // $ 次数规则: `此优惠券能使用{{1}}次，还能使用{{2}}次;`
    const frequencyRulesText = i18n.t('coupon_Text_rules_frequency',
        { 1: count_rule_total_count, 2: count_rule_remaining_count }) || '';
    // if (_.includes(use_fail_rule, 'date_rule_enable') && frequencyRulesText) {
    //     frequencyRulesText = failRuleText(frequencyRulesText);
    // }
    // $ 种类规则: `{1}的类型优惠券允许同时使用{2}张;`
    let categoryRulesText = type_rule_enable ? i18n.t('coupon_Text_rules_category',
        { 1: type_rule_name, 2: type_rule_num }) : '';
    if (_.includes(use_fail_rule, 'type_rule_enable') && categoryRulesText) {
        categoryRulesText = failRuleText(categoryRulesText);
    }
    // $ 排斥规则: `无法同其他优惠券一起使用;`
    let exclusionRulesText = exclusion_rule_enable ? i18n.t('coupon_Text_rules_exclusion') : '';
    if (_.includes(use_fail_rule, 'exclusion_rule_enable') && exclusionRulesText) {
        exclusionRulesText = failRuleText(exclusionRulesText);
    }
    // $ 订单总额规则: `满{1}元使用;`
    let orderTotalRulesText = order_price_rule_enable ? i18n.t('coupon_Text_rules_orderTotal',
        { 1: order_price_rule_value }) : '';
    if (_.includes(use_fail_rule, 'order_price_rule_enable') && orderTotalRulesText) {
        orderTotalRulesText = failRuleText(orderTotalRulesText);
    }
    // $ 订单类型规则: `(order_type_rule_types= 0: 仅限外卖订单使用 1: 仅限自取订单使用 2:堂食使用)`
    let orderTypeRulesText = '';
    if (order_type_rule_enable) {
        if (_.includes(order_type_rule_types, '0') && _.includes(order_type_rule_types, '1') && type === 'payment') {
            orderTypeRulesText = '';
        } else if (order_type_rule_types && order_type_rule_types.length <= 2) {
            const takeaway = (_.includes(order_type_rule_types, '0') && i18n.t('restaurant_info_page_text_order_delivery')) || '';
            const delivery = (_.includes(order_type_rule_types, '1') && i18n.t('restaurant_info_page_text_order_takeaway')) || '';
            const liveDining = (_.includes(order_type_rule_types, '2') && i18n.t('coupon_Text_rules_live_dining')) || '';
            const text = _.compact([takeaway, delivery, liveDining])
            orderTypeRulesText = i18n.t('coupon_Text_rules_orderType_by', { 1: `${text.join()}` })
        }
    }
    if (_.includes(use_fail_rule, 'order_type_rule_enable') && orderTypeRulesText) {
        orderTypeRulesText = failRuleText(orderTypeRulesText);
    }
    // $ 备餐时间规则: `至少{1}分钟的备餐时间;`
    let mealPreparationTime = meal_preparation_date_rule_enable ? i18n.t('coupon_Text_rules_meal_preparation_time',
        { 1: meal_preparation_date_rule_value }) : '';
    if (_.includes(use_fail_rule, 'meal_preparation_date_rule_enable') && mealPreparationTime) {
        mealPreparationTime = failRuleText(mealPreparationTime);
    }
    // $ 适用于哪家餐馆(非领取优惠券)
    let applyToRestaurants = '';
    // $ 生效目标
    const effectiveTargetsRestaurantsHelpful: string[] = [];
    if (effectiveTargetsRestaurants && _.isArray(effectiveTargetsRestaurants) && effectiveTargetsRestaurants.length > 0) {
        // let ETResItem = _.find(effectiveTargetsRestaurants, (n) => { return n.res_string_id.string_id === this.state.restaurantStringId });
        effectiveTargetsRestaurants.forEach((n) => {
            const menuName = _.map(n.menuData || [], (dishItem) => { return dishItem.name });
            const dishesName = _.map(n.dishData || [], (dishItem) => { return dishItem.name });
            const menuNameText = menuName.length > 0 ? `${i18n.t('coupon_Text_rules_effective_targets_menu', { menuNameText: menuName.join(',') })}` : '';
            const dishesNameText = dishesName.length > 0 ? `${i18n.t('coupon_Text_rules_effective_targets_dish', { dishesNameText: dishesName.join(',') })}` : '';
            const andText = menuNameText && dishesNameText ? i18n.t('coupon_Text_rules_effective_targets_and_text') : '';
            if (menuNameText || dishesNameText) {
                effectiveTargetsRestaurantsHelpful.push(
                    `${i18n.t('coupon_Text_rules_effective_targets', { nickname: n.res_string_id.nickname })}${menuNameText}${andText}${dishesNameText};`
                )
            }
        })
    } else {
        if (string_ids && _.isArray(string_ids)) {
            const restaurantNameArr = _.map(string_ids, (n) => { return n && n.nickname })
            // 有多个餐馆才显示
            if (restaurantNameArr.length > 1) {
                const restaurantNameText = _.join(restaurantNameArr, '、')
                applyToRestaurants = i18n.t('coupon_Text_rules_apply_to_Restaurants', { 1: restaurantNameText })
            }
        }
    }
    let effectiveTargetsRestaurantsRuleText = ''
    if (effectiveTargetsRestaurantsHelpful.length > 0) {
        effectiveTargetsRestaurantsRuleText = effectiveTargetsRestaurantsHelpful.join('');
    } else {
        effectiveTargetsRestaurantsRuleText = '';
    }
    // $所有规则拼接
    const allRulesText = `${applyToRestaurants} ${effectiveTargetsRestaurantsRuleText} ${timeRulesText} ${frequencyRulesText} ${categoryRulesText} ${exclusionRulesText} ${orderTotalRulesText} ${orderTypeRulesText} ${mealPreparationTime}`;

    return allRulesText
}

/**
 * 分析'领取规则'并国际化（免领取的优惠券）
 * @param coupon 每张优惠券
 */
export function analysisRulesOfReceive(coupon: CheckoutCoupon) {
    const { ignore_get_enable, count_obtain_strategy, user_obtain_strategy, order_frequency_enable, order_frequencies,
        integral_full_enable, integral_full_value, integral_deduction_enable, integral_deduction_value,
        total_consumption_fee_enable, total_consumption_fee_value, total_times_limit_enable, total_times_limit_value,
        receive_fail_rule, achieve_order_money_enable, achieve_order_money, achieve_order_money_day
    } = coupon;
    // $ 开启免领取的优惠券
    if (ignore_get_enable) {

        // $ 只能领取一次(不选择的话使用完才能再次领取)、无开关字段
        // 0：只能领取一次
        // 1：使用完了才能领取(不显示)
        let countObtainStrategy = count_obtain_strategy === '0' ? i18n.t('coupon_Text_rules_only_receive_once') : '';

        if (_.includes(receive_fail_rule, 'count_obtain_strategy') && countObtainStrategy) {
            countObtainStrategy = failRuleText(countObtainStrategy);
        }
        // $ 顾客类型规则、无开关字段
        // 0: 在此餐馆未下过订单的用户可以领取
        // 1: 在此餐馆下过订单的用户可以领取
        // 2: 所有顾客都可以领取(不显示)
        let userObtainStrategy = '';
        if (user_obtain_strategy === '0') {
            userObtainStrategy = i18n.t('coupon_Text_rules_not_placed_order_customer');
        } else if (user_obtain_strategy === '1') {
            userObtainStrategy = i18n.t('coupon_Text_rules_placed_order_customer');
        }
        if (_.includes(receive_fail_rule, 'user_obtain_strategy') && userObtainStrategy) {
            userObtainStrategy = failRuleText(userObtainStrategy);
        }
        // $ 下单频率规则:最近（x）个月（下过/未下过）的订单的顾客
        // 下单频率的类型 0: 下过 1：未下过
        // 下单频率的相隔时间（月）
        let orderFrequency = '';
        if (order_frequency_enable) {
            if (_.isArray(order_frequencies)) {
                const orderFrequenciesItem = order_frequencies[0];
                if (orderFrequenciesItem) {
                    if (orderFrequenciesItem.order_frequency_type === '0') {
                        // 最近{{1}}个月下过订单
                        orderFrequency = i18n.t('coupon_Text_rules_order_frequency_has_been_placed', { 1: orderFrequenciesItem.order_frequency_value })
                    } else if (orderFrequenciesItem.order_frequency_type === '1') {
                        // 最近{{1}}个月未下过订单'
                        orderFrequency = i18n.t('coupon_Text_rules_order_frequency_No_order_has_been_placed', { 1: orderFrequenciesItem.order_frequency_value })
                    }
                }
            }
        }
        if (_.includes(receive_fail_rule, 'order_frequency_enable') && orderFrequency) {
            orderFrequency = failRuleText(orderFrequency);
        }
        // $ 满足多少积分领取规则
        let integralFull = integral_full_enable ? i18n.t('coupon_Text_rules_meet_the_points_rules', { 1: integral_full_value }) : '';
        if (_.includes(receive_fail_rule, 'integral_full_enable') && integralFull) {
            integralFull = failRuleText(integralFull);
        }
        // $ 消耗积分领取规则
        let integralDeduction = integral_deduction_enable ? i18n.t('coupon_Text_rules_points_consumption', { 1: integral_deduction_value }) : '';
        if (_.includes(receive_fail_rule, 'integral_deduction_enable') && integralDeduction) {
            integralDeduction = failRuleText(integralDeduction);
        }
        // $ 历史总共消费多少领取规则
        let totalConsumptionFee = total_consumption_fee_enable ? i18n.t('coupon_Text_rules_history_total_consumption', { 1: `€${total_consumption_fee_value}` }) : '';
        if (_.includes(receive_fail_rule, 'total_consumption_fee_enable') && totalConsumptionFee) {
            totalConsumptionFee = failRuleText(totalConsumptionFee);
        }
        // $ 历史总共下单的次数领取规则
        let totalTimesLimit = total_times_limit_enable ? i18n.t('coupon_Text_rules_history_has_been_ordered_in_total', { 1: total_times_limit_value }) : '';
        if (_.includes(receive_fail_rule, 'total_times_limit_enable') && totalTimesLimit) {
            totalTimesLimit = failRuleText(totalTimesLimit);
        }
        // $ XX天内下过超过XX欧元订单
        let achieveOrderMoney = achieve_order_money_enable ? i18n.t('coupon_Text_rules_Orders_exceeding_X_amount_placed_within_Y_days', { x: achieve_order_money_day, y: achieve_order_money }) : '';
        if (_.includes(receive_fail_rule, 'achieve_order_money_enable') && achieveOrderMoney) {
            achieveOrderMoney = failRuleText(achieveOrderMoney);
        }
        // $ 所有规则拼接
        const allRulesText = `${countObtainStrategy} ${userObtainStrategy} ${orderFrequency} ${integralFull} ${integralDeduction} ${totalConsumptionFee} ${totalTimesLimit} ${achieveOrderMoney}`;
        return allRulesText;
    } else {
        return ''
    }
}

/**
 * 将错误规则文本字体变红
 * @param text
 */
function failRuleText(text) {
    return `<span style='color:red'>${text}</span>`;
}