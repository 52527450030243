import React from 'react';
import './top-bar.component.scss';
import { Drawer, Badge } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { SidebarComponet } from '../sidebar/sidebar.component';
import i18n from '../../i18n';
import { ReduxConnect } from '../decorators/redux-connect.decorator';
import { State as States } from '../../rudex/type';
import Store from '../../rudex/store';
import _ from 'lodash';
import { routeBack } from '../../constants/checkRoute';
// 公共头部组件


@ReduxConnect((defaultState: States ) => {
    return {
        // 新消息
        hasNewNotices: defaultState.hasNewNotices
    }
})

class TopBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            open: false
        }
    }
    // *********************
    // Service Function
    // *********************

    public isShowSidebar = () => {
        const { open } = this.state
        this.setState({
            open: !open
        })
    }
    // ***************
    // VIEW
    // ***************

    public render() {
        const { open } = this.state
        const { title, info, isHideMenu, isGoBack, backgroundstyle,
            isConfirm, rightIcon, closedTime, isShowClosed } = this.props;
        const { isHideGoback } = this.props;
        // 商家网址
        const merchantWebsite = _.get(Store.getState(), 'merchantWebsite', false);
        return (
            <div className={`top-bar pos-relative ${backgroundstyle || ''}`}>
                <div onClick={() => {
                    if (!isHideGoback && !merchantWebsite) {
                        if (isGoBack) {
                            isGoBack()
                        } else {
                            routeBack();
                        }
                    }
                }}>
                    {!isHideGoback && !merchantWebsite && <ArrowBackIos className='bar-left-icon tool-icon' />}
                </div>
                <div className={`title-text overall-center`}>
                    <div className='title'>{title}</div>
                    {
                        isShowClosed ? null : <div className='info'>{info}</div>
                    }
                    {
                        isShowClosed ? <div className='closed'>
                            <span className='text'>{i18n.t('checkout_page_text_temporary_closed')}</span>
                            <span className='text'>{closedTime}</span>
                        </div> : null
                    }
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (isConfirm) {
                            isConfirm()
                        } else {
                            this.isShowSidebar()
                        }
                    }}>
                    {
                        !isHideMenu ? (
                            rightIcon ? rightIcon() :
                                <Badge variant='dot' color={this.props.hasNewNotices ? 'error' : 'default'} className={'bar-menu'}>
                                    <MenuIcon className='tool-icon' />
                                </Badge>
                        ) : null
                    }

                </div>

                <Drawer anchor={'left'} open={open} onClose={() => this.isShowSidebar()}>
                    <SidebarComponet
                        hasNewNotices={this.props.hasNewNotices || false}
                        onClose={() => this.isShowSidebar()} />
                </Drawer>
            </div>
        )
    }
}

export default TopBar;

// **********
// TYPE
// **********

type Props = {
    // 标题
    title: string;
    // 标题下的信息
    info?: string;
    // 是否显示菜单按钮
    isHideMenu?: boolean;
    // 页面回退按钮是否回退，否则取消组件显示(或更新父子组件)
    isGoBack?: () => void;
    // 添加calssname改样式，否则默认色#FFDC33
    backgroundstyle?: string;
    // 右边icon事件 且设置 确认icon
    isConfirm?: () => void;
    // 右边icon  JSX.Element
    rightIcon?: () => JSX.Element;
    isShowClosed?: boolean;
    closedTime?: string;
    // 新消息
    hasNewNotices?: boolean;
    // 隐藏返回
    isHideGoback?: boolean;
};

type State = {
    // 点击展开侧边栏
    open?: boolean
}
