import React from 'react';
import { useSnackbar } from 'notistack';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import i18n from '../i18n';

/**
 * 打开APP通知组件
 */
export const OpenAppComponent = function OpenAppComponent(props: Props) {
    // useSnackbar只能在hook中使用
    const { closeSnackbar } = useSnackbar();
    return (
        <React.Fragment>
            <Button className='downloadBtn' size='small' onClick={() => { props.openApp() }}>{i18n.t('general_text_open')}</Button>
            <Button className='downloadBtn' size='small' onClick={() => { props.downloadApp() }}>{i18n.t('general_text_download')}</Button>
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => { closeSnackbar() }}>
                <CloseIcon fontSize='small' />
            </IconButton>
        </React.Fragment>
    );
}

type Props = {
    openApp: () => void;
    downloadApp: () => void;
}