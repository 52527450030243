import Divider from '@material-ui/core/Divider';
import QRCode from 'qrcode.react';
import ClearIcon from '@material-ui/icons/Clear';
import FaceIcon from '@material-ui/icons/Face';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import RoomIcon from '@material-ui/icons/Room';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import * as React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import AccountInformation from '../../component/account/account-information.component';
import ChangeEmailOrTel from '../../component/account/change-email-or-tel.component';
import ChangePassword, { CHANG_PASSWORD_COMPONENT_TYPE } from '../../component/account/change-password.component';
import DeliveryAddress from '../../component/account/delivery-address.component';
import FiscalInformation from '../../component/account/fiscal-information.component';
import PhoneInput from '../../component/account/phone.component';
import CouponList from '../../component/coupon/coupon-list.component';
// import Scroll from '../../component/scroll/scroll.component';
import TopBar from '../../component/top-bar/top-bar.component';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import i18n from '../../i18n';
import { TokenMetadata } from '../../models';
import { Customer, CustomerProjectionFixed } from '../../models/customer.entity';
import apiManage from '../../request';
import '../../static/css/accoun.scss';
import { errorCatching } from '../../tool/error-catching';
import Notification from '../../component/snackbar/snackbar';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { FACKBOOK_APP_ID } from '../../gconfig';
import { getMessages } from '../../tool/notices.tool';
import { cleanAccountCache } from '../../constants/tool-check-token';
import { GoogleLoginFixed } from '../../component/google-login/google-login';
import personLogo from '../../static/images/baseline_person_black_48dp.png';
import Barcode from 'react-barcode';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { MdQrCode } from 'react-icons/md';
import { Button, Dialog, DialogContent, IconButton } from '@material-ui/core';
import './my-account.page.scss'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import { BindPhoneDialog } from '../../component/bind-phone/bind-phone-dialog.component';

@inject_unount
class MyAccount extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            language: 'IT',
            insertPhone: '',
            insertPhonePrefix: '',
            insertEmail: `${i18n.t('account_page_text_collect_your_email')}`,
            // 是否显示修改手机号页面
            isShowPhone: false,
            // 是否显示地址編輯页面
            isShowAddress: false,
            // 第三方用户名
            thirdPartyFaceBookName: '',
            facebookRelatedStatus: false,
            thirdPartyGoogleName: '',
            // 地址信息
            addressModifiedInfo: {
                // 地址
                country: '',
                // 区域
                address: '',
                // 用户名
                username: '',
                // 电话
                tel: ''
            },
            isShowMyaccount: false,
            isShowMypassword: false,
            // 移除第三方确认框
            isRemovethirdPartyModal: false,
            // 显示电子发票组件
            isFiscalInformation: false,
            // 显示邮箱组件
            isShowEmail: false,
            customerInfo: {},
            // 用户名
            userName: '',
            // 移除第三方类型
            removeThirdPartyModalType: '',
            avatarLogo: this.getAccountInfo('avatar') || personLogo,
            // 用户是否选择了地址
            isSelectedAddress: false,
            // 用户id
            customerId: '',
            // 优惠券包
            couponPackage: [],
            // 优惠券list visible
            couponListVisible: false,
            // 选中的优惠券
            usedCouponIds: [],
            // 是否是第一次设置密码
            firstSetting: false,
            // 删除账户弹窗
            deleteAccountVisible: false,
            // 邀请码二维码窗口开关
            isOpenReferralDialog: false,
            // binding tip dialog
            openBindDialog: {
                isOpen: false,
            }
        }
    }
    // ***************
    //  生命周期
    // ***************
    async UNSAFE_componentWillMount() {
        // 初始化餐馆信息
        try {
            const RESULT_METADATA: any = await apiManage.get_auth_token_metadata();
            // 错误捕捉
            if (errorCatching(RESULT_METADATA, `${i18n.t('general_text_invaild_token')},${i18n.t('order_page_text_after_login_view')}`)) {
                setTimeout(() => {
                    this.props['history'].replace('/')
                }, 1000);
                return;
            } else {
                this.getCustomer();
            }
        } catch (error) { }
    }

    componentDidMount() { }

    componentWillUnmount() {
        this.setState = () => { return }
    }
    // ***************
    // 方法区
    // ***************

    /**
     * 获取用户信息
     * @param val 用户信息缓存里携带的字段 , 不传则返回整个用户信息
     */
    public getAccountInfo(val?: string) {
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        let getAccountParameter: any = ''
        if (account && val) getAccountParameter = _.get(account, `${val}`) || ''
        else getAccountParameter = account
        return getAccountParameter;
    }

    public async getCustomer(params?: { getCoupons?: boolean }) {
        const account = this.getAccountInfo();
        let customerId = '';
        if (account) { customerId = account._id }
        try {
            console.log('customerId', customerId);
            // 获取用户信息
            const customerInfo = await apiManage.get_customer_info(customerId, undefined, CustomerProjectionFixed(['pr_key_info', 'pr_basic_info', 'pr_messages_info', 'pr_third_party_related_info', 'pr_coupon']));

            // 错误捕捉
            if (errorCatching(customerInfo, 'scan_reslist_page_text_request_customer_info')) return;


            if (customerInfo && Object.values(customerInfo).length) {
                // 更新最新的账户信息
                const account: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                _.set(account, 'first_name', _.get(customerInfo, 'first_name'))
                _.set(account, 'last_name', _.get(customerInfo, 'last_name'))
                _.set(account, 'phone', _.get(customerInfo, 'phone'))
                _.set(account, 'phone_prefix', _.get(customerInfo, 'phone_prefix'))
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.ACCOUNT,
                    value: account
                });
                // 获取通知信息
                getMessages(_.get(customerInfo, 'messages', 0));
                // 初始化选中的地址栏
                const checkAddress = _.cloneDeep(customerInfo.address);
                if (_.isArray(checkAddress)) {
                    if (checkAddress.length === 0) {
                        this.setState({
                            isSelectedAddress: false
                        })
                    } else if (checkAddress.length > 0) {
                        const defaultAddress = _.find(checkAddress, (n) => n.default === true)
                        if (defaultAddress) {
                            const item: any = defaultAddress
                            this.setState({
                                isSelectedAddress: true,
                                addressModifiedInfo: {
                                    country: `${item.country}`,
                                    // address: `${item.street} ${item.home_number} ${item.code} ${item.city} ${item.state} ${item.country}`,
                                    address: `${item.street} ${item.home_number} ${item.door_number || ''}`,
                                    username: `${item.first_name || ''} ${item.last_name || ''}`,
                                    tel: `${item.phone}`
                                }
                            })
                        } else {
                            this.setState({
                                isSelectedAddress: false
                            })
                        }

                    }
                }

                // 是否有姓名
                const hasUserName = customerInfo.first_name || customerInfo.last_name;
                const customerName = `${_.get(customerInfo, 'first_name', '')} ${_.get(customerInfo, 'last_name', '')}`
                const couponPackage = _.get(customerInfo, 'coupons') || [];
                this.setState({
                    customerInfo,
                    thirdPartyFaceBookName: customerInfo.facebook_related_email || '',
                    facebookRelatedStatus: customerInfo.facebook_related_status || false,
                    thirdPartyGoogleName: customerInfo.google_related_email || '',
                    customerId,
                    insertPhone: customerInfo.phone || '',
                    insertPhonePrefix: customerInfo.phone_prefix || '',
                    insertEmail: customerInfo.email || `${i18n.t('account_page_text_collect_your_email')}`,
                    userName: hasUserName ? customerName : '',
                    couponPackage: couponPackage || [],
                    firstSetting: customerInfo.first_setting || false
                })

                if (!params || (params && !params.getCoupons)) {
                    // 获取用户头像图片地址
                    let getAvatar: any = ''
                    // 检查头像资源版本是否有更新
                    getAvatar = await apiManage.get_staff_id_avatar(customerId, { notNeedLoading: true }) || '';
                    this.setState({
                        avatarLogo: getAvatar
                    })
                    _.set(account, `avatar`, getAvatar)
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.ACCOUNT,
                        value: account
                    });
                }
            }
        } catch (error) {
            console.log(error, 'my_account');
        }

    }

    /**
     * 解绑邮箱
     */
    public async untieEmail() {
        if (i18n.t('account_page_text_collect_your_email') === this.state.insertEmail) return;
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        try {
            const result = await apiManage.customer_unlock_email(account._id || '');
            if (result.error) {
                Notification.notification(i18n.t('account_page_text_release_failed'));
            } else {
                this.setState({
                    insertEmail: i18n.t('account_page_text_collect_your_email')
                })
            }
        } catch (error) {
            //
        }
    }

    /**
     * 把input的file转换成可读取的图片
     * @file 图片文件
     */
    convertImageFile = async (file: any) => {
        try {
            const imgFile = await new Promise((resolve, reject) => {
                // 创建读取文件的对象
                const reader: FileReader = new FileReader();
                // 正式读取文件
                reader.readAsDataURL(file);
                // 为文件读取成功设置事件
                reader.onload = (e: any) => {
                    resolve(e.target.result);
                };
                reader.onerror = (e: any) => {
                    reject(e);
                };
            })
            return imgFile
        } catch (error) {
            return personLogo
        }
    }

    updateState = (value: any) => {
        this.setState(value)
    }

    public handleClose = (val: any) => {
        this.setState(val)
    }

    public async createImgURL(event) {
        const formData = new FormData();
        const { customerId } = this.state;
        if (!event.target.files) return;
        const files = { ...event.target.files }
        if (files.length === 0) return;
        // const patch_staff_id_avatar
        const IMG_FILE = await event.target.files[0];
        const accountInfo = this.getAccountInfo();
        if (IMG_FILE.size <= 500000) {
            formData.append('', IMG_FILE);
            try {
                console.log(IMG_FILE, 'IMG_FILE');
                console.log(formData, 'formData');
                const AVATAR_RES = await apiManage.patch_staff_id_avatar(customerId, formData);

                // 错误捕捉
                if (errorCatching(AVATAR_RES, 'account_page_text_upload_fail')) return;

                // 实现预览
                // const windowURL = window.URL || window.webkitURL;

                // 获取用户头像图片地址
                // const getAvatar = await apiManage.get_staff_id_avatar(customerId);
                // 指向文件路径
                // const avatarLogo = windowURL.createObjectURL(IMG_FILE);
                const avatarLogo = await this.convertImageFile(IMG_FILE);

                _.set(accountInfo, `avatar`, avatarLogo)
                this.setState({ avatarLogo });
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.ACCOUNT,
                    value: accountInfo
                });
            } catch (error) {
                console.log('error:', error);
            }
        } else {
            Notification.notification(i18n.t('account_page_text_upload_image'));
        }
        // console.log(getAvatar);
    }
    /**
     * 永久性删除账户
     * - 14天后硬删除
     */
    async deleteAccount() {
        const res = await apiManage.delect_account(this.state.customerId);
        if (!res?.error) {
            Notification.notification(i18n.t(`account_page_text_delete_account_successfully`));
            cleanAccountCache();
            this.props['history'].replace('/')
        } else {
            Notification.notification(i18n.t(`account_page_text_delete_account_failed`));
        }
        this.setState({ deleteAccountVisible: false });
    }
    // ***************
    // VIEW
    // ***************

    public render() {
        const { insertPhone, insertEmail, isShowPhone, thirdPartyGoogleName,
            thirdPartyFaceBookName, facebookRelatedStatus, isShowAddress, isShowMyaccount,
            isShowMypassword, isRemovethirdPartyModal, isShowEmail, userName,
            isFiscalInformation, isSelectedAddress, removeThirdPartyModalType,
            customerInfo, avatarLogo, addressModifiedInfo, couponListVisible,
            couponPackage, firstSetting, insertPhonePrefix, deleteAccountVisible, openBindDialog
        } = this.state;
        return (
            <div className='account-main overall-center'>
                {/* 修改我的账户 */}
                {isShowMyaccount && (
                    <AccountInformation
                        changeStatus={(val, isUpdata) => {
                            if (isUpdata) {
                                this.getCustomer({ getCoupons: true })
                            }
                            this.setState({
                                isShowMyaccount: val
                            });
                        }}
                        onchange={(val) => {
                            this.setState({
                                userName: `${_.get(val, 'first_name')} ${_.get(val, 'last_name')}`
                            })
                        }}
                        customerInfo={customerInfo}
                    />
                )}
                {/* 修改我的密码 */}
                {isShowMypassword && (
                    <ChangePassword
                        changeStatus={(val, isUpdata) => {
                            if (isUpdata) {
                                this.getCustomer({ getCoupons: true })
                            }
                            this.setState({
                                isShowMypassword: val
                            });
                        }}
                        componentType={firstSetting ? CHANG_PASSWORD_COMPONENT_TYPE.FIRST_SETTING : ''}
                    />
                )}
                {/* 修改邮箱 或 手机号  isShowPhone */}
                {
                    (isShowEmail || isShowPhone) && <ChangeEmailOrTel
                        phone={insertPhone}
                        isShowEmail={isShowEmail}
                        isShowPhone={isShowPhone}
                        changeStatus={(val) => {
                            this.getCustomer()
                            this.setState(val);
                        }} />
                }
                {/* 修改/添加 地址信息 */}
                {
                    isShowAddress && <DeliveryAddress
                        type={'customerInfo'}
                        customerInfo={customerInfo}
                        changeStatus={(val) => {
                            this.getCustomer()
                            this.setState(val)
                        }}
                        restaurantTheme={'FIRST_THEME'} />
                }
                {/* 电子发票信息 */}
                {
                    isFiscalInformation && <FiscalInformation changeStatus={(val) => {
                        this.setState({
                            isFiscalInformation: val
                        })
                    }} />
                }
                {/* 移除第三方账户弹窗 */}
                <RemoveThirdPartyModal
                    type={removeThirdPartyModalType}
                    customerInfo={customerInfo}
                    isRemovethirdPartyModal={isRemovethirdPartyModal}
                    updateState={(upDataVal: any) => {
                        this.updateState(upDataVal);
                    }} />

                {/* 邀请码的二维码 */}
                <div>
                    <Dialog
                        className='referral-dialog'
                        onBackdropClick={() => { this.setState({ isOpenReferralDialog: false }) }} open={this.state.isOpenReferralDialog} >
                        <div style={{
                            padding: '18px',
                            borderRadius: '70px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <QRCode value={`${window.location.origin}/#/sign-in?referrals=${this.state.customerInfo.my_recommendation_code}`}
                                size={180}
                            />
                            <div style={{
                                color: '#bcbcbc',
                                fontSize: '20px',
                                fontWeight: 40,
                            }}>
                                {this.state.customerInfo.my_recommendation_code}
                            </div>
                        </div>
                    </Dialog>
                </div >

                {/* 优惠券list */}
                {
                    couponListVisible ?
                        <CouponList
                            couponPackage={couponPackage}
                            onCancel={() => { this.setState({ couponListVisible: false }) }}
                            type={'myCoupon'}
                            className='myCoupon'
                            onSucceeded={() => { this.getCustomer({ getCoupons: true }) }}
                        />
                        : null
                }
                {/* 删除账户弹窗 */}
                {deleteAccountVisible ?
                    <DeleteAccountDialog
                        handleClose={() => { this.setState({ deleteAccountVisible: false }) }}
                        handleOk={async () => { await this.deleteAccount(); }}
                    />
                    : null}
                {/* 第三方绑定账号提示 */}
                {openBindDialog.isOpen &&
                    <BindPhoneDialog
                        onConfirm={() => { openBindDialog?.confirm?.(); }}
                        handleClose={() => { this.setState({ openBindDialog: { isOpen: false } }) }}
                        content={i18n.t('general_text_This_x_mobile_phone_number_has_been_registered_on_Ordelivery_should_I_merge_this_account', { x: openBindDialog.type })}
                    />}
                {/* Top bar */}
                <TopBar
                    backgroundstyle={'backgroundTransparent'} title={i18n.t('text_client_my_account')}
                />
                <div className='account-content  margin-bottom'>
                    {/* Account Avatar */}
                    <div className='account-avatar margin-bottom boxShadow' onClick={() => {
                        this.setState({
                            isShowMyaccount: true
                        })
                    }}>
                        <div className='account-avatar-info '>
                            <div className='flex-center-start'>
                                <div className='account-avatar-img' onClick={(e) => {
                                    e.stopPropagation();
                                }}>
                                    <img src={avatarLogo} alt='' />
                                    <input
                                        className={'account-avatar-input'}
                                        type='file'
                                        name='file'
                                        id='uploadFile'
                                        accept='image/png, image/jpeg, image/jpg'
                                        onChange={async (event) => {
                                            this.createImgURL(event)
                                        }} />
                                </div>
                                <div className='avatar-info-height'>
                                    <div className='avatar-name fontStyle'>{userName || i18n.t('sidebar_page_text_not_set')}</div>
                                </div>
                            </div>
                            <div className='icon'>
                                <KeyboardArrowRightIcon />
                            </div>
                        </div>
                        <Divider />
                        {/* 会员码 */}
                        <div className='member-box  '>
                            <div className='member-box-text'>
                                {insertPhone && <Barcode value={insertPhone} height={80} />}
                            </div>
                        </div>
                    </div>
                    {/* account info */}
                    <div className='account-info boxShadow'>
                        <div className='phone margin-bottom'>
                            <div className='phone-msg account-info-tit' onClick={() => {
                                this.setState({
                                    isShowPhone: true
                                })
                            }}>
                                <div className='tit'>{i18n.t('account_page_text_phone')}</div>
                                <div className='icon-left'>
                                    <KeyboardArrowRightIcon />
                                </div>
                            </div>
                            <div className='phone-input'>
                                <PhoneInput
                                    disabled={true}
                                    className='phone-main input-main'
                                    defaultValue={insertPhone}
                                    phonePrefix={insertPhonePrefix}
                                    onChange={(val: string) => {
                                        this.setState({
                                            insertPhone: val
                                        });
                                    }}
                                />
                            </div>
                            <Divider />
                        </div>
                        <div className='email'>
                            <div className='email-msg account-info-tit' onClick={() => {
                                this.setState({
                                    isShowEmail: true
                                })
                            }}>
                                <div className='tit'>{i18n.t('account_page_text_email')}</div>
                                <div className='icon-left'>
                                    <KeyboardArrowRightIcon />
                                </div>
                            </div>

                            <div className='email-input'>
                                <div className='input-main'>
                                    <input
                                        disabled={true}
                                        className='email-insert-in'
                                        value={insertEmail}
                                    />
                                    <Button
                                        className={i18n.t('account_page_text_collect_your_email') === insertEmail ? 'del-button' : 'add-button'}
                                        onClick={() => this.untieEmail()}
                                    >
                                        {i18n.t('delivery_address_page_text_remove')}
                                    </Button>
                                </div>
                            </div>
                            <Divider />
                        </div>
                        <div className='changePassword' onClick={() => {
                            this.setState({
                                isShowMypassword: true
                            })
                        }}>
                            <div className='changePassword-msg account-info-tit'>
                                <div className='tit'>{i18n.t('account_page_text_change_password')}</div>
                                <div className='icon-left'>
                                    <KeyboardArrowRightIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='account-tit'>{i18n.t('account_page_text_My_invitation_code')}</div>
                    <div className='delivery-address boxShadow my-referral' onClick={() => {
                        // 点击复制

                    }}>
                        <span style={{ fontSize: '16px', display: 'flex', }}>
                            {this.state.customerInfo.my_recommendation_code ?? ''}
                        </span>
                        <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center', fontWeight: 600 }} >
                            {/* 打开二维码 */}
                            <IconButton onClick={() => {
                                this.setState({ isOpenReferralDialog: true })
                            }} >
                                <MdQrCode color='#9791bf' size={'30px'} />
                            </IconButton>
                            {/* 复制到剪贴板 */}
                            <IconButton onClick={() => {
                                const code = this.state.customerInfo.my_recommendation_code ?? ''
                                if (code) {
                                    navigator.clipboard.writeText(code)
                                    Notification.notification(i18n.t('sing_in_phone_page_referrals_code') + i18n.t('copied'));
                                } else {
                                    Notification.notification(i18n.t('copy_failed'));
                                }
                            }} >
                                <FileCopyOutlinedIcon style={{ color: '#9791bf' }} />
                            </IconButton>

                            <IconButton onClick={() => {
                                const code = this.state.customerInfo.my_recommendation_code ?? ''
                                if (code) {
                                    navigator.clipboard.writeText(`${window.location.origin}/#/sign-in?referrals=${code}`)
                                    Notification.notification(i18n.t('general_link') + i18n.t('copied'));
                                } else {
                                    Notification.notification(i18n.t('copy_failed'));
                                }
                            }} >
                                <LinkOutlinedIcon style={{ color: '#9791bf' }} />
                            </IconButton>

                        </div>
                    </div>
                    {/* 第三方账号 */}
                    <div className='account-tit'>{i18n.t('account_page_text_third_party_account')}</div>
                    <div className='third-party boxShadow'>
                        <div className='face-book'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='icon'></div>
                                <div className='tit'>
                                    <div className='account-name'>{thirdPartyFaceBookName}</div>
                                    {
                                        thirdPartyFaceBookName ?
                                            null
                                            : <div>{facebookRelatedStatus ? i18n.t('account_page_text_bound') : i18n.t('account_page_text_unassociated')}</div>
                                    }
                                </div>
                            </div>
                            <FacebookLogin
                                appId={FACKBOOK_APP_ID}
                                fields='name,email,picture'
                                scope='public_profile'     // 自定义权限
                                autoLoad={false}
                                disableMobileRedirect={true}
                                callback={async (response) => {
                                    // console.log('response facebook',response)
                                    try {
                                        const bindFn = async (force?: boolean) => {
                                            return await apiManage.bindFackBook({
                                                _id: this.state.customerInfo._id || '',
                                                clientToken: response.accessToken,
                                                merge_customer: force ? '1' : undefined
                                            });
                                        }
                                        const resBind: any = await bindFn();
                                        // 错误捕捉
                                        console.log(resBind, 'RES_BIND');
                                        if (_.get(resBind, 'result.statusCode') === 403 &&
                                            _.get(resBind, 'result.code') === 1007) {
                                            Notification.notification(i18n.t('account_page_text_the_current_Facebook_account_is_linked_to_another_account'));
                                            return
                                        }
                                        // 绑定的第三方账号已注册且没有手机号
                                        if (resBind?.error && _.get(resBind, 'result.code') === 1062) {
                                            this.setState({
                                                openBindDialog: {
                                                    isOpen: true,
                                                    type: 'Facebook',
                                                    confirm: async () => {
                                                        const res: any = await bindFn(true);
                                                        // 绑定成功之后显示名字
                                                        if (res.nModified > 0) {
                                                            this.setState({
                                                                thirdPartyFaceBookName: (response && response.email) || '',
                                                                facebookRelatedStatus: true
                                                            })
                                                        }
                                                    }
                                                }
                                            });
                                            return
                                        }
                                        // 绑定成功之后显示名字
                                        if (resBind.nModified > 0) {
                                            this.setState({
                                                thirdPartyFaceBookName: (response && response.email) || '',
                                                facebookRelatedStatus: true
                                            })
                                        }
                                    } catch (error) {

                                        console.log('error:', error);

                                    }
                                }}
                                render={renderProps => (
                                    <Button className='add-button'
                                        disabled={renderProps.isDisabled}
                                        onClick={() => {
                                            // 有名字，证明点击的时候是解绑账号
                                            if (thirdPartyFaceBookName || facebookRelatedStatus) {
                                                this.setState({
                                                    isRemovethirdPartyModal: true,
                                                    removeThirdPartyModalType: insertPhone ? 'Facebook' : 'warning'
                                                })
                                            } else {
                                                renderProps.onClick();
                                            }
                                        }}>{
                                            thirdPartyFaceBookName || facebookRelatedStatus ? `${i18n.t('delivery_address_page_text_remove')}` : `${i18n.t('payment_page_text_adjunction')}`
                                        }</Button>
                                )}
                            />
                        </div>
                        <div className='google'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='icon'></div>
                                <div className='tit'>
                                    <div className='account-name'>{thirdPartyGoogleName}</div>
                                    {
                                        thirdPartyGoogleName ?
                                            null
                                            : <div>{i18n.t('account_page_text_unassociated')}</div>
                                    }
                                </div>
                            </div>

                            {thirdPartyGoogleName ?
                                <Button className='add-button'
                                    onClick={async () => {
                                        // 若有账号，那就需要解绑,弹出确认框
                                        this.setState({
                                            isRemovethirdPartyModal: true,
                                            removeThirdPartyModalType: insertPhone ? 'Google' : 'warning'
                                        })
                                    }}
                                >
                                    {`${i18n.t('delivery_address_page_text_remove')}`}
                                </Button> :
                                <GoogleLoginFixed
                                    dataText='signin'
                                    style={{ height: 'auto' }}
                                    callback={async (response) => {
                                        const bindFn = async (force?: boolean) => {
                                            return await apiManage.bindGoogle({
                                                _id: this.state.customerInfo._id || '',
                                                clientToken: response.credential,
                                                merge_customer: force ? '1' : undefined
                                            });
                                        }
                                        const resBind: any = await bindFn();
                                        // 错误捕捉
                                        if (errorCatching(resBind, 'account_page_text_the_current_Google_account_is_linked_to_another_account')) return;
                                        // 绑定的第三方账号已注册且没有手机号
                                        if (resBind?.error && _.get(resBind, 'result.code') === 1062) {
                                            this.setState({
                                                openBindDialog: {
                                                    isOpen: true,
                                                    type: 'Google',
                                                    confirm: async () => {
                                                        const res: any = await bindFn(true);
                                                        // 绑定成功之后显示名字
                                                        if (res.nModified > 0) {
                                                            this.getCustomer()
                                                        }
                                                    }
                                                }
                                            });
                                            return
                                        }
                                        // 绑定成功之后显示名字
                                        if (resBind.nModified > 0) {
                                            this.getCustomer()
                                        }
                                    }}
                                />}

                            {/* <GoogleLogin
                                clientId={GOOGLE_CLIENT_ID}
                                render={renderProps => (
                                    <Button className='add-button'
                                        onClick={async () => {
                                            // 若有账号，那就需要解绑
                                            if (thirdPartyGoogleName) {
                                                // 弹出确认框props
                                                this.setState({
                                                    isRemovethirdPartyModal: true,
                                                    removeThirdPartyModalType: 'Google'
                                                })
                                            } else {
                                                renderProps.onClick()
                                            }
                                        }}
                                        disabled={renderProps.disabled}>
                                        {
                                            thirdPartyGoogleName ? `${i18n.t('delivery_address_page_text_remove')}` : `${i18n.t('payment_page_text_adjunction')}`
                                        }
                                    </Button>
                                )}
                                buttonText='Login'
                                onSuccess={async (response: any) => {
                                    try {
                                        let resBind: any = await apiManage.bindGoogle({
                                            _id: this.state.customerInfo._id || '',
                                            clientToken: response.tokenId
                                        });

                                        // 错误捕捉
                                        if (errorCatching(resBind, 'account_page_text_the_current_Google_account_is_linked_to_another_account')) return;

                                        // 绑定成功之后显示名字
                                        if (resBind.nModified > 0) {
                                            this.setState({
                                                thirdPartyGoogleName:
                                                    (response.profileObj && response.profileObj.email) || ''
                                            })
                                        }
                                    } catch (error) {

                                        console.log('error:', error);
                                    }
                                }}
                                onFailure={(response: any) => {
                                    console.log('google_error', response);
                                }}
                                cookiePolicy={'single_host_origin'}
                            /> */}
                        </div>
                    </div>
                    {/* Delivery Address */}
                    <div className='account-tit'>{i18n.t('account_page_text_delivery_address')}</div>
                    <div className='delivery-address boxShadow' onClick={() => {
                        this.setState({
                            isShowAddress: true
                        })
                    }}>
                        <div className='tit'>
                            {
                                isSelectedAddress ?
                                    <div className='address-card '>
                                        {/* 具体地址 */}
                                        <div className='flex-start-start marginTopbottom'>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <RoomIcon style={{ color: '#ffdc33' }}></RoomIcon>
                                            </div>
                                            <div style={{ flex: 8 }}>
                                                {/* 城市 */}
                                                <div className='delivery-font-style-BC textAlign'>
                                                    {`${addressModifiedInfo.country}`}
                                                </div>
                                                {/* 地址 */}
                                                <div className='delivery-font-style-6C textAlign'>
                                                    {`${addressModifiedInfo.address}`}
                                                </div>
                                            </div>
                                        </div>
                                        {/* 用户名 */}
                                        <div className='flex-center-start marginTopbottom'>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <FaceIcon style={{ color: '#989EB6' }}></FaceIcon>
                                            </div>
                                            <div className='delivery-font-style-BC textAlign' style={{ flex: 8 }}>
                                                {`${addressModifiedInfo.username}`}
                                            </div>
                                        </div>
                                        {/* 电话 */}
                                        <div className='flex-center-start marginTopbottom'>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <RingVolumeIcon style={{ color: '#989EB6' }} />
                                            </div>
                                            <div className='delivery-font-style-BC textAlign' style={{ flex: 8 }}>
                                                {`${addressModifiedInfo.tel}`}
                                            </div>
                                        </div>
                                    </div> :
                                    <div style={{ textAlign: 'left' }}>
                                        {i18n.t('account_page_text_add_delivery_address')}
                                    </div>
                            }
                        </div>
                        <div className='icon-left'>
                            <KeyboardArrowRightIcon />
                        </div>
                    </div>
                    <div className='account-tit'>{i18n.t('coupon_text')}</div>
                    <div className='delivery-address boxShadow' onClick={() => {
                        this.setState({
                            couponListVisible: true
                        })
                    }}>
                        <div className='tit'>
                            <div style={{ textAlign: 'left' }}>
                                {i18n.t('coupon_text_my_coupons')}
                            </div>
                        </div>
                        <div className='icon-left'>
                            <KeyboardArrowRightIcon />
                        </div>
                    </div>
                    {/* 删除我的账户 */}
                    <div className='account-tit'>{i18n.t('account_page_text_delete_my_account')}</div>
                    <div className='delivery-address boxShadow' onClick={() => {
                        this.setState({
                            deleteAccountVisible: true
                        })
                    }}>
                        <div className='tit'>
                            <div style={{ textAlign: 'left' }}>
                                {i18n.t('account_page_text_delete_my_account')}
                            </div>
                        </div>
                    </div>
                    {/* Fiscal Information */}
                    {/* <div className='account-tit'>Fiscal Information</div>
                    <div className='fiscal-information boxShadow' onClick={() => {
                        this.setState({
                            isFiscalInformation: true
                        })
                    }}>
                        <div className='tit'>Add delivery address</div>
                        <div className='icon-left'>
                            <KeyboardArrowRightIcon />
                        </div>
                    </div> */}
                    <div className='margin-bottom' style={{ height: 20 }}></div>
                </div>
            </div >
        )
    }

}

export default withSnackbar(MyAccount as any) as any;


// 移除第三方账号确认框
function RemoveThirdPartyModal(
    props: {
        type: 'Google' | 'Facebook' | 'warning' | '',
        customerInfo: TokenMetadata,
        isRemovethirdPartyModal: boolean;
        updateState: (isShow: any) => void;
    }
) {
    return (
        <div>
            {/* 弹窗 */}
            <Dialog
                open={props.isRemovethirdPartyModal}
                onClose={() => {
                    props.updateState({
                        isRemovethirdPartyModal: false,
                        removeThirdPartyModalType: ''
                    })
                }}
                className='remove-thirdPartyModal'
            >
                <DialogContent>
                    <div className='dialogContent'>
                        <div className='dialogName'>
                            <div className='fontStyle'>
                                {i18n.t('delivery_address_page_text_remove')}
                                <div className='close' onClick={() => {
                                    props.updateState({
                                        isRemovethirdPartyModal: false
                                    })
                                }}>
                                    <ClearIcon className='clearIcon' />
                                </div>
                            </div>

                        </div>
                        <div className='remove-third-party-main margin-bottom'>
                            <div className='description'>
                                <p>
                                    {props.type === 'warning' ?
                                        i18n.t('signin_page_text_bind_phone_number')
                                        : i18n.t('account_page_text_remove_account', { 1: props.type })
                                    }
                                </p>
                            </div>
                        </div>
                        {props.type === 'warning' ? <></> : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button className='remove-third-btn ok'
                                onClick={async () => {
                                    try {
                                        // 解绑谷歌账号
                                        if (props.type === 'Google') {
                                            const resBind: any = await apiManage.unBindGoogle({
                                                _id: props.customerInfo._id || ''
                                            })

                                            // 错误捕捉
                                            if (errorCatching(resBind, 'account_page_text_release_failed')) return;
                                            if (resBind.nModified > 0) {
                                                props.updateState({
                                                    isRemovethirdPartyModal: false,
                                                    thirdPartyGoogleName: ''
                                                })
                                            }
                                        } else if (props.type === 'Facebook') {
                                            // 解绑facebook账号
                                            const resBind: any = await apiManage.unBindFaceBook({
                                                _id: props.customerInfo._id || ''
                                            })
                                            // 错误捕捉
                                            if (errorCatching(resBind, 'account_page_text_release_failed')) return;
                                            if (resBind.nModified > 0) {
                                                props.updateState({
                                                    isRemovethirdPartyModal: false,
                                                    thirdPartyFaceBookName: '',
                                                    facebookRelatedStatus: false
                                                })
                                            }
                                        }
                                    } catch (error) {

                                        console.log('error:', error);
                                    }
                                }} >
                                {i18n.t('general_text_ok')}
                            </Button>
                            <Button className='remove-third-btn cancel'
                                onClick={() => {
                                    props.updateState({
                                        isRemovethirdPartyModal: false,
                                        removeThirdPartyModalType: ''
                                    })
                                }} >
                                {i18n.t('general_text_cancel')}
                            </Button>
                        </div>}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

/**
 * 删除账户确认弹窗
 */
function DeleteAccountDialog(
    props: {
        handleClose: () => void;
        handleOk: () => void;
        className?: string
    }
) {
    return (
        <Dialog
            open={true}
            onClose={() => {
                props.handleClose();
            }}
            className={`DeleteAccountDialog ${props.className || ''}`}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <div className='dialogContent'>
                    <div className='dialogName'>
                        <div className='fontStyle'>
                            {i18n.t('account_page_text_delete_my_account')}
                            <div className='close' onClick={() => {
                                props.handleClose();
                            }}>
                                <ClearIcon className='clearIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='textBox'>
                        <p className='text1'>
                            {i18n.t('account_page_text_this_is_a_permanent_operation')}
                        </p>
                        <p className='text2'>
                            {i18n.t('account_page_text_delete_account_Annotation')}
                        </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className='remove-third-btn ok'
                            onClick={async () => {
                                props.handleOk?.();
                            }} >
                            {i18n.t('general_text_ok')}
                        </Button>
                        <Button className='remove-third-btn cancel'
                            onClick={() => { props.handleClose(); }} >
                            {i18n.t('general_text_cancel')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
/**
 * 会员码弹窗
 */
// function MemberCodeDialog(
//     props: {
//         value: string;
//         className?: string;
//         onClose: () => void;
//         open: boolean;
//     }
// ) {
//     return (
//         <Dialog
//             open={props.open}
//             onClose={() => {
//                 props.onClose()
//             }}
//             className={`DeleteAccountDialog ${props.className || ''}`}
//             aria-labelledby='alert-dialog-title'
//             aria-describedby='alert-dialog-description'

//         >
//             <DialogContent>
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                 }}>
//                     <Barcode value={props.value} />
//                 </div>
//             </DialogContent>
//         </Dialog>
//     )
// }
// **********
// TYPE
// **********

type Props = { history: any };

type State = {
    language: string;
    insertPhone: string;
    insertPhonePrefix: string;
    insertEmail: string;
    isShowPhone: boolean;
    isShowAddress: boolean;
    thirdPartyGoogleName: string;
    thirdPartyFaceBookName: string;
    facebookRelatedStatus: boolean;
    addressModifiedInfo: {
        address: string,
        country: string,
        username: string,
        tel: string
    }
    isShowMyaccount: boolean;
    isShowMypassword: boolean;
    isShowEmail: boolean;
    isFiscalInformation: boolean;
    isRemovethirdPartyModal: boolean;
    customerInfo: Customer;
    userName: string;
    removeThirdPartyModalType: 'Google' | 'Facebook' | 'warning' | '';
    avatarLogo: any
    isSelectedAddress: boolean;
    customerId: string;
    couponPackage: Array<any>;
    couponListVisible: boolean;
    usedCouponIds: Array<string>;
    firstSetting: boolean;
    deleteAccountVisible: boolean;
    isOpenReferralDialog: boolean;
    openBindDialog: {
        isOpen: boolean,
        type?: 'Google' | 'Facebook',
        confirm?: () => void
    }
}
