import React from 'react';
import SwipeableTextMobileStepper from '../../component/restaurant/SwipeableTextMobileStepper-component';
import ReactMarkdown from 'react-markdown';
import Chip from '@material-ui/core/Chip';
import { MdShare } from 'react-icons/md';
import CouponItem from '../../component/coupon/coupon-item.component';
import Paper from '@material-ui/core/Paper';
// import { GetCuoponBox } from '../../component/coupon/get-cuopon-box.component';
import { InsertCode } from '../../component/insert-code-dialog/insert-code-dialog.component';
import ScanCode from '../../component/scan-code/scan.component';
import { Bardatatype, RestaurantPageState } from './restaurant-page';
class DefaultThemeRestaurantPage extends React.Component<Props, State> {

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************


    // *********************
    // View
    // *********************

    render() {
        const {
            tutorialSteps, description, Chipdata, allPaperbar, restaurantlogo,
            showGetCuoponBox, /* showInsertCode, */ couponPackage,
            restaurantStringId
        } = this.props.params
        const { updateState, onPaper, onInsertCodeCoupon, onScanCodeCoupon, onAddCoupon } = this.props
        return (
            <div className='pagebody'>
                <SwipeableTextMobileStepper tutorialSteps={tutorialSteps} />
                <div className={`pagebodyinfoHead ${tutorialSteps.length === 0 && 'notHead'}`} ></div>
                <div className='pagebodyinfo' >
                    <div className='imgbox' onClick={() => updateState({ isDialogVisible: true })}>
                        <img
                            className='restaurant-logo'
                            src={restaurantlogo} alt=''
                        />
                    </div>
                    <div className='introductionbox'
                        onClick={() => updateState({ isDialogVisible: true })}>
                        <ReactMarkdown className='markdown' source={description} escapeHtml={false} />
                        <div className='chipbox'>
                            {Chipdata && Chipdata.map((value, index) => {
                                return <Chip key={index} size='small' label={value}></Chip>
                            })}
                        </div>
                    </div>
                    <div className={'bookAndShareBox'}>
                        <div className={'iconbutton'} onClick={() => {
                            updateState({ isShowShareBox: true })
                        }}>
                            <MdShare className={'iconItem'} />
                        </div>
                    </div>
                </div>
                <div className='restaurantCouponPackage' id='restaurantCouponPackage'>
                    <div style={{ display: 'flex' }}>
                        {
                            Array.isArray(couponPackage) && couponPackage.map((item, index) => {
                                if (item.display_status === '0' && !item.ignore_get_enable) {
                                    return (
                                        <CouponItem
                                            key={index}
                                            couponItem={item}
                                            onClick={async () => {
                                                await onAddCoupon(item);
                                            }}
                                            type='restaurant'
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                </div>
                <div className='Paperbarground'>
                    {allPaperbar && allPaperbar.map((item, index) => {
                        return item && item.content && (item.content.length > 0 || typeof item.content === 'function')
                            ? <Paperbar
                                key={index} barid={index} bardata={item}
                                onPaper={() => {
                                    onPaper(item, index)
                                }
                                } />
                            : ''
                    })}
                    {/* {showGetCuoponBox &&
                        <div className='GetCuoponBoxBackground'
                            onClick={() => { updateState({ showGetCuoponBox: false }) }}>
                        </div>}
                    {showGetCuoponBox &&
                        <GetCuoponBox
                            className='retaurantCouponBox'
                            onhandleClose={() => { updateState({ showGetCuoponBox: false }) }}
                            onhandleInsertCode={() => {
                                updateState({ showInsertCode: true, showGetCuoponBox: false })
                            }}
                            onhandleQR={() => { updateState({ showQR: true, showGetCuoponBox: false }) }} />} */}
                    {showGetCuoponBox &&
                        <InsertCode
                            restaurantStringId={restaurantStringId}
                            handleClose={() => { updateState({ showGetCuoponBox: false }) }}
                            onConfirm={(insertCode) => {
                                onInsertCodeCoupon(insertCode)
                            }} />}

                    {this.props.params.showQR &&
                        <ScanCode
                            handleCloseScan={() => { updateState({ showQR: false }) }}
                            successfulCallback={(result) => {
                                onScanCodeCoupon(result)
                            }}
                            type='coupon'
                        />
                    }
                </div>

            </div>
        )
    }
}
export default DefaultThemeRestaurantPage

/**
 * 营业时间、地址、电话、官网、媒体网址 bar
 * @param props
 * bardata 每个bar的数据
 * barid 对应的id
 * onpaper 点击事件
 */
export function Paperbar(props: {
    bardata: Bardatatype
    barid: number
    onPaper: () => void
}
) {
    const { bardata } = props
    return (
        <Paper className='paperbox' onClick={() => props.onPaper()}>
            <div className='paperboxbetween' >
                <div className='icoButton'>
                    {bardata?.leftIcon?.()}
                </div>
                <div className='contentbox'>
                    {bardata.title && <p>{bardata.title}</p>}
                    {typeof bardata.content === 'function'
                        ? bardata.content()
                        : bardata.key === 'phone'
                            ? <a href={`tel:${bardata.content}`}>{bardata.content}</a>
                            : <div>{bardata.content}</div>}
                </div>
                <div className='icoButton iconButtonright'>
                    {bardata.rightIcon && bardata.rightIcon()}
                </div>
            </div>
        </Paper>
    )
}
// *********************
// Types
// *********************

type Props = {
    params: RestaurantPageState;
    updateState: (value: any) => void;
    onPaper: (item: Bardatatype, index: number) => void;
    onOrder: (value: string) => void;
    onInsertCodeCoupon: (value: string) => void;
    onScanCodeCoupon: (value: string) => void;
    onAddCoupon: (value: any) => Promise<void>;
}

interface State extends RestaurantPageState {

}
