import _ from 'lodash';
import React from 'react';
import { getQueryString } from '../../constants/tool-get-parms';
import { getNearestRestaurant, GoogleMapRestaurant } from '../../constants/tool-googlemap';
import apiManage from '../../request';
import './group-page.style.scss';
import TopBar from '../../component/top-bar/top-bar.component';
import { Restaurant, Schedule, SettingClosedSchedule } from '../../models';
import RoomIcon from '@material-ui/icons/Room';
import { analyzeRestaurantSchedule } from '../../component/restaurant/analyzeRestaurantSchedule.tool';
import MotorcycleOutlinedIcon from '@material-ui/icons/MotorcycleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import i18n from '../../i18n';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { updateScheduleData } from '../../constants/tool-update-schedule-data';
import positioningLogo from '../../static/images/positioning.png';
import LocationfailureLogo from '../../static/images/Locationfailure.png';
import Store from '../../rudex/store';
import ConfirmDialogComponent from '../../component/my-order/confirm-dialog.component';

@inject_unount
export default class GroupPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            // 图片地址
            loadingImgUrl: positioningLogo,
            // 获取地址失败图片
            loadingFailureImgUrl: LocationfailureLogo,
            // 当前位置
            currentPosition: false,
            // 餐馆地址，数组
            restaurants: [],
            // 倒计时
            countdownValue: 3,
            // 是否是商家网页
            merchantWebsite: false,
            // 显示附件商家
            isShowShop: false,
            // 集团stringId
            groupStringId: getQueryString('string_id', window.location.href) || '',
            // 集团餐馆
            restaurantList: [],
            // 集团餐馆logo ID
            restaurantLogoList: [],
            restaurantLogo: [],
            showDialog: false
        }
    }

    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount() {
        // this.getCurrentLocation()
    }
    // *********************
    // Service Function
    // *********************

    async init() {
        try {
            const stringId = getQueryString('string_id', window.location.href)
            const merchantWebsite = getQueryString('merchant_website', window.location.href)
            if (stringId) {
                const res = await apiManage.getGroupRestaurantAddress(stringId, { notNeedLoading: true })
                this.setState({
                    // loadingImgUrl: _.get(res, 'loding_img_url', ''),
                    restaurants: _.get(res, 'resturants', []),
                    merchantWebsite: merchantWebsite === 'true' ? true : false,
                    groupStringId: _.get(res, 'resturants[0].belonged_group', '')
                }, async () => {
                    await this.getNearestRestaurant()
                })
            }
        } catch (error) {
        }
    }

    // 获取当前位置
    async getCurrentLocation() {
        await new Promise<void>((resolve) => {
            if (navigator.geolocation) {
                // $ 在手机浏览器地址域名必须是https,才获取到定位
                navigator.geolocation.getCurrentPosition((position) => {
                    const newlat = Number(position.coords.latitude)
                    const newlng = Number(position.coords.longitude)
                    // console.log('本地坐标：', newlat, newlng);
                    this.setState({
                        currentPosition: {
                            lat: newlat,
                            lng: newlng
                        }
                    }, async () => {
                        await this.init()
                        resolve()
                    })
                }, () => {
                    console.log('获取位置失败');
                    this.setState({
                        currentPosition: false
                    }, async () => {
                        await this.init()
                        resolve()
                    })
                }, { timeout: 3000 });

            } else {
                this.setState({
                    currentPosition: false
                })
                resolve()
            }
        })

    }

    // 获取当前位置距离最近的餐馆，跳转餐馆主页
    async getNearestRestaurant() {
        const { currentPosition, restaurants } = this.state
        if (currentPosition && restaurants) {
            const { nearestRestaurantStringId } = await getNearestRestaurant(currentPosition, restaurants)
            this.jumpRoute(nearestRestaurantStringId)
        }
    }


    // 跳转到餐馆主页
    jumpRoute(stringId) {
        const { merchantWebsite } = this.state;
        window!.top!.location.href = `${window.location.origin}${window.location.pathname}#/restaurantPage?string_id=${stringId.toUpperCase()}${merchantWebsite
            ? '&merchant_website=true' : ''}`
        window.location.href = `${window.location.origin}${window.location.pathname}#/restaurantPage?string_id=${stringId.toUpperCase()}${merchantWebsite
            ? '&merchant_website=true' : ''}`
    }

    // 获取到集团下的餐馆、餐馆LOGO
    async getGroupRestaurant(groupStringId: string) {
        try {
            const res = await apiManage.getGroupRestaurant(groupStringId)
            const restaurantList = _.get(res, 'dbResults', [])
            let restaurantLogoList = []

            restaurantLogoList = restaurantList.map((item) => {
                return item._id
            })

            this.setState({
                isShowShop: true,
                restaurantList
            }, async () => {
                // 获取集团下餐馆的图片
                const historyRestaurantsLogo: any = await apiManage.get_history_restaurant_logo({ restaurantIds: restaurantLogoList }, { notNeedLoading: true })
                restaurantList.map((item) => {
                    historyRestaurantsLogo.map((iitem) => {
                        if (item._id === iitem._id) {
                            return Object.assign(item, { restaurantLogo: iitem.logo })
                        }
                        return iitem
                    })
                    return item
                })
                this.setState({
                    restaurantList
                })
            })
        } catch (error) {
        }
    }


    /**
     * @param takeawayServiceStatus 营业总开关
     */
    public getBusinessHours(serviceSchedule: Array<Schedule>, takeawayServiceStatus: boolean, settingClosedSchedule: SettingClosedSchedule[]) {
        serviceSchedule = updateScheduleData(serviceSchedule, settingClosedSchedule);
        const resultBusinessHours = analyzeRestaurantSchedule(serviceSchedule);
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} >
                <div className='text'>
                    {
                        (() => {
                            // 营业总开关打开
                            if (takeawayServiceStatus) {
                                // 全天营业
                                if (resultBusinessHours.isAlldayOpen) {
                                    return `${i18n.t('restaurant_info_page_restaurant_open')}`
                                }
                                // 不是全天营业 && 现在不营业 && 没有下一个营业时间  则属于停业
                                else if (!resultBusinessHours.isRestaurantOpen && !resultBusinessHours.isAlldayOpen &&
                                    !resultBusinessHours.nextOpenTimeText) {
                                    return `${i18n.t('restaurant_info_page_Takeaway_service_not_available')}`
                                }
                                // 有下个营业时间
                                else if (!resultBusinessHours.isRestaurantOpen
                                    && resultBusinessHours.nextOpenTimeText) {
                                    return resultBusinessHours.nextOpenTimeText
                                }
                                // 营业
                                else {
                                    return `${i18n.t('restaurant_info_page_restaurant_open')}`
                                }
                            }
                            // 营业总开关关闭
                            else {
                                return `${i18n.t('restaurant_info_page_Takeaway_service_not_available')}`
                            }
                        })()
                    }
                </div>
                <div className='startsIcon'>
                    {
                        (() => {
                            if (takeawayServiceStatus) {
                                // 不是全天营业 && 现在不营业 && 没有下一个营业时间  则属于停业
                                if (!resultBusinessHours.isRestaurantOpen && !resultBusinessHours.isAlldayOpen
                                    && !resultBusinessHours.nextOpenTimeText) {
                                    return (
                                        <div className='txs-end'>
                                            <RemoveCircleOutlineOutlinedIcon />
                                        </div>
                                    )
                                }
                                // 现在不营业 && 存在下一个营业时间
                                else if (!resultBusinessHours.isRestaurantOpen) {
                                    return (
                                        <div className='txs-end'>
                                            {
                                                resultBusinessHours.selfTakeStatus
                                                && <CardGiftcardOutlinedIcon className='endOfMeal' />
                                            }
                                            {
                                                resultBusinessHours.takeawayStatus
                                                && <MotorcycleOutlinedIcon className='endOfMeal' />
                                            }

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='txs-end'>
                                            {
                                                resultBusinessHours.selfTakeStatus
                                                && <CardGiftcardOutlinedIcon style={{ color: '#ffdc33' }} />
                                            }
                                            {
                                                resultBusinessHours.takeawayStatus
                                                && <MotorcycleOutlinedIcon style={{ color: '#ffdc33' }} />
                                            }

                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className='txs-end'>
                                        <RemoveCircleOutlineOutlinedIcon />
                                    </div>
                                )
                            }
                        })()
                    }
                </div>
            </div >
        );
    }

    // *********************
    // View
    // *********************

    render() {
        const { loadingImgUrl, countdownValue, isShowShop, groupStringId, restaurantList, showDialog } = this.state
        return (
            <div className='groupPage' style={{ background: countdownValue === 0 ? '#fff' : '#ffdc33' }}>
                <TopBar
                    isHideMenu={isShowShop ? false : true}
                    isHideGoback={isShowShop ? false : true}
                    title={'Ordelivery'}
                    isGoBack={() => { this.setState({ isShowShop: !isShowShop }) }}
                />
                {/* <div className='countdown'>{countdownValue}</div> */}
                <div className='loadingBox'>
                    <img className='loadingImg '
                        src={loadingImgUrl}
                        alt='' />

                    <div className='tryAgain'
                        onClick={() => {
                            this.setState({ showDialog: true })
                        }}>
                        <span>{i18n.t('group_page_text_enter_the_nearest_restaurant')}</span>
                    </div>
                    <div className='clickBox' onClick={() => { this.getGroupRestaurant(groupStringId) }}>
                        <span>{i18n.t('group_page_text_view_the_list_of_Group_restaurants')}</span>
                        <ArrowForwardIosIcon className='arrowIcon' />
                    </div>
                </div>
                {isShowShop ?
                    <div className='content'>
                        <div className='title'>{i18n.t('group_page_text_Please_select_your_corresponding_restaurant')}</div>
                        <div className='restaurantList'>
                            {restaurantList.length ?
                                restaurantList.map((item) => {
                                    return (
                                        <div key={item._id} className='itemCard' onClick={() => { this.jumpRoute(item.string_id) }}>
                                            <div className='restaurantInfo'>
                                                <div className='imageBox'>
                                                    <img src={item.restaurantLogo} alt='' />
                                                </div>
                                                <div className='restaurantContent'>
                                                    <div className='restaurant-list-info-tit'>
                                                        {item.nickname}
                                                    </div>
                                                    <div className='restaurant-list-info-mark'>
                                                        {
                                                            item.label && item.label.map((lableVal: string, index: number) => {
                                                                return (
                                                                    <div key={index} className='mark-remark'>
                                                                        <span className='val'>{lableVal}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className='restaurant-list-info-startsTime'>
                                                        {/* 获取营业时间 */}
                                                        {
                                                            this.getBusinessHours(
                                                                item.setting_app_takeaway_service_schedule || [],
                                                                item.setting_app_takeaway_service_status || false,
                                                                item.setting_closed_schedule || []
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='restaurantAdress'>
                                                <div className='addressIcon'>
                                                    <RoomIcon style={{ color: '#bcbcbc' }} />
                                                </div>
                                                <div className='address'>{item.google_map_formatted_address}</div>
                                            </div>
                                        </div>
                                    )
                                }) : <div className='flex-center fontStyle-size-400' style={{ marginTop: '100px' }}>{i18n.t('general_text_no_relevant_data')}</div>}
                        </div>
                    </div> : null}
                <ConfirmDialogComponent
                    visible={showDialog}
                    title={i18n.t('group_page_text_Whether_to_enable_the_location_function')}
                    cancelButtonText={i18n.t('general_text_cancel')}
                    confirmButtonText={i18n.t('general_text_ok')}
                    onClose={() => this.setState({ showDialog: false })}
                    onConfirmFn={() => {
                        this.setState({ showDialog: false });

                        Store.dispatch({
                            type: 'SET_STATE',
                            path: 'globalLoading',
                            value: true
                        })
                        this.setState({ currentPosition: { lat: 0, lng: 0 } }, async () => {
                            await this.getCurrentLocation()
                            Store.dispatch({
                                type: 'SET_STATE',
                                path: 'globalLoading',
                                value: false
                            })
                        })
                    }}
                    onCancelFn={() => { this.setState({ showDialog: false }); this.getGroupRestaurant(groupStringId); }}
                />
            </div>
        )
    }
}


// *********************
// Props & State
// *********************

type Props = {}

type State = {
    loadingImgUrl: string;
    loadingFailureImgUrl: string
    currentPosition: {
        lat: number,
        lng: number
    } | false;
    restaurants: GoogleMapRestaurant[];
    countdownValue: number;
    merchantWebsite: boolean;
    isShowShop: boolean;
    groupStringId: string
    restaurantList: Restaurant[]
    restaurantLogoList: Array<string>
    restaurantLogo: Array<{ _id: string, logo: string }>
    showDialog: boolean;
}