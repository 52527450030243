/**
 * @CE AKclown
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import it from './locales/it.json';
import cn from './locales/cn.json';
import es from './locales/es.json'
import { LOCAL_STORAGE_KEYS } from './constants/local-storage.constant';
import { LocalStorageManager } from './constants/tool-local-storage';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	ZH: { translation: cn },
	IT: { translation: it },
	EN: { translation: en },
	ES: { translation: es }
};
/**
 * 初始化浏览器语言加载
 * 1、国家代码表：
 *    意大利语(意大利)it-it   简体中文(中国)zh-cn  英语(全球)en-ww
 *    法语(法国)fr-fr  西班牙语(西班牙)es-es    德语(德国)de-de
 * 2、获取当前所在地区的语言，初始化该语言
 * 3、默认设置为英文
 * 4、对比当前已有的语言  没有默认给定EN
 */

let lang = navigator.language.substring(0, 2).toLocaleUpperCase();
const result = Object.keys(resources).filter(item => item === lang);
lang = result.length > 0 ? lang : 'EN';

LocalStorageManager.setLocalStorage({
	path: LOCAL_STORAGE_KEYS.LANGUAGE,
	value: lang
});
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'EN',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;

/**
 *  根据浏览器语言设置语言发给后台用于发送whatsapp消息的语言
 */
export function fillbackWhatsappLngSetting(localString: string): string {
	const serverLang = [
		['ZH', 'CN'],
		['IT'],
		['EN'],
		['SP', 'ES'],
	];
	return serverLang.find(item => item.includes(localString))?.[0] ?? 'EN'
}