import React from 'react';
import { ReactComponent as SoldoutIcon } from '../../static/images/sold-out.svg';
import { ReactComponent as FilterOutIcon } from '../../static/images/filter-out.svg';
import './dish-disabled-mask.scss';

/**
 * 菜品禁用蒙层
 */
export function DishDisabledMask({ className, soldOut, filterOut, onClick }:
    { className?: string, soldOut: boolean, filterOut: boolean, onClick?: () => void }
) {
    return (soldOut || filterOut)
        ? <div className={`dishDisabledMask ${className || ''}`} onClick={onClick}>
            <div className='disabledTagBox'>
                {soldOut && <div className={`MdFilterAlt-icon-box ${(filterOut && filterOut) ? 'small' : ''}`}>
                    <SoldoutIcon className='MdFilterAlt-icon' />
                </div>}
                {filterOut && <div className={`MdFilterAlt-icon-box ${(filterOut && filterOut) ? 'small' : ''}`}>
                    <FilterOutIcon className='MdFilterAlt-icon' />
                </div>}
            </div>
        </div> : <></>
}