import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import i18n from '../../../i18n';
import './order-package-dialog.style.scss';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

export function OrderPackageDialog(
    props: {
        openDialog: boolean;
        onClose: () => void;
        isConfirm: () => void;
        isCancel?: () => void;
        contentText?: string;
    }
) {
    const { openDialog, onClose, isConfirm, isCancel, contentText } = props
    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            classes={{ root: 'dialog' }}
        >
            <DialogTitle id='alert-dialog-title'>
                <div className='title'>
                    <span className='titleText'>{i18n.t('restaurant_order_page_text_whether_to_return')}</span>
                    <IconButton onClick={onClose} className='clearIcon'>
                        <ClearIcon className='closeIcon' />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent classes={{ root: 'dialogContent' }}>
                <DialogContentText id='alert-dialog-description' classes={{ root: 'dialogContentText' }}>
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: 'dialogActions' }}>
                <Button onClick={isConfirm} className='remove-third-btn ok'>
                    {i18n.t('general_text_ok')}
                </Button>
                <Button onClick={isCancel ?? onClose} className='remove-third-btn cancel'>
                    {i18n.t('general_text_cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
