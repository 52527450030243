import React from 'react';
import './recaptcha-dialog.style.scss'
import { Dialog, DialogContent } from '@material-ui/core';
import i18n from '../../i18n';
import ClearIcon from '@material-ui/icons/Clear';
import ReCaptcha from '@matt-block/react-recaptcha-v2';
import { GOOGLE_RE_CAPTCHA_V2_KEY } from '../../gconfig';

/**
 * 谷歌人机校验图片选择弹窗
 * @param className 类名
 * @param handleClose 关闭
 * @param handelReCaptchaCallBack 谷歌人机校验回调
 */
class ReCaptChaDialog extends React.Component<Props, State> {

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Service Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        const { className, handleClose, handelReCaptchaCallBack } = this.props;
        return (
            <Dialog
                open={true}
                onClose={() => {
                    handleClose()
                }}
                className={`ReCaptChaDialog ${className}`}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogContent>
                    <div className='dialogContent'>
                        <div className='dialogName'>
                            <div className='fontStyle'>
                                {i18n.t('general_text_please_verify_that_you_are_not_a_robot')}
                                <div className='close' onClick={() => {
                                    handleClose()
                                }}>
                                    <ClearIcon className='clearIcon' />
                                </div>
                            </div>
                        </div>
                        <ReCaptcha
                            siteKey={GOOGLE_RE_CAPTCHA_V2_KEY}
                            theme='light'
                            size='normal'
                            onSuccess={() => { handelReCaptchaCallBack('Success') }}
                            onExpire={() => { handelReCaptchaCallBack('Expire') }}
                            onError={() => { handelReCaptchaCallBack('Error') }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}
export default ReCaptChaDialog;

// *********************
// Props & State
// *********************

type State = {}

type Props = {
    className?: string;
    handleClose: () => void;
    handelReCaptchaCallBack: (result: 'Success' | 'Expire' | 'Error') => void;
}
