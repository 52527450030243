/**
 * @author liweikang
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
class SwitchStatusComponent extends React.Component<Props, State> {

    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
            selectIndex: 0
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        // we check if already has a account
    }

    async componentWillUnmount() {
        // $ always unsubscribe in un mount function
    }

    // *********************
    // 方法区
    // *********************

    // *********************
    // 视图区
    // *********************

    render() {
        const { statusData, onClick, t } = this.props;
        const { selectIndex } = this.state;
        return (
            <div className='switchStatus'>
                {statusData.map((status, index) => {
                    return <Button
                        className={selectIndex === index ? 'active' : ''}
                        key={index}
                        variant='contained'
                        size='small'
                        disableRipple={true}
                        onClick={(e) => {
                            if (typeof onClick === 'function' && selectIndex !== index) onClick(status.value, e)
                            this.setState({
                                selectIndex: index
                            })
                        }}
                    >{t(status.text)}</Button>
                })}
            </div>
        );
    }

}

export default withTranslation()<Props>(SwitchStatusComponent as any);

// *********************
// Types
// *********************

type State = {
    selectIndex: number;
}

type Props = {
    statusData: Array<{
        text: string;
        value: string;
    }>,
    onClick?: (value: string, event?: any) => void
} & WithTranslation
