import { CircularProgress } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { createHashHistory } from 'history';
import { withSnackbar } from 'notistack';
import * as React from 'react';
import i18n from '../i18n';
import { MessengerModule } from '../messenger/module';
import { DefaultWebsocketClient } from '../modules/websocket/module';
import apiManage from '../request';
import BasicRoute from '../router';
import Store from '../rudex/store';
import _ from 'lodash';
import { ReduxConnect } from '../component/decorators/redux-connect.decorator';
import { State as LocalState } from '../rudex/type';
import { downloadApp, openApp } from '../constants/tool-open-app';
import Notification from '../component/snackbar/snackbar';
import { OpenAppComponent } from '../component/openAppComponent';
import { getQueryString } from '../constants/tool-get-parms';
import { isMobileOnly } from 'react-device-detect';
import { CheckSharkCustomerVerifyEvent, VivaOrderUpdatedEvent } from '../modules/websocket/type';
import { ToAuthentication, checkAccountVerify } from '../constants/checkRoute';

@ReduxConnect((defaultState: LocalState) => {
    return {
        globalLoading: defaultState?.globalLoading
    };
})

class AppPage extends React.Component<Props, State> {

    public state: State = {
        fetch: {
            isFetching: false,
            lastUpdated: 0,
            error: {}
        },
        // socket提示的弹窗
        socketDialog: {
            flag: false,
            // 0不跳转  1跳转
            isJump: 1,
            message: '',
            severity: 'info'
        },
        messageBox: {
            lastUpdated: 0
        },
        initError: 0
    }
    // 取消store监听
    private unsubscribe;
    // 提示框消息
    private messengerModule: MessengerModule;

    constructor(props: any) {
        super(props);
        this.messengerModule = new MessengerModule(this.props['enqueueSnackbar']);
    }
    // ************
    // 生命周期
    // ************
    // UNSAFE_componentWillMount() {
    // TODO 通知提示 (只有pc端网页起作用)
    // try {
    // window.Notification.requestPermission().then((permission) => {
    //     if (permission === 'granted') {
    //         console.log('用户允许通知');
    //     } else if (permission === 'denied') {
    //         console.log('用户拒绝通知');
    //     }
    // });
    // this.showNotification('测试通知', 0)
    // } catch (error) {
    // }
    // }
    UNSAFE_componentWillMount() {

        // 添加一个store监听器（全局提示框）
        this.unsubscribe = Store.subscribe(() => {
            // 全局loading动画
            const newFetchState = _.get(Store.getState(), 'fetch');
            if (newFetchState && (newFetchState.lastUpdated !== this.state.fetch.lastUpdated)) {
                this.setState({
                    fetch: newFetchState
                });
            }
            // 判断是否需要显示提示框
            const newMessageBox = _.get(Store.getState(), 'messageBox');
            if (newMessageBox && (newMessageBox.lastUpdated !== this.state.messageBox.lastUpdated)) {
                this.setState({
                    messageBox: {
                        lastUpdated: newMessageBox.lastUpdated
                    }
                });
                this.messengerModule.say({
                    level: newMessageBox.level,
                    message: newMessageBox.message,
                    details: newMessageBox.details
                });
            }
        });

        // $ 初始化websocket对象
        this.addWebsocketListeners();
        apiManage.websocket_init();
        // $ 跳转APP（除了商家地址）
        const storeMerchantWebsite = _.get(Store.getState(), 'merchantWebsite');
        const merchantWebsite = getQueryString('merchant_website');
        const stringID = getQueryString('string_id');
        const isScanCodePage = createHashHistory().location.pathname === '/';

        if (!(merchantWebsite || storeMerchantWebsite) && isMobileOnly && !isScanCodePage) {
            Notification.notification(i18n.t('general_text_open_with_App'), {
                persist: false, autoHideDuration: 5000,
                action: <OpenAppComponent
                    openApp={() => { openApp(stringID); }}
                    downloadApp={() => { downloadApp(); }}
                />
            });
        }
        // 检查账号是否有审核记录
        checkAccountVerify()
    }

    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
    }

    // *********************
    // Service Function
    // *********************

    public async addWebsocketListeners() {
        // $ 初始化
        DefaultWebsocketClient.removeAllListeners();

        //  监听CONNECT_EVENT事件
        DefaultWebsocketClient.addListener('CONNECT_EVENT', (data) => {
            console.log('CONNECT_EVENT', data);

            // 设置重连错误提示次数
            this.setState({
                initError: 0
            })
        });
        // 监听DISCONNECT_EVENT事件
        DefaultWebsocketClient.addListener('DISCONNECT_EVENT', (data) => {
            console.log(data, 'DISCONNECT_EVENT');
        });



        // 监听RECONECT_ERROR事件 (重新连接错误和失败提示)
        DefaultWebsocketClient.addListener('RECONECT_ERROR', (data) => {
            console.log(data, 'RECONECT_ERROR ---');
            let initError = this.state.initError;
            initError++;
            if (initError === 5) {
                this.handleShowTip(`${i18n.t('app_page_text_server_connected_error')}`, 0);
            }
            this.setState({
                initError
            })
        });



        // 添加`OrderCreatedEvent`事件
        DefaultWebsocketClient.addListener('ORDER_CREATED_EVENT', () => {
            // 判断是不是被修改订单的用户
            Store.dispatch({
                type: 'SET_STATE',
                path: 'hasNewNotices',
                value: true
            })
        });
        // 添加`OrderUpdataEvent`事件
        DefaultWebsocketClient.addListener('ORDER_UPDATED_EVENT', (data) => {
            const type: string = data.messageType;
            const content: string = data.newValue;
            let text: string = '';
            if (type === '0') {
                // 订单状态改变
                switch (content) {
                    case '0':
                        text = i18n.t('app_page_text_your_order_is_unconfirmed')
                        break;
                    case '1':
                        text = i18n.t('app_page_text_your_order_has_been_confirmed')
                        break;
                    case '2':
                        text = i18n.t('app_page_text_your_order_has_been_locked')
                        break;
                    case '3':
                        text = i18n.t('app_page_text_your_order_has_been_cancel')
                        break;
                    case '4':
                        text = i18n.t('app_page_text_your_order_has_been_complete')
                        break;
                    default: text = i18n.t('app_page_text_your_order_is_unconfirmed');
                }
            } else if (type === '1') {
                // 配送状态改变
                switch (content) {
                    case '0':
                        text = `${i18n.t('my_notices_page_your_order')} ${i18n.t('my_notices_page_unshipped')}`
                        break;
                    case '1':
                        text = i18n.t('app_page_text_your_order_is_being_deliver')
                        break;
                    case '2':
                        text = i18n.t('app_page_text_your_order_has_been_delivered')
                        break;
                    default: text = `${i18n.t('my_notices_page_your_order')} ${i18n.t('my_notices_page_unshipped')}`;
                }
            } else if (type === '2') {
                // 订单地址改变
                text = `${i18n.t('my_notices_page_your_order')} ${i18n.t('my_notices_page_order_address_has_been_modified')}`
            } else if (type === '3') {
                // 其它状态
            }
            // 弹出通知
            if (text) {
                this.handleShowTip(text, 1)
            }
            // 判断是不是被修改订单的用户
            Store.dispatch({
                type: 'SET_STATE',
                path: 'hasNewNotices',
                value: true
            });
        });

        // 添加`BOOKING_ORDER_CREATED_EVENT`事件
        DefaultWebsocketClient.addListener('BOOKING_ORDER_CREATED_EVENT', () => {
            // console.log(data, 'BOOKING_ORDER_CREATED_EVENT');
            Store.dispatch({
                type: 'SET_STATE',
                path: 'hasNewNotices',
                value: true
            })
        })

        // 添加`BOOKING_ORDER_UPDATE_EVENT`事件
        DefaultWebsocketClient.addListener('BOOKING_ORDER_UPDATE_EVENT', (data) => {
            try {
                // console.log(data, 'BOOKING_ORDER_UPDATE_EVENT');
                /**
                 * 预定订单状态
                 * 0: 未确认
                 * 1: 已确认
                 * 2: 已履约
                 * 3: 未履约
                 * 4: 已取消
                 */
                const eventData = data.newValue || {};
                const orderStatus: string = eventData.status
                if (orderStatus) {
                    let messageInfo = ''
                    switch (orderStatus) {
                        case '0':
                            messageInfo = 'app_page_text_your_booking_has_not_been_confirmed';
                            break;
                        case '1':
                            messageInfo = 'app_page_text_your_booking_has_been_confirmed';
                            break;
                        case '2':
                            messageInfo = 'app_page_text_your_booking_has_been_fulfilled';
                            break;
                        case '3':
                            messageInfo = 'app_page_text_your_reservation_did_not_go_to_the_appointment';
                            break;
                        case '4':
                            messageInfo = 'app_page_text_your_booking_has_been_cancelled';
                            break;
                        default:
                            messageInfo = '';
                            break;
                    }
                    this.handleShowTip(i18n.t(messageInfo), 2)
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'hasNewNotices',
                        value: true
                    });
                }
            } catch (error) {
            }
        })

        // 同步消息
        DefaultWebsocketClient.addListener('CLEAR_MESSAGES_EVENT', (data) => {
            console.log('消息被阅读 ', data)
            Store.dispatch({
                type: 'SET_STATE',
                path: 'hasNewNotices',
                value: false
            })
        })

        // 系统消息
        DefaultWebsocketClient.addListener('SYSTEM_UPDATE_EVENT', (data) => {
            console.log('系统消息', data)
            Store.dispatch({
                type: 'SET_STATE',
                path: 'hasNewNotices',
                value: true
            })
        })
        // viva订单在viva的付款失败时通知
        DefaultWebsocketClient.addListener('ORDER_VIVA_PAYMENT_UPDATED_EVENT', (data: VivaOrderUpdatedEvent) => {
            if (data.value === 'PAYMENT_FAILED') {
                console.log('viva订单', data);
                Notification.notification(i18n.t('app_page_text_Your_order_Viva_payment_failed'));
            }
        })
        // 身份验证弹窗
        DefaultWebsocketClient.addListener('CHECK_SHARK_CUSTOMER_VERIFY_EVENT', (event: CheckSharkCustomerVerifyEvent) => {
            event?.data?.verify_id && ToAuthentication(event.data.verify_id)
        })
    }

    // $ 数据更新
    updateState(value) {
        this.setState({ ...value })
    }

    // 提示框显示方法
    handleShowTip(message: string, isJump: number) {
        this.setState({
            socketDialog: {
                flag: true,
                message,
                isJump,
                // error info success warning
                severity: 'info'
            }
        }, () => {
            setTimeout(() => {
                // 两秒内自动关闭
                this.setState({
                    socketDialog: {
                        flag: false,
                        message,
                        isJump,
                        // error info success warning
                        severity: 'info'
                    }
                })
            }, 2000);
        })
    }
    // TODO 只能在电脑端可使用 通知提醒
    // showNotification(message: string, isJump: number) {
    //     try {
    //         if (window.Notification.permission === 'granted') {
    //             console.log('用户允许通知');
    //             let origin = window.top.location.origin
    //             let url = ''
    //             if (isJump === 1) {
    //                 url = `${origin}/#/my-order-page`
    //             }
    //             const notification = new Notification('Ordelivery', {
    //                 body: message,
    //                 // tag:'',// 代表通知的一个识别标签，相同tag时只会打开同一个通知窗口。
    //                 // icon:'', // 要在通知中显示的图标的URL。
    //                 // image:'', // 要在通知中显示的图像的URL。
    //                 data: { url } // '想要和通知关联的任务类型的数据。',
    //                 // requireInteraction: false   // 通知保持有效不自动关闭，默认为false。
    //             })
    //             notification.onclick = () => {
    //                 if (isJump === 1) {
    //                     window.open(notification.data.url, '_blank');      // 打开网址
    //                     notification.close();                              // 并且关闭通知
    //                 }
    //             }

    //         } else if (window.Notification.permission === 'denied') {
    //             console.log('用户拒绝通知');
    //         } else {
    //             console.log('用户还没选择，去向用户申请权限吧');
    //         }
    //     } catch (error) {
    //         console.log('error:', error)
    //     }
    // }

    // ************
    // 视图
    // ************
    render() {
        const { socketDialog } = this.state;
        const { globalLoading } = this.props;
        return (
            <div className='App'  >
                <BasicRoute />
                {
                    globalLoading ?
                        <div className='globalLoading'>
                            <CircularProgress style={{ color: '#ffdc33' }} />
                        </div> : null
                }
                {/* {
                    globalLoading && <LoadingPage />
                } */}
                <SocketTip
                    openFlag={socketDialog.flag}
                    message={socketDialog.message}
                    severity={socketDialog.severity}
                    isJump={socketDialog.isJump}
                    handleCloseTip={() => {
                        this.updateState({
                            socketDialog: {
                                flag: false,
                                message: '',
                                // error info success warning
                                severity: 'info'
                            }
                        })
                    }}
                />
            </div >
        )
    }
}

export default withSnackbar(AppPage as any);



// *********************
// socket提示弹窗
// *********************

function SocketTip(
    props: {
        openFlag: boolean,
        message: string,
        isJump: number,
        severity: 'error' | 'info' | 'success' | 'warning',
        handleCloseTip: () => void
    }
) {
    const { message, severity, openFlag, isJump } = props;
    // 跳转到我的订单
    function handleMyOrder() {
        if (isJump === 1) {
            createHashHistory().push('/my-order-page');
        } else if (isJump === 2) {
            createHashHistory().push('/my-reservations-page');
        }
    }
    return (
        <div className='socketTip' onClick={handleMyOrder}>
            <Collapse in={openFlag}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label='close'
                            color='inherit'
                            size='small'
                            onClick={(evt) => {
                                evt.stopPropagation();
                                props.handleCloseTip()
                            }}
                        >
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    }
                >
                    {message}
                </Alert>
            </Collapse>
        </div>

    )
}




// *********
// TYPE
// *********

type Props = {
    globalLoading?: boolean;
    enqueueSnackbar: any
};

type State = {
    fetch: {
        isFetching: boolean,
        lastUpdated: number,
        error: any
    }
    socketDialog: {
        flag: boolean,
        isJump: number,
        message: string,
        severity: 'error' | 'info' | 'success' | 'warning'
    },
    messageBox: {
        lastUpdated: number
    },
    initError: number;
};