
/**
 * @CE AKclown
 */
export const GlobalConfig = {
    local: {
        HttpsHttpServerUrl: 'https://dev-test-qftech-yennefer-be.evophotic.com',
        WebsocketServerUrl: 'https://dev-test-qftech-yennefer-be.evophotic.com:2053/system',
        paypalMode: 'sandbox'
    },
    development: {
        HttpsHttpServerUrl: 'https://dev-test-qftech-yennefer-be.evophotic.com',
        WebsocketServerUrl: 'https://dev-test-qftech-yennefer-be.evophotic.com:2053/system',
        paypalMode: 'sandbox'
    },
    developtest: {
        HttpsHttpServerUrl: 'https://dev-test-qftech-yennefer-be.evophotic.com',
        WebsocketServerUrl: 'https://dev-test-qftech-yennefer-be.evophotic.com:2053/system',
        paypalMode: 'sandbox'
    },
    stabletest: {
        HttpsHttpServerUrl: 'https://dev-statictest-yennefer-be.evophotic.com',
        WebsocketServerUrl: 'https://dev-statictest-yennefer-be.evophotic.com:2053/system',
        paypalMode: 'live'
    },
    production: {
        HttpsHttpServerUrl: 'https://ordelivery-public-api.evophotic.com',
        WebsocketServerUrl: 'https://ordelivery-public-api.evophotic.com:2053/system',
        paypalMode: 'live'
    }
};
// 谷歌应用客户端api库
export const GOOGLE_CLIENT_LIBRARY: string = 'https://accounts.google.com/gsi/client';
// 谷歌应用id
export const GOOGLE_CLIENT_ID: string = '988233580512-dhc0cu5snf52ofqcmtjml62m6o0v0h92.apps.googleusercontent.com';
// fackbook应用id
export const FACKBOOK_APP_ID: string = '211292713528183';
// 测试用的fackbook应用
// export const FACKBOOK_APP_ID:string = '1471941569679651';
// 谷歌校验人机的值阀
export const GOOGLE_ROBOT_SCORE = 0.5;
// 谷歌校验人机v2的key
export const GOOGLE_RE_CAPTCHA_V2_KEY = '6LfocBoaAAAAAHLP9r3QRYRITy0RzlMJw7-DiA5H';
// 谷歌校验人机v3的key
export const GOOGLE_RE_CAPTCHA_V3_KEY = '6LfTcBoaAAAAAFMwOtaf0H1Jd-oqOmRVP4bAweKT';
// 谷歌地图key
export const GOOGLE_MAP_KEY = 'AIzaSyDt7RIWCC-H9-r8KR-eR39PHMaubp_Gskg';
// 谷歌地图libraries
export const GOOGLE_MAP_LIBRARIES = ['places', 'visualization', 'geometry'];
// Ordelvery APP 的 URL Scheme
export const ORDELIVERY_APP_SCHEME = 'ordelivery://';
// ios 应用商店的app id
export const APP_STORE_APP_ID = '1551915349';
// 打开ios的应用商店 需拼接APP_STORE_APP_ID
export const APP_STORE_SCHEME = 'itms-apps://itunes.apple.com/us/app/apple-store/';
// 安卓安装包名,对应app项目的配置文件 expo.android.package
export const PACKAGE_NAME = 'com.maintank.ordelivery';
// Google Play id=${PACKAGE_NAME}
export const GOOGLE_PLAY_LINK = 'http://play.google.com/store/apps/details?id=';
// 打开华为应用商店 id=${PACKAGE_NAME}
export const HUAWEI_STORE_SCHEME = 'appmarket://details?id=';
// 谷歌预定沙盒模式 rwg_token
export const GOOGLE_RESERVATIONS_SANDBOX_RWG_TOKEN = 'ADQ7psRE9YyDSVR6YpfD-fYdxoFYVKS1xeTvXdSxqF8a3bnk0W62eMEnUjoNPwjhNHG0elwBnM1awTjr9vXET8yOowCeuODjwA==';
// 官网
export const SMARTWAY_WEBSITE = 'https://smartway-it.com/contattaci/'
// viva 支付链接
export const SANDBOX_VIVA_PAYMENT_URL = 'https://demo.vivapayments.com/web/checkout?ref=';
export const RELEASE_VIVA_PAYMENT_URL = 'https://www.vivapayments.com/web/checkout?ref=';