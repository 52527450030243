import React from 'react';
import './scss/checkout-payment-button.styles.scss';
import { ICheckoutPaymentButton, ICheckoutPaymentButtonProps, ICheckoutPaymentButtonState } from '../../interfaces';
import i18n from '../../i18n';
import { AirBubbles } from '../payment/payment.componet';
import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import SendIcon from '@material-ui/icons/Send';
import { WhatsappIcon } from 'react-share';

class CheckoutPaymentButton extends React.Component<Props, State> implements ICheckoutPaymentButton {

    public state: State = {
        showErrorTips: this.props.errorTips.length !== 0
    }
    // *********************
    // Life Cycle Function
    // *********************
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!_.isEqual(this.props.errorTips, nextProps.errorTips)) {
            this.setState({
                showErrorTips: nextProps.errorTips.length !== 0
            })
        }
    }
    // *********************
    // Default Function
    // *********************
    scrollIntoView() {
        let element: Element | null = null;
        switch (this.props.errorTips[0]) {
            case 'TEMPORARY_CLOSE_TIME':
            case 'BUSINESS_TIME':
                element = document.querySelector('#fulfillmentTime');
                break;
            case 'ADDRESS':
                element = document.querySelector('#FulfillmentAddress');
                break;
            case 'PRICE':
            case 'POINTS':
            case 'UNAVAILABLE_DISH':
                element = document.querySelector('#checkoutCartBox');
                break;
            default:
                break;
        }
        if (element) {
            // 滚到对应地方
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    // *********************
    // View
    // *********************
    render() {
        const { finalOrderPrice, goToPayment, isBlockUsers, errorTips, showCartErrorText, originalTotalPoints } = this.props;
        const { showErrorTips } = this.state;
        return (
            <div className='checkoutPaymentButton  '>
                <Collapse in={showErrorTips} className='checkoutErrorTip'>
                    <Alert
                        icon={false}
                        severity='error'
                        onClose={() => { this.setState({ showErrorTips: false }) }}
                    >
                        {errorTips[0] === 'TEMPORARY_CLOSE_TIME'
                            ? i18n.t('request_failure_text_restaurant_temporarily_business')
                            : errorTips[0] === 'BUSINESS_TIME'
                                ? i18n.t('payment_page_text_selected_time_business_hours_unavailable')
                                : errorTips[0] === 'ADDRESS'
                                    ? i18n.t('payment_page_text_The_address_you_selected_is_not_in_the_delivery_range_or_the_delivery_address_is_not_selected')
                                    : errorTips[0] === 'PRICE'
                                        ? i18n.t('checkout_page_text_the_original_price_did_not_reach_the_minimum_delivery_amount', { 1: showCartErrorText.deliveryMinimumFee })
                                        : errorTips[0] === 'POINTS'
                                            ? i18n.t('order_page_text_not_enough_for_points')
                                            : errorTips[0] === 'UNAVAILABLE_DISH'
                                                ? i18n.t('order_page_text_There_are_unsold_items_in_the_cart')
                                                : ''
                        }
                    </Alert>
                </Collapse>
                {isBlockUsers ?
                    <AirBubbles
                        title={i18n.t('general_text_you_have_been_banned_by_this_restaurant')}
                        attachClass='blackListBubbles' />
                    : null
                }
                <div className='left-title'>
                    <span className='name'>{i18n.t('general_text_total')}</span>
                    <span className='price'>
                        <span>€{finalOrderPrice.toFixed(2)}</span>
                        {originalTotalPoints ? <span>{`+${originalTotalPoints} ${i18n.t('my_fidelity_page_scores')}`}</span> : null}
                    </span>
                </div>
                <div className='right'>
                    <div
                        className={`btnBox ${(errorTips.length === 0 && !isBlockUsers) ? 'click' : 'disabled'}`}
                        onClick={() => {
                            if (errorTips.length === 0) {
                                goToPayment();
                            } else {
                                this.scrollIntoView();
                                if (errorTips?.length) {
                                    this.setState({ showErrorTips: true })
                                }
                            }
                        }}
                    >
                        {
                            this.props.isSettingWhatsappOn ?
                                <WhatsappIcon
                                    size='50px'
                                    round
                                    iconFillColor='#4a456c'
                                    bgStyle={{ fill: errorTips.length === 0 && !isBlockUsers ? '#ffdc33' : '#e8e8e8' }} />
                                : <SendIcon fontSize='small' />
                        }
                        <span className='text'>{i18n.t('table_order_page_btn_send')}</span>
                    </div>
                </div>
            </div >
        )
    }
}

export default CheckoutPaymentButton

// *********************
// Props & State
// *********************

interface State extends ICheckoutPaymentButtonState {
    showErrorTips: boolean;
}

interface Props extends ICheckoutPaymentButtonProps {
    goToPayment: () => void;
    isBlockUsers: boolean;
    showCartErrorText: {
        show: boolean;
        deliveryMinimumFee: number;
    };
    isSettingWhatsappOn: boolean;
}


