import React from 'react';
import { ICheckoutOrderDetailOrderDescribeStatusNames, ICheckoutTotalPrice, ICheckoutTotalPriceProps, ICheckoutTotalPriceState } from '../../interfaces';
import { MdLocalActivity } from 'react-icons/md';
import i18n from '../../i18n';
import './scss/checkout-total-price.style.scss';

class CheckoutTotalPrice extends React.Component<Props, State> implements ICheckoutTotalPrice {

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        const { discount, selectedCouponNumber, originalDeliveryFee, discountedDeliveryFee,
            originalTotalPrice, orderDescribeStatus, originalTotalPoints } = this.props;
        return (
            <div className={'checkoutTotalPrice'}>
                {/* 小计 */}
                <div className='totalPriceItem'>
                    <span className='title'>{i18n.t('payment_page_text_subtotal')}</span>
                    <span className='content originalTotalPrice'>€{originalTotalPrice.toFixed(2)}</span>
                </div>
                {/* 积分 */}
                {originalTotalPoints ? <div className='totalPriceItem'>
                    <span className='title'>{i18n.t('my_fidelity_page_scores')}</span>
                    <span className='content originalTotalPrice'>{originalTotalPoints} {i18n.t('my_fidelity_page_scores')}</span>
                </div> : null}
                {/* 折扣 */}
                {selectedCouponNumber > 0 && <div className='totalPriceItem'>
                    <span className='title'>{i18n.t('payment_page_text_discount')}</span>
                    <span className='content'>
                        <span>-€{discount.toFixed(2)}</span>
                        <span className='couponIconBox'><MdLocalActivity className='couponIcon' /></span>
                        <span>{selectedCouponNumber}</span>
                    </span>
                </div>}
                {/* 配送费 */}
                {orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY &&
                    <div className='totalPriceItem'>
                        <span className='title'>{i18n.t('order_page_text_delivery_fee')}</span>
                        <span className=''>
                            <span>
                                {discountedDeliveryFee === originalDeliveryFee ? ` € 0` : ` €${(discountedDeliveryFee - originalDeliveryFee > 0 ? 0 : (originalDeliveryFee - discountedDeliveryFee)).toFixed(2)}`}
                            </span>
                            <span className='delLine'>
                                {
                                    (discountedDeliveryFee === 0) ? '' :
                                        ` €${(originalDeliveryFee).toFixed(2)}`
                                }
                            </span>
                        </span>
                    </div>
                }

            </div>
        )
    }
}
export default CheckoutTotalPrice

// *********************
// Props & State
// *********************

interface State extends ICheckoutTotalPriceState {

}

interface Props extends ICheckoutTotalPriceProps {
    selectedCouponNumber: number;
}