import React from 'react';
import './scss/checkout-payment-button.styles.scss';
import i18n from '../../i18n';
import { IconButton } from '@material-ui/core';
import { IconHeadBox } from './checkout-icon-head-box';
import { MdAssignment } from 'react-icons/md';
import './scss/checkout-order-tableware.styles.scss';
// import { IoIosCloseCircle } from 'react-icons/io';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';

class CheckoutOrderTableware extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
    }

    state: State = {
    }

    // *********************
    // Life Cycle Function
    // *********************

    // *********************
    // Default Function
    // *********************

    // *********************
    // View
    // *********************

    render() {
        // const { isShowCollapse } = this.state;
        const { tablewareNumber } = this.props;
        return (
            <div className='checkoutOrderTableware'>
                <IconHeadBox
                    containerStyle={{ paddingBottom: 0 }}
                    iCon={() => <MdAssignment />}
                    title={i18n.t('checkout_page_text_tableware')}
                    actionLabel={() => {
                        return <div className='priceNumber'>
                            {/* 减号控件 */}
                            <IconButton
                                className={`iconBtn ${(tablewareNumber === 1) ? 'disabledIconBtn' : ''}`}
                                disabled={(tablewareNumber === 1)}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (tablewareNumber <= 1) return;
                                    this.props.onChangeTableware(tablewareNumber - 1);
                                }}>
                                <AiFillMinusCircle
                                    className='minusBtn'
                                />
                            </IconButton>
                            <p className={'number'}>
                                {tablewareNumber ?? 0}
                            </p>
                            {/* 加号控件 */}
                            <IconButton
                                className={`iconBtn ${(tablewareNumber >= 99) ? 'disabledIconBtn' : ''}`}
                                disabled={(tablewareNumber >= 99)}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (tablewareNumber >= 99) return;
                                    this.props.onChangeTableware(tablewareNumber + 1);
                                }}>
                                <AiFillPlusCircle className='plusBtn' />
                            </IconButton>
                        </div>
                    }}
                />
            </div >
        )
    }
}

export default CheckoutOrderTableware

// *********************
// Props & State
// *********************

interface State {
}

interface Props {
    tablewareNumber: number;
    onChangeTableware: (val: number) => void;
}