import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { PropType } from '../../constants/base.constant';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { checkToken } from '../../constants/tool-check-token';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { ICheckout, ICheckoutProps, ICheckoutShowedPageNames, ICheckoutState, CheckoutDetailCartDish } from '../../interfaces';
import { Customer, CustomerProjectionFixed, SettingClosedSchedule } from '../../models';
import apiManage from '../../request';
import { errorCatching } from '../../tool/error-catching';
import CheckoutCompleteCustomerInfo from './checkout-complete-customer-info';
import CheckoutOrderDetailPage from './checkout-order-detail-page';
import CheckoutSignInCodePage from './checkout-sign-in-code-page';
import CheckoutSignInPasswordPage, { signIn_param } from './checkout-sign-in-password-page';
import CheckoutSignInPhonePage from './checkout-sign-in-phone-page';
import './scss/checkout.styles.scss';
import Notification from '../../component/snackbar/snackbar';
import { DefaultWebsocketClient } from '../../modules/websocket/module';
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';
import i18n from '../../i18n';
import { showApiMessage } from '../../messenger/messageManager';
import { getQueryString } from '../../constants/tool-get-parms';
import CheckoutThirdPartySignInPhone from './checkout-third-party-signIn-phone';
import Store from '../../rudex/store';
import { getMessages } from '../../tool/notices.tool';
import BookingBox from '../restaurant/booking-box';
import { TimeoutAtCodeDelay } from '../../page/sign-in/sign-in.page';

class Checkout extends React.Component<Props, State> implements ICheckout {

    public state: State = {
        /**
         * 当前显示的子页面
         * SIGN_IN_INPUT_PHONE_PAGE - 登入页面 - 输入手机号
         * SIGN_IN_INPUT_VCODE_PAGE - 登入页面 - 输入验证码
         * SIGN_IN_INPUT_PASSWORD_PAGE - 登入页面 - 输入密码
         * COMPLETE_CUSTOMER_INFO_PAGE - 信息补全页面
         * ORDER_DETAIL_PAGE - 订单详情页面
         * BOOKING_ORDER_PAGE - 预定订单页面
         */
        showedPage: this.props.componentType === 'BOOKING' ? ICheckoutShowedPageNames.BOOKING_ORDER_PAGE : ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE,
        /**
         * 验证码倒计时相关
         */
        // 验证码倒计时时间（秒）
        vcodeTimeMS: TimeoutAtCodeDelay,
        /**
         * 手机号码验证相关
         */
        phonePrefix: getBrowserPhoneCode(),
        phone: '',
        // 缺失
        isFirstName: false,
        isLastName: false,
        isAddress: false,
        isPassword: false,
        thirdPartyToken: '',
        thirdPartyType: undefined,
        whatsappVcodeTimeMS: TimeoutAtCodeDelay,
        referralsCode: null,
        isReferralsCode: true,
        isVisibleAccount: false
    }

    // *********************
    // Life Cycle Function
    // *********************

    componentDidMount() {
        this.init(this.props.componentType);
    }

    public async UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.componentType !== this.props.componentType) {
            this.init(nextProps.componentType);
        }
    }

    componentWillUnmount() {
        if (this.state.vcodeTimeoutObject) {
            clearInterval(this.state.vcodeTimeoutObject)
        }
    }

    // *********************
    // Default Function
    // *********************
    async init(componentType: Props['componentType']) {
        if (componentType === 'DELIVERY') {
            // 判断当前是否登入
            this.checkLogin();
        } else if (componentType === 'COMPLETE_CUSTOMER') {
            const customerInfo: Customer = await LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
            this.setState({
                showedPage: ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE,
                isFirstName: true,
                isLastName: true,
                isAddress: false,
                isReferralsCode: false,
                isVisibleAccount: !customerInfo.phone
            })
        } else if (componentType === 'BOOKING') {
            this.setState({
                showedPage: ICheckoutShowedPageNames.BOOKING_ORDER_PAGE,
                isReferralsCode: false
            })
        }
    }

    updateState(update: Partial<State>): void {
        // tslint:disable-next-line: no-any
        this.setState(update as any);
    }

    public closeCheckoutComponent() {
        //
    }

    public updateShowedPage(targetPage: PropType<ICheckoutState, 'showedPage'>) {
        if (targetPage === ICheckoutShowedPageNames.ORDER_DETAIL_PAGE) {
            if (this.state.vcodeTimeoutObject) {
                clearInterval(this.state.vcodeTimeoutObject);
            }
        }
        this.setState({
            showedPage: targetPage
        })
    }

    /**
     * 是否登录
     */
    public async checkLogin() {
        try {
            const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
            // 有token并且没有过期
            if (_.get(token, 'access_token') && (!checkToken())) {
                try {
                    if (this.props.componentType === 'BOOKING') {
                        return true;
                    }
                    const customerId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
                    const customerInfo = await apiManage.get_customer_info(_.get(customerId, '_id', ''), { notNeedLoading: true }, CustomerProjectionFixed(['pr_key_info', 'pr_basic_info']));
                    if (customerInfo._id) {
                        // 获取通知信息
                        getMessages(_.get(customerInfo, 'messages', 0));
                        // 判断信息是否缺失
                        if ((getQueryString('orderway') === '2' ? !customerInfo.address : false) || !customerInfo.first_name || !customerInfo.last_name || !customerInfo.phone) {


                            this.setState({
                                showedPage: ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE,
                                isFirstName: customerInfo.first_name ? false : true,
                                isLastName: customerInfo.last_name ? false : true,
                                isAddress: customerInfo.address ? false : true,
                                isVisibleAccount: !customerInfo.phone,
                                isReferralsCode: false
                            })
                            return false;
                        }
                        else {
                            if (this.state.vcodeTimeoutObject) {
                                clearInterval(this.state.vcodeTimeoutObject);
                            }
                            if (this.props.componentType === 'DELIVERY') {
                                this.setState({
                                    showedPage: ICheckoutShowedPageNames.ORDER_DETAIL_PAGE
                                })
                            }
                            return true;
                        }
                    }
                } catch (error) { return false; }
            } else {
                if (this.props.restaurantsNeedSignIn) {
                    this.setState({
                        showedPage: ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE
                    })
                }
                return false;
            }
        } catch (error) { return false; }
    }

    /**
     * 获取验证码
     */
    public async getVerificationCode(toWhatsapp = false) {
        let type = 'INIT_BY_PHONE';
        if (toWhatsapp) {
            type = 'INIT_BY_WHATS'
        }
        const { phonePrefix, phone } = this.state;
        const postData = {
            account_type: 'CUSTOMER',
            type,
            phone_prefix: phonePrefix,
            phone
        }
        try {
            //  请求验证码
            const RESULT_VCODE = await apiManage.post_admin_code(postData);
            if (toWhatsapp) {
                console.log(RESULT_VCODE, RESULT_VCODE?.whatsResult?.messages?.[0]?.id);
                if (RESULT_VCODE?.whatsResult?.messages?.[0]?.id || !RESULT_VCODE.error) {
                    // 提示发送成功
                    Notification.notification(i18n.t('account_page_text_send_success'));
                    // whatsapp记录验证码发送时间加90秒
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'sendWhatsappVcodeEndTimeInit',
                        value: moment().add(TimeoutAtCodeDelay, 'second').format('YYYY-MM-DD HH:mm:ss')
                    })
                    this.startWhatsappTiming();
                } else {
                    // 提示发送失败
                    showApiMessage(RESULT_VCODE, 'vcode');
                    const sendVcodeEndTimeInit = _.get(Store.getState(), 'sendWhatsappVcodeEndTimeInit');
                    // 发送验证码结束时间 - 现在时间
                    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
                    let countdownValue = moment(sendVcodeEndTimeInit).diff(currentTime, 'seconds');
                    if (countdownValue < 0) {
                        countdownValue = 0;
                    } else if (countdownValue > TimeoutAtCodeDelay) {
                        countdownValue = TimeoutAtCodeDelay;
                    }
                    this.setState({
                        whatsappVcodeTimeMS: countdownValue
                    }, () => {
                        if (countdownValue <= 0) {
                            this.checkoutClearWhatsappInterval();
                        } else {
                            this.startWhatsappTiming();
                        }
                    })
                }
            } else {
                // 请求失败
                if (RESULT_VCODE.error) {
                    // 提示获取验证码失败
                    showApiMessage(RESULT_VCODE, 'vcode');
                    const sendVcodeEndTimeInit = _.get(Store.getState(), 'sendVcodeEndTimeInit');
                    // 发送验证码结束时间 - 现在时间
                    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
                    let countdownValue = moment(sendVcodeEndTimeInit).diff(currentTime, 'seconds');
                    if (countdownValue < 0) {
                        countdownValue = 0;
                    } else if (countdownValue > TimeoutAtCodeDelay) {
                        countdownValue = TimeoutAtCodeDelay;
                    }
                    this.setState({
                        vcodeTimeMS: countdownValue
                    }, () => {
                        if (countdownValue <= 0) {
                            this.checkoutClearInterval();
                        } else {
                            this.startTiming();
                        }
                    })
                }
                else {
                    // 记录验证码发送时间加90秒
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'sendVcodeEndTimeInit',
                        value: moment().add(TimeoutAtCodeDelay, 'second').format('YYYY-MM-DD HH:mm:ss')
                    })
                    this.startTiming();
                }
            }
        } catch (error) {
            //
        }
    }
    async loginByThirdPartyApi(params: { third_party_token: string; third_party_type: 'GOOGLE' | 'FACEBOOK'; }) {
        const res_signIn: any = await apiManage.loginByThirdParty(params);
        this.loginByThirdParty(res_signIn, params);
    }
    /**
     * 判断第三方登录
     * - 第三方账号，在本应用没有账号,则会创建账号,可以没有手机号码;
     */

    loginByThirdParty(res_signIn, params) {
        if (_.get(res_signIn, 'error') && _.get(res_signIn, 'result.statusCode') === 403 && _.get(res_signIn, 'result.code') === 1051) {
            // 第三方账号，在本应用没有账号
            // this.setState({
            //     showedPage: ICheckoutShowedPageNames.THIRD_PARTY_SIGN_IN_PHONE
            // })
            // return
        } else if (_.get(res_signIn, 'error')) {
            Notification.notification(i18n.t('request_failure_text_operation_failed'));
            return
        }
        // 用户可能缺少的字段([ 'first_name', 'last_name', 'phone','phone_prefix', 'password', 'is_password_page'])
        const shortage = _.get(res_signIn, 'shortage', []);
        if (_.includes(shortage, 'phone') || _.includes(shortage, 'phone_prefix')) {
            // 在本应用有账号但是没有手机，每次都进入完善信息页
            // this.setState({
            //     showedPage: ICheckoutShowedPageNames.THIRD_PARTY_SIGN_IN_PHONE
            // })
            // return
        }

        // 保存缓存第三方token
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.THIRD_PARTY_TOKEN,
            value: {
                third_party_token: params.third_party_token,
                third_party_type: params.third_party_type
            }
        });
        // 已有账号检查缺失
        this.CheckCustomerInfoMissing(res_signIn, _.includes(shortage, 'password'));
    }

    /**
     * 获取顾客信息，检验顾客是否缺失信息
     */

    public async CheckCustomerInfoMissing(res_signIn, password?) {
        try {
            if (res_signIn.error) {
                showApiMessage(res_signIn, 'customerInit');
            } else {
                const param = {
                    access_token: _.get(res_signIn, 'accessToken'),
                    expiresInDate: moment(new Date()).add(res_signIn.expiresIn, 's').format()
                }

                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.TOKEN,
                    value: param
                });

                const customerInfo = await apiManage.get_customer_info(_.get(res_signIn, '_id', ''), undefined, CustomerProjectionFixed(['pr_key_info', 'pr_basic_info']));
                if (_.get(customerInfo, 'messages', 0) > 0) {
                    Store.dispatch({
                        type: 'SET_STATE',
                        path: 'hasNewNotices',
                        value: true
                    })
                }
                if (!customerInfo) {
                    showApiMessage(customerInfo, 'customerInit');
                    return;
                }
                // 判断之前用户阅读协议
                if (!customerInfo.protocol_status) {
                    await apiManage.patch_customer_info({ protocol_status: true }, _.get(res_signIn, '_id', ''));
                }
                // // 获取用户头像图片地址
                const getAvatar = await apiManage.get_staff_id_avatar(res_signIn._id) || ''
                // 错误捕捉
                if (errorCatching(getAvatar, 'account_page_text_request_avatar_failed')) return;
                _.set(customerInfo, 'avatar', getAvatar)
                const customer: Customer = {
                    _id: customerInfo._id,
                    nickname: customerInfo.nickname,
                    phone: customerInfo.phone,
                    phone_prefix: customerInfo.phone_prefix,
                    email: customerInfo.email,
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    avatar: _.get(customerInfo, 'avatar'),
                    // 是否是测试账号
                    is_virtual: customerInfo.is_virtual
                }
                // 存储账号信息
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.ACCOUNT,
                    value: customer
                });
                // 断开连接socket.io
                DefaultWebsocketClient.disconnect();
                // 添加socket.io连接
                apiManage.websocket_init();
                // 判断信息是否缺失
                if (((this.props.componentType === 'DELIVERY' && getQueryString('orderway') === '2') ? !customerInfo.address : false)
                    || !customerInfo.first_name || !customerInfo.last_name || !customerInfo.phone) {
                    // 保存账号信息
                    const isReferralsCode = !(customerInfo.first_name || customerInfo.last_name || customerInfo.address);

                    if (isReferralsCode) {
                        Store.dispatch({
                            type: 'SET_STATE',
                            path: 'checkLoginInfo',
                            value: {
                                first_name: res_signIn.first_name,
                                last_name: res_signIn.last_name
                            }
                        });
                    }
                    this.setState({
                        showedPage: ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE,
                        isFirstName: customerInfo.first_name ? false : true,
                        isLastName: customerInfo.last_name ? false : true,
                        isAddress: customerInfo.address ? false : true,
                        isPassword: password ? true : (_.indexOf(_.get(res_signIn, 'shortage', []), 'is_password_page') !== -1),
                        isVisibleAccount: !customerInfo.phone
                    })
                } else {
                    if (this.state.vcodeTimeoutObject) {
                        clearInterval(this.state.vcodeTimeoutObject)
                    }

                    // 如果是绑定的账号，解绑后，重新用旧的手机号和旧的第三方登录，信息没有缺失，重新调用绑定的方法
                    if (this.state.thirdPartyType !== undefined && this.state.thirdPartyToken) {
                        const params = {
                            third_party: {
                                type: this.state.thirdPartyType?.toLocaleUpperCase(),
                                token: this.state.thirdPartyToken
                            }
                        }
                        await apiManage.patch_customer_info(params, customerInfo._id || '');
                    }
                    if (this.props.componentType === 'BOOKING') {
                        // 更新餐馆主页
                        this.props.updateOrderPageState();
                        this.setState({
                            showedPage: ICheckoutShowedPageNames.BOOKING_ORDER_PAGE
                        })
                    } else {
                        this.setState({
                            showedPage: ICheckoutShowedPageNames.ORDER_DETAIL_PAGE
                        })
                    }
                }
            }
        } catch (error) {
            //
        }
    }

    /**
     * 登录接口
     */

    public async signIn(params: signIn_param) {
        try {
            if (params.type === 'password') {
                delete params.type
            }
            /**
             * 获取账号信息和token
             */
            const res_signIn = await apiManage.post_customer_init(params);

            // 判断第三方账号登入
            if (params.third_party_token && params.third_party_type) {
                this.loginByThirdParty(res_signIn, params)
            } else {

                // 手机登入
                const shortage = _.get(res_signIn, 'shortage', []);
                this.CheckCustomerInfoMissing(res_signIn, _.includes(shortage, 'password'));
            }
        } catch (error) {
            //
        }
    }

    /**
     * 开启倒计时
     * @param countdownValue 倒计时秒钟
     */
    public startTiming() {
        this.checkoutClearInterval();
        this.setState({
            vcodeTimeoutObject: setInterval(
                () => {
                    this.setState({
                        vcodeTimeMS: this.state.vcodeTimeMS - 1
                    })
                    if (this.state.vcodeTimeMS <= 0) {
                        this.checkoutClearInterval();
                    }
                },
                1000
            ),
            vcodeTimeMS: this.state.vcodeTimeMS - 1
        });
    }

    /**
     * 开启whatsapp倒计时
     */
    public startWhatsappTiming() {
        this.checkoutClearWhatsappInterval();
        this.setState({
            whatsappVcodeTimeoutObject: setInterval(
                () => {
                    this.setState({
                        whatsappVcodeTimeMS: this.state.whatsappVcodeTimeMS - 1
                    })
                    if (this.state.whatsappVcodeTimeMS <= 0) {
                        this.checkoutClearWhatsappInterval();
                    }
                },
                1000
            ),
            whatsappVcodeTimeMS: this.state.whatsappVcodeTimeMS - 1
        });
    }
    /**
     * 清定时器
     */
    public checkoutClearInterval() {
        if (this.state.vcodeTimeoutObject) {
            clearInterval(this.state.vcodeTimeoutObject)
        }
        this.setState({ vcodeTimeMS: TimeoutAtCodeDelay });
    }

    /**
     * 清除whatsapp定时器
     */
    public checkoutClearWhatsappInterval() {
        if (this.state.whatsappVcodeTimeoutObject) {
            clearInterval(this.state.whatsappVcodeTimeoutObject)
        }
        this.setState({ whatsappVcodeTimeMS: TimeoutAtCodeDelay });
    }
    // *********************
    // View
    // *********************

    render() {
        const { showedPage, phonePrefix, phone, vcodeTimeMS, isFirstName, isLastName, isAddress } = this.state;
        const {
            visible, closeCheckoutComponent, updateOrderPageState, bookingServiceSchedule, bookingNoServiceSchedule,
            componentType, drawerMainClassName, drawerMainBGClassName, isBlacklistedUser, settingMaxBookingDay, restaurantsTimeZoneName,
            editSetMeal, showProvidedTip, restaurantsNeedSignIn
        } = this.props;
        const showedPageView: { [key in ICheckoutShowedPageNames]: JSX.Element } = {
            [ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE]:
                <CheckoutSignInPhonePage
                    phonePrefix={phonePrefix}
                    phone={phone}
                    closeCheckoutComponent={() => componentType === 'BOOKING' ? this.updateShowedPage(ICheckoutShowedPageNames.BOOKING_ORDER_PAGE) : closeCheckoutComponent()}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={(state: Pick<State, 'phone' | 'phonePrefix'>) => this.updateState(state)}
                    sendPhoneGetVerificationCode={async (toWhatsapp?: boolean) => {
                        await this.getVerificationCode(toWhatsapp)
                    }}
                    checkoutClearInterval={() => { this.checkoutClearInterval(); }}
                    isShowBack={componentType === 'BOOKING'}
                    jumpToPerfectInfo={(type, token) => {
                        this.setState({
                            thirdPartyToken: token,
                            thirdPartyType: type
                        }, async () => {
                            const params = { third_party_token: token, third_party_type: type! };
                            this.loginByThirdPartyApi(params)
                        })
                    }}
                    signInPhoneWithPassword={async (param: signIn_param) => { await this.signIn(param) }}
                />,
            [ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE]:
                <CheckoutSignInCodePage
                    phonePrefix={phonePrefix}
                    phone={phone}
                    vcodeTimeMS={vcodeTimeMS}
                    whatsappVcodeTimeMS={this.state.whatsappVcodeTimeMS}
                    closeCheckoutComponent={() => closeCheckoutComponent()}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={() => {
                        //
                    }}
                    sendPhoneGetVerificationCode={(toWhatsapp?: boolean) => {
                        if (toWhatsapp) {
                            this.startWhatsappTiming();
                        }
                        this.getVerificationCode(toWhatsapp)
                    }}
                    signIn={async (param: signIn_param) => {
                        const isWhatsappLoginByCodeReviced = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE)
                        await this.signIn({ ...param, type: isWhatsappLoginByCodeReviced && isWhatsappLoginByCodeReviced === 'WHATSAPP' ? 'INIT_BY_WHATS' : 'INIT_BY_PHONE' })
                    }}
                    jumpToPerfectInfo={(type, token) => {
                        this.setState({
                            thirdPartyToken: token,
                            thirdPartyType: type
                        }, async () => {
                            const params = { third_party_token: token, third_party_type: type };
                            this.signIn(params)
                        })
                    }}
                />,
            [ICheckoutShowedPageNames.SIGN_IN_INPUT_PASSWORD_PAGE]:
                <CheckoutSignInPasswordPage
                    phonePrefix={phonePrefix}
                    phone={phone}
                    closeCheckoutComponent={() => closeCheckoutComponent()}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={() => {
                        //
                    }}
                    signIn={(param: signIn_param) => {
                        this.signIn(param)
                    }}
                />,
            [ICheckoutShowedPageNames.COMPLETE_CUSTOMER_INFO_PAGE]:
                <CheckoutCompleteCustomerInfo
                    // 显示姓名补全
                    isVisibleCustomerName={isFirstName && isLastName}
                    // 显示地址补全
                    isVisibleAddress={isAddress}
                    isVisiblePassword={this.state.isPassword}
                    isOrderway={componentType === 'DELIVERY' ? getQueryString('orderway') === '2' : false}
                    closeCheckoutComponent={() => closeCheckoutComponent()}
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    updateState={(obj: any) => {
                        this.updateState(obj)
                    }}
                    thirdPartyToken={this.state.thirdPartyToken}
                    thirdPartyType={this.state.thirdPartyType}
                    componentType={componentType}
                    updateOrderPageState={() => { updateOrderPageState() }}
                    referralsCode={this.state.referralsCode}
                    isReferralsCode={this.state.isReferralsCode}
                    isVisibleAccount={this.state.isVisibleAccount}
                />,
            [ICheckoutShowedPageNames.ORDER_DETAIL_PAGE]:
                <CheckoutOrderDetailPage
                    closeCheckoutComponent={() => { closeCheckoutComponent() }}
                    updateShowedPage={() => {
                    }}
                    visible={visible}
                    updateState={() => {
                        //
                    }}
                    updateOrderPageState={(action) => { updateOrderPageState(action) }}
                    editSetMeal={(CheckoutDetailCartDish) => { editSetMeal?.(CheckoutDetailCartDish) }}
                    handleClearCartModal={(b) => { this.props.handleClearCartModal && this.props.handleClearCartModal(b) }}
                />,
            [ICheckoutShowedPageNames.THIRD_PARTY_SIGN_IN_PHONE]:
                <CheckoutThirdPartySignInPhone
                    checkoutClearInterval={() => {
                        //
                    }}
                    updateState={(state: any) => this.updateState(state)}
                    // 发送验证码
                    sendPhoneGetVerificationCode={() => { this.getVerificationCode() }}
                    // 跳转页面
                    updateShowedPage={(targetPage) => this.updateShowedPage(targetPage)}
                    // 第三方手机号密码
                    phone={phone}
                    phonePrefix={phonePrefix}
                    vcodeTimeMS={vcodeTimeMS}
                    signIn={(param: signIn_param) => {
                        // 跳转信息补全页
                        this.signIn(param)
                    }}
                />,
            [ICheckoutShowedPageNames.BOOKING_ORDER_PAGE]:
                <BookingBox
                    onHandleClose={() => { closeCheckoutComponent() }}
                    bookingServiceSchedule={bookingServiceSchedule || []}
                    bookingNoServiceSchedule={bookingNoServiceSchedule || []}
                    isBlacklistedUser={isBlacklistedUser || false}
                    checkLogin={async () => await this.checkLogin()}
                    updateState={(state: any) => this.updateState(state)}
                    settingMaxBookingDay={settingMaxBookingDay}
                    restaurantsTimeZoneName={restaurantsTimeZoneName || ''}
                    restaurantsNeedSignIn={restaurantsNeedSignIn}
                    showProvidedTip={showProvidedTip}
                    visible={visible}
                />
        };

        return (
            <div
                className={`checkoutBackground ${visible ? 'checkoutBackgroundVisible' : 'checkoutBackgroundHidden'} ${drawerMainBGClassName}`}
                onClick={(e) => {
                    e.stopPropagation();
                    closeCheckoutComponent();
                }}
            >
                <div
                    className={`checkoutDrawerMain ${visible ? 'checkoutDrawerMainVisible' : 'checkoutDrawerMainHidden'} ${drawerMainClassName || ''}`}
                    onClick={(e) => { e.stopPropagation(); }}>
                    {/* 子页面 */}
                    {
                        showedPageView[showedPage]
                    }
                </div >
            </div>
        )
    }
}
export default Checkout

// *********************
// Props & State
// *********************
interface State extends ICheckoutState {
    isFirstName: boolean
    isLastName: boolean;
    isAddress: boolean;
    isPassword: boolean;
    // 第三方的token
    thirdPartyToken: string;
    // 第三方类型
    thirdPartyType: 'GOOGLE' | 'FACEBOOK' | undefined;
    isVisibleAccount: boolean;
}

interface Props extends ICheckoutProps {
    updateOrderPageState: (action?: { fulfillmentTime: Date }) => void;
    // 预定服务营业时间
    bookingServiceSchedule?: SettingClosedSchedule[]
    // 预定服务休业时间
    bookingNoServiceSchedule?: SettingClosedSchedule[]
    // 最大可预定时间（天数）
    settingMaxBookingDay?: number;
    // 样式
    drawerMainClassName?: string;
    // 背景样式
    drawerMainBGClassName?: string;
    // 是否是屏蔽用户
    isBlacklistedUser?: boolean;
    // 餐厅时区
    restaurantsTimeZoneName?: string;
    // 编辑套餐、包餐
    editSetMeal?: (CheckoutDetailCartDish: CheckoutDetailCartDish) => void;
    // 预定显示 showProvidedTip
    showProvidedTip?: boolean;
    // 控制清空购物车弹窗
    handleClearCartModal?: (state: boolean) => void;
    // 免登录
    restaurantsNeedSignIn?: boolean;
}