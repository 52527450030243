/**
 * @CE AKclown
 */
import './sidebar-component.style.scss';

import { IconButton, Badge } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SettingsIcon from '@material-ui/icons/Settings';
import { createHashHistory } from 'history';
import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import i18n from '../../i18n';
import Store from '../../rudex/store';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import _ from 'lodash';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { cleanAccountCache } from '../../constants/tool-check-token';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { MdBook } from 'react-icons/md';
import { DefaultWebsocketClient } from '../../modules/websocket/module';


// 侧边栏组件
export function SidebarComponet(
    props: {
        onClose?: () => void;
        hasNewNotices?: boolean;
    }
) {
    // 获取到登陆信息
    const cacheAccount = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
    const cacheToken = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN) || {};
    // 登陆状态
    let isLogin = false;
    let user;
    const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
    if (cacheAccount) {
        if (_.get(cacheToken, 'access_token') && _.get(cacheAccount, '_id')) {
            isLogin = true;
            const hasUserName = cacheAccount.first_name || cacheAccount.last_name;
            user = {
                userName: hasUserName ? `${cacheAccount.first_name || ''} ${cacheAccount.last_name || ''}` : '',
                email: cacheAccount.email,
                avatar: cacheAccount.avatar,
                is_virtual: cacheAccount.is_virtual
            }
        }
    }
    const history = createHashHistory();
    // 是否有新消息
    const [isLoginFlag, updateIsLoginFlag] = React.useState(isLogin);
    const [userInfo] = React.useState(user);
    // 退出登陆
    function handleLogout() {
        // 当前已登入 点击登出
        if (isLoginFlag) {
            // 餐馆主页清掉 屏蔽样式
            DefaultWebsocketClient.emit('BLOCKED_USERS_RELIEVE_EVENT', {
                type: 'BLOCKED_USERS_CLEAN_EVENT'
            })
            // 退出账号
            DefaultWebsocketClient.emit('LOGOUT_EVENT');
            // 清除缓存
            cleanAccountCache();
            updateIsLoginFlag(false);
            Store.dispatch({
                type: 'SET_STATE',
                path: 'hasNewNotices',
                value: false
            })
            // 是否是商家网站
            const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
            const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
            const isRestaurantHref = history.location.pathname;
            if (merchantWebsite) {
                history.push(`/restaurantPage?string_id=${currentResStringID}${merchantWebsite
                    ? '&merchant_website=true' : ''}`)
            } else {
                // 餐馆相关页面登出 页面回到餐馆主页
                if (isRestaurantHref.includes('/restaurantPage') || isRestaurantHref.includes('/orderpage') || isRestaurantHref.includes('/paymentMethodPage')) {
                    history.push(`/restaurantPage?string_id=${currentResStringID}`);
                } else {
                    history.push('/sign-in');
                }
            }
            props.onClose?.()
        }
        // 当前已登出 点击登入
        else {
            history.push('/sign-in');
        }

    }
    // 跳转到我的账户
    function handleToMyAccount() {
        if (isLoginFlag) {
            history.push('/my-account');
        }
    }
    // 跳转到我的订单
    function handleToMyOrders() {
        if (isLoginFlag) {
            history.push('/my-order-page');
        }
    }
    // 跳转到我的预订
    function handleToMyReservations() {
        if (isLoginFlag) {
            history.push('/my-reservations-page');
        }
    }
    // 跳转到我的忠诚|我的优惠
    function handleToMyFidelity() {
        if (isLoginFlag) {
            history.push('/my-fidelity-page');
        }
    }
    // 跳转到我的设置
    function handleToSettings() {
        history.push('/settingsPage');
    }
    // 跳转到扫码页
    function handleToscanCodePage() {
        if (isLoginFlag) {
            history.push('/');
        }
    }
    // 跳转我的通知
    function handleToMyNotices() {
        if (isLoginFlag) {
            history.push('/my-notices-page')
        }
    }
    return (
        <div className='sidebarContainer'>
            <div className='sidebarContent'>
                {
                    isLoginFlag && userInfo ? (
                        <header className={isLoginFlag ? 'loginHeader' : ''}>
                            <div className='imgContainer'>
                                <img src={userInfo.avatar ? userInfo.avatar : ''} alt='' />
                            </div>
                            <p className='userName'>{userInfo.userName || i18n.t('sidebar_page_text_not_set')}</p>
                            {userInfo.is_virtual ? <p className='testText'>
                                {i18n.t('sidebar_page_text_test')}
                            </p> : null}
                            {
                                isLoginFlag ? <p className='email'>
                                    {userInfo.email ? userInfo.email : ''}
                                </p> : null
                            }
                        </header>
                    ) : (
                        <header>
                            <div className='isNoLogin'>
                                <AccountCircleIcon className='accountIcon' />
                            </div>
                            <p className='userName'>{i18n.t('sidebar_page_text_anonymous')}</p>
                        </header>
                    )
                }

                <div className='content'>
                    <ul>
                        <li onClick={handleToMyAccount}>
                            <IconButton className='IconButtonContainer'>
                                <AccountBoxIcon className='icoImg'
                                    style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }} />
                            </IconButton>
                            <span style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }}>
                                {i18n.t('sidebar_page_text_my_account')}
                            </span></li>
                        <li onClick={handleToMyOrders}>
                            <IconButton className='IconButtonContainer'>
                                <BookmarksIcon className='icoImg'
                                    style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }} />
                            </IconButton>
                            <span style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }}>
                                {i18n.t('sidebar_page_text_my_order')}
                            </span></li>
                        <li onClick={handleToMyReservations}>
                            <IconButton className='IconButtonContainer'>
                                <MdBook className='icoImg'
                                    style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }} />
                            </IconButton>
                            <span style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }}>
                                {i18n.t('my_reservations_page_text')}
                            </span></li>
                        {!merchantWebsite &&
                            <li onClick={handleToscanCodePage}>
                                <IconButton className='IconButtonContainer'>
                                    <FavoriteIcon className='icoImg'
                                        style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }} />
                                </IconButton>
                                <span style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }}>
                                    {i18n.t('sidebar_page_my_favorite_restaurant')}
                                </span></li>}
                        <li onClick={handleToMyFidelity}>
                            <IconButton className='IconButtonContainer'>
                                <EmojiEmotionsOutlinedIcon className='icoImg' style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }} />
                            </IconButton>
                            <span style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }}>
                                {i18n.t('my_fidelity_page')}
                            </span>
                        </li>
                        {/* 消息中心 */}
                        <li onClick={handleToMyNotices}>
                            <IconButton className='IconButtonContainer'>
                                <ChatBubbleIcon className='icoImg' style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }} />
                            </IconButton>
                            <Badge variant='dot' color={props.hasNewNotices ? 'error' : 'default'}>
                                <span style={{ color: (isLoginFlag ? '#000' : '#7F7F7F') }}>

                                    {i18n.t('my_notices_page')}
                                </span>
                            </Badge>
                        </li>

                        <li onClick={handleToSettings}>
                            <IconButton className='IconButtonContainer'>
                                <SettingsIcon className='icoImg' />
                            </IconButton>
                            <span>
                                {i18n.t('setting_page')}
                            </span></li>
                    </ul>
                </div>
                <footer onClick={handleLogout}>
                    {isLoginFlag ? `${i18n.t('sidebar_page_text_logout')}` : `${i18n.t('signin_page_text_log_in_Register')}`}
                </footer>
            </div>
        </div>
    )
}