
import React from 'react';
import './business-box.style.scss';

export function BusinessBox(
    props: {
        className?: string;
        icon: () => JSX.Element;
        title: string;
        onClick: () => void;
    }
) {
    const { className, title, icon, onClick } = props
    return (
        <div className={`businessBox ${className || ''}`}
            onClick={( ) => {
                onClick()
            }}>
            <div className='iconBox'>{icon()}</div>
            <div className='title'>{title}</div>
        </div>
    )
}
