import React from 'react';
import {
    makeStyles, FormControl
} from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import '../static/css/sign-in.scss';
import { IS_PHONE } from '../constants/base.constant';
import CountrySelect from './country-select.component';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../static/country-style/country-select.scss';
import i18n from '../i18n';

/**
 * 用户账号输入框
 */
export default function AccountInput(props: {
    className: any;
    defaultValue: string;
    onChange?: (val: string) => void;
    phone_prefix?: (val: string) => void;
    phonePreFixValue?: string
    inputType?: 'phone' | 'password'
}) {
    const [isPhone, setIsPhone] = React.useState(true);
    const { inputType } = props;
    return (
        <div
            className={props.className}
        >
            {/* <Selete
                defaultValue={language}
                options={searchItem}
                handleChange={(val: CountyVaule) => {
                    setLanguage(val.value);
                    if (typeof props.phone_prefix === 'function') {
                        props.phone_prefix(val.value);
                    }

                }}
            /> */}
            <ReactPhoneInput
                enableSearch={false}
                disableSearchIcon={true}
                searchPlaceholder={i18n.t('general_text_search')}
                searchNotFound={''}
                // preferredCountries={['it', 'es', 'lu']}
                onlyCountries={['it', 'cn', 'es', 'lu']}
                onChange={phone => props?.phone_prefix?.(phone)}
                // localization={language === 'ZH' ? cn : language === 'IT' ? it : language === 'ES' ? es : undefined}
                // localization={cn}
                containerClass='countrySelectBox'
                searchClass='search-pre'
                dropdownClass='drop-pre'
                buttonClass='button-pre'
                inputStyle={{ display: 'none' }}
                value={props.phonePreFixValue}
            />
            <input
                type={inputType === 'phone' ? 'tel' : ''}
                pattern={inputType && inputType === 'phone' ? '[0-9]' : undefined}
                className='signin-password-input'
                value={props.defaultValue}
                onChange={(val: any) => {
                    if (inputType === 'phone') {
                        const EXP = /^[0-9]*$/;
                        if (EXP.test(val.target.value)) {
                            if (typeof props.onChange === 'function') props.onChange(val.target.value);
                            setIsPhone(IS_PHONE(props.phonePreFixValue || '', val.target.value))
                        }
                    } else {
                        if (typeof props.onChange === 'function') props.onChange(val.target.value);
                        setIsPhone(IS_PHONE(props.phonePreFixValue || '', val.target.value))
                    }
                }}
            />
            {/* <FilledInput
                disableUnderline={true}
                value={props.defaultValue}
                className={useStyle().root}
                onChange={(val) => {
                    // tslint:disable-next-line: no-unused-expression
                    typeof props.onChange === 'function'
                        && props.onChange(val.target.value);
                        setIsPhone(IS_PHONE(props.phonePreFixValue,val.target.value))
                }}
            /> */}
            {/* <CheckCircle
                style={{
                    color: 'green', fontSize: 18, width: '10%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            /> */}

            {
                props.defaultValue.length > 0 ? (
                    isPhone ?
                        <CheckCircle
                            style={{
                                color: 'green', width: '20%'
                            }}
                            className='signin-label'
                        /> :
                        <Cancel style={{ color: 'red', width: '20%' }} className='signin-label' />) :
                    <Cancel style={{ color: '#FFF', fontSize: 18, width: '20%' }} />
            }
        </div>
    )
}


export function BaseInput(props: {
    className: any;
    defaultValue: string;
    onChange?: (val: string) => void;
    placeholderText?: string;
    isNotText?: boolean;
}) {
    // const [isName, setIsName] = React.useState(false);
    return (
        <div
            className={props.className}
        >
            <input
                className={`signin-password-input ${props.isNotText ? 'isNotText' : ''}`}
                placeholder={props.placeholderText}
                value={props.defaultValue}
                onChange={(val: any) => {
                    if (typeof props.onChange === 'function') props.onChange(val.target.value);
                    // setIsName(val.target.value)
                }}
            />
            {
                props.defaultValue.trim().length > 0 ?
                    <CheckCircle
                        style={{
                            color: 'green', width: '15%'
                        }}
                        className='signin-label'
                    /> :
                    <Cancel style={{ color: '#FFF', fontSize: 18, width: '15%' }} />
            }

        </div>
    )
}

/**
 * 下拉选择框选项样式
 */
// const BootstrapInput = withStyles((theme: Theme) =>
//     createStyles({
//         root: {
//         },
//         input: {
//             width: '100%',
//             borderRadius: 88,
//             position: 'relative',
//             backgroundColor: '#fff',
//             border: '1px solid #e7ecf4',
//             fontSize: 16,
//             color: '#9791BF',
//             padding: '10px 26px 10px 12px',
//             transition: theme.transitions.create(['border-color', 'box-shadow']),
//             // Use the system font instead of the default Roboto font.
//             fontFamily: [
//                 '-apple-system',
//                 'BlinkMacSystemFont',
//                 '"Segoe UI"',
//                 'Roboto',
//                 '"Helvetica Neue"',
//                 'Arial',
//                 'sans-serif',
//                 '"Apple Color Emoji"',
//                 '"Segoe UI Emoji"',
//                 '"Segoe UI Symbol"'
//             ].join(','),
//             '&:focus': {
//                 borderRadius: 88,
//                 borderColor: '#e7ecf4',
//                 backgroundColor: '#fff'
//             }
//         }
//     })
// )(InputBase);
// 下拉选项
export declare type Option = {
    // 组名
    label: string;
}
export function Selete(props: {
    defaultValue: string | number;
    options: Array<any>;
    handleChange?: (val: CountyVaule) => void;
    disabled?: boolean;
}) {
    const useStyle = makeStyles({
        root: {
            '& .MuiSelect-root': {
                border: 'none',
                backgroundColor: '#FFF',
                width: '100%'
            },
            '& .MuiInputBase-root': {
                border: 'none'
            }

        },
        margin: {
            width: '25%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: '#000'
        }
    });
    return (
        <FormControl className={useStyle().margin}>
            {/* <Select
                value={props.defaultValue}
                className={useStyle().root}
                input={<BootstrapInput />}
                onChange={(val: any) => {
                    if (typeof props.handleChange === 'function')
                        props.handleChange(val.target.value);
                    } }
            >
                {
                props.options.map((option:Option, index) => (
                    <MenuItem
                    key={index}
                    value={option.label}>
                    {option.label}
                    </MenuItem>
                ))
                }

            </Select> */}
            <CountrySelect
                key={props.defaultValue}
                defaultValue={props.defaultValue || ''}
                onChange={(val: any) => {
                    if (typeof props.handleChange === 'function')
                        props.handleChange(val);
                }}
                disabled={props.disabled}
            ></CountrySelect>
        </FormControl>
    )
}

// **********
// TYPE
// **********

type CountyVaule = {
    label: string;
    dial_code: string;
    value: string;
}