import { APP_STORE_APP_ID, APP_STORE_SCHEME, ORDELIVERY_APP_SCHEME, PACKAGE_NAME, GOOGLE_PLAY_LINK, HUAWEI_STORE_SCHEME } from '../gconfig';
import { isMobileOnly } from 'react-device-detect';

/**
 * 打开ordelivery APP
 * - 设备有安装应用则打开APP
 * - 设备无安装应用则跳转对应的应用商店
 */
export function openApp(stringID?: string, referrals?: string): void {
    try {
        if (isMobileOnly) {
            // $ 打开APP
            window.location.href = `${ORDELIVERY_APP_SCHEME}restaurant?string_id=${stringID}&referrals=${referrals}`;
        }
    } catch (error) { }
}

export function downloadApp(): void {
    try {
        if (isMobileOnly) {
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                // $ 打开ios的应用商店
                window.location.href = `${APP_STORE_SCHEME}${APP_STORE_APP_ID}`;
            } else {
                // 检查是否打开APP
                if (navigator.userAgent.toLowerCase().match(/(huawei);?/i)) {
                    // $ 打开华为应用商店
                    window.location.href = `${HUAWEI_STORE_SCHEME}${PACKAGE_NAME}`;
                } else {
                    // $ 打开Google PLAY的应用商店
                    window.open(`${GOOGLE_PLAY_LINK}${PACKAGE_NAME}`);
                }
            }
        }
    } catch (error) { }
}