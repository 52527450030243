import _ from 'lodash';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { BasketDish, BasketPackageMealArea, BasketSetMeal, CollectiveItem, CollectiveItemArea, ICollectiveDishPanelBasket, ICollectiveDishPanelState, ICollectiveDishPanelType } from '../../../interfaces/collective-dish-panel.interface';
import i18n from '../../../i18n';
import apiManage from '../../../request';
import { allDishImages } from '../../../static/dish-images';
import './set-dish-detail-item.scss'
import { Dish } from '../../../models';
import { MdOutlineShoppingCart } from 'react-icons/md';
import Notification from '../../snackbar/snackbar';
import { v4 as uuidv4 } from 'uuid';
import { isCollectiveItemIsBasketPackageMeal, isCollectiveItemIsBasketSetMeal } from '../../../constants/type-checker.constant';
import { LocalStorageManager } from '../../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../../constants/local-storage.constant';
import { showHDPic } from '../../../constants/tool-show-hd-pic';
import { DishImgRes } from '../../../models/fixed/menu.entity';
import { DishDisabledMask } from '../dish-disabled-mask';
import { analysisCart, DishTagBox } from '../order.component';
import { PM } from '../tool-points';

// 加入或移出购物车
enum UPGRADE {
    REMOVE_DISH = -1,
    ADD_DISH = 1
}
/**
 * 套餐\包餐
 */
class SetDishDetailItem extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.onScroll = _.throttle(this.onScroll, 50).bind(this) as any;
        this.getDishItemNum = this.getDishItemNum.bind(this);
    }

    public state: State = {
        // 源数据
        orginDish: {},
        // 区域组
        areas: [],
        // 不符合条件的区域
        failAreas: [],
        // 检查规则
        checkFlag: false,
        // 超出，显示头
        overFlow: false,
        // 总购物篮
        basket: [],
        // 当前所在购物篮Id
        currentBasketId: '',
        // 当前所在购物篮层级
        currentBasketLevel: 0,
        // 当前所在购物篮类型（包餐|套餐）
        currentBasketType: ICollectiveDishPanelType.SET_MEAL
    }

    // *********************
    // Life Cycle Function
    // *********************

    async componentDidMount() {
        await this.init();
    }

    // *********************
    // Default Function
    // *********************

    async init() {
        this.getSetMealData()
    }

    /**
     * 获取当前层级区域和菜品数据
     * @param areaCloned 当前区域数据
     */
    async getSetMealData() {
        const { stringId, details } = this.props;
        const { _id, dish_type } = details || {};

        let result: CollectiveItem = {};
        let resultClone: CollectiveItem = {};

        let itemAreas: CollectiveItemArea[] = [];
        let currentBasketType: ICollectiveDishPanelType = ICollectiveDishPanelType.SET_MEAL;
        // 获取 包餐 区域数据
        if (dish_type === '1') {
            result = await apiManage.getPackageMeal(stringId, _id || '');
            resultClone = _.cloneDeep(result);
            itemAreas = _.get(resultClone, 'package_meal_areas', []);
            currentBasketType = ICollectiveDishPanelType.PACKAGE_MEAL;
        }
        // 获取 套餐 区域数据
        else if (dish_type === '2') {
            result = await apiManage.getSetMeal(stringId, _id || '');
            resultClone = _.cloneDeep(result);
            itemAreas = _.get(resultClone, 'set_meal_areas', []);
            currentBasketType = ICollectiveDishPanelType.SET_MEAL;
        }
        if (!result && _.get(result, 'error')) {
            return
        }
        //

        const basketId = uuidv4();
        // 重组数据，保留区域（不含菜品）
        const deResultClone = _.cloneDeep(resultClone);
        const areas = this.findAreas(deResultClone) || [];
        areas?.forEach(areaItem => {
            // 套餐或包餐 区域内的菜品清空
            _.set(areaItem, 'dishes', []);
            if (dish_type === '1') {
                // 包餐 区域内的套餐清空
                _.set(areaItem, 'set_meals', []);
            }
        });
        // 设置 firstId
        _.set(deResultClone, 'firstId', details.firstId);
        // 设置 menuId
        _.set(deResultClone, 'menuId', details.menuId);
        // 设置 menuName
        _.set(deResultClone, 'menuName', details.menuName);
        // 设置 price
        _.set(deResultClone, 'price', details.price);

        // 购物袋
        let basket: ICollectiveDishPanelBasket = {
            // 唯一标识符
            id: basketId,
            // 标识是包餐或套餐
            type: currentBasketType,
            // 当前所处层级
            level: 0,
            // 内部的菜品
            item: deResultClone
        }
        basket = this.reRenderEditDetail(basket, itemAreas);

        this.setState({
            orginDish: result,
            // 总购物篮
            basket: [basket],
            currentBasketId: basketId,
            areas: itemAreas,
            // 当前所在购物篮层级
            currentBasketLevel: 0,
            // 当前所在购物篮类型（包餐|套餐）
            currentBasketType
        }, async () => {
            const newAreas = await this.getDishPic(this.state.areas);
            this.setState({
                areas: newAreas
            })
        })

    }
    /**
     * 重新赋值编辑详情
     * - 在购物车，点击编辑套餐
     */
    reRenderEditDetail(basket: ICollectiveDishPanelBasket, itemAreas: CollectiveItemArea[]) {
        const clondeBasket = _.cloneDeep(basket);
        if (this.props.isEdit) {
            const dishDetails = this.props.details;
            const collectiveItem = clondeBasket.item;
            const areas = this.findAreas(collectiveItem);
            const dishDetailsAreas = this.findAreas(dishDetails as CollectiveItem);

            areas?.forEach(item => {
                const targetArea = dishDetailsAreas.find((dishDetailsAreaItem) => dishDetailsAreaItem._id === item._id);
                if (targetArea) {
                    // 菜单的区域的菜品
                    const menuAreasDishes = itemAreas.find((iiitem) => iiitem._id === targetArea._id)?.dishes;
                    const dishes: BasketDish[] = [];
                    targetArea?.dishes?.forEach(iitem => {
                        const menuDishes = menuAreasDishes?.find((iiitem) => iiitem._id === iitem._id);
                        if (menuDishes) {
                            menuDishes.num = iitem.num;
                            dishes.push(menuDishes);
                        }
                    });
                    _.set(item, 'dishes', dishes);

                    if (dishDetails.dish_type === '1') {
                        _.set(item, 'set_meals', targetArea.set_meals);
                    }
                }
            });
        }
        return clondeBasket
    }


    /**
     * 滚动
     */
    onScroll() {
        const wrapper = document.getElementById('set-dish-item-dialog-content');
        const dom = document.getElementById(`set-dish-content`);
        if (wrapper && dom) {
            const overFlow = wrapper?.scrollTop > dom?.offsetTop;
            const stateOverFlow = this.state.overFlow;
            if (stateOverFlow !== overFlow) {
                this.setState({ overFlow })
            }
        }
    }
    /**
     * 获取购物篮中的购物袋的（包餐|套餐）
     */
    findBacketItem(backetId: string) {
        const { basket } = this.state;
        // 当前购物袋
        const targetBasketBag = basket?.find((basketItem) => basketItem.id === backetId);
        // 购物袋里的套餐或包餐
        return targetBasketBag?.item
    }

    /**
     * 找到区域组
     * - 为了区分包餐区域，套餐区域字段
     */
    findAreas(collectiveItem: CollectiveItem) {
        // 获取 包餐 区域数据
        if (collectiveItem?.dish_type === '1') {
            return _.get(collectiveItem, 'package_meal_areas', []);
        }
        // 获取 套餐 区域数据
        else if (collectiveItem?.dish_type === '2') {
            return _.get(collectiveItem, 'set_meal_areas', []);
        }
        return []
    }

    /**
     * 点击菜品
     * - 普通菜品 加减
     */
    changeDishQuantity(area: CollectiveItemArea, item: BasketDish, type: UPGRADE) {
        const { currentBasketId } = this.state;
        const cloneDishItem = _.cloneDeep(item);
        // 购物袋里的套餐或包餐
        const collectiveItem = this.findBacketItem(currentBasketId) || {};
        // 目标区域组
        const itemAreas = this.findAreas(collectiveItem);

        // 目标区域
        const itemArea: CollectiveItemArea | undefined = itemAreas.find((itemAreaItem: CollectiveItemArea) => itemAreaItem._id === area._id);
        if (itemArea) {
            // 普通菜品
            if (item.dish_type === '0') {
                const targetDish = itemArea?.dishes?.find((dishItem) => dishItem._id === item._id);
                if (targetDish) {
                    if (type === 1) {
                        targetDish.num += 1;
                    } else if (type === -1) {
                        targetDish.num -= 1;
                    }
                } else {
                    if (type === 1) {
                        cloneDishItem.num = 1;
                        itemArea.dishes.push(cloneDishItem)
                    }
                }
                itemArea.dishes = itemArea?.dishes.filter((dishItem) => dishItem.num > 0)
            }
            // TODO 套餐
        }

        if (this.state.checkFlag) {
            const checkRuleRes = this.checkRule();
            this.setState({ failAreas: checkRuleRes.failAreas });
        }
        this.setState({})
    }



    /**
     * 校验规则
     */
    checkRule() {
        let areas: CollectiveItemArea[] = [];
        const collectiveItem = this.findBacketItem(this.state.currentBasketId);
        areas = collectiveItem ? this.findAreas(collectiveItem) : [];

        const failAreas: CollectiveItemArea[] = [];
        let totalDishesNum = 0;

        areas?.forEach((item) => {
            const maximumNum = item.customer_max_num_dishes;
            // 必选是至少选择一个
            let minimumNum = item.is_required ? 1 : 0;
            if (item.customer_min_num_dishes && item.customer_min_num_dishes >= 1) {
                minimumNum = item.customer_min_num_dishes;
            }
            const totalNum = item?.dishes?.reduce((p: number, c) => p + _.get(c, 'num', 0), 0) || 0;
            totalDishesNum += totalNum;
            // 非必选
            if (!item.is_required) {
                // 且必选满（则可以不选，如果选了就要选满）
                if ((totalNum > 0 && totalNum < minimumNum) || (typeof (maximumNum) === 'number' && totalNum > maximumNum)) {
                    failAreas.push(item);
                }
            }
            // 必选
            else if ((typeof (minimumNum) === 'number' && totalNum < minimumNum) || (typeof (maximumNum) === 'number' && totalNum > maximumNum)) {
                failAreas.push(item);
            }
        })

        return { failAreas, totalDishesNum, pass: failAreas.length === 0 }
    }

    /**
     * 点击确认按钮（添加至购物车|添加至购物篮）
     */
    clickConfirmBtn(isUpdateCart?: boolean) {
        const checkRuleRes = this.checkRule();
        if (!checkRuleRes.pass) {
            // 至少选择一道菜
            // if (checkRuleRes.totalDishesNum === 0) {
            //     Notification.notification(i18n.t('restaurant_order_page_text_choose_at_least_one_dish'));
            // }
            // 显示错误图标
            this.setState({ failAreas: checkRuleRes.failAreas, checkFlag: true }, () => {
                const wrapper = document.querySelector(`#set-dish-item-dialog-content`);
                const dom = document.getElementById(`set-dish-item-course-card-${checkRuleRes.failAreas?.[0]?._id}`);
                if (wrapper && dom) {
                    wrapper.scrollTo({ top: dom.offsetTop - 100, behavior: 'smooth' });
                }
            });
            return
        }
        const { basket } = this.state;

        const basketItem = _.cloneDeep(basket).find((item) => item.level === 0)?.item;
        if (basketItem) {
            // $ 计算该套餐或包餐的总价格
            basketItem.price = this.analyzeBacket()?.totalPrice || 0;
            // $ 去掉没有菜品的区域
            let areas = this.findAreas(basketItem);
            areas = areas.filter((item) => item.dishes && item.dishes.length > 0);
            if (basketItem?.dish_type === '1') {
                // 设置 包餐 区域数据
                _.set(basketItem, 'package_meal_areas', areas);
            }
            else if (basketItem?.dish_type === '2') {
                // 设置 套餐 区域数据
                _.set(basketItem, 'set_meal_areas', areas);
            }
            // 保存套餐图片
            _.set(basketItem, 'realpics', this.props.details.realpics);
            // 如果没有图片,调用api获取
            if (this.props.details?.realpics?.length) {
                // 保存包餐图片
                _.set(basketItem, 'realpics', this.props.details.realpics);
            } else {
                if (this.props.details?._id) {
                    const pic = apiManage.getSetDishImg(this.props.stringId, [this.props.details?._id], { notNeedLoading: true })
                    if (pic?.[0]?.picUrl) {
                        _.set(basketItem, 'realpics', [pic?.[0]?.picUrl]);
                    }
                }
            }
            // 添加至购物车
            if (isUpdateCart) {
                this.updateCart(basketItem);
            }
            // 添加至购物篮
            else {
                // 将套餐在购物篮的的唯一id，记录。
                _.set(basketItem, 'differentSetDishId', _.get(this.props.details, 'differentSetDishId'))
                // 添加套餐到购物篮（包餐里的套餐）
                this.props?.updateBesket?.(basketItem);
            }
        }

    }

    /**
     * 添加套餐到购物车
     */
    updateCart(basketItem: CollectiveItem) {
        try {
            const { details, isEdit } = this.props;
            // 获取缓存里购物车
            const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
            // 添加到购物车
            let shopCatList = _.cloneDeep(cacheShopCar[this.props.stringId]?.shopCarList);

            if (basketItem) {

                // 从购物车里编辑套餐
                if (isEdit) {

                    const editDishItem = shopCatList.find(shopCatItem => _.get(shopCatItem, 'inCartId') === _.get(details, 'inCartId'));
                    if (editDishItem) {
                        if (basketItem?.dish_type === '1') {
                            _.set(editDishItem, 'package_meal_areas', _.get(basketItem, 'package_meal_areas', []));
                        }
                        else if (basketItem?.dish_type === '2') {
                            _.set(editDishItem, 'set_meal_areas', _.get(basketItem, 'set_meal_areas', []));
                        }
                        // 设置新的价格
                        _.set(editDishItem, 'price', basketItem.price)
                    }
                }

                // $ 查看包餐套餐已选菜品是否与购物车相同
                let isEqual: boolean = false;
                let isEqualItemId: string = ''

                shopCatList.map(shopCatItem => {
                    const isSelf = _.get(shopCatItem, 'inCartId') === _.get(details, 'inCartId');
                    if (shopCatItem.firstId === basketItem.firstId && !isEqual && !isSelf) {
                        isEqual = this.isEqualCollectiveData(shopCatItem as CollectiveItem, basketItem);
                        if (isEqual) {
                            isEqualItemId = shopCatItem.inCartId || '';
                        }
                    }
                    return shopCatItem
                })

                if (isEqual) {
                    // 编辑的菜品
                    const editDishItem = shopCatList.find(shopCatItem => _.get(shopCatItem, 'inCartId') === _.get(details, 'inCartId'));
                    // 相同的菜品
                    const equalDishIItem = shopCatList.find(shopCatItem => _.get(shopCatItem, 'inCartId') === isEqualItemId);
                    // 编辑后，合并相同的套餐，合并后，删除编辑的套餐
                    if (equalDishIItem) {
                        const buyNumber = isEdit ? (editDishItem?.buyNumber || 0) : 1
                        // 相同的菜品的数量  +  编辑的菜品的数量
                        equalDishIItem.buyNumber = (equalDishIItem.buyNumber || 0) + buyNumber;
                        if (isEdit && editDishItem) {
                            // 合并后，删除编辑的套餐
                            shopCatList = shopCatList.filter((shopCatItem) => shopCatItem.inCartId !== editDishItem.inCartId);
                        }
                    }
                } else {
                    if (!isEdit) {
                        shopCatList.push({
                            ...basketItem,
                            buyNumber: 1,
                            inCartId: uuidv4()
                        })
                    }
                }

                const shopCar = analysisCart({ shopCarList: shopCatList })

                // 是否已登录， token
                const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
                const pass = PM.updatePoints({ key: 'dishUsedPoints', value: shopCar.totalPoints || 0 }, !token);
                if (pass) {
                    // 更新购物车数据
                    this.props.updateOrderState({
                        saveShopCatDate: shopCatList,
                        totalShopPrice: shopCar.totalPrice,
                        totalShopPoints: shopCar.totalPoints,
                    })
                    // 更新存储里面的购物车数据
                    const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};

                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                        value: {
                            ...cacheShopCar,
                            [this.props.stringId]: shopCar
                        }
                    });
                    this.props.goBack()
                } else {
                    Notification.notification(`${i18n.t('order_page_text_not_enough_for_points')}`);
                }
            }
        } catch (error) {
            console.log(error, 'error');
        }
    }

    /**
     * 获取菜品已选数量
     */
    getDishItemNum(dish: BasketDish, area: CollectiveItemArea) {
        let num = 0;
        const { currentBasketId } = this.state;
        const collectiveItem = this.findBacketItem(currentBasketId);
        const areas: CollectiveItemArea[] = collectiveItem ? this.findAreas(collectiveItem) : [];
        const tartgetArea = areas.find((item) => item._id === area._id);
        if (tartgetArea) {
            const tartgetDish = tartgetArea.dishes?.find((item) => item._id === dish._id);
            num = tartgetDish?.num || 0;
        }
        return num
    }
    /**
     * 获取区域已选数量、总价
     */
    getAreaItemNum(area: CollectiveItemArea) {
        // 总数量
        let totalNum = 0;
        // 总价格
        let totalPrice = 0;
        const { currentBasketId } = this.state;
        const collectiveItem = this.findBacketItem(currentBasketId);
        const areas: CollectiveItemArea[] = collectiveItem ? this.findAreas(collectiveItem) : [];
        const tartgetArea = areas.find((item) => item._id === area._id);
        if (tartgetArea) {
            // 普通菜品
            const res = tartgetArea?.dishes?.reduce((p: { totalNum: number, totalPrice: number }, c) => {
                const totalNum = p.totalNum += (c.num || 0);
                const totalPrice = p.totalPrice += ((c.num || 0) * (c.price || 0));
                return { totalNum, totalPrice }
            }, {
                totalNum: 0, totalPrice: 0
            });
            totalNum += res.totalNum;
            totalPrice += res.totalPrice;
            // TODO 套餐
            // totalNum += (tartgetArea as BasketPackageMealArea)?.set_meals?.reduce((p: number, c) => p + _.get(c, 'num', 0), 0) || 0;
            // 区域价格
            if (totalNum > 0) {
                totalPrice += tartgetArea.additional_fees || 0;
            }
        }
        return { totalNum, totalPrice }
    }

    /**
     * 分析购物篮
     * - 总价格、总数量
     * - 套餐本身价格 + 里面菜品的价格
     */
    analyzeBacket() {
        const { areas, orginDish } = this.state;
        const { isPackageMenu, details } = this.props;

        let totalNum = 0;
        // 如果是包餐里的套餐，则显示额外价格。
        let totalPrice = isPackageMenu ? (details.price || 0) : (orginDish.price || 0);
        areas.forEach((item) => {
            const res = this.getAreaItemNum(item);
            totalNum += res.totalNum;
            totalPrice += res.totalPrice;
        })
        return { totalNum, totalPrice };
    }
    /**
     * 比较两个套餐选的菜品是否一致
     */
    isEqualCollectiveData(menuItem: CollectiveItem, cartItem: CollectiveItem) {
        let isEqual = true;

        // 套餐 dish_type=2
        if (isCollectiveItemIsBasketSetMeal(menuItem) && isCollectiveItemIsBasketSetMeal(cartItem)) {
            if (menuItem?.set_meal_areas?.length === cartItem?.set_meal_areas?.length) {
                const menuItemResult = this.flatCollectiveItem(menuItem);
                const cartItemResult = this.flatCollectiveItem(cartItem);
                isEqual = _.isEqual(menuItemResult?.dishes, cartItemResult?.dishes)
            } else {
                isEqual = false
            }
        }
        // 包餐 dish_type=1
        else if (isCollectiveItemIsBasketPackageMeal(menuItem) && isCollectiveItemIsBasketPackageMeal(cartItem)) {
            if (menuItem?.package_meal_areas?.length === cartItem?.package_meal_areas?.length) {
                const menuItemResult = this.flatCollectiveItem(menuItem);
                const cartItemResult = this.flatCollectiveItem(cartItem);
                isEqual = _.isEqual(menuItemResult?.dishes, cartItemResult?.dishes) && _.isEqual(menuItemResult?.setMeals, cartItemResult?.setMeals)
            } else {
                isEqual = false
            }
        }


        return isEqual
    }


    /**
     * 将区域里的菜品重组
     */
    flatCollectiveItem(collectiveItem: CollectiveItem): { dishes: BasketDish[], setMeals: BasketDish[][] } | undefined {
        // 套餐
        if (isCollectiveItemIsBasketSetMeal(collectiveItem)) {
            const newItem: BasketDish[] = [];
            (collectiveItem?.set_meal_areas || [])?.forEach((areaItem) => {
                (areaItem?.dishes || []).forEach((dishItem) => (
                    newItem.push(
                        {
                            belongedAreaId: areaItem._id,
                            _id: dishItem._id,
                            num: dishItem.num || 0
                        }
                    )
                ))
            })
            return { dishes: newItem, setMeals: [] }
        }
        // 包餐
        else if (isCollectiveItemIsBasketPackageMeal(collectiveItem)) {
            const newItem: BasketDish[] = [];
            const setMeals: BasketDish[][] = [];
            (collectiveItem?.package_meal_areas || [])?.forEach((areaItem) => {
                (areaItem?.dishes || []).forEach((dishItem) => (
                    newItem.push(
                        {
                            belongedAreaId: areaItem._id,
                            _id: dishItem._id,
                            num: dishItem.buyNumber || 0
                        }
                    )
                ));

                (areaItem?.set_meals)?.forEach((mealsItem) => {
                    const res = this.flatCollectiveItem(mealsItem);
                    res?.dishes && setMeals.push(res?.dishes);
                })

            })
            return { dishes: newItem, setMeals }
        }
    }

    /**
     * 获取菜品图片
     */
    async getDishPic(itemAreas: CollectiveItemArea[]) {
        const dishImg: DishImgRes = {};
        const picIds: Array<string> = [];
        // 获取菜品图片
        itemAreas.forEach((item) => {
            item.dishes.forEach(dishesItem => {
                dishesItem._id && picIds.push(dishesItem._id);
            });
            if ((item as BasketPackageMealArea)?.set_meals?.length) {
                (item as BasketPackageMealArea)?.set_meals.forEach(setMealsItem => {
                    setMealsItem._id && picIds.push(setMealsItem._id);
                })
            }
        })

        // 拆成10张图为一组，并发请求
        const request = _.chunk(picIds, 10)?.map((ids) => {
            return apiManage.getSetDishImg(this.props.stringId, ids, { notNeedLoading: true })
        });
        const dishImgRes = await Promise.all(request);
        dishImgRes.forEach((item) => {
            if (item && !_.get(item, 'error')) {
                item?.forEach((dishItem) => {
                    dishImg[dishItem._id] = dishItem.picUrl
                })
            }
        })
        itemAreas = itemAreas.map((item) => {
            item.dishes.forEach((dishItem) => {
                dishItem.realpics = dishImg[dishItem._id || ''] ? [dishImg[dishItem._id || '']] : [];
            });
            (item as BasketPackageMealArea)?.set_meals?.forEach(setMealsItem => {
                (setMealsItem as BasketSetMeal).realpics = dishImg[setMealsItem._id || ''] ? [dishImg[setMealsItem._id || '']] : [];
            })
            return item
        })
        return itemAreas
    }
    // *********************
    // View
    // *********************

    render() {
        const { details, isEdit, goBack, isPackageMenu, selectFilterLabeldAllergen, selectFilterLabeldIngredients } = this.props;
        const { areas, failAreas, overFlow, orginDish } = this.state;
        const analyzeBacketRes = this.analyzeBacket();
        // 已经选满 （达到套餐或包餐最大值）
        const isBasketFull = orginDish.customer_max_num_dishes ? analyzeBacketRes.totalNum === orginDish.customer_max_num_dishes : false;
        const HDPic = details.realpics ? showHDPic(details.realpics[0]) : '';

        // 如果是包餐里的套餐，则显示额外价格。
        const setMealPrice = isPackageMenu ? (details.price || 0) : (orginDish.price || 0);
        return (
            <div className='set-dish-item-dialog-bg'>
                <div className='set-dish-item-dialog' >
                    <div className='set-dish-item-dialog-content'
                        id='set-dish-item-dialog-content'
                        onScroll={() => {
                            this.onScroll();
                        }}
                    >
                        {/* 头部 */}
                        <div className='title-bar  ' style={{ opacity: overFlow ? 1 : 0 }}  >
                            <div className='title-text'>{details.name}</div>
                        </div>
                        <div className='close-icon ' onClick={() => goBack()}>
                            <CloseIcon />
                        </div>
                        {/* 图片 */}
                        <div className={`dish-img`}
                            style={{ backgroundImage: `url(${HDPic})` }}
                        >
                        </div>
                        <div className='set-dish-content' id={'set-dish-content'}>
                            {/* 套餐名称 */}
                            <div className='set-dish-name' >{details.name}</div>
                            {/* 套餐介绍 */}
                            <div className='set-dish-name-ext'>{details.description}</div>
                            {/* 套餐价格 */}
                            <div className='set-dish-price-text'>
                                {PM.showPointsOrPrice(orginDish.is_points_redemption, setMealPrice || 0)}
                            </div>
                            {/* '选择产品'文本 */}
                            <div className='choose-a-product-text'>{i18n.t('restaurant_order_page_text_please_add_dishes')}</div>
                            {/* 菜品组以及菜品 */}
                            <div className='course-group-list'>
                                {areas.map((item, index) => {

                                    const isfail = failAreas.find((iitem) => iitem._id === item._id);
                                    const { totalNum } = this.getAreaItemNum(item);

                                    return (
                                        <SetDishItemCourseCard
                                            key={index}
                                            data={item}
                                            changeDishQuantity={(CourseDetail, type: number) => {
                                                this.changeDishQuantity(item, CourseDetail, type)
                                            }}
                                            isfail={!!isfail}
                                            getDishItemNum={this.getDishItemNum}
                                            totalNum={totalNum}
                                            isBasketFull={isBasketFull}
                                            allselectAllergen={[...selectFilterLabeldAllergen, ...selectFilterLabeldIngredients]}
                                            isPoints={!!orginDish.is_points_redemption}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                        {/* 添加购物车 */}
                        <div className={`add-dish-btn `}>
                            <Button
                                fullWidth
                                className={`add-dish-btn-content ${!this.checkRule().pass ? 'add-dish-btn-disabled' : ''}`}
                                onClick={() => {
                                    this.clickConfirmBtn(!isPackageMenu);
                                }}
                                disabled={false}
                            >
                                <div className='order-cart-icon-box'>
                                    <MdOutlineShoppingCart className='md-cart-icon' />
                                    <div className='order-cart-icon-box-text'>
                                        {isEdit ?
                                            i18n.t('restaurant_order_page_text_confirm_the_changes') :
                                            isPackageMenu ?
                                                i18n.t('restaurant_order_page_text_add_to_shopping_basket') :
                                                i18n.t('restaurant_order_page_button_add_to_cart')
                                        }
                                    </div>
                                    <div className='beask-total-num-text'>
                                        {analyzeBacketRes.totalNum}{orginDish.customer_max_num_dishes ? `/${orginDish.customer_max_num_dishes}` : ''}
                                    </div>
                                </div>
                                <div className='price-text'>
                                    {PM.showPointsOrPrice(orginDish.is_points_redemption, analyzeBacketRes?.totalPrice || 0)}
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SetDishDetailItem

/**
 * 套餐里的菜品组
 */
function SetDishItemCourseCard(props: {
    data: CollectiveItemArea;
    isfail: boolean;
    changeDishQuantity: (item: BasketDish, type: number) => void;
    getDishItemNum: (dish: BasketDish, area: CollectiveItemArea) => number;
    totalNum: number;
    // 购物篮总量已经选满
    isBasketFull: boolean;
    // 过敏源、其他标识
    allselectAllergen: string[];
    // 积分
    isPoints: boolean;
}) {
    const { data, changeDishQuantity, isfail, totalNum, isBasketFull, allselectAllergen, isPoints } = props;
    /**
     * 套餐区域菜品的最多点菜数量
     * is_required: 是否 必选区域
     */
    const maximumNum = data.customer_max_num_dishes;
    // 必选是至少选择一个
    let minimumNum = data.is_required ? 1 : 0;
    if (data.customer_min_num_dishes && data.customer_min_num_dishes >= 1) {
        minimumNum = data.customer_min_num_dishes;
    }
    // 已选满
    const isFull = totalNum === maximumNum;
    // 达到最小值
    const isMin = totalNum >= minimumNum;
    // 是否可以重复选择
    const isRepeated = data.is_repeated;
    return (
        <div className='set-dish-item-course-card' id={`set-dish-item-course-card-${data._id}`}>
            {/* 套餐区域名称 */}
            <div className='name-and-qiantity-box'>
                <div className='course-group-name'>{data.name}</div>
                <div className='quantity-info'>
                    {(data.is_required && isMin) || (isMin && totalNum) ?
                        <div className='check-icon'>
                            <CheckCircleOutlineIcon />
                        </div>
                        : null
                    }
                    <div className='min-num-dishes-text' >
                        {data.customer_min_num_dishes ? i18n.t('restaurant_order_page_text_choose_x_dishes', { x: data.customer_min_num_dishes }) : ''}
                    </div>
                    <div className={`${isfail ? 'quantity-info-fail' : 'required-text'}`}>
                        {isfail ?
                            <div className='error-icon'>
                                <ErrorOutlineIcon />
                            </div> : null}
                        <div >{data.is_required ? i18n.t('restaurant_order_page_text_set_menu_required') : ''}</div>
                    </div>
                    {maximumNum ?
                        <div className={`select-num-text`}>
                            {(maximumNum ? `${totalNum}/${maximumNum}` : '')}
                        </div> : null}
                    {data.additional_fees ?
                        <div className={`area-additional-fees-text`}>
                            {(data.additional_fees ? `+` + PM.showPointsOrPrice(isPoints, data.additional_fees || 0) : '')}
                        </div> : null}
                </div>
            </div>
            {/* 套餐下的菜品 */}
            <div className='course-dish-list'>
                {
                    (data.dishes || [])?.map((item, index: number) => {
                        // 已选择的数量
                        const quantity = props.getDishItemNum(item, data);
                        // 过敏原
                        const allergen = _.get(item, 'allergen', []) as string[];
                        // 加号按钮禁用（不可复选且数量是1  || 已经该区域点满 || 达到购物篮最大值）
                        const disabledAdd = (!isRepeated && (quantity === 1)) || isFull || isBasketFull;
                        // 菜品图片
                        const pic = _.get(item, 'realpics[0]', '');
                        // 过敏源、标识
                        const allergenAndIngredients = (item.allergen || []).concat(item.ingredients || [])
                        if (_.difference(allergenAndIngredients, allselectAllergen).length !== allergenAndIngredients.length) {
                            item.filter_status = true;
                        }

                        return (
                            <div key={index} className={`content  ${disabledAdd ? 'course-dish-list-dish-disabled' : ''}`} >
                                <DishTagBox dishItem={item} />
                                {/* 菜品图片 */}
                                <div className='dishImgbox'>
                                    <img className='dishImg' src={pic} alt='' />
                                </div>
                                {/* 菜品详情、操作按钮 */}
                                <div className='dish-detail-box'>
                                    <div className='dish-info-box'>
                                        <div className='dish-name'>
                                            <p>{item.name}</p>
                                        </div>
                                        <div className='dish-description' >
                                            <p>{item?.description}</p>
                                        </div>
                                        <div className=''>
                                            {allergen?.map((allergenItem: string, allergenIndex: number) => {
                                                const pic = allDishImages[allergenItem];
                                                if (pic) {
                                                    return (
                                                        <img className='allergen-pic' src={pic} alt={allergenItem} key={allergenIndex} />
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className='pricebox'>
                                        <p className='price-box-price'>
                                            {item.price ? PM.showPointsOrPrice(isPoints, item.price || 0) : null}
                                        </p>
                                        < div className='price-number'>
                                            {
                                                <IconButton
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        changeDishQuantity(item, UPGRADE.REMOVE_DISH)
                                                    }}
                                                    disabled={false}
                                                >
                                                    <RemoveCircleOutlinedIcon
                                                        className={`AiFillMinusCircle animate-fadeIn ${quantity ? '' : 'no-number-circle'}`}
                                                    />
                                                </IconButton>
                                            }
                                            <p className={`number animate-fadeIn ${quantity ? 'number' : 'is_no_number'}`}>
                                                {quantity}
                                            </p>
                                            {
                                                <IconButton
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        changeDishQuantity(item, UPGRADE.ADD_DISH)
                                                    }}
                                                    disabled={disabledAdd}
                                                >
                                                    <AddCircleOutlinedIcon className={`AiFillPlusCircle ${disabledAdd ? 'disabled-plus-icon' : ''}`} />
                                                </IconButton>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* 禁用蒙层 */}
                                {<DishDisabledMask soldOut={item.status === '2'} filterOut={!!item.filter_status} />}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


// *********************
// State & Props
// *********************
interface State extends ICollectiveDishPanelState {
    // 源数据（套餐或包餐）
    orginDish: CollectiveItem;
    // 区域组
    areas: CollectiveItemArea[];
    // 不符合条件的区域
    failAreas: CollectiveItemArea[];
    // 检查规则
    checkFlag: boolean;
    // 超出，显示头
    overFlow: boolean;
}

type Props = {
    // 详情
    details: Dish;
    // 更新状态
    updateOrderState(val: any): void;
    // 餐厅码
    stringId: string;
    goBack: () => void;
    // 是否编辑
    isEdit: boolean;
    // 是否是包餐内的套餐
    isPackageMenu?: boolean;
    // 更新购物篮（包餐里的套餐）
    updateBesket?: (basketItem: CollectiveItem) => void;
    // 刷选器选中的标签-过敏原
    selectFilterLabeldAllergen: Array<string>;
    // 刷选器选中的标签-配料
    selectFilterLabeldIngredients: Array<string>;
}
