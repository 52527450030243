import { Restaurant, Dish, Order, Customer, Token } from '../models';

// *********************
// Actions
// *********************

export declare type Actions = ResetStateAction | SetStateAction | any;

export declare type ResetStateAction = {
    type: 'RESET_STATE';
    path: string;
};

export declare type SetStateAction = {
    type: 'SET_STATE';
    path: string;
    value: any;
};

// *********************
// Caching
// *********************

/**
 * @deprecated
 */
export const CACHING_KEY = 'DEFAULT_CACHING';

/**
 * @deprecated
 */
export const CACHING_EMPTY_STRING = '{}';

/**
 * @deprecated
 */
export declare type CachingDataStructure = {
    /**
     * 缓存所有浏览过的餐馆信息
     * @更新时间
     * - 扫码或输入代码成功时
     */
    restaurants: {
        [string_id: string]: {
            restaurant: Partial<Restaurant>;
            menuDish: Array<{
                _id?: string;
                status?: boolean;
                pics?: Array<string>;
                name?: string;
                price?: number;
                description?: string;
                dishs?: Array<Dish>;
            }>;
        }
    };
    /**
     * 缓存所有参与过的订单信息
     * @更新时间
     * - 每次订单完成时（每次更新时要先查一下是否已存在）
     */
    orders: Array<Order>;
    // S:缓存当前登录的顾客账户
    account: Partial<Customer>;
    // S:缓存当前登录的顾客账户的令牌
    token: Partial<Token>;
    // S:远程Https/Http服务器地址
    httpsHttpServerUrl: string;
    // S:远程Websocket服务器地址
    websocketServerUrl: string;
    // S:缓存当前系统语言
    language: string;
    // S:缓存最后一次登录的账号
    lastLoginAccount: {
        phone: string;
        phonePerfix: string;
    };
    // 全局loading
    globalLoading: boolean;
    // 停业时间
    closedTime: {
        id: string;
        time: {
            setting_temporarily_closed_enable: boolean;
            setting_temporarily_closed_end_data: string;
            setting_temporarily_closed_start_data: string;
        }
    },
    // 新的消息
    hasNewNotices: boolean;
};

// *********************
// State
// *********************

export declare type State = {
    // $ 基础参数
    // C:顾客账户
    account: Partial<Customer>;
    // C:顾客账户的令牌
    token: Partial<Token>;
    // C:远程Https/Http服务器地址
    httpsHttpServerUrl: string;
    // C:远程Websocket服务器地址
    websocketServerUrl: string;
    // C:语言
    language: string;
    // $ 当前餐馆
    // 当前餐馆的string_id
    currentResStringID: string;
    // $ 接口请求状态
    fetch: {
        isFetching: boolean;
        lastUpdated: number;
        error: {
            statusCode: number;
            message: string;
            code: number;
            details: string;
        } | null;
    },
    // C:验证码发送倒计时
    resendOrderSecond: number;
    // C:提示框
    messageBox: {
        lastUpdated: number;
        level: 'Warning' | 'Error' | 'Default' | 'Info' | 'Success';
        message: string;
        details?: any[] | string;
    },
    // 全局loading
    globalLoading: boolean;
    // 停业时间
    closedTime: {
        id: string;
        time: {
            setting_temporarily_closed_enable: boolean;
            setting_temporarily_closed_end_data: string;
            setting_temporarily_closed_start_data: string;
        }
    },
    // 新的消息
    hasNewNotices: boolean;
    // 审核id
    verifyId: string;
};

export const INIT_STATE: State = {
    // $ 基础参数
    // C:顾客账户
    account: {},
    // C:顾客账户的令牌
    token: {},
    // C:服务器地址
    httpsHttpServerUrl: 'http://dev-statictest-yennefer-be.evophotic.com:9000',
    websocketServerUrl: 'http://dev-statictest-yennefer-be.evophotic.com:9100',
    // C:语言
    language: 'EN',
    // $ 当前餐馆
    // 当前餐馆的string_id
    currentResStringID: 'ddddd',
    // $ 接口请求状态
    fetch: {
        isFetching: false,
        lastUpdated: 0,
        error: null
    },
    // C:验证码发送倒计时
    resendOrderSecond: 90,
    // C:提示框
    messageBox: {
        lastUpdated: 0,
        level: 'Error',
        message: '',
        details: ''
    },
    // 全局loading
    globalLoading: false,
    // 停业时间
    closedTime: {
        id: '',
        time: {
            setting_temporarily_closed_enable: false,
            setting_temporarily_closed_end_data: '',
            setting_temporarily_closed_start_data: ''
        }
    },
    hasNewNotices: false,
    // 审核id
    verifyId: ''
};
