import React from 'react';
import './authentication.scss'
import { Button } from '@material-ui/core';
import i18n from '../../i18n';
import TopBar from '../../component/top-bar/top-bar.component';
import apiManage from '../../request';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import Notification from '../../component/snackbar/snackbar'
import { createHashHistory } from 'history';
import Store from '../../rudex/store';
import { ReactComponent as VipIcon } from '../../static/images/vip.svg';
import { CustomerProjectionFixed } from '../../models';

/**
 * 身份验证
 */
export function Authentication(
) {
    const [verifyInfo, setVerifyInfo] = React.useState<Verify>({});
    const [showView, setShowView] = React.useState<{ status: 'pending' | 'success' | 'fail', text?: string }>({ status: 'pending', text: '' });
    const [account] = React.useState(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {});

    const init = async () => {
        const verifyId = Store.getState().verifyId;
        if (verifyId) {
            // 先清理verifyId, 仅单次有效
            Store.dispatch({ type: 'SET_STATE', path: 'verifyId', value: '' });
        } else {
            setShowView({ status: 'fail', text: i18n.t('verify_page_text_The_authorization_is_invalid_Please_reauthorize_the_authorization') });
            return;
        }
        const verifyApi = await apiManage.getVerify({ verify_id: verifyId });
        if (verifyApi && !verifyApi?.error) {
            setVerifyInfo(verifyApi);
            // 审核状态 0-等待|1-通过 |2-拒绝|3-过期 |4-扫码成功
            if (verifyApi.status !== '0' && verifyApi.status !== '4') {
                setShowView({ status: 'fail', text: i18n.t('verify_page_text_The_authorization_is_invalid_Please_reauthorize_the_authorization') });
                return;
            }

        } else {
            setShowView({ status: 'fail', text: i18n.t('verify_page_text_The_authorization_is_invalid_Please_reauthorize_the_authorization') });
        }
    }

    React.useEffect(() => {
        init();
    }, [])
    React.useEffect(() => {
        // 修改审核状态为 "4:扫码成功"
        if (verifyInfo && Object.keys(verifyInfo).length > 0) {
            checkCodeShark('4');
        }
    }, [verifyInfo])

    /**
     * 审核
     * status 审核状态 //0-等待|1-通过 |2-拒绝|3-过期 |4-扫码成功
     */
    const checkCodeShark = async (status: string) => {
        const res = await apiManage.checkCodeShark({
            type: verifyInfo.type || '',
            verify_id: verifyInfo.verify_id || '',
            string_id: verifyInfo.string_id || '',
            phone: account.phone || '',
            phone_prefix: account.phone_prefix || '',
            status: status,
        });
        if (!res?.error) {
            setShowView({ status: 'success' });
            if (status === '1') {
                Notification.notification(i18n.t('verify_page_text_Authorization_succeeds'));
                // 自动加入餐厅会员
                await joinMember();
            }
        } else {
            if (res.result?.code === 'CODE_1008' && res.result?.statusCode === 403) {
                setShowView({ status: 'fail', text: i18n.t('signin_page_text_you_have_exceeded_the_login_limit_today') });
            } else {
                Notification.notification(i18n.t('verify_page_text_The_authorization_is_invalid_Please_reauthorize_the_authorization'));

                setShowView({ status: 'fail', text: i18n.t('verify_page_text_The_authorization_is_invalid_Please_reauthorize_the_authorization') });
            }
        }
        if (status !== '4') {
            // 跳转到餐厅主页
            setTimeout(() => {
                toRestaurantPage();
            }, 1500);
        }

    }

    const joinMember = async () => {
        const customerInfo = await apiManage.get_customer_info(account?._id || '',undefined,CustomerProjectionFixed(['pr_record']));
        // 是否是会员
        const isMember = (customerInfo?.restaurant_member_ids || []).includes(verifyInfo.string_id || '');
        if (!isMember) {
            const res = await apiManage.join_member(verifyInfo.string_id || '', account?._id || '', { notNeedLoading: true });
            if (!res?.error) {
                Notification.notification(i18n.t('restaurant_info_page_text_you_have_joined_a_member'));
            }
        }
    }
    const toRestaurantPage = () => {
        createHashHistory().replace({ pathname: `/restaurantPage?string_id=${verifyInfo.string_id}` })
    }
    return (
        <div className={`AuthenticationDialog  `}>
            <TopBar
                title={verifyInfo.nickname || ''}
                isGoBack={() => { createHashHistory().replace('/'); }}
                isHideMenu
            />
            {showView.status === 'success' ? <div className='dialog-content'>
                <div className='auth-login-content'>
                    <div className='title-box'>
                        <span className='icon'><VipIcon /></span>
                        <span>{i18n.t('verify_page_text_member_login')}</span>
                    </div>
                    <div className='img-box'>
                        <img
                            className='restaurant-logo'
                            src={verifyInfo.logo || ''} alt=''
                        />
                    </div>
                    <div className='nickname-box '>
                        <p className='nickname-text'>
                            {`${i18n.t('verify_page_text_Log_on_to')} ${verifyInfo.nickname || ''}`}
                        </p>
                    </div>

                    <div className='btn-box'>
                        <Button className='btn ok'
                            onClick={() => {
                                checkCodeShark?.('1');
                            }}>
                            {i18n.t('verify_page_text_Confirm_login')}
                        </Button>
                        <Button className='btn cancel'
                            onClick={() => {
                                checkCodeShark?.('2');
                            }}>
                            {i18n.t('verify_page_text_Cancel_login')}
                        </Button>
                    </div>
                </div>
            </div>
                : showView.status === 'fail' ? <div className='not-find'>{showView.text}</div> : <></>}
        </div>
    )
}


type Verify = {
    _id?: string;
    // 审核id（加密)
    verify_id?: string;
    // 餐厅码
    string_id?: string;
    // 手机号码
    phone?: string;
    // 手机区号
    phone_prefix?: string;
    // 创建时间
    customer_id?: string;
    // 审核状态 //0-等待|1-通过 |2-拒绝|3-过期 |4-扫码成功
    status?: string;
    // 项目
    project?: string;
    // 审核方式 类型 PHONE QR_CODE
    type?: string;
    // 次数
    used_times?: number;
    // 
    customer_name?: string;
    first_generated_at?: string;
    // 积分
    integrals?: number;
    // 餐馆logo
    logo?: string;
    // 餐馆名称
    nickname?: string;
}  