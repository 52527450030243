import * as React from 'react';
import _ from 'lodash';
import TopBar from '../../component/top-bar/top-bar.component';
import { Button, Input } from '@material-ui/core';
import './delivery-address.component.scss';
import './fiscal-information.component.scss';
import i18n from '../../i18n';

// 我的账户-电子发票
class FiscalInformation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            language: 'EN',
            insertPhone: '',
            insertEmail: '',
            cardInfo: [
                { name: '1', regoin: '', tel: '', city: '', code: '', isSelect: true },
                { name: '2', regoin: '', tel: '', city: '', code: '', isSelect: false }
            ],
            isEdiAddressModal: false,
            fiscalInformationInfo: {
                country: '',
                vatNumber: '',
                companyName: '',
                city: '',
                zipCode: '',
                fiscalCode: '',
                reciplentCode: '',
                reciplentPEC: '',
                fiscalAddress: ''
            }
        }
    }
    public fiscalInformationInfo: State['fiscalInformationInfo'] = {
        country: '',
        vatNumber: '',
        companyName: '',
        city: '',
        zipCode: '',
        fiscalCode: '',
        reciplentCode: '',
        reciplentPEC: '',
        fiscalAddress: ''
    }

    // ***************
    //  生命周期
    // ***************

    // ***************
    // 方法区
    // ***************
    public isSelected(name: string) {
        const setCardInfo = _.cloneDeep(this.state.cardInfo);
        setCardInfo.forEach((item) => {
            if (name === item.name) {
                item.isSelect = true
            } else {
                item.isSelect = false
            }
        })

        this.setState({
            cardInfo: setCardInfo
        })
    }

    updateState = (value: any) => {
        this.setState(value)
    }
    // ***************
    // VIEW
    // ***************

    public render() {
        const { fiscalInformationInfo } = this.state;
        return (
            <div className='fiscal-information-main overall-center'>
                {/* Top bar */}
                <TopBar
                    backgroundstyle={'backgroundTransparent'}
                    isHideMenu={true}
                    title='Fiscal Information'
                    isGoBack={() => {
                        this.props.changeStatus(false)
                    }} />
                <div className='fiscal-information-content'>
                    <div className='main-body'>
                        <div className='edit-input'>
                            <div className='account-tit'>My fiscal</div>
                            <div className='account-msg'>Your fiscal information</div>
                        </div>
                        <div className='edit-input country'>
                            <div className='account-tit'>Country</div>
                            <Input
                                className='editInput'
                                defaultValue={fiscalInformationInfo.country}
                                rows={2}
                                disableUnderline
                                fullWidth
                                onChange={(event) => { this.fiscalInformationInfo.country = event.target.value }}
                            />
                        </div>
                        <div className='edit-input vatNumber'>
                            <div className='account-tit'>Vat Number</div>
                            <Input
                                className='editInput'
                                defaultValue={fiscalInformationInfo.vatNumber}
                                rows={2}
                                disableUnderline
                                fullWidth
                                onChange={(event) => { this.fiscalInformationInfo.vatNumber = event.target.value }}
                            />
                        </div>
                        <div className='edit-input companyName'>
                            <div className='account-tit'>Company Name</div>
                            <Input
                                className='editInput'
                                defaultValue={this.fiscalInformationInfo.companyName}
                                rows={2}
                                disableUnderline
                                fullWidth
                                onChange={(event) => { this.fiscalInformationInfo.companyName = event.target.value }}
                            />
                        </div>
                        <div className='edit-input fiscalAddress'>
                            <div className='account-tit'>Fiscal Address</div>
                            <Input
                                className='editInput'
                                defaultValue={this.fiscalInformationInfo.fiscalAddress}
                                rows={2}
                                disableUnderline
                                fullWidth
                                onChange={(event) => { this.fiscalInformationInfo.fiscalAddress = event.target.value }}
                            />
                        </div>
                        <div className='edit-input city'>
                            <div className='account-tit'>City</div>
                            <Input
                                className='editInput'
                                defaultValue={this.fiscalInformationInfo.city}
                                rows={2}
                                disableUnderline
                                fullWidth
                                onChange={(event) => { this.fiscalInformationInfo.city = event.target.value }}
                            />
                        </div>
                    </div>

                    <Button className='confirm boxShadow-btn' onClick={() => {
                    }}>{i18n.t('delivery_address_page_text_address_confirmed')}</Button>
                </div>
            </div>
        )
    }

}

export default FiscalInformation;

// **********
// TYPE
// **********

type Props = {
    changeStatus: (val: boolean) => void
};

type State = {
    language: string;
    insertPhone: string;
    insertEmail: string;
    cardInfo: Array<any>;
    isEdiAddressModal: boolean;
    fiscalInformationInfo: {
        country: string;
        vatNumber: string;
        companyName: string;
        city: string;
        zipCode: string;
        fiscalCode: string;
        reciplentCode: string;
        reciplentPEC: string;
        fiscalAddress: string;
    }
}

