import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
class BookStatusButtonComponent extends Component<ButtonComponentProps, ButtonComponentState> {

    /**
     * 0: 未确认
     * 1: 已确认
     * 2: 已履约
     * 3: 未履约
     * 4: 已取消
     */
    private orderStatus = {
        '0': this.props.t('my_reservations_page_status_button_unconfirmed'),
        '1': this.props.t('my_reservations_page_status_button_confirmed'),
        '2': this.props.t('my_reservations_page_status_button_fulfilled'),
        '3': this.props.t('my_reservations_page_status_button_non_performance'),
        '4': this.props.t('my_reservations_page_status_button_cancel')
    }
    constructor(props: ButtonComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // state
        this.state = {
            btnActive: false
        }
    }

    // *********************
    // Function
    // *********************
    /**
     * 根据状态判断当前按钮显示的内容
     */
    getBtnStatus = () => {
        const { status } = this.props;
        if (status) {
            return this.orderStatus[status]
        } else {
            return ''
        }
    }
    // *********************
    // View
    // *********************

    render(this: any) {
        return (
            <Button
                className={`orderStatusBtn`}
                size='small'
                variant='outlined'
            >
                {this.getBtnStatus()}
            </Button>

        )
    }
}

export default withTranslation()<ButtonComponentProps>(BookStatusButtonComponent as any);

// *********************
// States
// *********************

type ButtonComponentProps = {
    /**
     * 订单状态
     * 0: 未确认
     * 1: 已确认
     * 2: 已取消
     * 3: 未赴约
     * 4: 已完成
     * 5: 已过期
     */
    status?: string;
} & WithTranslation

type ButtonComponentState = {
    // 按钮激活状态
    btnActive: boolean
}
