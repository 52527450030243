/**
 * @CE AKclown
 */
import './setting.scss';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { createHashHistory } from 'history';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import TopBar from '../../component/top-bar/top-bar.component';
import i18n, { fillbackWhatsappLngSetting } from '../../i18n';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PrivacyApgreement } from '../../component/privacy-apgreement/privacy-apgreement.componet';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { cleanAccountCache } from '../../constants/tool-check-token';
import apiManage from '../../request';

class SettingsPage extends React.Component<Props, State> {

    public state: State = {
        language: this.props.i18n.language,
        // 显示隐私协议
        isShowPrivacyApgreementDialog: false
    }
    // *********************
    // Service Function
    // *********************

    // 改变语言
    handleChange(evt) {
        const language = evt.target.value;
        this.props.i18n.changeLanguage(language);
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.LANGUAGE,
            value: language
        });

        // 更换语言的时候，在已经登陆的情况下，提交新的语言情况用于whatsapp的消息语言
        const account = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        if (account) {
            apiManage.patch_customer_info({ language: fillbackWhatsappLngSetting(language) }, account._id);
        }
        this.updateState({ language })
    }

    // 改变数据方法
    public updateState(value: any) {
        this.setState({ ...value })
    }

    // 退出登陆 清空所有缓存
    public handleLagout() {
        cleanAccountCache();
        // 跳转到扫码页
        const history = createHashHistory()
        history.push('/')
    }
    // 返回
    public handleBack() {
        const history = createHashHistory()
        history.goBack()
    }

    // *********************
    // View
    // *********************
    render() {
        const { language, isShowPrivacyApgreementDialog } = this.state
        return (
            <div className='settingsPage'>
                <TopBar
                    title={i18n.t('setting_page')}
                    backgroundstyle={'backgroundTransparent'}
                    isHideMenu={true}
                />
                <p className='basic'>{i18n.t('setting_page_text_basic_settings')}</p>
                <ul className='mainContainer'>
                    <li>
                        <div className='title'>
                            <p>{i18n.t('setting_page_text_language')}</p>
                            <p>{i18n.t('setting_page_text_choose_language')}</p>
                        </div>
                        <div className='optionContainer'>
                            <FormControl >
                                <NativeSelect
                                    value={language}
                                    onChange={this.handleChange.bind(this)}
                                >
                                    <option value='IT'>IT</option>
                                    <option value='ZH'>ZH</option>
                                    <option value='EN'>EN</option>
                                    <option value='ES'>ES</option>
                                </NativeSelect>
                            </FormControl>
                            <MdKeyboardArrowDown className='arrowBottomIco' />
                        </div>
                    </li>
                    <li>
                        <div className='title'>
                            <p>{i18n.t('sidebar_page_text_logout')}</p>
                        </div>
                        <p className='logoutBtn' onClick={this.handleLagout}>{i18n.t('sidebar_page_text_logout')}</p>
                    </li>
                    <li className='pricaybox'
                        onClick={() => { this.setState({ isShowPrivacyApgreementDialog: true }) }}>
                        <span>{i18n.t('privacy_agreement_text')}</span>
                        <span className='pricayboxIcon'><ChevronRightIcon /></span>
                    </li>
                </ul>
                {
                    isShowPrivacyApgreementDialog
                        ? <PrivacyApgreement
                            // onConfirm={() => { this.isAgree() }}
                            handleClose={() => { this.setState({ isShowPrivacyApgreementDialog: false }) }}
                        />
                        : null
                }
            </div >
        )
    }
}

export default withTranslation()<Props>(SettingsPage as any)


// *********************
// Types
// *********************


type Props = {
    // ...
} & WithTranslation;

type State = {
    // ...
    language: string;
    isShowPrivacyApgreementDialog: boolean;
};