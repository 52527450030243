import _ from 'lodash';
import errorCode from '../static/error-code';
import i18n from '../i18n';
import Notification from '../component/snackbar/snackbar';


export function showApiMessage(error: Error, module?: Module,
    params?: { message?: string, level?: Level, details?: string }) {
    let showMessage = '';
    // let showDetails = params && params.details ? params.details : undefined;
    const statusCode = _.get(error, 'result.statusCode');
    if (params && params.message) showMessage = params.message;
    else if (/^4/.test(statusCode)) {
        // 判断4XX的错误信息提示
        const code = _.get(error, 'result.code');
        const details = _.get(error, 'result.details');
        const mongoMessage = _.get(error, 'result.name') === 'MongoError' ? _.get(error, 'result.message') : '';
        if (module === 'customerInit') {
            if (code === 1052 && statusCode === 403) {
                // 登录 账号或者密码错误
                showMessage = 'signin_page_text_login_user_identification_error'
            } else if (code === 1046 && statusCode === 403) {
                // google令牌无效
                showMessage = 'signin_page_text_invalid_google_token'
            } else if (code === 1047 && statusCode === 403) {
                // google的令牌不包含email
                showMessage = 'signin_page_text_google_token_does_not_contain_email'
            } else if (code === 1048 && statusCode === 403) {
                // facebook令牌无效
                showMessage = 'signin_page_text_invalid_facebook_token'
            } else if (code === 1049 && statusCode === 403) {
                // facebook的令牌不包含id
                showMessage = 'signin_page_text_facebook_token_does_not_contain_id'
            } else if (code === 'CODE_1003' && statusCode === 403) {
                // 验证码不匹配
                showMessage = 'signin_page_textphone_number_and_verification_code_do_not_match'
            } else if (code === 'CODE_1004' && statusCode === 403) {
                // 验证码已过期（统称为‘手机号和验证码不匹配’）
                showMessage = 'signin_page_textphone_number_and_verification_code_do_not_match'
            } else if (code === 'CODE_1005' && statusCode === 403) {
                // 验证码已使用（统称为‘手机号和验证码不匹配’）
                showMessage = 'signin_page_textphone_number_and_verification_code_do_not_match'
            } else if (code === 1053 && statusCode === 403) {
                // 今天登陆超过20次
                showMessage = 'signin_page_text_you_have_exceeded_the_login_limit_today'
            } else if (code === 1042 && statusCode === 403) {
                // 帐户资源已删除
                showMessage = 'reset_password_page_text_account_does_not_exist'
            }
        } else if (module === 'thridPartlogin') {
            if (code === 1003 && statusCode === 403 && details && details.indexOf('account has not been verified yet') !== -1) {
                // 第三方 首次登录先绑定手机号
                showMessage = 'signin_page_text_bind_phone_number'
            } else if (code === 1003 && statusCode === 403 && details && details.indexOf('code expired') !== -1) {
                // 第三方 绑定手机号验证码过期了
                showMessage = 'general_text_verification_code_expired'
            }
        } else if (module === 'signUp') {
            if (code === 1007 && statusCode === 403) {
                // 注册 账户已存在
                showMessage = 'signup_page_text_account_the_current_phone_number_has_been_registered'
            } else if (code === 1003 && statusCode === 403 && details && details.indexOf('code expired') !== -1) {
                // 注册 验证码过期了
                showMessage = 'general_text_verification_code_expired'
            } else if (code === 1003 && statusCode === 403 && details && details.indexOf('not find account') !== -1) {
                // 注册 验证码不匹配
                showMessage = 'reset_password_page_text_vcode_mismatched'
            }
        } else if (module === 'forgetPassword') {
            if (code === 1001 && statusCode === 404) {
                // 忘记密码 验证码不匹配
                showMessage = 'reset_password_page_text_vcode_mismatched'
            } else if (code === 1003 && statusCode === 403 && details && details.indexOf('code expired') !== -1) {
                // 忘记密码 验证码过期了
                showMessage = 'general_text_verification_code_expired'
            } else if (code === 'CODE_1003' && statusCode === 403) {
                // 忘记密码 验证码不匹配
                showMessage = 'reset_password_page_text_vcode_mismatched'
            }
        } else if (module === 'vcode' || module === 'vCodeEmail') {
            if (module === 'vcode' && code === 'CODE_1001' && statusCode === 403) {
                // 同一个手机号码二十四小时只能获取5次验证码
                showMessage = 'general_text_the_same_mobile_phone_number_can_only_get_five_verification_codes_in_24_hours'
            } else if (module === 'vCodeEmail' && code === 1038 && statusCode === 400) {
                // 同一个邮箱号码二十四小时只能获取5次验证码
                showMessage = 'general_text_the_same_email_number_can_only_get_five_verification_codes_in_24_hours'
            } else if (code === 1007 && statusCode === 403) {
                // 验证码的判断
                showMessage = 'signup_page_text_account_the_current_phone_number_has_been_registered'
            } else if (code === 1001 && statusCode === 404) {
                // 验证码和手机号不匹配
                showMessage = 'signin_page_textphone_number_and_verification_code_do_not_match'
            } else if (code === 'CODE_1003' && statusCode === 403) {
                // 验证码和手机号不匹配
                showMessage = 'signin_page_textphone_number_and_verification_code_do_not_match'
            } else if (code === 'CODE_1002' && statusCode === 403) {
                // 验证码不能连续发送，请稍等90秒
                showMessage = 'general_text_the_verification_code_cannot_be_sent_continuously_and_please_wait_for_90_seconds'
            } else if (code === 'CODE_1004' && statusCode === 403) {
                // 登录验证码已过期
                showMessage = 'general_text_verification_code_expired'
            } else if (code === 'CODE_1006' && statusCode === 403) {
                // 该区域未开通验证码功能
                showMessage = 'signin_page_text_the_verification_code_function_is_not_activated_in_this_area'
            }
        } else if (code === 11000 && statusCode === 422 && mongoMessage && mongoMessage.indexOf('duplicate key') !== -1) {
            // 账号是否已存在的判断
            showMessage = 'signup_page_text_account_the_current_phone_number_has_been_registered'
        } else if (module === 'coupon') {
            if (code === 1015 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_can_only_be_collected_once'
            } else if (code === 1016 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_has_not_been_used'
            } else if (code === 1017 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_does_not_exist'
            } else if (code === 1001 && statusCode === 404) {
                showMessage = 'coupon_text_coupon_does_not_exist'
            } else if (code === 1018 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_sold_out'
            } else if (code === 1019 && statusCode === 422) {
                showMessage = `coupon_text_old_customers_can_Receive`
            } else if (code === 1020 && statusCode === 422) {
                showMessage = 'coupon_text_new_customers_can_Receive'
            } else if (code === 1022 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_has_expired'
            } else if (code === 1024 && statusCode === 422) {
                showMessage = 'coupon_text_integral_are_not_enough'
            } else if (code === 1025 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_total_consumption_is_not_reached'
            } else if (code === 1026 && statusCode === 422) {
                showMessage = 'coupon_text_coupon_not_enough_orders_num'
            } else if (code === 1033 && statusCode === 422) {
                if (details.order_frequency_type === '0') {
                    showMessage = i18n.t('coupon_text_Unfulfilled_orders_placed_in_the_last_months',
                        { 1: details.order_frequency_value })
                } else if (details.order_frequency_type === '1') {
                    showMessage = i18n.t('coupon_text_Unsatisfied_No_order_has_been_placed_in_the_last_3_months',
                        { 1: details.order_frequency_value })
                }
            } else if (code === 1035 && statusCode === 422) {
                showMessage = i18n.t('coupon_text_status_is_unavailable')
            } else if (code === 'COUPON_1005' && statusCode === 422) {
                showMessage = 'coupon_text_code_Incompatible_with_the_rules'
            } else if (code === 1036 && statusCode === 422) {
                showMessage = 'coupon_text_the_order_was_not_completed_when_the_coupon_was_in_use'
            } else if (code === 1059 && statusCode === 422) {
                showMessage = `${i18n.t('coupon_text_unsatisfied')}：${i18n.t('coupon_Text_rules_Orders_exceeding_X_amount_placed_within_Y_days', { x: details.achieve_order_money_day, y: details.achieve_order_money })}`;
            } else {
                // 未找到对应错误编码，提示优惠券不存在
                showMessage = 'coupon_text_coupon_does_not_exist'
            }
        } else if (module === 'booking') {
            if (code === 'RES_1022' && statusCode === 400) {
                showMessage = 'reservation_page_request_failure_text_the_scheduled_time_needs_to_be_within_the_bookable_time_range'
            } else if (code === 'RES_1007' && statusCode === 400) {
                showMessage = 'reservation_page_request_failure_text_Reservations_for_restaurants_are_not_valid'
            } else if (code === 'BOOKINGORDER_1002' && statusCode === 422) {
                showMessage = i18n.t('reservation_page_request_failure_text_The_same_account_number_up_to_x_pre_orders',
                    { x: 10 })
            } else if (code === 'RES_1018' && statusCode === 400) {
                showMessage = i18n.t('reservation_page_request_failure_text_restaurant_booking_service_is_temporarily_closed')
            } else if (code === 'RES_1027' && statusCode === 400) {
                showMessage = i18n.t('general_text_the_restaurant_does_not_open_test_mode')
            } else if (code === 'BOOKINGORDER_1022' && statusCode === 403) {
                showMessage = i18n.t('reservation_page_The_reservation_period_is_full_Please_choose_a_different_time_period')
            }
        } else {
            const index = errorCode.findIndex(target => target.code === code && target.statusCode === statusCode)
            if (index !== -1) {
                showMessage = errorCode[index].message
                // showDetails = details;
            }
            else showMessage = 'request_failure_text_operation_failed'
        }
    }

    if (showMessage) {
        Notification.notification(i18n.t(`${showMessage}`));
    }
}

type Error = {
    error: boolean,
    status: number,
    result: any
}

type Level = 'Warning' | 'Error' | 'Default' | 'Info' | 'Success'

type Module = 'customerInit' | 'thridPartlogin' | 'vcode' | 'signUp' | 'forgetPassword' | 'coupon' | 'booking' | 'vCodeEmail'