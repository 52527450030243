import '../../component/my-order/my-order.style.scss';
import './my-reservations.styles.scss';
import _ from 'lodash';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import CategoryPanelComponent from '../../component/my-order/category-panel.component';
import SwitchStatusComponent from '../../component/my-order/switch-status.component';
import Scroll from '../../component/scroll/scroll.component';
import TopBar from '../../component/top-bar/top-bar.component';
import { BookingOrder, Restaurant } from '../../models';
import apiManage from '../../request';
import Store from '../../rudex/store';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { createHashHistory } from 'history';

@inject_unount
class MyReservations extends React.Component<Props, State> {
    private orderStatus: any = []
    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
            pendingOrder: [],
            historyOrder: [],
            pag_per_page: 5,
            pag_page: 1,
            currentOrderStatus: 'ALL'
        }
        // 状态：ALL:全部 (0:未确认、1:已确认、2:已履约、3:未履约、4:已取消)
        this.orderStatus = [{
            text: 'my_reservations_page_text_all',
            value: 'ALL'
        }, {
            text: 'my_reservations_page_status_button_unconfirmed',
            value: '0'
        }, {
            text: 'my_reservations_page_status_button_fulfilled',
            value: '2'
        }, {
            text: 'my_reservations_page_status_button_cancel',
            value: '4'
        }]
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        // 检查是否登陆
        if (this.checkToken()) {
            this.getOrder()
        }
    }

    // *********************
    // 方法区
    // *********************
    /**
     * 检查token
     */
    checkToken = () => {
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)
        if (!_.get(token, 'access_token')) {
            Notification.notification(i18n.t('order_page_text_after_login_view'))
            setTimeout(() => {
                createHashHistory().push('/');
            }, 1000);
            return false;
        }
        return true;
    }
    /**
     * 获取订单
     */
    getOrder = async (params?: any, config?: any) => {
        try {
            const { currentOrderStatus } = this.state
            const result: any = await apiManage.getCustomerBookOrder({
                status: currentOrderStatus === 'ALL' ? undefined : currentOrderStatus,
                pag_per_page: this.state.pag_per_page,
                pag_page: this.state.pag_page,
                sort_value: -1,
                ...params
            }, config);
            if (result.error) {
                const code = _.get(result, 'result.code');
                const statusCode = _.get(result, 'result.statusCode');
                if (code === 1003 && statusCode === 403) {
                    Notification.notification(i18n.t('order_page_text_after_login_view'))
                    setTimeout(() => {
                        createHashHistory().push('/');
                    }, 1000);
                }
                throw new Error('');
            }
            const orders: FixedBookingOrder[] = result.dbResults || [];
            const cloneOrders = _.cloneDeep(orders)
            // 把历史订单提取出来
            let historyOrder = _.remove(orders,
                (item) => {
                    if (item && item.booking_orders && item.booking_orders.origin_status) {
                        return item.booking_orders.origin_status === '2' || item.booking_orders.origin_status === '4'
                    } else return false;
                })
            // 如何是不是第一页的数据，则把数据添加到数组中，如果是一页，就重新替换新数组
            const pendingOrder = params && params.pag_page > 1 ? this.state.pendingOrder.concat(orders) : orders;
            historyOrder = params && params.pag_page > 1 ? this.state.historyOrder.concat(historyOrder) : historyOrder;
            this.setState({
                pendingOrder,
                historyOrder
            })
            // 用于页码判断
            return { orders: cloneOrders };
        } catch (error) {
            return {
                pendingOrder: [],
                historyOrder: []
            }
        }

    }
    // *********************
    // 视图区
    // *********************

    render() {
        const { pendingOrder, historyOrder, currentOrderStatus, pag_page } = this.state
        return (
            <div className='myOrderPage myReservations'>
                <TopBar
                    title={i18n.t('my_reservations_page_text')}
                >
                </TopBar>
                <div className='myOrderPageContainer'>
                    <div className='switchStatusBox'>
                        <SwitchStatusComponent
                            statusData={this.orderStatus}
                            onClick={(val: string) => {
                                this.setState({
                                    pendingOrder: [],
                                    historyOrder: [],
                                    currentOrderStatus: val,
                                    pag_page: 1
                                }, () => {
                                    this.getOrder()
                                })
                            }}
                        />
                    </div>
                    {
                        (pendingOrder.length > 0 || historyOrder.length > 0)
                            ? <div className='wrappper'>
                                <Scroll
                                    data={currentOrderStatus === 'ALL' ?
                                        pendingOrder.concat(historyOrder) : historyOrder}
                                    options={{
                                        pullDownRefresh: true,
                                        pullUpLoad: true
                                    }}
                                    pullDownRefresh={() => {
                                        this.getOrder({ pag_page: 1 }, { notNeedLoading: true })
                                        this.setState({
                                            pag_page: 1
                                        })
                                    }}
                                    pullUpLoad={async () => {
                                        const result = await this.getOrder({
                                            pag_page: pag_page + 1
                                        }, { notNeedLoading: true })
                                        const concatResult = result.orders || [];
                                        // 当有数据返回的时候才需要把页码加1
                                        this.setState({
                                            pag_page: concatResult.length > 0 ? pag_page + 1 : pag_page
                                        })
                                        return concatResult.length > 0 ? true : false
                                    }}
                                >
                                    <div className='categoryWrapper'>
                                        {/* 进行中的预定订单 */}
                                        {(currentOrderStatus === '0' || currentOrderStatus === 'ALL') ?
                                            <CategoryPanelComponent
                                                title={'order_page_text_pending'}
                                                panelData={pendingOrder}
                                                type={'myReservations'}
                                            /> : null}
                                        {/* 历史的预定订单 */}
                                        {(currentOrderStatus !== '0') ?
                                            <CategoryPanelComponent
                                                title={'order_page_text_history'}
                                                panelData={historyOrder}
                                                type={'myReservations'}
                                            /> : null}
                                    </div>
                                </Scroll>
                            </div> :
                            _.get(Store.getState(), 'fetch') && _.get(Store.getState(), 'fetch').isFetching ?
                                null :
                                <div className='noFoundData'>{i18n.t('order_page_text_no_order_found')}</div>
                    }
                </div>
            </div>
        );
    }

}

export default MyReservations;

// *********************
// Types
// *********************

type State = {
    // 进行中的订单
    pendingOrder: Array<FixedBookingOrder>
    // 历史订单
    historyOrder: Array<FixedBookingOrder>
    // 每页显示条数
    pag_per_page: number
    // 页码
    pag_page: number
    // 当前需要展示的订单状态
    currentOrderStatus: string | undefined;
}

type Props = {
} & WithTranslation

interface FixedBookingOrder {
    belonged_restaurant: Restaurant,
    booking_orders: BookingOrder
}