import './App.css';

import _ from 'lodash';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import Iframe from 'react-iframe';
import AppPage from './page/app.page';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IApp, IAppState, IAppProps } from './interfaces/app.interface';
import { GOOGLE_RE_CAPTCHA_V3_KEY } from './gconfig';

export class App extends React.Component<Props, State> implements IApp {

	render() {
		return (
			// 手机版的或者已经有iframe的，则不需要再投iframe了
			(isMobileOnly || window.self !== window.top) || !_.get(window.top, 'location.href') ?
				<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RE_CAPTCHA_V3_KEY}>
					<AppPage></AppPage>
				</GoogleReCaptchaProvider>
				:
				<div className='laytoutCenter'>
					<Iframe
						url={_.get(window.top, 'location.href')}
						// url={PREURI}
						id='myId'
						className='ignore_iframe'
						frameBorder={0}
						display='block' />
				</div>
		);
	}
}

interface State extends IAppState { }

interface Props extends IAppProps { }

export default App;
