
import { Chip, Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import { orderHeaderDatatype } from '../../page/restaurant/restaurant-page'
import CloseIcon from '@material-ui/icons/Close';
import ReactMarkdown from 'react-markdown';
import './restaurant-info-dialog.style.scss'

// 餐管简介弹窗
export function RestaurantInfoDialog(
    props: {
        className?: string;
        open: boolean;
        handleClose: () => void;
        orderHeaderData: orderHeaderDatatype;
        Chipdata: Array<string>;
        description: string;
        restaurantlogo: string;
    }
) {
    const { open, handleClose, orderHeaderData, Chipdata, description, className, restaurantlogo } = props
    return (
        <Dialog className={`restaurantInfo ${className || ''}`}
            maxWidth='md' fullWidth={true} aria-labelledby='simple-dialog-title' open={open} onClose={handleClose}>
            <div className='closeIcon'>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div >
            <div className={'dialogbody'}>
                <div>
                    <div className='imgbox'>
                        <img
                            className='restaurant-logo'
                            src={restaurantlogo} alt=''
                        />
                    </div>
                    <div className='restaurantname'>
                        <p>{orderHeaderData.title}</p>
                        <span>{orderHeaderData.subtitle}</span>
                    </div>
                    <div className='chipbox'>
                        {Chipdata && Chipdata.map((value, index) => {
                            return <Chip key={index} size='small' label={value}></Chip>
                        })}
                    </div>
                </div>
                <div className='introductiontext'>
                    <ReactMarkdown className='markdown markdown1' source={description} escapeHtml={false} />
                </div>
            </div>
        </Dialog>
    )
}