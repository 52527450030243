/**
 * @author liweikang
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import OrderCardComponent from './order-card.component';
import _ from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createHashHistory } from 'history';
class CategoryPanelComponent extends React.Component<Props, State> {

    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {

    }

    // *********************
    // 方法区
    // *********************

    // *********************
    // 视图区
    // *********************

    render() {
        const { title, panelData, t, type } = this.props;
        return (
            <div className='categoryPanel'>
                <h1 className='title'>{t(title, { 1: panelData.length })}</h1>
                {panelData.map((target, index) => {
                    return (
                        <OrderCardComponent key={index} cardData={target} type={type}
                            onClick={(order: any) => {
                                if (type === 'myReservations') {
                                    const booking_order_id = _.get(order, 'booking_orders.origin_id');
                                    createHashHistory().replace(`/my-reservations-detail-page/${booking_order_id}`)
                                } else {
                                    const stringId = _.get(order, 'belonged_restaurant.string_id');
                                    const id = _.get(order, 'orders.origin_id');
                                    const status = _.get(order, 'orders.origin_status');
                                    createHashHistory().replace(`/my-order-detail-page/${stringId ? stringId : 'a'}/${id ? id : 'a'}/${status ? status : '0'}`)
                                }
                            }} />
                    )
                })}
            </div>
        );
    }

}

export default withTranslation()<Props>(withRouter(CategoryPanelComponent as any) as any) as any;

// *********************
// Types
// *********************

type State = {
}

type Props = {
    // 分类面板标题
    title: string;
    // 面板展示数据
    panelData: Array<any>;
    // 类型 - myReservations:我的预定
    type?: string;
} & WithTranslation & RouteComponentProps
