import _ from 'lodash';
import { Order } from '../models';
import i18n from '../i18n';

/**
 * 获取付款方式 货到付款
 * @param type  1 pos 0 cash
 */
export const getPaymentTypeCOD = (type: string) => {
    const paymentText = new Map([
        // pos
        ['1', 'POS'],
        // 现金
        ['0', 'order_page_cash']
    ]);
    const text = paymentText.get(type) ?? '';
    return text
}

/**
 * 获取付款方式 Deliverect平台
 * @param type 支付方式类型
 */
export const getPaymentTypeByDeliverect = (type: number) => {
    const statusText = new Map([
        // 刷卡
        [0, 'order_page_credit_card_online'],
        // 现金
        [1, 'order_page_cash'],
        // 货到付款
        [2, 'order_page_on_delivery'],
        // 线上
        [3, 'order_page_online'],
        // 货到刷卡付款
        [4, 'order_page_credit_card_at_door'],
        // 货到PIN码付款
        [5, 'order_page_pin_at_door'],
        // 货到餐券付款
        [6, 'order_page_voucher_at_door'],
        // 餐券
        [7, 'order_page_meal_voucher'],
        // Bancontact 支付平台
        [8, 'order_page_bank_contact'],
        // 其他
        [9, 'order_page_other']
    ]);
    const text = statusText.get(type) || '';
    return text
}

/**
 * 获取付款方式 MOP平台
 * @param type 支付方式类型 'AUTO' | 'PAX' | 'CASHIER' | 'VIVA'
 */
export const getPaymentTypeByMop = (type: string) => {
    const paymentText = new Map([
        // 收银台
        ['CASHIER', 'order_page_cashier_payment'],
        // 自动收款机
        ['AUTO', 'order_page_cashmatic_automatic_cash_registert'],
        // PAX 刷卡机
        ['PAX', 'PAX POS'],
        // VIVA POS
        ['pos', 'Viva POS'],
        // VIVA QR code
        ['qrcode', 'QR code'],
        // kiosknfc
        ['kiosknfc', 'Kiosk NFC'],
        // GLORY 自助收款机
        ['GLORY', 'GLORY'],
        // VNE 自助收款机
        ['VNE', 'VNE'],
    ]);
    const text = paymentText.get(type) ?? type;
    return text
}
/**
 * 付款方式文字转换
 * @param order
 */
export const getPaymentText = (order: Order) => {
    let payment = '';
    const payment_type = _.get(order, 'payment_type');
    // D平台付款方式
    const paymentType = _.get(order, 'payment_detail.type', 0);
    // 订单隶属的程序
    const order_origin_program = _.get(order, 'order_origin_program', '');
    // 付款方式
    if (payment_type || `${payment_type}` === '0') {
        if (order?.order_creation_origin === 'OD_MOP') {
            // viva 付款方式
            const vivaType = _.get(order, 'payment_detail.viva_type', '');
            // MOP订单  
            payment = getPaymentTypeByMop(paymentType === 'VIVA' ? vivaType : paymentType);
        }
        else if (order_origin_program === 'Deliverect') {
            // Deliverect平台订单
            payment = getPaymentTypeByDeliverect(paymentType);
        } else {
            // 线下付款
            const paymentCODType = _.get(order, 'payment_detail.paymentCODType', '');
            // OD 订单
            payment = `${payment_type}` === '0' ? i18n.t('order_page_text_online_payment') : getPaymentTypeCOD(paymentCODType);
        }
    }
    return payment;
}

