import { Typography } from '@material-ui/core';
import React from 'react';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import i18n from '../../i18n';
import './temporary-closed.scss';
import closedCNLogo from '../../static/images/closed-cn.png';
import closedENLogo from '../../static/images/closed-en.png';
import closedITLogo from '../../static/images/closed-it.png';

class TemporaryClosedComponent extends React.Component<Props, State> {

    /**
     * 显示对应的图片
     */
    getImg() {
        const language = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE);
        let img = '';
        switch (language) {
            case 'ZH':
                img = closedCNLogo;
                break;
            case 'EN':
                img = closedENLogo;
                break;
            case 'IT':
                img = closedITLogo;
                break;
            default:
                img = closedENLogo;
                break;
        }
        return img;
    }

    // ****************
    // VIEW
    // ****************

    public render() {
        const { nextOpenTimeText, time, showPage } = this.props;
        const temporaryClosedView = showPage.includes('TemporaryClosed');
        return (
            <div className='temporary-closed-com'>
                <div className='content'>
                    <Typography variant={'subtitle1'} className='text'>
                        {temporaryClosedView
                            ? i18n.t('checkout_page_text_temporary_closed')
                            : i18n.t('payment_page_text_close_down')}
                    </Typography>
                    <img alt='' src={this.getImg()} className='img' />
                    {temporaryClosedView ? <>
                        <Typography variant={'subtitle1'} className='in'>
                            {i18n.t('checkout_page_text_in')}
                        </Typography>
                        <span className='time'>
                            {time}
                        </span>
                    </> : <>
                        <Typography variant={'subtitle1'} className='in'>
                            {i18n.t('restaurant_order_page_text_The_next_business_hours_are')}
                        </Typography>
                        <span className='time'>
                            {nextOpenTimeText}
                        </span>
                    </>}
                </div>
                <div className='tail-bar'>
                    <Typography variant={'subtitle1'} className='text'>
                        {i18n.t('checkout_page_text_does_not_affect_reservation_order')}
                    </Typography>
                </div>
            </div>
        )
    }
}

export default TemporaryClosedComponent;

// ***************
// TYPE
// ***************

type State = {
};

type Props = {
    // 倒计时的时间
    time: string;
    // 下个营业时间文本
    nextOpenTimeText?: string;
    // 停业弹窗显示内容 停业'closed', 临时停业'TemporaryClosed', 优惠券'coupon'
    showPage: string[];
};