/**
 * 显示高清图片
 * - 截掉 ?x-oss-process=image/resize,w_300
 * @param url 地址
 */
export function showHDPic(url: string): string {
    try {
        if (url) {
            url = url.replace('?x-oss-process=image/resize,w_300', '')
        }
    } catch (error) { }
    return url
}