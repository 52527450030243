import _ from 'lodash';
import moment from 'moment';
import { businessSchedule, getTargetDateClosedSchedule } from '../component/restaurant/schedule-dialog.component';
import { Schedule } from '../models';

/**
 * 分析营业时间表和闭店时间表，返回新的营业时间表
 */
export function updateScheduleData(serviceSchedule, settingClosedSchedule) {
    try {
        let newScheduleData = _.cloneDeep(serviceSchedule);
        const todyIsoWeekday = moment(new Date()).isoWeekday();
        // 排序（今天放在第一位）
        newScheduleData = newScheduleData.sort((n, m) => {
            const position = (n.week - todyIsoWeekday) < 0 ? n.week + 7 : n.week;
            const nextPosition = (m.week - todyIsoWeekday) < 0 ? m.week + 7 : m.week;
            return position - nextPosition;
        })
        // 设置新的营业时间表数据
        newScheduleData = newScheduleData.map((n, index) => {
            // 周几
            const todyIsoWeekday = moment(new Date()).add(index, 'day').isoWeekday();
            if (n.week === todyIsoWeekday) {
                // 年月日
                const targetDate = moment(new Date()).add(index, 'day').format('YYYY-MM-DD')
                // 目标日期的营业时间段
                const dateTimeRanges: Schedule = _.find(newScheduleData, (item) => { return item.week === todyIsoWeekday }) || {};
                // 目标日期的营业时间段（是否是全体营业）
                const timeRanges = dateTimeRanges.time_24_hours ? [{
                    start_time: '00:00:00',
                    end_time: '23:59:00',
                    selftake_status: dateTimeRanges.selftake_status,
                    takeaway_status: dateTimeRanges.takeaway_status
                }] : (dateTimeRanges.time_ranges || [])
                // 目标日期，在休业时间内，则24小时开关为false
                const hasClose = _.find(settingClosedSchedule, (v) => {
                    return moment(targetDate, 'YYYY-MM-DD').isBetween(
                        moment(v.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS),
                        moment(v.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS),
                        'day',
                        '[]'
                    )
                })
                if (hasClose) {
                    _.set(n, 'time_24_hours', false)
                }
                // 最终显示的营业时间表（对应的周几）
                const finalTimeRanges = businessSchedule(timeRanges, getTargetDateClosedSchedule(targetDate, settingClosedSchedule))
                _.set(n, 'time_ranges', finalTimeRanges)
            }
            return n;
        })
        // 数组恢复原来的位置（周一第一位）
        newScheduleData = newScheduleData.sort((n, m) => {
            return n.week - m.week;
        })
        return newScheduleData
    } catch (error) {
        console.log(error);
    }
}