/* eslint-disable prefer-const */
/**
 * @CE AKclown
 */
import './payment.componet.style.scss';
import './blackGoldThemeComponent/blackgoldtheme.payment.style.scss'

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createHashHistory } from 'history';
import _ from 'lodash';
import momentTimeZone from 'moment-timezone';
import React from 'react';
import {
    MdAccessTime,
    MdClear,
    MdDone,
    MdError,
    MdFace,
    MdKeyboardArrowRight,
    MdRingVolume,
    MdRoom,
    MdPhoneIphone,
    MdRecentActors
} from 'react-icons/md';
import { DT } from '../../constants/tool-constant';
import i18n from '../../i18n';
import { Address, Customer, Restaurant } from '../../models';
import apiManage from '../../request';
import errorCode from '../../static/error-code';
import getRelatedCode from '../../static/international-code';
// import { GoogleMapInput } from '../googlemap-input/googlemap-input.component'
import { Check, Clear } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { checkVersion } from '../../constants/tool-check-resource';
import { loadStripe } from '@stripe/stripe-js';
import { DefaultPaymentBoxContent } from './default-payment-box-content.component';
import { BlackGoldPaymentBoxContent } from './blackGoldThemeComponent/black-gold-payment-box-content.component';
import { RestaurantTheme, RestaurantThemeColor } from '../../constants/tool-restaurant-theme';
import { FreeModeCustomerInfo } from '../../page/payment/payment.page';
import VerificationComponent from '../verification-input.component';
import { IS_CUSTOMER_NAME, IS_PHONE } from '../../constants/base.constant';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import '../../static/country-style/country-select.scss'
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
// import GoogleMapComponent from '../googlemap-component/google-map';
import GoogleMapComponent from '../googlemap-search/google-search';

// 付款侧边流程
export function PaymentSidebar(
    props: {
        stepIndex: number,
        loginFreeMode: boolean,
        isPickUp: boolean
    }
) {
    const { stepIndex, loginFreeMode } = props;
    return (
        <div className='paymentSidebar'>
            {loginFreeMode &&
                <>
                    <MdPhoneIphone className={stepIndex > 0 ? 'initIco suspendedBox' : 'initIco'} />
                    <span className='line'></span>
                </>
            }
            <MdAccessTime className={stepIndex > 1 ? 'initIco suspendedBox' : 'initIco'} />
            <span className='line'></span>
            <MdRecentActors className={stepIndex > 2 ? 'initIco suspendedBox' : 'initIco'} />
            <span className='line'></span>
            <MdDone className={stepIndex > 3 ? 'initIco suspendedBox' : 'completeIco'} />
        </div>
    )
}

// 新建地址
export function CreateAddress(
    props: {
        refreshAddress: () => void;
        handleClose: () => void;
        customerInfo: Customer;
        modiftAddress: Address;
        addressList: Array<Address>;
        restaurantTheme: RestaurantTheme;
        loginFreeMode?: boolean;
        handelFreeModeAddress?: (action: string, address?: any) => void;
        freeModeCustomerInfo?: FreeModeCustomerInfo;
        restaurantPosition?: { lat: number, lng: number };
        handleChoose?: (addressList: Array<Address>) => void;
        type?: string;
    }
) {
    // 根据浏览器当前语言，自动识别（若识别出错，则默认IT）
    const browserPhoneCode = getBrowserPhoneCode()
    // 初始化数据,这里也有可能是修改地址
    const FLAG = Object.keys(props.modiftAddress).length > 0 ? true : false;
    const [checkedA, setChecked] = React.useState(FLAG ? props.modiftAddress.default : true);
    // 登录者信息
    const cacheAccount = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
    const account_first_name = _.get(cacheAccount, 'first_name');
    const account_last_name = _.get(cacheAccount, 'last_name');
    const account_phone = _.get(cacheAccount, 'phone');
    const account_phone_prefix = _.get(cacheAccount, 'phone_prefix');
    // 账户没有姓名和手机，则勾选'代替收件人',不可取消勾选。提示'账户信息不完整'
    const noAccountName = !account_first_name || !account_last_name || !account_phone;

    // 是否使用登录号码作为配送联系人
    // const isFirstNameSame = account_first_name === props.modiftAddress.first_name;
    // const isLastNameSame = account_last_name === props.modiftAddress.last_name;
    // const isPhoneSame = account_phone === props.modiftAddress.phone;
    // const isPhonePrefixSame = account_phone_prefix === props.modiftAddress.phone_prefix;
    const [contacts, changeContacts] = React.useState(
        FLAG ?
            (account_phone === props.modiftAddress.phone && account_phone_prefix === props.modiftAddress.phone_prefix)
            :
            true
    );
    // const [stressInput, setStressInput] = React.useState(FLAG ? props.modiftAddress.street : '');
    const [googleInputValue, setgoogleInput] = React.useState(FLAG ? props.modiftAddress.street : '');
    const [ZipCodeInput, setZipCodeInput] = React.useState(FLAG ? props.modiftAddress.code : '');
    const [isCompleteInfo, setIsCompleteInfo] = React.useState(true);
    const [detailsInput, setDetailsInput] = React.useState(FLAG ? props.modiftAddress.details : '');
    const [cityInput, setCityInput] = React.useState(FLAG ? props.modiftAddress.city : '');
    const [provinceInput, setProvinceInput] = React.useState(FLAG ? props.modiftAddress.state : '');
    const [countryInput, setCountryInput] = React.useState(FLAG ? props.modiftAddress.country : '');
    const [homeNumberInput, setHomeNumberInput] = React.useState(FLAG ? props.modiftAddress.home_number : '');
    const [doorNumberInput, setDoorNumberInput] = React.useState(FLAG ? props.modiftAddress.door_number || '' : '');
    // 谷歌地址输入框初始值
    let [googleMapFormattedAddress] = React.useState(FLAG ? props.modiftAddress.google_map_formatted_address : '');
    let phone = _.get(props.modiftAddress, 'phone', '');
    let [phoneNumber, setPhoneNumber] = React.useState(phone);
    // 获取到手机前缀
    const pre = _.get(props.modiftAddress, 'phone_prefix', browserPhoneCode);
    let [prePhone, setPrePhone] = React.useState(pre);

    const first = _.get(props.modiftAddress, 'first_name', '');
    let [firstName, setFirstName] = React.useState(first);
    const last = _.get(props.modiftAddress, 'last_name', '');
    let [lastName, setLastName] = React.useState(last);
    // 错误提示
    let [isError, setErrorPromptBoxConfig] = React.useState({
        message: '',
        flag: false,
        closeFlag: true
    })
    // googlemap
    // const [isshowGoogleMap, onShowGoogleMap] = React.useState(false)
    // 当前地址
    // const [currentLocationInfo, onChangGoogleMap] = React.useState()
    // 当前地点place_id  google_map_lat google_map_lng
    let [lat, setCurrentlat] = React.useState(FLAG ? props.modiftAddress.google_map_lat : undefined)
    let [lng, setCurrentlng] = React.useState(FLAG ? props.modiftAddress.google_map_lng : undefined)
    let [place_id, setCurrentplaceId] = React.useState(FLAG ? props.modiftAddress.google_map_place_id : '')
    let [formatted_address, setCurrentformatted] = React.useState(FLAG ? props.modiftAddress.google_map_formatted_address : '')

    // 是否为从列表选择的地址
    let [isGoogleAddress, setIsGoogleAddress] = React.useState(props.modiftAddress ? true : false);
    // 列表选择的邮编
    let [isGoogleCodeInput, setIsGoogleCodeInput] = React.useState('');
    // 输入框输入的地址
    let [googleInputVal, setGoogleInputVal] = React.useState('');
    React.useEffect(() => {
        // 账户没有名字，在付款页不限制
        if (props.type !== 'payment' && noAccountName) {
            changeContacts(false)
        }
        // 在免注册模式下
        if (props.loginFreeMode) {
            // 判断迷你账户个人信息，是否与地址个人信息一致
            const { first_name, last_name, phone_prefix, phone } = props.freeModeCustomerInfo || {};
            const sameFirst = first_name === props.modiftAddress.first_name;
            const sameLat = last_name === props.modiftAddress.last_name;
            const samePhone = phone === props.modiftAddress.phone;
            const samePrefix = phone_prefix === props.modiftAddress.phone_prefix;
            changeContacts(FLAG ? sameFirst && sameLat && samePhone && samePrefix : true)
        }
    }, [FLAG, noAccountName, props.freeModeCustomerInfo, props.loginFreeMode, props.modiftAddress.first_name, props.modiftAddress.last_name, props.modiftAddress.phone, props.modiftAddress.phone_prefix, props.type])
    // $ 创建地址和修改地址
    function handleCreateAddress() {
        const { handleChoose } = props
        // 如果使用登录号码作为配送联系人
        if (contacts) {
            const { first_name, last_name, phone_prefix, phone } = props.freeModeCustomerInfo || {};
            if (props.loginFreeMode) {
                phoneNumber = phone || '';
                prePhone = phone_prefix || browserPhoneCode;
                lastName = last_name || '';
                firstName = first_name || '';
            } else {
                phoneNumber = account_phone;
                prePhone = account_phone_prefix;
                lastName = noAccountName ? last_name : account_last_name;
                firstName = noAccountName ? first_name : account_first_name;
            }
        }

        // 填写信息是否为空，不为空不显示提示框
        if (googleInputValue?.trim() !== '' && ZipCodeInput?.trim() !== '' && cityInput?.trim()
            !== '' && provinceInput?.trim() !== '' && phoneNumber?.trim() !== '' && doorNumberInput?.trim() !== '' && IS_PHONE(prePhone, phoneNumber) && firstName?.trim()
            !== '' && lastName?.trim() !== '' && homeNumberInput?.trim() !== '' && countryInput?.trim() !== '' && isGoogleAddress) {

            // 是否信息完整
            setIsCompleteInfo(true);
            // 拷贝一份全部地址数据
            let newAddressList: any = _.cloneDeep(props.addressList);
            // 新增添加的数据
            newAddressList = _.isArray(newAddressList) ? newAddressList : [];
            // 判断是否是设置为默认地址,如果是遍历原来的数组,将default属性设置为false

            if (checkedA) {
                newAddressList = newAddressList.map(item => {
                    item.default = false;
                    return item;
                })
            }
            // 判断是增加还是修改, 是修改删除掉这个数据
            if (Object.keys(props.modiftAddress).length > 0) {
                const index = _.get(props, 'modiftAddress.index', 0);
                newAddressList.splice(index, 1);
            }
            const newAddress = {
                default: checkedA,
                details: detailsInput,
                city: cityInput,
                street: googleInputValue,
                home_number: homeNumberInput,
                door_number: doorNumberInput,
                state: provinceInput,
                country: countryInput,
                code: ZipCodeInput,
                first_name: firstName,
                last_name: lastName,
                nickname: `${firstName}${lastName}`,
                phone: phoneNumber,
                phone_prefix: prePhone,
                google_map_place_id: place_id,
                google_map_formatted_address: formatted_address,
                google_map_lat: lat,
                google_map_lng: lng
            }
            newAddressList.push(newAddress)
            newAddressList.map((v) => {
                v.isWithin = undefined
                v.line = undefined
                return v
            })
            const params = {
                address: newAddressList
            }
            // 免注册模式
            if (props.loginFreeMode) {
                props.handelFreeModeAddress?.('ADD', newAddress)
            } else {
                const ID = _.get(props, 'customerInfo._id', '');
                createCustomerAddress(params, ID);
            }


            // 默认地址 是否灰掉确定按钮
            if (typeof handleChoose === 'function') handleChoose(newAddressList)

        }
        else if (!isGoogleAddress) {
            // 选取的谷歌地址有变动错误
            document.getElementById(`google-address-input`)?.scrollIntoView();
            return
        }
        else {
            document.getElementById(`google-address-input`)?.scrollIntoView();
            // 信息不完整显示红色字体
            setIsCompleteInfo(false);
        }
    }
    /**
     * 删除地址
     */
    function handleDelAddress() {
        const { handleChoose } = props
        try {
            if (Object.keys(props.modiftAddress).length > 0) {
                // 拷贝一份全部地址数据
                let newAddressList: any = _.cloneDeep(props.addressList);
                newAddressList = _.isArray(newAddressList) ? newAddressList : [];
                const index = _.get(props, 'modiftAddress.index', 0);
                // 清掉isWithin、line字段，后端不需要
                newAddressList.map((v) => {
                    v.isWithin = undefined
                    v.line = undefined
                    return v
                })
                // 找的需要删除地址的index
                newAddressList.splice(index, 1);
                const params = {
                    address: newAddressList
                }
                // 免注册模式
                if (props.loginFreeMode) {
                    props.handelFreeModeAddress?.('DEL')
                } else {
                    const ID = _.get(props, 'customerInfo._id', '');
                    createCustomerAddress(params, ID);
                }
                // 是否灰掉确定按钮
                if (typeof handleChoose === 'function') handleChoose(newAddressList)
            }
        } catch (error) {
        }

    }
    // 调用创建地址api
    async function createCustomerAddress(params: Customer, id: string) {
        try {
            const result = await apiManage.patch_customer_info(params, id);
            // 这里需要做一个错误代码提示
            if (_.get(result, 'error')) {
                // 创建失败弹窗 关闭加载框、打开失败窗口
                const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
                if (errorTip.length > 0) {
                    // 有对应错误编码
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t(errorTip[0].message)}`,
                        closeFlag: true
                    });
                } else {
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t('payment_page_text_update_failed')}`,
                        closeFlag: true
                    })
                }
            } else {
                /**
                 * 成功触发
                 * 1、成功关闭
                 * 2、刷新地址列表数据
                 */
                props.handleClose();
                props.refreshAddress();
            }
        } catch (err) {
            console.log(err)
        }
    }
    // 更新国家 省份 城市 || 邮编 门铃
    function onChangAddress(currentLocationInfo) {
        try {
            // setgoogleInput(currentLocationInfo.formatted_address);
            // onChangGoogleMap(currentLocationInfo)
            // 国家
            setCountryInput(_.get(currentLocationInfo, 'country', ''));
            // 省份
            setProvinceInput(_.get(currentLocationInfo, 'administrative_area_level_1', ''));
            // 城市
            setCityInput(_.get(currentLocationInfo, 'locality', ''));
            // 邮编
            setZipCodeInput(_.get(currentLocationInfo, 'postal_code', ''));
            setIsGoogleCodeInput(_.get(currentLocationInfo, 'postal_code', ''))
            // 经纬度
            setCurrentlat(_.get(currentLocationInfo, 'google_map_lat', ''));
            setCurrentlng(_.get(currentLocationInfo, 'google_map_lng', ''))
            // google map id
            setCurrentplaceId(_.get(currentLocationInfo, 'google_map_place_id', ''))
            // 详细地址

            setCurrentformatted(_.get(currentLocationInfo, 'google_map_formatted_address', ''));
            setgoogleInput(_.get(currentLocationInfo, 'google_map_formatted_address', ''));
            setIsCompleteInfo(true);
            // try {
            //     if (currentLocationInfo) {
            //         let componentForm = {
            //             route: '',
            //             locality: '',
            //             administrative_area_level_1: '',
            //             country: '',
            //             postal_code: ''
            //         };
            //         let address_components = _.get(currentLocationInfo, 'address_components')
            //         let isSpecialCountry = false
            //         for (let item of address_components) {
            //             let addressType = item.types[0];
            //             if (_.has(componentForm, `${addressType}`)) {
            //                 // $ 处理中国特别行政区
            //                 let longName = item.long_name
            //                 let shortName = item.short_name
            //                 let specialCountry = ['HK', 'MO', 'TW']
            //                 if (_.includes(specialCountry, shortName)) {
            //                     if (addressType === 'country') {
            //                         _.set(componentForm, 'country', '中国')
            //                         _.set(componentForm, 'administrative_area_level_1', longName)
            //                     }
            //                     isSpecialCountry = true
            //                 }
            //                 if (isSpecialCountry) {
            //                     if (addressType === 'administrative_area_level_1') {
            //                         _.set(componentForm, 'locality', longName)
            //                     }
            //                 } else {
            //                     _.set(componentForm, `${addressType}`, longName)
            //                 }
            //             }
            //         }
            //         // console.log('componentForm', componentForm);
            //         setgoogleInput(currentLocationInfo.formatted_address)
            //         onChangGoogleMap(currentLocationInfo)
            //         // 国家
            //         setCountryInput(_.get(componentForm, 'country'))
            //         // 省份
            //         setProvinceInput(_.get(componentForm, 'administrative_area_level_1'))
            //         // 城市
            //         setCityInput(_.get(componentForm, 'locality'))
            //         // 邮编
            //         setZipCodeInput(_.get(componentForm, 'postal_code'))
            //     }



        } catch (error) {
            console.log(error, 'error');
        }

    }
    // 是否使用登录号码为配送信息
    function onchangeContacts() {
        changeContacts(!contacts);
        if (contacts) {
            setPhoneNumber('');
            setPrePhone(browserPhoneCode);
            setFirstName('');
            setLastName('');
        }
    }

    const { restaurantTheme } = props;

    return (
        <div className={`createAddress overall-center ${restaurantTheme === 'FIRST_THEME' ? '' : 'secCreateAddress'}`}>
            <div className={isCompleteInfo ? 'mainContainer' : 'mainContainer isNoComplete'}>
                <AddressAndNoteHeader
                    title={FLAG ?
                        `${i18n.t('payment_page_text_edit_address')}` :
                        `${i18n.t('delivery_address_page_text_client_add_address')}`}
                    handleClose={props.handleClose} />
                <div className='allContainer'>
                    {/* <GoogleMapInput
                        styleName='GoogleMapstreetInput'
                        labelName={i18n.t('delivery_address_page_your_address')}
                        inputValue={googleInputValue}
                        placeholderTxt={i18n.t('delivery_address_page_text_enter_your_address')}
                        handelChange={(val) => { setgoogleInput(val?.trim()) }}
                        showGoogleMap={() => { onShowGoogleMap(true) }}
                        readOnly
                    /> */}
                    <div className={'address-input'} id='google-address-input'>
                        <span className={restaurantTheme === 'FIRST_THEME' ? 'text' : 'text-second'}>
                            {i18n.t('delivery_address_page_your_address')}
                        </span>
                        <GoogleMapComponent
                            setInputVal={(val: string) => setGoogleInputVal(val)}
                            componentType={'address'}
                            setIsGoogleAdress={(val: boolean) => { setIsGoogleAddress(val) }}
                            defaultValue={googleMapFormattedAddress || ''}
                            className={restaurantTheme === 'FIRST_THEME' ? 'complete-google-map' : 'complete-google-map-second'}
                            placeholder={i18n.t('delivery_address_page_text_enter_your_address')}
                            onSave={(val: Address) => {
                                onChangAddress(val)
                            }}
                        />
                        {!isGoogleAddress ?
                            <div className={'tipsText'}>
                                {i18n.t('general_text_incorrect_address_entered')}
                            </div> : null}
                    </div>

                    <AddressInput styleName='homeInput'
                        labelName={`${i18n.t('delivery_address_page_text_client_home_number')}`}
                        placeholderTxt={i18n.t('delivery_address_page_text_enter_your_home_number')}
                        inputValue={homeNumberInput}
                        handelChange={(input) => {
                            setHomeNumberInput(input?.trim())
                        }} />
                    <AddressInput styleName='homeInput'
                        labelName={`${i18n.t('delivery_address_page_text_client_door_number')}`}
                        placeholderTxt={i18n.t('delivery_address_page_text_enter_your_door_number')}
                        inputValue={doorNumberInput}
                        handelChange={(input) => {
                            setDoorNumberInput(input?.trim())
                        }} />
                    <AddressInput styleName='zipInput'
                        labelName={`${i18n.t('text_client_zip_code')}`}
                        placeholderTxt={i18n.t('delivery_address_page_text_enter_your_zip_code')}
                        inputValue={ZipCodeInput}
                        handelChange={(input) => {
                            setZipCodeInput(input?.trim())
                        }}
                        blurEvent={(inputValue: string) => {
                            try {
                                const input = document.getElementById(`${i18n.t('text_client_zip_code')}`);
                                const searchBox = document.getElementById('google-map-search');
                                if (isGoogleCodeInput === inputValue || !inputValue || !isGoogleCodeInput) {
                                    if (input) {
                                        input.style.border = '1px solid #989eb6'
                                    }
                                    if (searchBox && (googleInputVal === formatted_address || !googleInputVal)) {
                                        searchBox.style.border = '1px solid #989eb6'
                                        setIsGoogleAddress(true);
                                    }
                                } else {
                                    if (input) {
                                        input.style.border = '1px solid #fd7b88'
                                    }
                                    if (searchBox) {
                                        searchBox.style.border = '1px solid #fd7b88';
                                    }
                                    setIsGoogleAddress(false);
                                }
                            } catch (error) {

                            }
                        }} />

                    <AddressInput styleName='city'
                        labelName={i18n.t('delivery_address_page_text_details')}
                        placeholderTxt={i18n.t('delivery_address_page_text_describe_details_about_the_address')}
                        inputValue={detailsInput}
                        readOnly={false}
                        handelChange={(input) => {
                            setDetailsInput(input?.trim())
                        }} />
                    <div className='selectDefault'>
                        <div className='optionIco' style={{ background: (props.type !== 'payment' && noAccountName) ? '#b4b5b5' : '#fff' }} onClick={() => {
                            if (!(props.type !== 'payment' && noAccountName)) {
                                onchangeContacts()
                            }
                        }}>
                            {
                                !contacts ? <MdDone style={{ color: '#9791BF' }} /> : null
                            }
                        </div>
                        <p className='defaultTip'>{i18n.t('delivery_address_page_text_contact_person')}</p>
                        {(props.type !== 'payment' && noAccountName) && <p className='accountInfoInvalid'>{i18n.t('delivery_address_page_text_account_information_is_not_set')}</p>}
                    </div>
                    {
                        !contacts &&
                        <div>
                            <AddressInput
                                styleName='firstName'
                                labelName={`${i18n.t('signup_page_text_first_name')}`}
                                placeholderTxt={i18n.t('delivery_address_page_text_enter_your_first_name')}
                                inputValue={firstName}
                                handelChange={(input) => {
                                    if (input) {
                                        if (IS_CUSTOMER_NAME.test(input)) {
                                            setFirstName(input)
                                        }
                                    } else {
                                        setFirstName('')
                                    }
                                }} />
                            <AddressInput
                                styleName='lastName'
                                labelName={`${i18n.t('signup_page_text_last_name')}`}
                                placeholderTxt={i18n.t('delivery_address_page_text_enter_your_last_name')}
                                inputValue={lastName}
                                handelChange={(input) => {
                                    if (input) {
                                        if (IS_CUSTOMER_NAME.test(input)) {
                                            setLastName(input)
                                        }
                                    } else {
                                        setLastName('')
                                    }
                                }}
                            />
                            <PhoneInput
                                selectItem={prePhone}
                                defaultInput={phoneNumber}
                                handleChange={(val) => {
                                    setPhoneNumber(val?.trim())
                                }}
                                handleSelect={(val) => {
                                    setPrePhone(val)
                                }
                                }
                            />
                        </div>
                    }
                </div>
                {!props.loginFreeMode &&
                    <div className='selectDefault margin70'>
                        <div className='optionIco' onClick={() => setChecked(!checkedA)}>
                            {
                                checkedA ? <MdDone style={{ color: '#9791BF' }} /> : null
                            }
                        </div>
                        <p className='defaultTip'>{i18n.t('delivery_address_page_text_set_as_default')}</p>
                    </div>}
                {!FLAG
                    ? <div>
                        <button className={
                            googleInputValue?.trim() !== '' && homeNumberInput?.trim() !== '' && doorNumberInput?.trim() !== '' && ZipCodeInput?.trim()
                                && (!contacts ? (firstName?.trim() !== '' && lastName?.trim() && IS_PHONE(prePhone, phoneNumber?.trim())) : true)
                                && isGoogleAddress ?
                                'addAddressBtn' : 'addAddressBtn not-active'}
                            onClick={handleCreateAddress}>
                            {`${i18n.t('payment_page_text_adjunction')}`}
                        </button>
                    </div>
                    : <div className='addAddressEditBtn'>
                        <Button className={googleInputValue?.trim() !== '' && homeNumberInput?.trim() !== '' && doorNumberInput?.trim() !== '' && ZipCodeInput?.trim()
                            && (!contacts ? (firstName?.trim() !== '' && lastName?.trim() && IS_PHONE(prePhone, phoneNumber?.trim())) : true)
                            && isGoogleAddress ? 'addAddressEdit ok' : 'addAddressEdit not-active'}
                            onClick={handleCreateAddress} >
                            {i18n.t('payment_page_text_edit')}
                        </Button>
                        <Button className='addAddressEdit cancel'
                            onClick={handleDelAddress}  >
                            {i18n.t('delivery_address_page_button_delete')}
                        </Button>
                    </div>}
                {/* {
                    <div style={{ visibility: isshowGoogleMap ? 'visible' : 'hidden' }}>

                    </div>
                } */}
                {/* {isshowGoogleMap ? <GoogleMapComponent
                    onCancel={() => {
                        onShowGoogleMap(false)
                    }}
                    onConfirm={(currentLocationInfor: any) => {
                        onChangAddress(currentLocationInfor)
                    }}
                    center={{ lat, lng }}
                    restaurantPosition={props.restaurantPosition ?
                        { lat: props.restaurantPosition?.lat, lng: props.restaurantPosition?.lng }
                        : undefined}
                /> : null} */}
            </div>

            {/* 所有请求错误弹出框 */}
            {isError.flag ? <ErrorDialog
                message={isError.message}
                closeFlag={isError.closeFlag}
                handleCloseError={() => {
                    setErrorPromptBoxConfig({
                        flag: false,
                        message: '',
                        closeFlag: true
                    })
                }} /> : null}


        </div>
    )
}

// 创建菜单和备注 头部
export function AddressAndNoteHeader(
    props: {
        title: any,
        handleClose: () => void
    }
) {
    const { title } = props
    return (
        <div className='addressAndNoteHeader'>
            <span></span>
            <p>{title}</p>
            <IconButton className='closeBtn'
                onClick={(evt) => {
                    evt.stopPropagation()
                    props.handleClose()
                }}>
                <CloseIcon className='closeIco' />
            </IconButton>
        </div>
    )
}

//  输入框
export function AddressInput(
    props: {
        labelName?: string;
        placeholderTxt: string;
        styleName: string;
        inputValue: string | undefined;
        handelChange: (inputValue: string) => void;
        readOnly?: boolean;
        blurEvent?: (inputVal: string) => void
    }
) {
    const { labelName, placeholderTxt, styleName, handelChange, inputValue, readOnly, blurEvent } = props;
    return (
        <div className='addressInput'>
            {labelName && <label htmlFor={labelName}>{labelName}</label>}
            <input className={styleName}
                id={labelName}
                type='text'
                placeholder={placeholderTxt}
                value={inputValue || ''}
                readOnly={readOnly}
                onChange={(evt) => {
                    handelChange(evt.target.value)
                }}
                onBlur={(e) => blurEvent?.(e.target.value)} />
        </div>
    )
}

// 文本框
export function TextBox(
    props: {
        labelName: string,
        placeholderTxt: string,
        styleName: string,
        inputValue: string,
        handleChange: (value: string) => void
    }
) {
    const { placeholderTxt, styleName, handleChange, inputValue } = props;
    return (
        <div className='textBox'>
            {/* <label htmlFor={labelName}>{labelName}</label> */}
            <textarea
                className={styleName}
                placeholder={placeholderTxt}
                value={inputValue}
                onChange={(evt) => { handleChange(evt.target.value) }}></textarea>
        </div>
    )
}

// 添加备注
export function AddNote(
    props: {
        handleClose: () => void,
        handleAddNote: (noteValue: string) => void,
        inputValue: string
    }
) {
    const { handleClose, handleAddNote, inputValue } = props;
    const [noteValue, setNoteValue] = React.useState(inputValue);
    return (
        <div className='addNote'>
            <div className='mainContainer'>
                <AddressAndNoteHeader
                    title={i18n.t('payment_page_text_add_note')}
                    handleClose={handleClose} />
                <TextBox
                    labelName={i18n.t('payment_page_text_insert_note')}
                    styleName='addNoteText'
                    placeholderTxt={i18n.t('payment_page_text_enter_order_remarks')}
                    inputValue={noteValue}
                    handleChange={(value) => { setNoteValue(value) }} />
                <button className='addAddressBtn'
                    onClick={() => { handleAddNote(noteValue) }}>
                    {i18n.t('payment_page_text_adjunction')} </button>
            </div>
        </div>
    )
}


// 显示地址的卡片
export function AddressCars(
    props: {
        addressItem: Address,
        isSelected: boolean,
        handleModiftFn: (item: Address, e?: any) => void
    }
) {
    const { addressItem, handleModiftFn, isSelected } = props;
    return (
        <div className={`defaultAddressCars ${!isSelected ? 'noDeafultAddressCars' : ''} ${addressItem.isWithin === false ? 'errorBorder' : ''}`}>
            <MdKeyboardArrowRight className='editModifyBtn'
                onClick={(e) => {
                    handleModiftFn(addressItem, e)
                }} />
            <ul>
                <li>
                    <MdRoom className='addressBtn' style={{ color: '#FFDC33' }} />
                    <div className='content locationContent'>
                        <p className='city'>{addressItem.street} {addressItem.home_number} {addressItem.door_number || ''}
                            {/* {addressItem.code} {' ' + addressItem.city} {addressItem.state}
                            {addressItem.country} */}
                        </p>
                    </div>
                </li>
                <li>
                    <MdFace className='addressBtn' style={{ color: '#989EB6' }} />
                    <div className='content userInfo'>
                        <p className='userName'>{addressItem.first_name}{addressItem.last_name}</p>
                    </div>
                </li>
                <li>
                    <MdRingVolume className='addressBtn' style={{ color: '#989EB6' }} />
                    <p className='content addressPhone'>{addressItem.phone}</p>
                </li>
                {/* 门牌号，缺失提醒，且不可选用 */}
                {!addressItem.door_number && <li>
                    <span className='isNotWithin'>{i18n.t('payment_page_text_address_error')}</span>
                </li>}
                {addressItem.isWithin === false &&
                    <li>
                        <span className='isNotWithin'>{i18n.t('payment_page_text_this_address_is_not_within_the_scope_of_shipping')}</span>
                    </li>}
            </ul>
        </div>
    )
}

// 提示悬浮框
export function TipBox() {
    return (
        <div className='tipBox'>
            <span>{i18n.t('payment_page_text_select_delivery_method')}</span>
        </div>
    )
}

// 付款弹窗
export function PaymentBox(
    props: PaymentBoxType
) {
    const { handleClosePaymentBox, saveCurrentAddress,
        restaurantsInfo, isPickUp, noteValue,
        paymenMethod, setPaymenMethod, performanceDate, orderId,
        payment_status, orderData, handlePaypalBubble,
        originalTotalPrice, discountedPrice, totalDeliveryFee,
        usedCouponIds, couponPackage, lowestPriceForDelivery, inRingDeliveryFee,
        restaurantTheme, restaurantThemeColor, loginFreeMode
    } = props;
    let shopCar;
    try {
        const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID) || {};
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        if (cacheShopCar && cacheShopCar[currentResStringID]) {
            shopCar = cacheShopCar[currentResStringID];
        } else {
            shopCar = {
                shopCarList: [],
                totalPrice: 0
            }
        }
    } catch (err) {
    }
    // 购物车数据
    const [shopCarDate, setShopCarDate] = React.useState(shopCar);
    //  $ 需要判断餐馆的版本号是否是1.2之后。1.2之后才有圆环
    const currentVersion = _.get(restaurantsInfo, 'version') || '0'
    const isUpdate = checkVersion(currentVersion, '1.2.0')
    // 配送费用
    let deliveryFee = isUpdate ? inRingDeliveryFee : (_.get(restaurantsInfo, 'setting_order_delivery_fee') || 0);
    if (orderId) {
        // 从订单过来的拿订单的配送费
        deliveryFee = _.get(orderData, 'total_delivery_fee_with_tax') + _.get(orderData, 'total_delivery_amount_discount')
    }
    // $ 阶梯配送费用
    // let levelDelivery = _.get(restaurantsInfo, 'setting_level_delivery_fee') || [];
    // console.log(levelDelivery, 'levelDelivery')
    // TODO 最高线下自取价格   restaurant_cod_maximum_amount：货到付款最高金额
    const maxOfflineFee = _.get(restaurantsInfo, 'restaurant_cod_maximum_amount') || 0;
    // 餐馆的string_id
    const string_id = _.get(restaurantsInfo, 'string_id') || '';
    // 点餐价格最低满足多少，才可配送
    const minDeliveryFee = lowestPriceForDelivery || 0;
    // PayPal支付状态
    const paypalStatus = _.get(restaurantsInfo, 'setting_app_takeaway_service_payment_paypal_status') || false;
    // cash支付状态
    const cashStatus = _.get(restaurantsInfo, 'setting_app_takeaway_service_payment_cash_status') || false;
    // Stripe支付状态
    const stripeStatus = _.get(restaurantsInfo, 'setting_app_takeaway_service_payment_stripe_status') || false;
    // 2payNow支付状态
    const twoPayNowStatus = _.get(restaurantsInfo, 'setting_app_takeaway_service_payment_2paynow_status') || false;
    // 第三方Stripe的公开密钥
    const stripePkKey = _.get(restaurantsInfo, 'setting_stripe_pk_key', '');
    // 支付状态 (0 去支付，点击之后变为1，检查支付状态  。 1 检查支付状态  -1  付款)
    const [status, setStatus] = React.useState(payment_status);
    // 控制显示完成界面
    const [isPaymentComplete, setIsPaymentComplete] = React.useState(false);
    // TODO 第三方页面 暂时不需要
    // const [isThirdPartyBox, setIsThirdPartyBox] = React.useState(false);
    // 创建订单加载框
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    // 创建订单失败提示框
    const [isError, setErrorPromptBoxConfig] = React.useState<{
        flag: boolean, message: string, showPhone: boolean, subMessage?: string,
        phoneMessage: string, returnButton?: boolean
    }>({
        flag: false,
        message: '',
        showPhone: true,
        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant',
        returnButton: false
    });

    // 存储订单账号
    const [orderCode, setOrderCode] = React.useState(orderId);

    // 第三方payPalUrl跳转路径
    const [payPalUrl, setPayPalUrl] = React.useState('');

    // 第三方Stripe ID
    const [stripeId, setStripeId] = React.useState('');

    // 去支付控件按钮是否显示和隐藏
    const [toPaymenBtn, setToPaymenBtn] = React.useState(true);

    // loading加载图标
    const [loadIcoFlag, setLoadIcoFlag] = React.useState(true);

    // 2payNow支付渠道  1:支付宝 2:微信 3:银行卡
    const [twoPayNowWay, setTwoPayNowWay] = React.useState(-1);

    // 第三方2PayNow跳转路径
    const [twoPayNowUrl, setTwoPayNowUrl] = React.useState('');

    // 付款按钮是否可用 2配送不可用 1自取不可用 0 可用
    /**
     * 1、先判断是自取还是配送
     * 2、自取判断 不做任何限制
     * 3、如果是配送,判断购物车总价格是否大于最低配送费
     */
    const isPaymentButtonClickable = orderCode ? 0 : isPickUp ?
        0 : (originalTotalPrice >= minDeliveryFee) ? 0 : 2;


    // PayPal付款
    function handlePaypal() {
        if (paypalStatus) {
            setPaymenMethod(1);
        }
    }
    // Stripe付款
    function handleStripe() {
        if (stripeStatus && stripePkKey) {
            setPaymenMethod(3);
        }
    }
    // 2PayNow付款
    function handleTwoPayNow() {
        if (twoPayNowStatus) {
            setPaymenMethod(4);
        }
    }
    // 线下付款
    function handleOfflinePayment() {
        /**
         * 1、这里如果是自取不加判断，货到付款不加限制/其实在最大已限制
         * 2、如果是配送则需要判断     需要判断总价格是否小于最大自取 费用价格
         * 3、付款方式不可用
         */
        if (!cashStatus) {
            return
        }
        if (isPickUp) {
            // 自取
            setPaymenMethod(2);
        } else {
            // 配送
            if (maxOfflineFee >= originalTotalPrice) {
                // if (paymentType === '-1') {
                setPaymenMethod(2);
                // }
            }
        }
    }

    // 付款
    async function handleCheckout() {
        /**
         * 1、PayPal付款
         * 2、线下付款
         * 3、从订单详情回来得支付得订单。
         * 不需要在创建订单，如果是PayPal支付的话，那么这里调一下获取PayPal支付的接口
         * 开启一下loading弹出
         */
        // 如果是自取购物车总价格不能大于最大自取金额
        if (isPaymentButtonClickable === 0) {
            // 不是详情跳转过来的
            if (orderId === '') {
                // 这里判断购物车是否有数据
                if (shopCarDate && shopCarDate.shopCarList.length > 0) {
                    const totalPrice = usedCouponIds.length
                        ? (discountedPrice + (!isPickUp ? totalDeliveryFee : 0)).toFixed(2)
                        : (originalTotalPrice + (!isPickUp ? totalDeliveryFee : 0)).toFixed(2)
                    if (paymenMethod === 1) {
                        // paypal付款 不能小于0.01元，且不创建订单
                        if (Number(totalPrice) < 0.01) {
                            setErrorPromptBoxConfig({
                                flag: true,
                                message: `${i18n.t('payment_page_text_PayPals_total_payment_amount_cannot_be_zero')}`,
                                showPhone: false,
                                phoneMessage: '',
                                returnButton: false
                            });
                        } else {
                            // paypal付款
                            createOrder(performanceDate);
                        }
                    } else if (paymenMethod === 2) {
                        // 线下 （调用创建订单接口）
                        createOrder(performanceDate);
                    } else if (paymenMethod === 3) {
                        // Stripe付款 不能小于0.01元，且不创建订单
                        if (Number(totalPrice) < 0.01) {
                            setErrorPromptBoxConfig({
                                flag: true,
                                message: `${i18n.t('payment_page_text_Stripe_total_payment_amount_cannot_be_zero')}`,
                                showPhone: false,
                                phoneMessage: '',
                                returnButton: false
                            });
                        } else {
                            // Stripe付款
                            createOrder(performanceDate);
                        }
                    } else if (paymenMethod === 4 && twoPayNowWay !== -1) {
                        // 2PayNow付款;需要选择付款渠道;不能小于0.01元，且不创建订单
                        if (Number(totalPrice) < 0.01) {
                            setErrorPromptBoxConfig({
                                flag: true,
                                message: `${i18n.t('payment_page_text_2PayNow_total_payment_amount_cannot_be_zero')}`,
                                showPhone: false,
                                phoneMessage: '',
                                returnButton: false
                            });
                        } else {
                            // Stripe付款
                            createOrder(performanceDate);
                        }
                    }
                } else {
                    // 购物车没有数据
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t('payment_page_text_cart_empty_or_view_my_order')}`,
                        showPhone: false,
                        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                    });
                }
            } else {
                // 判断是否支付完成界面
                if (status === '1') {
                    // 支付完成  检查支付状态   （跳转到我的订单页面）
                    const history = createHashHistory();
                    history.push('/my-order-page');
                } else {
                    // 点击支付之后显示 检查支付状态
                    if (paymenMethod === 1) {
                        // $ paypal付款
                        setLoadingFlag(true);
                        await handleSuccessPayPal(orderId);
                        // setStatus('1');
                    } else if (paymenMethod === 2) {
                        // $ 线下 货到付款
                        setLoadingFlag(true);
                        // 关闭loading框框
                        setLoadIcoFlag(false);
                        setStatus('1');
                        // 确认线下支付
                        toPayMent()
                    } else if (paymenMethod === 3) {
                        // $ Stripe支付
                        setLoadingFlag(true);
                        await handleSuccessStripe(orderId)
                        // 关闭loading框框
                        setLoadIcoFlag(false);
                        // setStatus('1');
                    } else if (paymenMethod === 4 && twoPayNowWay !== -1) {
                        // $ 2PayNow支付
                        setLoadingFlag(true);
                        await handleSuccess2PayNow(orderId);
                    }
                }
            }
        }
    }

    // 创建订单
    async function createOrder(performanceDate: Date) {
        // console.log(saveCurrentAddress?.deliveryLine);
        if (!isPickUp) {
            if (loginFreeMode) {
                if (!props.freeModeCustomerInfo.address) {
                    return
                }
            } else {
                if (!(saveCurrentAddress?.deliveryLine || saveCurrentAddress?.deliveryLine === 0)) {
                    return
                }
            }
        }
        // 打开loading 显示正在加载
        setLoadingFlag(true);
        try {
            const belonged_restaurant = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
            const belonged_customer = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT), '_id');
            const cacheShopCar = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR), belonged_restaurant);
            const type = isPickUp ? '1' : '0';
            // 购物车数据需要属性需要筛选
            const bagItemDish = cacheShopCar.shopCarList.map(item => {
                return {
                    _id: item._id,
                    num: item.buyNumber,
                    remark: item.remark ? item.remark : '',
                    menu_name: item.menuName,
                    // 0:菜品，1：包餐, 2:套餐 string
                    dish_type: item.dish_type || '0'
                }
            })
            const bags = [bagItemDish];
            // 这里需要判断是自取还是配送 (自取不传地址)
            let address = {};
            // 直线距离
            let delivery_kilometer: number | undefined;
            // 路经距离
            let route_kilometer: number | undefined;

            if (loginFreeMode) {
                address = isPickUp ? {} : props.freeModeCustomerInfo.address ? (props.freeModeCustomerInfo.address[0] || {}) : {}
                delivery_kilometer = _.get(props, 'freeModeCustomerInfo.address[0].line')
                route_kilometer = _.get(props, 'freeModeCustomerInfo.address[0].deliveryLine')
            } else {
                address = isPickUp ? {} : _.cloneDeep([saveCurrentAddress])[0];
                delivery_kilometer = _.get(saveCurrentAddress, 'line')
                route_kilometer = _.get(saveCurrentAddress, 'deliveryLine')
            }
            _.set(address, 'line', undefined)
            _.set(address, 'isWithin', undefined)
            _.set(address, 'deliveryLine', undefined)
            const remark = noteValue;
            // 选中的优惠券
            const selectCoupon = _.filter(couponPackage, (n) => { return _.includes(usedCouponIds, n.coupon_id) });
            const coupons: any = []
            selectCoupon.forEach((v) => {
                coupons.push({
                    coupon_code: (v.ignore_get_enable && !v.is_customer_coupon) ? v.code : v.coupon_code,
                    _id: v._id,
                    is_ignore_get: (v.ignore_get_enable && !v.is_customer_coupon) || undefined
                })
            })
            // 提交的参数
            const params: any = {
                belonged_restaurant,
                belonged_customer,
                type,
                bags,
                address,
                remark,
                coupons
            }
            // 免登录模式下要传
            if (loginFreeMode) {
                params.first_name = props.freeModeCustomerInfo.first_name;
                params.last_name = props.freeModeCustomerInfo.last_name;
            } else if (!loginFreeMode) {
                // $ 已登录，没有姓名的话，要填写姓名
                const cacheAccount = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                const cacheFirstName = _.get(cacheAccount, 'first_name', '');
                const cacheLastName = _.get(cacheAccount, 'last_name', '');
                if (!(cacheFirstName && cacheLastName)) {
                    params.first_name = props.freeModeCustomerInfo.first_name;
                    params.last_name = props.freeModeCustomerInfo.last_name;
                }
            }
            // payment_type '0':线上 '1'：线下
            // $ 在调用接口处理 payment_detail :{type: 'PAYPAL' | 'STRIPE', mode:'sandbox' | 'live'}
            // $ payment2PayNowType   0： 支付宝  1：微信   5：银行卡
            if (paymenMethod) {
                params.payment_type = paymenMethod === 2 ? '1' : '0';
                params.payment_detail = paymenMethod === 2
                    ? undefined :
                    paymenMethod === 4
                        ? {
                            type: '2PAYNOW',
                            payment2PayNowType: twoPayNowWay === 1 ? '0' : twoPayNowWay === 2 ? '1' : '5'
                        }
                        : {
                            type: paymenMethod === 1 ? 'PAYPAL' : 'STRIPE'
                        }
            }
            /**
             * 1、需要判断是自取还是配送selftake_time 和selftake_time_zone_name时区
             * 2、还是配送delivery_timese 和 delivery_time_zone_name 这个是时区
             */
            if (type === '0') {
                // 外卖订单
                params.delivery_time = DT(performanceDate);
                params.delivery_time_zone_name = momentTimeZone.tz.guess();
                params.delivery_kilometer = delivery_kilometer;
                params.route_kilometer = route_kilometer;
            } else if (type === '1') {
                // 自取订单
                params.selftake_time = DT(performanceDate);
                params.selftake_time_zone_name = momentTimeZone.tz.guess();
            }
            // 请求接口
            const result: any = await
                apiManage.postOrderPayment(
                    params,
                    {
                        notNeedLoading: true
                    }
                );

            // 这里需要做一个错误代码提示
            if (_.get(result, 'error')) {
                // 创建失败弹窗 关闭加载框、打开失败窗口
                if (_.get(result, 'result.code') === 'ORDER_1005' && _.get(result, 'result.statusCode') === 400) {
                    setLoadingFlag(false);
                    // $ 以下优惠券当前不能使用
                    const details = _.get(result, 'result.details', []);
                    const FailedCouponsText = _.map(details, 'name')
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t('payment_page_text_the_following_coupons_cannot_currently_be_used')}`,
                        subMessage: `${_.join(FailedCouponsText, ',')}`,
                        showPhone: false,
                        phoneMessage: '',
                        returnButton: false
                    });
                    return
                }
                const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
                if (errorTip.length > 0) {
                    // 有对应错误编码
                    setLoadingFlag(false);
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t(errorTip[0].message)}`,
                        showPhone: true,
                        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                    });
                } else {
                    // 没有错误对应编码
                    setLoadingFlag(false);
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: '',
                        showPhone: true,
                        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                    });
                }
            } else {
                // 没有错误的情况
                const { createOrderInCustomerResult, createOrderInRestaurantResult } = result;
                // 判断顾客和餐厅都是ok才行
                if (createOrderInCustomerResult.ok === 1
                    && createOrderInCustomerResult.n === 1
                    && createOrderInCustomerResult.modifiedCount === 1
                    && createOrderInRestaurantResult.ok === 1
                    && createOrderInRestaurantResult.n === 1
                    && createOrderInRestaurantResult.insertedCount === 1) {
                    /**
                     * 1、PayPal支付
                     * 2、线下支付
                     * 3、选择支付方式不一样则调用接口不一致，需要区分
                     */
                    // 订单号保存
                    setOrderCode(createOrderInRestaurantResult.insertedId);

                    // 购物车清除
                    const initShop = {
                        shopCarList: [],
                        totalPrice: 0
                    }
                    // 更新存储里面的购物车数据
                    const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
                    LocalStorageManager.setLocalStorage({
                        path: LOCAL_STORAGE_KEYS.SHOP_CAR,
                        value: {
                            ...cacheShopCar,
                            [belonged_restaurant]: initShop
                        }
                    });
                    // 本地购物车也清空
                    setShopCarDate(initShop);
                    // 修改侧边栏数据
                    const cacheAccount = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
                    if (loginFreeMode) {
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.ACCOUNT,
                            value: {
                                ...cacheAccount,
                                first_name: props.freeModeCustomerInfo.first_name,
                                last_name: props.freeModeCustomerInfo.last_name
                            }
                        })
                    } else if (!loginFreeMode) {
                        // $ 已登录，没有姓名的话，要填写姓名，并缓存
                        const cacheFirstName = _.get(cacheAccount, 'first_name', '');
                        const cacheLastName = _.get(cacheAccount, 'last_name', '');
                        if (!(cacheFirstName && cacheLastName)) {
                            LocalStorageManager.setLocalStorage({
                                path: LOCAL_STORAGE_KEYS.ACCOUNT,
                                value: {
                                    ...cacheAccount,
                                    first_name: props.freeModeCustomerInfo.first_name,
                                    last_name: props.freeModeCustomerInfo.last_name
                                }
                            });
                        }
                    }
                    if (cacheAccount._id) {
                        // 加入历史餐馆
                        const historyRestaurant = { _id: cacheAccount._id, restaurant_string_ids: [belonged_restaurant] };
                        await apiManage.updateHistoryRestaurants(historyRestaurant, { notNeedLoading: true });
                    }
                    // 1:PayPal支付  (直接调用接口获取到PayPal支付接口)
                    if (paymenMethod === 1) {
                        // 跳转PayPal第三方网址(创建订单要成功才能跳转)
                        setPayPalUrl(_.get(result, 'createPaymentResult.approval_url.href', ''));
                        setLoadIcoFlag(false);
                        // 去PayPal付款
                        toPayMent({ newPayPalUrl: _.get(result, 'createPaymentResult.approval_url.href', '') })
                    }
                    // 2:线下支付
                    else if (paymenMethod === 2) {
                        setIsPaymentComplete(true)
                        // 关闭loading图标
                        setLoadingFlag(false);
                        setPayPalUrl('Offline');
                    }
                    // 3:Stripe
                    else if (paymenMethod === 3) {
                        setStripeId(_.get(result, 'createPaymentResult.id', ''))
                        setLoadIcoFlag(false);
                        // 去Stripe付款
                        toPayMent({ newStripeId: _.get(result, 'createPaymentResult.id', '') })
                    }
                    else if (paymenMethod === 4) {
                        setTwoPayNowUrl(_.get(result, 'createPaymentResult.url', ''))
                        toPayMent({ newTwoPayNowUrl: _.get(result, 'createPaymentResult.url', '') })
                    }
                } else {
                    // 创建失败弹窗 关闭加载框、打开失败窗口
                    setLoadingFlag(false);
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: '',
                        showPhone: true,
                        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                    });
                }
            }
        } catch (err) {
            console.log('createOrder-error:', err)
            const error = err as unknown as { statusCode: string, code: number };
            // 这里需要加一层异常提示判断
            setLoadingFlag(false);
            if (error.statusCode !== undefined) {
                // 标识后台返回的错误
                const errorTip = errorCode.filter(item => (item.code === error.code));
                // 如果errorTip数据为空标识错误不在后台提示范围内
                if (errorTip.length > 0) {
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t(errorTip[0].message)}`,
                        showPhone: true,
                        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                    });
                } else {
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: '',
                        showPhone: true,
                        phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                    });
                }
            } else {
                setErrorPromptBoxConfig({
                    flag: true,
                    message: '',
                    showPhone: true,
                    phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant'
                });
            }
        }
    }

    // 线下支付创建订单成功调用链接
    async function handleSuccessOffline() {
        /**
         * 直接成功
         * 2、关闭loading  打开支付完成页面
         * 3、清空购物车数据
         */
        try {
            // 二次付款
            const result = await apiManage.postOrderOffline(
                string_id,
                orderCode,
                '', // !这里需要添加货到付款子方式
                {
                    notNeedLoading: true
                }
            );
            if (_.get(result, 'error')) {
                // 创建失败弹窗 关闭加载框、打开失败窗口
                const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
                if (errorTip.length > 0) {
                    // 有对应错误编码
                    setLoadingFlag(false);
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t(errorTip[0].message)}`,
                        showPhone: true,
                        phoneMessage: 'payment_page_text_offline_payment_confirmation_failed'
                    });
                } else {
                    // 没有错误对应编码
                    setLoadingFlag(false);
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t('payment_page_text_offline_payment_confirmation_failed')}`,
                        showPhone: true,
                        phoneMessage: ''
                    });
                }
            } else {
                // 没有错误
                setLoadingFlag(false);
                setIsPaymentComplete(true);
            }
        } catch (err) {
            setLoadingFlag(false);
        }
    }

    // PayPal创建订单成功调用链接
    async function handleSuccessPayPal(id: string) {
        // 二次付款
        const result = await apiManage.postOrderPaypal(
            string_id,
            id,
            {
                notNeedLoading: true
            });
        // $ 这里需要做一个错误代码提示
        if (_.get(result, 'error')) {
            // 创建失败弹窗 关闭加载框、打开失败窗口
            const errorTip = errorCode.filter(item => (item.code === _.get(result, 'result.code')));
            if (errorTip.length > 0) {
                // 有对应错误编码
                setLoadingFlag(false);
                if (errorTip[0].code === 1008) {
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t('payment_page_text_PayPals_total_payment_amount_cannot_be_zero')}`,
                        showPhone: true,
                        phoneMessage: 'payment_page_text_paypal_payment_error',
                        returnButton: true
                    });
                } else {
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `${i18n.t(errorTip[0].message)}`,
                        showPhone: true,
                        phoneMessage: 'payment_page_text_paypal_payment_error'
                    });
                }
            } else {
                // 没有错误对应编码
                setLoadingFlag(false);
                setErrorPromptBoxConfig({
                    flag: true,
                    message: `${i18n.t('payment_page_text_paypal_payment_error')}`,
                    showPhone: true,
                    phoneMessage: 'payment_page_text_paypal_payment_error'
                });
            }
        } else {
            if (result.emitMessageToCustomerResult && result.emitMessageToRestaurantResult) {
                // 解决ios浏览器不能打开问题
                // 跳转PayPal第三方网址(创建订单要成功才能跳转)
                setPayPalUrl(result.createPaymentResult.approval_url.href);
                // 关闭loading框框
                setLoadIcoFlag(false);
                // 去PayPal付款
                toPayMent({ newPayPalUrl: result.createPaymentResult.approval_url.href })
            }
        }
    }
    // Stripe创建订单成功调用链接
    async function handleSuccessStripe(id: string) {
        try {
            // 二次付款
            const result = await apiManage.postOrderStripe(
                string_id,
                id,
                {
                    notNeedLoading: true
                });
            if (_.get(result, 'error')) {
                setLoadingFlag(false);
                setErrorPromptBoxConfig({
                    flag: true,
                    message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                    showPhone: false,
                    phoneMessage: '',
                    returnButton: false
                });
            } else {
                setStripeId(_.get(result, 'createStripeResult.id', ''))
                // 去Stripe付款
                toPayMent({ newStripeId: _.get(result, 'createStripeResult.id', '') })
            }
        } catch (error) {
            setLoadingFlag(false);
            setErrorPromptBoxConfig({
                flag: true,
                message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                showPhone: false,
                phoneMessage: '',
                returnButton: false
            });
        }
    }
    // 2PayNow创建订单成功调用链接
    async function handleSuccess2PayNow(id: string) {
        try {
            // 二次付款
            // 0:微信 1:支付宝 5:银行卡
            const result = await apiManage.postOrder2PayNow(
                string_id,
                id,
                twoPayNowWay === 1 ? '0' : twoPayNowWay === 2 ? '1' : '5',
                {
                    notNeedLoading: true
                });
            if (_.get(result, 'error')) {
                setLoadingFlag(false);
                setErrorPromptBoxConfig({
                    flag: true,
                    message: `2PayNow ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                    showPhone: false,
                    phoneMessage: '',
                    returnButton: false
                });
            } else {
                // 去2PayNow付款
                toPayMent({ newTwoPayNowUrl: _.get(result, 'payment_url', '') })
            }
        } catch (error) {
            setLoadingFlag(false);
            setErrorPromptBoxConfig({
                flag: true,
                message: `2PayNow ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                showPhone: false,
                phoneMessage: '',
                returnButton: false
            });
        }
    }
    // 去支付控件按钮
    async function toPayMent(params?: { newPayPalUrl?: string, newStripeId?: string, newTwoPayNowUrl?: string }) {
        try {
            /**
             * 1、PayPal支付
             * 2、确认线下支付
             * 3、点击控件之后隐藏控件
             *   点击支付之后  改变控件文字为支付中..  加载框按钮消失
             */
            // 隐藏控件
            setToPaymenBtn(false);

            // $ 1：PayPal支付
            if (paymenMethod === 1) {
                /**
                 * $ 在Safari浏览器，有检测到window.open 是广告会被屏蔽
                 * 1、PayPal支付
                 * 2、开启loading
                 * 3、三秒后跳转到我的订单
                 */
                window.location.replace(params?.newPayPalUrl ?? payPalUrl);
                setLoadIcoFlag(true);
                // handleTiming('/my-order-page', 3000)
            }
            // $ 2：确认线下支付  (直接确认弹出成功窗口)
            else if (paymenMethod === 2) {
                handleSuccessOffline();
                // 开启loading图标
                setLoadIcoFlag(true);
            }
            // $ 3：stripe支付
            else if (paymenMethod === 3) {
                try {
                    if (stripePkKey) {
                        const stripePromise = loadStripe(stripePkKey);
                        const stripe = await stripePromise;
                        const result = await stripe!.redirectToCheckout({
                            sessionId: params?.newStripeId ?? stripeId
                        });
                        if (result.error) {
                            setLoadingFlag(false);
                            setErrorPromptBoxConfig({
                                flag: true,
                                message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                                showPhone: false,
                                phoneMessage: '',
                                returnButton: false
                            });
                        }
                    }
                } catch (error) {
                    setLoadingFlag(false);
                    setErrorPromptBoxConfig({
                        flag: true,
                        message: `Stripe ${i18n.t('request_failure_text_service_temporarily_unavailable')}`,
                        showPhone: false,
                        phoneMessage: '',
                        returnButton: false
                    });
                }
            } else if (paymenMethod === 4) {
                /**
                 * $ 在Safari浏览器，有检测到window.open 是广告会被屏蔽
                 * 1、2PAYNOW支付
                 * 2、开启loading
                 * 3、三秒后跳转到我的订单
                 */
                window.location.replace(params?.newTwoPayNowUrl ?? twoPayNowUrl);
                setLoadIcoFlag(true);
                // handleTiming('/my-order-page', 3000)
            }
        } catch (error) {
            console.log(error);
        }
    }


    /**
     * 定时跳转路由
     * @param path 跳转路径
     * @param duration 跳转时长
     */
    // function handleTiming(path: string, duration: number) {
    //     const history = createHashHistory();
    //     // 直接将当前地址退还掉，防止用户二次支付
    //     if (timer) {
    //         clearTimeout(timer);
    //     }
    //     timer = setTimeout(() => {
    //         history.replace(path);
    //     }, duration)
    // }

    // 关闭这个弹窗的时候，需要判断自取还是配送
    function handlePaymentBox() {
        if (orderId && loginFreeMode) {
            // 二次付款则不能关闭付款弹窗
            return false
        }
        /**
         * 1\因为自取的时候不再判断是否线下支付最大值。
         * 2\因此切换自取和配送的时候需要判断是否当前选中线下支付
         */
        if (isPickUp) {
            // 自取不做操作
        } else {
            // 配送需要判断当前是否线下支付以及最大线下支付是否大于总购物车价格
            if (paymenMethod === 2 && originalTotalPrice >= maxOfflineFee) {
                setPaymenMethod(0);
            }
        }
        // 关闭气泡提示
        handlePaypalBubble(false);
        // 关闭支付
        handleClosePaymentBox();
    }
    // 跳到点餐页
    function jumpToOrederpage() {
        const history = createHashHistory();
        history.push(`/orderpage?string_id=${string_id}&orderway=${isPickUp ? '1' : '2'}`)
    }
    return (
        <div className='paymentBox' onClick={handlePaymentBox}>
            {restaurantTheme === 'FIRST_THEME'
                ? <DefaultPaymentBoxContent
                    params={props}
                    {...{
                        handlePaymentBox,
                        orderCode,
                        jumpToOrederpage,
                        paypalStatus,
                        twoPayNowStatus,
                        stripeStatus,
                        stripePkKey,
                        handleStripe,
                        handlePaypal,
                        handleTwoPayNow,
                        handleOfflinePayment,
                        maxOfflineFee,
                        cashStatus,
                        deliveryFee,
                        isPaymentButtonClickable,
                        minDeliveryFee,
                        handleCheckout,
                        status,
                        twoPayNowWay,
                        setTwoPayNowWay: (val) => { setTwoPayNowWay(val) }
                    }}
                />
                : restaurantTheme === 'SECOND_THEME'
                    ? <BlackGoldPaymentBoxContent
                        params={props}
                        {...{
                            handlePaymentBox,
                            orderCode,
                            jumpToOrederpage,
                            paypalStatus,
                            twoPayNowStatus,
                            stripeStatus,
                            stripePkKey,
                            handleStripe,
                            handlePaypal,
                            handleTwoPayNow,
                            handleOfflinePayment,
                            maxOfflineFee,
                            cashStatus,
                            deliveryFee,
                            isPaymentButtonClickable,
                            minDeliveryFee,
                            handleCheckout,
                            status,
                            twoPayNowWay,
                            setTwoPayNowWay: (val) => { setTwoPayNowWay(val) }
                        }}
                    /> : <div></div>}
            {/* 支付完成 */}
            {
                isPaymentComplete ?
                    <PaymentComplete
                        restaurantTheme={restaurantTheme}
                        restaurantThemeColor={restaurantThemeColor} />
                    : null
            }
            {/* 暂时支付页面 */}
            {
                loadingFlag ? <PayMentLoading
                    restaurantTheme={restaurantTheme}
                    restaurantThemeColor={restaurantThemeColor}
                    loadIcoFlag={loadIcoFlag}
                    orderId={orderId}
                    payPalUrl={payPalUrl}
                    orderCode={orderCode}
                    paymenMethod={paymenMethod}
                    toPaymenBtn={toPaymenBtn}
                    stripeId={stripeId}
                    toPayMent={() => {
                        toPayMent()
                    }} /> : null
            }
            {/* 创建订单失败界面 */}
            {
                isError.flag ?
                    <PaymentError
                        restaurantTheme={restaurantTheme}
                        restaurantThemeColor={restaurantThemeColor}
                        showPhone={isError.showPhone}
                        message={isError.message}
                        subMessage={isError.subMessage}
                        phoneMessage={isError.phoneMessage}
                        returnButton={isError.returnButton}
                        handleCloseError={() => {
                            setErrorPromptBoxConfig({
                                flag: false,
                                message: '',
                                showPhone: true,
                                phoneMessage: 'payment_page_button_failed_create_order_contact_restaurant',
                                returnButton: false
                            })
                        }} /> : null
            }
        </div>
    )
}

// 付款完成页面
export function PaymentComplete(props: {
    restaurantTheme: RestaurantTheme;
    restaurantThemeColor: RestaurantThemeColor;
}) {
    // 返回到我的订单页面
    function handleReturn() {
        const history = createHashHistory();
        // 直接将当前地址退还掉，防止用户二次支付
        history.replace('/my-order-page');
    }
    const { restaurantTheme } = props;
    if (restaurantTheme === 'SECOND_THEME') {
        return (
            <div className='paymentComplete blackGoldPaymentComplete' onClick={(evt) => { evt.stopPropagation(); }}>
                <div className='paymentMainBox'>
                    <p className='title'>{i18n.t('payment_page_text_order_completed')}</p>
                    <p className='content'>{i18n.t('payment_page_text_thank_you')} <br />
                        {i18n.t('payment_page_text_received_your_order')}</p>
                    <p className='track orderConfirmation'>{i18n.t('payment_page_text_order_confirmation_and_pay_attention_possible_change_time')}</p>
                    <p className='track'>{i18n.t('payment_page_text_track_your_order')}
                        <span onClick={handleReturn}>  {i18n.t('general_text_order')}</span></p>
                    <button className='returnBtn'
                        onClick={handleReturn}>
                        {i18n.t('payment_page_button_return')}</button>
                </div>
            </div>
        )
    } else if (restaurantTheme === 'THIRD_THEME') {
        return (
            <div className='paymentComplete' onClick={(evt) => { evt.stopPropagation(); }}>
                <div className='paymentMainBox'>
                    <p className='title'>{i18n.t('payment_page_text_order_completed')}</p>
                    <p className='content'>{i18n.t('payment_page_text_thank_you')} <br />
                        {i18n.t('payment_page_text_received_your_order')}</p>
                    <p className='track orderConfirmation'>{i18n.t('payment_page_text_order_confirmation_and_pay_attention_possible_change_time')}</p>
                    <p className='track'>{i18n.t('payment_page_text_track_your_order')}
                        <span onClick={handleReturn}>  {i18n.t('general_text_order')}</span></p>
                    <button className='returnBtn'
                        onClick={handleReturn}>
                        {i18n.t('payment_page_button_return')}</button>
                </div>
            </div>
        )
    } else {
        return (
            <div className='paymentComplete' onClick={(evt) => { evt.stopPropagation(); }}>
                <div className='paymentMainBox'>
                    <p className='title'>{i18n.t('payment_page_text_order_completed')}</p>
                    <p className='content'>{i18n.t('payment_page_text_thank_you')} <br />
                        {i18n.t('payment_page_text_received_your_order')}</p>
                    <p className='track orderConfirmation'>{i18n.t('payment_page_text_order_confirmation_and_pay_attention_possible_change_time')}</p>
                    <p className='track'>{i18n.t('payment_page_text_track_your_order')}
                        <span onClick={handleReturn}>  {i18n.t('general_text_order')}</span></p>
                    <button className='returnBtn'
                        onClick={handleReturn}>
                        {i18n.t('payment_page_button_return')}</button>
                </div>
            </div>
        )
    }

}


// paypal成功之后的跳转链接
function PayMentLoading(
    props: {
        orderCode: string;
        paymenMethod: number;
        toPayMent: () => void;
        payPalUrl: string;
        orderId: string;
        toPaymenBtn: boolean;
        loadIcoFlag: boolean;
        stripeId: string;
        restaurantTheme: RestaurantTheme;
        restaurantThemeColor: RestaurantThemeColor;
    }
) {
    const { paymenMethod, toPayMent, orderCode,
        payPalUrl, orderId, toPaymenBtn, loadIcoFlag, stripeId, restaurantTheme } = props;
    // function handleMyOrder() {
    //     const history = createHashHistory();
    //     // 直接将当前地址退还掉，防止用户二次支付
    //     history.replace('/my-order-page');
    // }
    if (restaurantTheme === 'SECOND_THEME') {
        return (
            <div className='payMentLoading blackGOldpayMentLoading'
                onClick={(evt) => evt.stopPropagation()}>
                <div className='mainContainer'>
                    <p>{
                        // 四种状态
                        // paymenMethod,1:paypal支付 2:线下支付 3:Stripe支付
                        // toPaymenBtn,去支付控件按钮是否显示和隐藏
                        !toPaymenBtn ? `${i18n.t('payment_page_text_payment_ongoing')}` :
                            orderId ? `${i18n.t('payment_page_text_to_pay_wait')}` :
                                (paymenMethod === 1 && orderCode && payPalUrl) ||
                                    (paymenMethod === 2 && orderCode) ||
                                    (paymenMethod === 3 && stripeId)
                                    ? `${i18n.t('payment_page_text_create_order_successfully_to_pay')}`
                                    : `${i18n.t('payment_page_text_create_orde')}`}</p>
                    {
                        !toPaymenBtn || loadIcoFlag ?
                            <CircularProgress className='loading' /> : null
                    }

                    {
                        toPaymenBtn && (payPalUrl || stripeId) ?
                            <button className='loadingBtn'
                                onClick={() => {
                                    toPayMent()
                                }}>{paymenMethod === 1 ? `${i18n.t('payment_page_buttton_to_paypal')}`
                                    : paymenMethod === 3 ?
                                        `${i18n.t('payment_page_button_to_Stripe')}`
                                        : `${i18n.t('payment_page_buttton_confirm_offline_paypal')}`}</button> : null

                    }
                    {/* 暂时注释（新的支付流程-支付过程中不需要按钮“我的订单”）*/}
                    {/* {
                        !(toPaymenBtn || stripeId) ?
                            <button className='loadingBtn'
                                onClick={() => {
                                    handleMyOrder()
                                }}>{i18n.t('order_page_text_my_orders')}</button>
                            : null
                    } */}
                </div>

            </div>
        )
    } else if (restaurantTheme === 'THIRD_THEME') {
        return (
            <div className='payMentLoading'
                onClick={(evt) => evt.stopPropagation()}>
                <div className='mainContainer'>
                    <p>{
                        // 四种状态
                        // paymenMethod,1:paypal支付 2:线下支付 3:Stripe支付
                        // toPaymenBtn,去支付控件按钮是否显示和隐藏
                        !toPaymenBtn ? `${i18n.t('payment_page_text_payment_ongoing')}` :
                            orderId ? `${i18n.t('payment_page_text_to_pay_wait')}` :
                                (paymenMethod === 1 && orderCode && payPalUrl) ||
                                    (paymenMethod === 2 && orderCode) ||
                                    (paymenMethod === 3 && stripeId)
                                    ? `${i18n.t('payment_page_text_create_order_successfully_to_pay')}`
                                    : `${i18n.t('payment_page_text_create_orde')}`}</p>
                    {
                        !toPaymenBtn || loadIcoFlag ?
                            <CircularProgress className='loading' /> : null
                    }

                    {
                        toPaymenBtn && (payPalUrl || stripeId) ?
                            <button className='loadingBtn'
                                onClick={() => {
                                    toPayMent()
                                }}>{paymenMethod === 1 ? `${i18n.t('payment_page_buttton_to_paypal')}`
                                    : paymenMethod === 3 ?
                                        `${i18n.t('payment_page_button_to_Stripe')}`
                                        : `${i18n.t('payment_page_buttton_confirm_offline_paypal')}`}</button> : null

                    }
                    {/* 暂时注释（新的支付流程-支付过程中不需要按钮“我的订单”）*/}
                    {/* {
                        !(toPaymenBtn || stripeId) ?
                            <button className='loadingBtn'
                                onClick={() => {
                                    handleMyOrder()
                                }}>{i18n.t('order_page_text_my_orders')}</button>
                            : null
                    } */}
                </div>

            </div>
        )
    } else {
        return (
            <div className='payMentLoading'
                onClick={(evt) => evt.stopPropagation()}>
                <div className='mainContainer'>
                    <p>{
                        // 四种状态
                        // paymenMethod,1:paypal支付 2:线下支付 3:Stripe支付
                        // toPaymenBtn,去支付控件按钮是否显示和隐藏
                        !toPaymenBtn ? `${i18n.t('payment_page_text_payment_ongoing')}` :
                            orderId ? `${i18n.t('payment_page_text_to_pay_wait')}` :
                                (paymenMethod === 1 && orderCode && payPalUrl) ||
                                    (paymenMethod === 2 && orderCode) ||
                                    (paymenMethod === 3 && stripeId)
                                    ? `${i18n.t('payment_page_text_create_order_successfully_to_pay')}`
                                    : `${i18n.t('payment_page_text_create_orde')}`}</p>
                    {
                        !toPaymenBtn || loadIcoFlag ?
                            <CircularProgress className='loading' /> : null
                    }

                    {
                        toPaymenBtn && (payPalUrl || stripeId) ?
                            <button className='loadingBtn'
                                onClick={() => {
                                    toPayMent()
                                }}>{paymenMethod === 1 ? `${i18n.t('payment_page_buttton_to_paypal')}`
                                    : paymenMethod === 3 ?
                                        `${i18n.t('payment_page_button_to_Stripe')}`
                                        : `${i18n.t('payment_page_buttton_confirm_offline_paypal')}`}</button> : null

                    }
                    {/* 暂时注释（新的支付流程-支付过程中不需要按钮“我的订单”）*/}
                    {/* {
                        !(toPaymenBtn || stripeId) ?
                            <button className='loadingBtn'
                                onClick={() => {
                                    handleMyOrder()
                                }}>{i18n.t('order_page_text_my_orders')}</button>
                            : null
                    } */}
                </div>

            </div>
        )
    }

}


// paypal错误之后的跳转链接
export function PaymentError(
    props: {
        handleCloseError: () => void;
        message: string;
        subMessage?: string;
        showPhone: boolean;
        phoneMessage: string;
        returnButton?: boolean;
        restaurantTheme: RestaurantTheme;
        restaurantThemeColor: RestaurantThemeColor;
    }
) {
    const { message, subMessage, showPhone, phoneMessage, returnButton, restaurantTheme } = props;
    // 获取到当前餐馆的电话号码
    let phone;
    let tel_prefix;
    try {
        const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID) || {};
        const cacheRestaurant = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS) || {};
        phone = cacheRestaurant[currentResStringID].restaurant.tel;
        tel_prefix = cacheRestaurant[currentResStringID].restaurant.tel_prefix;
    } catch (err) { }
    // 返回到我的订单页面
    function handleReturn() {
        const history = createHashHistory();
        // 直接将当前地址退还掉，防止用户二次支付
        history.replace('/my-order-page');
    }
    if (restaurantTheme === 'SECOND_THEME') {
        return (
            <div className='PaymentError blackGoldPaymentError'
                onClick={(evt) => evt.stopPropagation()}>
                <div className='mainContainer'>
                    <MdClear className='closeIco' onClick={() => { props.handleCloseError() }} />
                    <MdError className='errorIco' />
                    {message ? <p>{message}</p> : null}
                    {subMessage ? <p>{subMessage}</p> : null}
                    {showPhone ?
                        <div>
                            <p>{i18n.t(phoneMessage)}</p>
                            <br />
                            {/* 区号和电话号码要同时存在 */}
                            {
                                tel_prefix && phone ?
                                    <a href={`tel: ${tel_prefix} ${phone}`}>{'+'}{tel_prefix} {phone}</a> : null
                            }
                        </div> : null}
                    {returnButton ? <button className='returnBtn'
                        onClick={handleReturn}>
                        {i18n.t('payment_page_button_return')}</button> : null}
                </div>

            </div>
        )
    } else if (restaurantTheme === 'THIRD_THEME') {
        return (
            <div className='PaymentError'
                onClick={(evt) => evt.stopPropagation()}>
                <div className='mainContainer'>
                    <MdClear className='closeIco' onClick={() => { props.handleCloseError() }} />
                    <MdError className='errorIco' />
                    {message ? <p>{message}</p> : null}
                    {subMessage ? <p>{subMessage}</p> : null}
                    {showPhone ?
                        <div>
                            <p>{i18n.t(phoneMessage)}</p>
                            <br />
                            {/* 区号和电话号码要同时存在 */}
                            {
                                tel_prefix && phone ?
                                    <a href={`tel: ${tel_prefix} ${phone}`}>{'+'}{tel_prefix} {phone}</a> : null
                            }
                        </div> : null}
                    {returnButton ? <button className='returnBtn'
                        onClick={handleReturn}>
                        {i18n.t('payment_page_button_return')}</button> : null}
                </div>

            </div>
        )
    } else {
        return (
            <div className='PaymentError'
                onClick={(evt) => evt.stopPropagation()}>
                <div className='mainContainer'>
                    <MdClear className='closeIco' onClick={() => { props.handleCloseError() }} />
                    <MdError className='errorIco' />
                    {message ? <p>{message}</p> : null}
                    {subMessage ? <p>{subMessage}</p> : null}
                    {showPhone ?
                        <div>
                            <p>{i18n.t(phoneMessage)}</p>
                            <br />
                            {/* 区号和电话号码要同时存在 */}
                            {
                                tel_prefix && phone ?
                                    <a href={`tel: ${tel_prefix} ${phone}`}>{'+'}{tel_prefix} {phone}</a> : null
                            }
                        </div> : null}
                    {returnButton ? <button className='returnBtn'
                        onClick={handleReturn}>
                        {i18n.t('payment_page_button_return')}</button> : null}
                </div>

            </div>
        )
    }
}


// 错误弹窗
function ErrorDialog(
    props: {
        message: string,
        closeFlag: boolean,
        handleCloseError?: () => void
    }
) {
    const { message, closeFlag } = props;
    return (
        <div className='errorDialog'>
            <div className='mainContainer'>
                {closeFlag ? <MdClear className='closeIco'
                    onClick={() => {
                        if (props.handleCloseError) {
                            props.handleCloseError()
                        }
                    }} />
                    : null}
                <MdError className='errorIco' />
                <p>{message}</p>

            </div>
        </div>
    )
}

// 获取到电话输入框
export function PhoneInput(
    props: {
        selectItem: string,
        defaultInput: string,
        handleChange: (val: string) => void;
        handleSelect: (val: string) => void;
        hideLabel?: boolean;
        className?: string;
        type?: string;
        disabled?: boolean;
        changePhone?: () => void;
    }
) {
    const { selectItem, defaultInput, hideLabel, className, type, disabled, changePhone } = props;

    // 选择手机前缀
    // function handleSelect(val) {
    //     props.handleSelect(val.dial_code);
    // }
    return (
        <div className={`phoneInput ${className || ''}`} >
            {!hideLabel && <p className='title'>{i18n.t('account_page_text_phone')}</p>}
            <div className={`mainContainer ${disabled ? 'disabledMainContainer' : ''}`}  >
                <div>
                    {/* <Selete
                        defaultValue={selectItem}
                        options={optionList}
                        handleChange={(val) => {
                            if (!disabled) {
                                handleSelect(val)
                            }
                        }}
                        disabled={disabled}
                    /> */}
                    <ReactPhoneInput
                        disabled={disabled}
                        enableSearch={false}
                        disableSearchIcon={true}
                        searchPlaceholder={i18n.t('general_text_search')}
                        searchNotFound={''}
                        // preferredCountries={['it', 'es', 'lu']}
                        onlyCountries={['it', 'cn', 'es', 'lu']}
                        onChange={(_, data) => {
                            props?.handleSelect?.((data as { dialCode: string })?.dialCode);
                        }}
                        containerClass='countrySelectBox'
                        searchClass='search-pre'
                        dropdownClass='drop-pre'
                        buttonClass='button-pre'
                        inputStyle={{ display: 'none' }}
                        value={selectItem || '39'}
                    />
                </div>

                <input className='phone'
                    value={defaultInput}
                    type='tel'
                    pattern='[0-9]'
                    placeholder={i18n.t('delivery_address_page_text_enter_your_phone')}
                    disabled={disabled}
                    onChange={(evt) => {
                        const EXP = /^[0-9]*$/;
                        if (EXP.test(evt.target.value)) {
                            props.handleChange(evt.target.value)
                        }
                    }} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '2.222vw' }}>
                    {defaultInput.length > 0 ? (
                        IS_PHONE(selectItem, defaultInput) ?
                            <Check style={{ right: 10, color: '#fff', width: 20, height: 20, padding: 1, backgroundColor: 'green', borderRadius: 50 }} className='signin-label' /> :
                            <Clear style={{ right: 10, color: '#fff', width: 20, height: 20, padding: 1, backgroundColor: 'red', borderRadius: 50 }} className='signin-label' />) :
                        <Check style={{ opacity: 0, width: 20, height: 20 }} />}
                </div>
                {type === 'autoComplete' && disabled ?
                    <div className='changePhone' onClick={() => { changePhone?.() }}>
                        {i18n.t('payment_page_text_change')}
                    </div>
                    : null}
            </div>

        </div >
    )
}

// 气泡组件
export function AirBubbles(
    props: {
        title: string;
        attachClass?: string;
        onClick?: () => void;
        style?: React.CSSProperties;
    }
) {
    const { title, attachClass, onClick, style } = props;
    return (
        <div style={{ ...style }} className={`airBox ${attachClass || ''}`} onClick={() => { onClick?.() }}>
            <span dangerouslySetInnerHTML={{ __html: title }}>
            </span>
        </div>
    )

}

// 免注册模式用户信息
export function FreeModeCustomerInfoBox(props: {
    freeModeCustomerInfo: FreeModeCustomerInfo;
    verificationCode: string;
    setCustomerInfo: (val) => void;
    updateState: (val) => void;
    isCompleteFreeModeCustomer: boolean;
}) {
    const { freeModeCustomerInfo, verificationCode, setCustomerInfo, updateState, isCompleteFreeModeCustomer } = props;
    const { first_name, last_name, phone, phone_prefix } = freeModeCustomerInfo
    return (
        <div className={`customerInfoBox ${isCompleteFreeModeCustomer ? '' : 'errorInput'}`} >
            <div className='customerNameBox'>
                <AddressInput
                    styleName='firstName customerNameInput'
                    placeholderTxt={i18n.t('delivery_address_page_text_enter_your_first_name')}
                    inputValue={first_name}
                    handelChange={(input) => {
                        setCustomerInfo({ first_name: input?.trim() })
                    }} />
                <span style={{ width: '1vw' }}></span>
                <AddressInput
                    styleName='firstName customerNameInput'
                    placeholderTxt={i18n.t('delivery_address_page_text_enter_your_last_name')}
                    inputValue={last_name}
                    handelChange={(input) => {
                        setCustomerInfo({ last_name: input?.trim() })
                    }} />
            </div>
            <PhoneInput
                className={'customerInfoPhoneInput'}
                hideLabel={true}
                selectItem={getRelatedCode(Number(phone_prefix || '39'))}
                defaultInput={phone || ''}
                handleChange={(val) => {
                    setCustomerInfo({ phone: val?.trim() })
                }}
                handleSelect={(val) => {
                    setCustomerInfo({ phone_prefix: val?.trim() })
                }}
            />
            <VerificationComponent
                // textColor={'black'}
                className='paymentVerification'
                defaultValue={verificationCode}
                value={phone}
                phone_prefix={phone_prefix}
                type={'payment'}
                onChange={(val: string) => {
                    updateState({ verificationCode: val })
                }}
            />
        </div >
    )
}



export type PaymentBoxType = {
    handleOpenNote: () => void,
    handleClosePaymentBox: () => void,
    noteValue: string,
    isAddNoted: boolean,
    restaurantsInfo: Restaurant,
    isPickUp: boolean,
    saveCurrentAddress: Address,
    // 判断支付方式 0，1，2   0未选择 1选择paypal  2选择为线下支付  (这个也决定跳转框)
    paymenMethod: number,
    // 日期
    performanceDate: Date,
    setPaymenMethod: (method: number) => void,
    // 订单数据
    orderId: string,
    paymentType: string,
    // 支付状态
    payment_status: string,
    // 订单数据
    orderData: object
    // 控制气泡显示
    isShowPaypal: boolean
    // 控制气泡
    handlePaypalBubble: (val: boolean) => void
    // 优惠券list
    handleOpenCouponList: () => void
    // 优惠券包
    couponPackage: Array<any>
    // 选中的优惠券id
    usedCouponIds: Array<string>
    // 总价
    originalTotalPrice: number
    // 点击使用coupon
    onClickCoupon: (coupon: any) => void
    // 优惠后的价钱
    discountedPrice: number;
    // 优惠了多少钱
    deducted: number;
    // 优惠后的配送费
    totalDeliveryFee: number;
    // 圆环配送费的所在环的最低配送金额
    lowestPriceForDelivery: number;
    // 圆环内的配送费
    inRingDeliveryFee: number;
    // 餐馆主题
    restaurantTheme: RestaurantTheme;
    // 餐馆主题色
    restaurantThemeColor: RestaurantThemeColor;
    // 免注册模式
    loginFreeMode: boolean;
    // 免注册模式个人信息
    freeModeCustomerInfo: FreeModeCustomerInfo;
    // 显示领取优惠券框
    showInsertCode: () => void;


}