import React from 'react';
import _, { isString } from 'lodash';
import { PropType } from '../../constants/base.constant';
import { analyzeRulesForUseOfCoupons } from '../../constants/tool-analyze-rules-for-use-of-coupons';
import { getQueryString } from '../../constants/tool-get-parms';
import { ignoreGetCouponByCustomer } from '../../constants/tool-ignore-get-coupon-by-customer';
import { CartActions, CheckoutDetailCartDish, CheckoutErrorTips, ICheckoutOrderDetail, ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions, ICheckoutOrderDetailOrderDescribeStatusNames, ICheckoutOrderDetailProps, ICheckoutOrderDetailShowedPageNames, ICheckoutOrderDetailState } from '../../interfaces';
import { Address, Customer, CustomerProjectionFixed, Restaurant, SettingClosedSchedule } from '../../models';
import { CheckoutCoupon } from '../../models/fixed/checkout-coupon.entity';
import apiManage from '../../request';
import CheckoutCart from './checkout-cart';
import CheckoutCouponComponent from './checkout-coupon';
import CheckoutCouponListComponent from './checkout-coupon-list';
import CheckoutFulfillmentAddress from './checkout-fulfillment-address';
import CheckoutFulfillmentTime from './checkout-fulfillment-time';
import CheckoutNewAddress from './checkout-new-address';
import CheckoutPaymentButton from './checkout-payment-button';
import CheckoutTotalPrice from './checkout-total-price';
import './scss/checkout-order-detail-page.styles.scss'
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import { onSelectCoupons } from '../coupon/tool.coupon';
import moment from 'moment';
import i18n from '../../i18n';
import { checkToken } from '../../constants/tool-check-token';
import { showApiMessage } from '../../messenger/messageManager';
import { checkAddress, checkDistance } from '../../constants/tool-googlemap';
import Notification from '../../component/snackbar/snackbar';
import { CachedAddressDeliveryFee } from '../../constants/tool-check-level-delivery';
import { createHashHistory } from 'history';
import { CircularProgress } from '@material-ui/core';
import CheckoutOrderRemarks from './checkout-order-remarks';
import { CURRENT_TIME } from '../../constants/tool-check-time';
import Store from '../../rudex/store';
import { v4 as uuidv4 } from 'uuid';
import { CheckoutCouponPackage } from '../../models/fixed/checkout-coupon-package.entity';
import { getMessages } from '../../tool/notices.tool';
import { DefaultWebsocketClient } from '../../modules/websocket/module';
import { updateScheduleData } from '../../constants/tool-update-schedule-data';
import momentTimeZone from 'moment-timezone';
import { checkSchedule, DT } from '../../constants/tool-constant';
import { getNextTimeRanges } from '../restaurant/analyzeRestaurantSchedule.tool';
import { getTargetDateCanceledSchedule, isAvailableDish } from '../restaurant/schedule-dialog.component';
import { LevelDeliveryFee } from '../../models/abstract/level-delivery-fee.entity';
import { renderSetMenuDetail } from './checkout-cart-item';
import { PM } from '../order/tool-points';
import { DishFixed } from '../../models/fixed/dish.entity';
import CheckoutOrderTableware from './checkout-order-tableware';

class CheckoutOrderDetailPage extends React.Component<Props, State> implements ICheckoutOrderDetail {

    constructor(props: Props) {
        super(props)
        this.handleWebsocketBlacklistedUser = this.handleWebsocketBlacklistedUser.bind(this)
        this.analysisAndAdjustCoupon = this.analysisAndAdjustCoupon.bind(this)
    }
    public state: State = {
        /**
         * 当前显示的子页面
         * MAIN_PAGE - 带有多个子组件的主页面
         * NEW_ADDRESS_PAGE - 新增地址页面
         * COUPON_LIST_PAGE - 优惠券列表页面
         */
        showedPage: ICheckoutOrderDetailShowedPageNames.MAIN_PAGE,
        // 订单的描述状态
        orderDescribeStatus: ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_PICKUP,
        // 账户信息
        customerInfo: {},
        // 餐厅string_id
        restaurantStringId: LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID),
        // 餐厅信息
        restaurantInfo: {},
        // 餐厅logo
        restaurantLogo: '',
        // 餐厅地址和餐厅名称
        restaurantAddress: { nickname: '', address: '' },
        // 当前地址
        currentAddress: {},
        // 所有地址
        allAddresses: [],
        // 下单间隔时间
        orderIntervalTime: 20,
        // 营业时间
        serviceSchedules: [],
        // 停业时间
        closeSchedules: [],
        // 营业总开关
        takeawayServiceStatus: false,
        // 购物车
        cartDishes: [],
        // 购物车菜品总数量
        totalCartDishes: 0,
        // 免领取的优惠券
        originExemptCoupon: [],
        // 新领取的优惠券code数组
        newlyReceivedCouponCodes: [],
        // 账户的优惠券
        originCoupon: [],
        // 所有优惠券
        allCoupons: [],
        // 已选择的优惠券
        selectedCoupons: [],
        // 折扣
        discount: 0,
        // 原始配送费
        originalDeliveryFee: _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE), 'delivery_fee', 0),
        // 配送费
        discountedDeliveryFee: 0,
        // 原始总价格（菜品总价格）
        originalTotalPrice: 0,
        // 不参于折扣的菜品原始总价格
        originalTotalNoDiscountPrice: 0,
        // 参于折扣的菜品原始总价格
        originalTotalDiscountPrice: 0,
        // 是否可以付款,是否有错误
        errorTips: [],
        // 最终价格
        finalOrderPrice: 0,
        // 当前餐厅的集团积分
        integralValue: 0,
        // 履约时间值
        fulfillmentTime: CURRENT_TIME,
        // 可选择的最小时间
        minTime: CURRENT_TIME,
        // 可选择的最大时间 （默认7天）
        maxTime: new Date(moment(new Date()).add(6, 'day').set({ 'hour': 23, 'minute': 59, 'second': 0 }).valueOf()),
        // 是否禁用用户操作（在执行一些操作时，应当禁止用户进行其他操作）
        isDisableCustomerAction: false,
        // 修改的地址
        editAddress: {},
        // 是否修改地址
        isEditAddress: false,
        // 是否在营业时间
        isBusinessTime: false,
        // 加载动画
        loadingStatus: false,
        // 订单备注
        orderRemark: '',
        // 是否已经初始化优惠券
        isInitCoupon: false,
        // 是否已经初始化整体
        init: false,
        // 是否为被屏蔽用户
        isBlockUsers: false,
        // 检查履约时间是否合法
        checkFulfillMentTimeFlat: false,
        // 餐具数量
        tablewareNumber: 1
    }

    updateState<S = Partial<State>>(update: S): void {
        this.setState(update as any);
    }

    // *********************
    // Life Cycle Function
    // *********************

    async componentDidMount() {
        // 初始化
        const initResult = await this.init<State>();
        // 更新视图
        this.setState(initResult, () => {
            // 更新点餐页面State
            this.props.updateOrderPageState({ fulfillmentTime: this.state.fulfillmentTime });
            Store.dispatch({
                type: 'SET_STATE',
                path: 'globalLoading',
                value: false
            })
        });
        // 监听用户是否被屏蔽
        DefaultWebsocketClient.addListener('BLOCKED_USERS_CREATE_EVENT', this.handleWebsocketBlacklistedUser);
        // 监听用户是否解除屏蔽
        DefaultWebsocketClient.addListener('BLOCKED_USERS_RELIEVE_EVENT', this.handleWebsocketBlacklistedUser);
        // 更新优惠券状态
        DefaultWebsocketClient.addListener('REFRESH_COUPON_POINTS', this.analysisAndAdjustCoupon);

    }

    async UNSAFE_componentWillReceiveProps(nextPros: Props) {
        if (nextPros.visible === true && this.state.init) {
            this.getCartInfo();
        }
    }

    componentWillUnmount() {
        // 移除黑名单相关监听
        DefaultWebsocketClient.removeListener('BLOCKED_USERS_CREATE_EVENT', this.handleWebsocketBlacklistedUser);
        DefaultWebsocketClient.removeListener('BLOCKED_USERS_RELIEVE_EVENT', this.handleWebsocketBlacklistedUser)
        // 更新优惠券状态
        DefaultWebsocketClient.removeListener('REFRESH_COUPON_POINTS', this.analysisAndAdjustCoupon);
    }

    // *********************
    // Default Function
    // *********************

    /**
     * 初始化
     */
    public async init<T>(): Promise<T> {
        Store.dispatch({
            type: 'SET_STATE',
            path: 'globalLoading',
            value: true
        })
        const currentResStringID = this.state.restaurantStringId;
        // 获取订单交付方式：地址渠道
        const orderWay = getQueryString('orderway');
        let orderDescribeStatus = this.state.orderDescribeStatus;
        if (orderWay === '1') {
            orderDescribeStatus = ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_PICKUP;
            LocalStorageManager.setLocalStorage(
                {
                    path: LOCAL_STORAGE_KEYS.DELIVERY_FEE,
                    value: {}
                }
            )
            LocalStorageManager.setLocalStorage(
                {
                    path: LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR,
                    value: []
                }
            )
        } else if (orderWay === '2') {
            orderDescribeStatus = ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY
        }
        // 获取餐馆详情
        const restaurantCache = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const customerId: string = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT), '_id', '')
        let restaurantInfo: Restaurant = {};
        if (restaurantCache && currentResStringID) {
            restaurantInfo = _.get(_.get(restaurantCache, currentResStringID), 'restaurant', {});
        }
        if (JSON.stringify(restaurantInfo) === '{}') {
            // 餐馆信息
            restaurantInfo = (await apiManage.getRestaurantInfo(currentResStringID, { notNeedLoading: true }, customerId))?.dbResults?.[0] || {};
        }
        // 获取顾客信息
        let customerInfo: Customer = {};
        if (customerId) {
            const customerInfoResult = await apiManage.get_customer_info(customerId, { notNeedLoading: true },
                {
                    ...{ blocked_group_string_id: restaurantInfo.belonged_group },
                    ...CustomerProjectionFixed(['pr_key_info', 'pr_basic_info', 'pr_coupon', 'pr_integral_info', 'pr_member', 'pr_messages_info', 'pr_key_info'])
                }
            );
            if (!_.get(customerInfo, 'error')) {
                customerInfo = customerInfoResult;
                // 获取通知信息
                getMessages(_.get(customerInfo, 'messages', 0));
            }
        }
        // 获取餐馆免领取优惠券：接口渠道
        let originExemptCoupon: CheckoutCouponPackage[] = [];
        const result = await apiManage.getExemptCoupon({ string_id: currentResStringID }, { notNeedLoading: true });
        if (!_.get(result, 'error')) {
            // 拿到我的优惠券
            const couponPackage = _.get(_.cloneDeep(customerInfo), 'coupons') || [];
            originExemptCoupon = result;
            // 免领取优惠券数组（过滤自己拥有的免领取的优惠券）
            originExemptCoupon = _.filter(originExemptCoupon, (n) => {
                return !(_.find(couponPackage, (v) => { return v._id === n._id }))
            })
        }
        // 获取餐馆LOGO图片：接口渠道
        const restaurantLogoResult = await apiManage.get_restaurant_logo(restaurantInfo._id || '', { notNeedLoading: true });
        let restaurantLogo = ''
        if (!_.get(restaurantLogoResult, 'error')) {
            restaurantLogo = restaurantLogoResult;
        }
        // $ 获取用户在集团下的积分
        // 找到所在集团在数组对象
        const integralsItem = _.find(customerInfo.integrals, { group_string_id: restaurantInfo.belonged_group });
        let integralValue = 0;
        if (integralsItem) {
            // 在所在集团有多少积分
            integralValue = _.get(integralsItem, 'value', 0)
        }

        // 获取菜品原始总价
        const CartCache = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR);
        let originalTotalPrice = 0;
        let originalTotalPoints = 0;
        if (CartCache && currentResStringID) {
            const shopCarList = _.get(_.get(CartCache, currentResStringID), 'shopCarList');
            // 菜品原始总价格
            if (_.isArray(shopCarList)) {
                shopCarList.forEach((item) => {
                    if (item.is_points_redemption) {
                        originalTotalPoints += (item.buyNumber * item.price);
                    } else {
                        originalTotalPrice += item.buyNumber * item.price;
                    }
                })
            }
        }

        // 调用子组件初始化方法：`initFulfillmentAddress()`
        const initAddress = await this.initFulfillmentAddress(customerInfo, originalTotalPrice);

        // 调用子组件初始化方法：`initFulfillmentTime()`
        const minTime = this.initFulfillmentTime(_.get(restaurantInfo, 'setting_order_interval_time'));
        // 设置最大可选日期
        let maxTime = this.state.maxTime;
        if (restaurantInfo.setting_max_takeout_day) {
            maxTime = new Date(moment().add(Number(restaurantInfo.setting_max_takeout_day) - 1, 'day').set({ 'hour': 23, 'minute': 59, 'second': 0 }).valueOf());
        }
        // 找出下一个营业时间
        const fulfillmentTime = this.checkFulfillmentTimeRecentlyAvailableTime(minTime, maxTime);
        // 更新积分
        PM.updatePoints({ key: 'originPoints', value: integralValue });
        PM.updatePoints({ key: 'dishUsedPoints', value: originalTotalPoints }, true);

        return {
            orderDescribeStatus,
            serviceSchedules: restaurantInfo.setting_app_takeaway_service_schedule || [],
            closeSchedules: restaurantInfo.setting_closed_schedule || [],
            takeawayServiceStatus: restaurantInfo.setting_app_takeaway_service_status || false,
            restaurantAddress: { nickname: restaurantInfo.nickname, address: restaurantInfo.google_map_formatted_address },
            restaurantInfo,
            customerInfo,
            originExemptCoupon,
            restaurantLogo,
            fulfillmentTime: fulfillmentTime ? this.initFulfillmentTime(0, fulfillmentTime) : minTime,
            minTime,
            maxTime,
            allAddresses: initAddress.allAddresses || [],
            currentAddress: initAddress.currentAddress || {},
            integralValue,
            originalDeliveryFee: initAddress.originalDeliveryFee || 0,
            discountedDeliveryFee: initAddress.discountedDeliveryFee || 0,
            isBlockUsers: customerInfo.is_blocked_user,
            init: true
        } as unknown as T;
    }

    /**
     * 用户被纳入黑名单或恢复正常
     */
    handleWebsocketBlacklistedUser(data) {
        try {
            const { restaurantStringId, restaurantInfo } = this.state;
            const groupId = restaurantInfo.belonged_group
            const groupStringId = _.get(data, 'group_string_id', '')
            const resStringIdArr = _.get(data, 'value', [])
            let isBlackUser = false
            // 该用户是否为所属集团下被禁用或解禁的餐馆
            if (groupId === groupStringId && _.isArray(resStringIdArr)) isBlackUser = resStringIdArr.includes(restaurantStringId)
            if (isBlackUser) {
                if (_.get(data, 'type', '') === 'BLOCKED_USERS_CREATE_EVENT') {
                    this.setState({ isBlockUsers: true })
                } else if (_.get(data, 'type', '') === 'BLOCKED_USERS_RELIEVE_EVENT') {
                    this.setState({ isBlockUsers: false })
                }
            }
        } catch (error) {

        }
    }

    // ***************
    // 子组件初始化类方法
    // ***************

    initFulfillmentTime(orderIntervalTime?: number, date?: string): Date {
        const step = this.getTimeStep();

        // 获取当餐馆配送最低时间,初始时间要加上餐厅最低配送时间
        // 在这里排除0的情况  null和undefined都设置为30分钟
        orderIntervalTime = orderIntervalTime ?? 30;
        // 当分钟数不大于20的时候，要当前的%20 加上餐馆定的事件的分钟数
        // 这里有一种情况就是当前分钟+餐厅分钟 === 20 或者 0 . 下面除法依旧时1 ，应该改为2才对
        // 20 - 40 这个范围就不对
        orderIntervalTime = orderIntervalTime > 40 ?
            orderIntervalTime : (moment(date ?? new Date()).minute() % step) + orderIntervalTime;

        let minTime = new Date(moment(date ?? new Date()).valueOf());
        // 补充的时间差，补充到20 40 60
        const timeEquation = moment(date ?? new Date()).minute() % step;
        // 刚好等于则不需要补
        if (timeEquation !== 0 || !date) {
            minTime = new Date(moment(date ?? new Date())
                .add(
                    ((Math.floor((orderIntervalTime) / step) + 1) * step) - (moment(date ?? new Date()).minute() % step),
                    'minutes'
                )
                .valueOf()
            );
        }
        return minTime;
    }

    async initFulfillmentAddress(customerInfo: Customer, originalTotalPrice: number): Promise<{
        allAddresses: Array<Address>;
        currentAddress: Address;
        originalDeliveryFee: number;
        discountedDeliveryFee: number;
    }> {
        // 判断自取还是配送
        const orderWay = getQueryString('orderway');
        let allAddresses = _.get(customerInfo, 'address') || [];

        const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const res_info = _.get(res, `[${res_string_id}].restaurant`);

        // 自取
        if (orderWay === '1') {
            return {
                allAddresses: [],
                currentAddress: {},
                // 原始配送费 在detail里更新配送费
                originalDeliveryFee: 0,
                // 折扣后的配送费
                discountedDeliveryFee: 0
            }
        }
        // 计算是否在配送范围
        allAddresses = await checkAddress(res_info, allAddresses);

        let currentAddress = _.find(allAddresses, (n) => { return n.default === true }) || {};
        // 是否在配送范围内
        if (!currentAddress.isWithin) {
            currentAddress = {};
        }
        // 计算配送费
        await CachedAddressDeliveryFee(currentAddress);
        const deliveryFeeArr = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR) || [];
        // 找出最贵的配送费区域规则
        this.getBestMatchDeliveryFee(deliveryFeeArr, originalTotalPrice);
        // 更新点餐页面State
        this.props.updateOrderPageState();
        return {
            allAddresses,
            currentAddress,
            // 原始配送费 在detail里更新配送费
            originalDeliveryFee: _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE), 'delivery_fee', 0),
            // 折扣后的配送费
            discountedDeliveryFee: this.state.discountedDeliveryFee
        }
    }

    initCoupon(): CheckoutCouponPackage[] {
        try {
            const { customerInfo, restaurantInfo, orderDescribeStatus, originExemptCoupon, fulfillmentTime, selectedCoupons,
                restaurantStringId, originalTotalDiscountPrice, isInitCoupon, cartDishes
            } = this.state;
            if (!isInitCoupon) {
                let newCouponPackage: CheckoutCoupon[] = [];
                const stringId = restaurantStringId;
                // 免领取的优惠券
                let exemptCoupon = originExemptCoupon;
                // 账户的优惠券
                const couponPackage = _.get(_.cloneDeep(customerInfo), 'coupons') || [];
                // 分析免领取优惠券领取规则
                exemptCoupon = exemptCoupon.map((item) => {
                    // 领取规则
                    const result = ignoreGetCouponByCustomer(customerInfo, item, restaurantInfo);
                    // 领取规则不通过
                    item.received_status = result.pass;
                    // 不符合的规则
                    item.receive_fail_rule = result.receive_fail_rule;
                    // 添加优惠券的coupon_id，用作与创建订单需要传的id
                    item.coupon_id = item._id;
                    // 不是账户自己的优惠券
                    item.is_customer_coupon = false;
                    return item;
                })
                const isPickUp = orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_PICKUP;
                // 免领取调用分析使用规则
                // 拿到分析后的优惠券组 display_status显示状态（'0'：可显示；'1'：不显示）
                exemptCoupon = _.filter(exemptCoupon, (n) => { return n.display_status === '0' });
                // 免领取优惠券数组（过滤自己拥有的免领取的优惠券）
                exemptCoupon = _.filter(exemptCoupon, (n) => {
                    return !(_.find(couponPackage, (v) => { return v._id === n._id }));
                });
                const newExemptCoupon = _.cloneDeep(exemptCoupon);
                const analyzeResult = analyzeRulesForUseOfCoupons(newExemptCoupon, originalTotalDiscountPrice, isPickUp, fulfillmentTime, selectedCoupons, cartDishes, stringId);
                exemptCoupon = _.concat(analyzeResult.qualified, analyzeResult.unqualified);
                // 使用规则
                const analyzeResult2 = analyzeRulesForUseOfCoupons(couponPackage, originalTotalDiscountPrice, isPickUp, fulfillmentTime, selectedCoupons, cartDishes, stringId);
                newCouponPackage = _.concat(analyzeResult2.qualified, analyzeResult2.unqualified);
                // $ 只显示当前餐馆的优惠券 && '自取'或'配送'
                newCouponPackage = _.filter(newCouponPackage, (n) => {
                    // 适用与当前餐馆的优惠券
                    const ApplicableRestaurants = _.map(n.string_ids || [], (item) => { return item && item.string_id });
                    // 优惠券类型有且只有一个'堂食'
                    const onlyDineInTheShop = n.order_type_rule_enable && n.order_type_rule_types?.length === 1 && n.order_type_rule_types[0] === '2';
                    const pass = _.includes(ApplicableRestaurants, stringId) && !onlyDineInTheShop;
                    return pass;
                });
                // 设置一个值，识别是否是用户的优惠券
                newCouponPackage = newCouponPackage.map((n) => {
                    _.set(n, 'is_customer_coupon', true)
                    return n;
                });
                const allCoupons = _.concat(exemptCoupon, newCouponPackage);
                // 优惠券排序
                allCoupons.sort(this.compareCoupon);
                return allCoupons
            } else {
                return this.state.allCoupons
            }

        } catch (error) {
            return []
        }
    }

    /**
     * 获取购物车详情
     */
    public getCartInfo() {
        try {
            const CartCache = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR);
            const currentResStringID = this.state.restaurantStringId;
            if (CartCache && currentResStringID) {
                const shopCarList = _.get(_.get(CartCache, currentResStringID), 'shopCarList');
                const cartInfo: CheckoutDetailCartDish[] = [];
                // 不参于折扣的菜品原始总价格
                let originalTotalNoDiscountPrice = 0;
                // 参于折扣的菜品原始总价格
                let originalTotalDiscountPrice = 0;
                // 兑换积分
                let originalTotalPoints = 0;
                if (_.isArray(shopCarList)) {
                    shopCarList.map((item: DishFixed) => {
                        // 总积分
                        if (item.is_points_redemption) {
                            originalTotalPoints += ((item.buyNumber || 0) * (item.price || 0));
                        } else if (item.is_no_discount && item.is_no_discount === true) {
                            // 不参与折扣
                            originalTotalNoDiscountPrice += ((item.buyNumber || 0) * (item.price || 0));
                        } else {
                            // 参与折扣
                            originalTotalDiscountPrice += ((item.buyNumber || 0) * (item.price || 0));
                        }

                        // 是否可售菜品
                        item.unavailableTime = !isAvailableDish(item.availableSchedule || [], this.state.fulfillmentTime, this.state.orderDescribeStatus === 'TAKEAWAY_ORDER_PICKUP' ? '1' : '2');
                        // 更新购物车在托盘的数据结构
                        cartInfo.push({
                            coupons: [],
                            dish: item,
                            num: item.buyNumber || 0,
                            helpfulId: uuidv4()
                        })
                        return item
                    })
                }
                // 菜品总数量
                const totalCartDishes = _.sum(_.map(cartInfo, (n) => { return n.num }));
                this.setState({
                    cartDishes: cartInfo,
                    totalCartDishes,
                    originalTotalNoDiscountPrice,
                    originalTotalDiscountPrice,
                    originalTotalPrice: originalTotalNoDiscountPrice + originalTotalDiscountPrice,
                    originalTotalPoints,
                }, () => {
                    // 初始化优惠券
                    const allCoupons = this.initCoupon();
                    this.setState({ allCoupons, isInitCoupon: true }, () => {
                        // 分析和执行购物车
                        this.analysisAndAdjustCart([{ type: 'REFRESH' }]);
                    })
                })
            }
        } catch (error) { }
    }

    // *********************
    // 子组件分析类方法
    // *********************

    public updateShowedPage(targetPage: ICheckoutOrderDetailShowedPageNames) {
        this.setState({ showedPage: targetPage })
    }

    public analysisFulfillmentTime(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions): boolean {
        // 判断设置的时间是否在营业时间
        const fulfillment = _.get(clonedStateForFns, 'fulfillmentTime');
        // 配送时间不能早于现在
        if (moment(fulfillment, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').isBefore(moment().subtract(2, 'minutes'))) {
            Notification.notification(`${i18n.t('payment_page_text_selected_time_cannot_earlier_current_time')}`);
            return false;
        }
        // 配送时间不能晚于一周
        // if (moment(fulfillment, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').isAfter(moment().add(24 * 7, 'hours'))) {
        //     Notification.notification(`${i18n.t('payment_page_text_selected_time_cannot_later_seven_day')}`);
        //     return false;
        // }
        return true
    }

    public analysisFulfillmentAddress(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions): boolean {
        const allAddress = _.get(clonedStateForFns, 'allAddresses');

        return _.isArray(allAddress) && allAddress.length > 0;
    }

    public analysisCart(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions, ignorePoints?: boolean): boolean {
        const cartDishes: Array<CheckoutDetailCartDish> = _.get(clonedStateForFns, 'cartDishes');
        const invalid = _.find(cartDishes, (n) => { return n.num > 99 });

        // 积分不够
        const pass = ignorePoints || PM.getAvailablePoints() >= 0;
        if (invalid) {
            Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
            return false
        } else if (!pass) {
            Notification.notification(`${i18n.t('order_page_text_not_enough_for_points')}`);
            return false
        } else {
            return true;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public analysisCoupon(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions): boolean {
        return true
    }

    // *********************
    // 子组件调整类方法
    // *********************

    public adjustCart(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions): {
        cartDishes: Array<CheckoutDetailCartDish>, totalCartDishes: number, originalTotalPrice: number
    } {
        let cartDishes: CheckoutDetailCartDish[] = _.get(clonedStateForFns, 'cartDishes');
        const totalCartDishes: number = _.get(clonedStateForFns, 'totalCartDishes');
        const originalTotalPrice: number = _.get(clonedStateForFns, 'originalTotalPrice');
        const selectedCoupons: CheckoutCoupon[] = _.get(clonedStateForFns, 'selectedCoupons');
        const fulfillmentTime: Date = _.get(clonedStateForFns, 'fulfillmentTime');
        // 修改菜品优惠券使用情况
        if (_.isArray(cartDishes) && cartDishes.length > 0) {
            cartDishes = cartDishes.map((dishItem) => {
                if (_.isArray(selectedCoupons)) {
                    if (selectedCoupons.length === 0 || dishItem.dish.is_no_discount) {
                        dishItem.coupons = [];
                    } else if (selectedCoupons.length > 0) {
                        const usedCoupons: {
                            _id: PropType<CheckoutCoupon, '_id'>,
                            name: PropType<CheckoutCoupon, 'name'>
                        }[] = [];
                        // 生效目标对应的菜品
                        selectedCoupons.forEach((couponItem) => {
                            const ETResItem = _.find(couponItem.effectiveTargetsRestaurants, (n) => { return n.res_string_id.string_id === this.state.restaurantStringId });
                            // 且有对应餐馆
                            if (couponItem.effectiveTargetsRestaurants && ETResItem) {
                                const hasMenuTarget = _.findIndex(ETResItem?.menuData, (targetMenu) => { return targetMenu._id === dishItem.dish.menuId }) !== -1;
                                const hasDishTarget = _.findIndex(ETResItem?.dishData, (targetDish) => { return targetDish._id === dishItem.dish._id }) !== -1;
                                if (hasMenuTarget || hasDishTarget) {
                                    usedCoupons.push({
                                        _id: couponItem.coupon_id,
                                        name: couponItem.name
                                    })
                                }
                            } else {
                                usedCoupons.push({
                                    _id: couponItem.coupon_id,
                                    name: couponItem.name
                                })
                            }
                        })
                        dishItem.coupons = usedCoupons;
                    }
                    // 可售时间
                    dishItem.dish.unavailableTime = !isAvailableDish(dishItem.dish.availableSchedule || [], fulfillmentTime || this.state.fulfillmentTime, this.state.orderDescribeStatus === 'TAKEAWAY_ORDER_PICKUP' ? '1' : '2');
                }
                return dishItem
            })
            // 是否显示左侧优惠券
            cartDishes.forEach((dishItem) => {
                // 单个菜品使用优惠券情况是否与其他菜品情况一致
                if (_.findIndex(cartDishes, (n) => !_.isEqual(dishItem.coupons, n.coupons)) !== -1) {
                    dishItem.showCoupon = dishItem.coupons.length === 0 ? false : true;
                } else {
                    dishItem.showCoupon = false;
                }
            })
        }
        return { cartDishes, totalCartDishes, originalTotalPrice };
    }

    public adjustCoupon(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions): {
        allCoupons: CheckoutCoupon[];
        selectedCoupons: CheckoutCoupon[];
    } {
        const fulfillmentTime: Date = _.get(clonedStateForFns, 'fulfillmentTime');
        let allCoupons: CheckoutCoupon[] = _.get(clonedStateForFns, 'allCoupons');
        const selectedCoupons: CheckoutCoupon[] = _.get(clonedStateForFns, 'selectedCoupons');
        const originalTotalDiscountPrice: number = _.get(clonedStateForFns, 'originalTotalDiscountPrice');
        const cartDishes: CheckoutDetailCartDish[] = _.get(clonedStateForFns, 'cartDishes');
        const isPickUp = this.state.orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_PICKUP;
        const { qualified, unqualified } = analyzeRulesForUseOfCoupons(allCoupons, originalTotalDiscountPrice, isPickUp, fulfillmentTime, selectedCoupons, cartDishes, this.state.restaurantStringId);
        // 将已选择的优惠券数组中去掉不符合规则的优惠券
        if (selectedCoupons.length > 0) {
            _.remove(selectedCoupons, (n) => _.find(unqualified, (m) => m.coupon_id === n.coupon_id));
            _.remove(selectedCoupons, (n) => _.find(qualified, (m) => m.disClickstatus === true && (m.coupon_id === n.coupon_id)));
        }
        allCoupons = _.concat(qualified, unqualified);
        // 已选中的优惠券中，有“当前购物车菜品无法使用此优惠券”
        const hasDiscountDish = _.find(cartDishes, (n) => !n.dish.is_no_discount);
        allCoupons = allCoupons.map((allCouponsItem) => {
            // 没有参与折扣的菜品
            if (!hasDiscountDish) {
                if (_.find(selectedCoupons, (selectedCouponsItem) => selectedCouponsItem.coupon_id === allCouponsItem.coupon_id)) {
                    allCouponsItem.showCantUseText = true;
                } else {
                    allCouponsItem.showCantUseText = false;
                }
            }
            // 有参与折扣的菜品
            else {
                // 选择的优惠券，生效目标不存在购物车
                if (this.checkCouponEffectiveTarget(allCouponsItem, cartDishes) === false) {
                    if (_.find(selectedCoupons, (selectedCouponsItem) => selectedCouponsItem.coupon_id === allCouponsItem.coupon_id)) {
                        allCouponsItem.showCantUseText = true;
                    } else {
                        allCouponsItem.showCantUseText = false;
                    }
                } else {
                    allCouponsItem.showCantUseText = false;
                }
            }
            return allCouponsItem;
        })
        // 优惠券排序
        allCoupons = allCoupons.sort(this.compareCoupon);
        return { allCoupons, selectedCoupons };
    }

    public adjustTotal(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions): {
        discount: number,
        discountedDeliveryFee: number,
        finalOrderPrice: number,
        originalDeliveryFee: number,
        discountedOriginalTotalDiscountPrice: number
    } {
        const selectedCoupons: CheckoutCoupon[] = _.get(clonedStateForFns, 'selectedCoupons');
        const originalTotalDiscountPrice: number = _.get(clonedStateForFns, 'originalTotalDiscountPrice');
        const originalTotalNoDiscountPrice: number = _.get(clonedStateForFns, 'originalTotalNoDiscountPrice');
        const cartDishes: CheckoutDetailCartDish[] = _.get(clonedStateForFns, 'cartDishes');
        // 参与折扣的菜品总价
        const discountedOriginalTotalDiscountPrice = this.getDiscountedOriginalTotalDiscountPrice(originalTotalDiscountPrice, selectedCoupons, cartDishes);
        // 最终的购物车菜品总价 = 优惠后的参与折扣的菜品总价 + 不参与折扣的菜品总价
        const finalCartPrice = discountedOriginalTotalDiscountPrice + originalTotalNoDiscountPrice;
        // 配送费
        const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE);
        let discounted = 0;
        // 自取不需要配送费
        const orderWay = getQueryString('orderway');
        if (orderWay === '2') {
            _.get(deliveryFee, 'delivery_amount_discount', []).forEach(element => {
                if ((_.get(clonedStateForFns, 'originalTotalDiscountPrice') + _.get(clonedStateForFns, 'originalTotalNoDiscountPrice')) >= element.amount_reached) {
                    if (element.discount_fee > discounted) {
                        discounted = element.discount_fee
                    }
                }
            });
        }
        // 折扣后的配送费
        const discounted_Delivery_Fee = (_.get(deliveryFee, 'delivery_fee', 0) - discounted) < 0 ? 0 :
            (_.get(deliveryFee, 'delivery_fee', 0) - discounted);
        return {
            discount: originalTotalDiscountPrice - discountedOriginalTotalDiscountPrice,
            discountedDeliveryFee: discounted,
            finalOrderPrice: finalCartPrice + discounted_Delivery_Fee,
            originalDeliveryFee: _.get(deliveryFee, 'delivery_fee', 0),
            discountedOriginalTotalDiscountPrice
        };
    }

    // *********************
    // 子组件分析并调整类方法
    // *********************

    public analysisAndAdjustFulfillmentTime(selectedDate: Date): void {
        const { selectedCoupons, allCoupons, cartDishes, totalCartDishes, originalTotalPrice,
            discount, originalDeliveryFee, discountedDeliveryFee, originalTotalDiscountPrice,
            finalOrderPrice, originalTotalNoDiscountPrice
        } = this.state;
        const clonedStateForFns = _.cloneDeep({
            fulfillmentTime: selectedDate, minTime: CURRENT_TIME, selectedCoupons, allCoupons, cartDishes, totalCartDishes, originalTotalPrice,
            discount, originalDeliveryFee, discountedDeliveryFee, originalTotalDiscountPrice,
            finalOrderPrice, originalTotalNoDiscountPrice
        });
        // 调用履约时间的分析方法
        const analysisFulfillmentTime = this.analysisFulfillmentTime(clonedStateForFns);

        if (!analysisFulfillmentTime) return;
        // 获取餐厅的备餐时间设置
        const restaurantCache = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const currentResStringID = this.state.restaurantStringId;
        let restaurantInfo: Restaurant = {};
        if (restaurantCache && currentResStringID) {
            restaurantInfo = _.get(_.get(restaurantCache, currentResStringID), 'restaurant');
        }

        clonedStateForFns.fulfillmentTime = selectedDate;
        // 重置最小的时间
        clonedStateForFns.minTime = this.initFulfillmentTime(_.get(restaurantInfo, 'setting_order_interval_time'));
        clonedStateForFns.selectedCoupons = selectedCoupons;
        clonedStateForFns.allCoupons = allCoupons;

        /**
         * 调用优惠劵的调整方法返回被调整的优惠劵
         */
        const adjustCouponResult = this.adjustCoupon(clonedStateForFns);

        clonedStateForFns.selectedCoupons = adjustCouponResult.selectedCoupons;
        clonedStateForFns.allCoupons = adjustCouponResult.allCoupons;

        /**
         * 调用购物车的调整方法
         */
        const adjustCart = this.adjustCart(clonedStateForFns);

        clonedStateForFns.cartDishes = adjustCart.cartDishes;
        clonedStateForFns.totalCartDishes = adjustCart.totalCartDishes;
        clonedStateForFns.originalTotalPrice = adjustCart.originalTotalPrice;

        /**
         * 调用总价的调整方法
         */
        const adjustTotal = this.adjustTotal(clonedStateForFns);
        clonedStateForFns.discount = adjustTotal.discount;
        clonedStateForFns.discountedDeliveryFee = adjustTotal.discountedDeliveryFee;
        clonedStateForFns.finalOrderPrice = adjustTotal.finalOrderPrice;
        clonedStateForFns.originalDeliveryFee = adjustTotal.originalDeliveryFee;

        // 更新视图
        this.setState({ ...clonedStateForFns })

        // 修改点餐视图
        this.props.updateOrderPageState({ fulfillmentTime: clonedStateForFns.fulfillmentTime });
    }

    public analysisAndAdjustFulfillmentAddress(selectedAddress: Address, allAddresses: Array<Address>): void {

        const { currentAddress, discountedDeliveryFee, selectedCoupons, originalTotalDiscountPrice,
            originalTotalNoDiscountPrice, originalDeliveryFee, discount, finalOrderPrice, originalTotalPrice,
            cartDishes
        } = this.state;

        const clonedStateForFns = _.cloneDeep({
            currentAddress, allAddresses, discountedDeliveryFee, selectedCoupons,
            originalTotalDiscountPrice, originalTotalNoDiscountPrice, originalDeliveryFee, discount, finalOrderPrice,
            cartDishes
        });

        const analysisFulfillmentAddress = this.analysisFulfillmentAddress(clonedStateForFns);
        if (!analysisFulfillmentAddress) return;

        clonedStateForFns.currentAddress = selectedAddress;
        clonedStateForFns.allAddresses = allAddresses;

        // 总价详情调整
        const adjustTotalResult = this.adjustTotal(clonedStateForFns);

        // 折扣后的配送费
        clonedStateForFns.discountedDeliveryFee = adjustTotalResult.discountedDeliveryFee;
        clonedStateForFns.discount = adjustTotalResult.discount;
        clonedStateForFns.finalOrderPrice = adjustTotalResult.finalOrderPrice;
        clonedStateForFns.originalDeliveryFee = adjustTotalResult.originalDeliveryFee;

        // 购物车原价小于起送价，提示
        if (this.state.orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY) {
            // 最低配送费金额(起送价)
            const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE) || {};
            if (originalTotalPrice < deliveryFee.delivery_minimum_fee) {
                Notification.notification(`${i18n.t('checkout_page_text_the_original_price_did_not_reach_the_minimum_delivery_amount', { 1: deliveryFee.delivery_minimum_fee })}`);
            }
        }

        // 更新视图
        this.setState({ ...clonedStateForFns })
    }

    public analysisAndAdjustCart(actions: Array<CartActions>): void {
        if (this.state.isDisableCustomerAction) return;
        this.setState({ isDisableCustomerAction: true });
        try {
            const { cartDishes, totalCartDishes, allCoupons, selectedCoupons, originalTotalPrice, discount,
                originalTotalDiscountPrice, originalTotalNoDiscountPrice, discountedDeliveryFee,
                finalOrderPrice, fulfillmentTime, originalTotalPoints
            } = this.state;
            const clonedStateForFns = _.cloneDeep({
                cartDishes, totalCartDishes, allCoupons, selectedCoupons, originalTotalPrice, discount,
                originalTotalDiscountPrice, originalTotalNoDiscountPrice, discountedDeliveryFee,
                finalOrderPrice, fulfillmentTime, originalTotalPoints
            });

            const updateDishNumResult = this.updateDishNum(clonedStateForFns, actions);
            clonedStateForFns.cartDishes = updateDishNumResult.cartDishes;
            clonedStateForFns.totalCartDishes = updateDishNumResult.totalCartDishes;
            clonedStateForFns.originalTotalPrice = updateDishNumResult.originalTotalPrice;
            clonedStateForFns.originalTotalNoDiscountPrice = updateDishNumResult.originalTotalNoDiscountPrice;
            clonedStateForFns.originalTotalDiscountPrice = updateDishNumResult.originalTotalDiscountPrice;

            const pass = PM.updatePoints({ key: 'dishUsedPoints', value: updateDishNumResult.dishUsedPoints, action: 'cart' })
            if (!pass) {
                Notification.notification(`${i18n.t('order_page_text_not_enough_for_points')}`);
            }

            const analysisCartResult = this.analysisCart(clonedStateForFns, true);
            if (!analysisCartResult) {
                this.setState({ isDisableCustomerAction: false });
                return
            }

            // 更新积分兑换梳理
            clonedStateForFns.originalTotalPoints = PM.getUsedPoints();

            // 优惠券调整
            const adjustCouponResult = this.adjustCoupon(clonedStateForFns);
            clonedStateForFns.allCoupons = adjustCouponResult.allCoupons;
            clonedStateForFns.selectedCoupons = adjustCouponResult.selectedCoupons;
            // 购物车调整（优惠券使用数量）
            const adjustCartResult = this.adjustCart(clonedStateForFns);
            clonedStateForFns.cartDishes = adjustCartResult.cartDishes;
            clonedStateForFns.totalCartDishes = adjustCartResult.totalCartDishes;
            clonedStateForFns.originalTotalPrice = adjustCartResult.originalTotalPrice;
            // 总价详情调整
            const adjustTotalResult = this.adjustTotal(clonedStateForFns);
            clonedStateForFns.discount = adjustTotalResult.discount;
            clonedStateForFns.discountedDeliveryFee = adjustTotalResult.discountedDeliveryFee;
            clonedStateForFns.finalOrderPrice = adjustTotalResult.finalOrderPrice;
            // 获取匹配的配送区域
            const deliveryFeeArr = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR) || [];
            this.getBestMatchDeliveryFee(deliveryFeeArr, clonedStateForFns.originalTotalPrice);
            // 更新视图、检查履约时间是否合法
            this.setState({ ...clonedStateForFns, isDisableCustomerAction: false, checkFulfillMentTimeFlat: !this.state.checkFulfillMentTimeFlat });
            // 更新购物车缓存
            this.updateCartLocalStorage(clonedStateForFns.cartDishes, clonedStateForFns.originalTotalPrice, clonedStateForFns.originalTotalPoints);

            // 购物车没有菜品时关闭结算托盘
            if (clonedStateForFns.cartDishes.length === 0) {
                this.props.closeCheckoutComponent();
                return
            }
            // 购物车原价小于起送价，提示
            if (this.state.orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY) {
                // 最低配送费金额(起送价)
                const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE) || {};
                if (clonedStateForFns.originalTotalPrice < deliveryFee.delivery_minimum_fee) {
                    Notification.notification(`${i18n.t('checkout_page_text_the_original_price_did_not_reach_the_minimum_delivery_amount', { 1: deliveryFee.delivery_minimum_fee })}`);
                }
            }
        } catch (error) {
            this.setState({ isDisableCustomerAction: false })
        }
    }

    public analysisAndAdjustCoupon(couponItem: CheckoutCoupon | null): void {
        if (this.state.isDisableCustomerAction) return;
        this.setState({ isDisableCustomerAction: true });
        try {
            const { allCoupons, selectedCoupons, integralValue, discount,
                cartDishes, totalCartDishes, originalTotalDiscountPrice, originalTotalNoDiscountPrice, discountedDeliveryFee,
                finalOrderPrice, fulfillmentTime, originalTotalPrice, originalTotalPoints, restaurantStringId
            } = this.state;
            const clonedStateForFns = _.cloneDeep({
                allCoupons, selectedCoupons, discount, cartDishes, totalCartDishes,
                originalTotalDiscountPrice, originalTotalNoDiscountPrice, discountedDeliveryFee,
                finalOrderPrice, integralValue, originalTotalPoints, fulfillmentTime
            });
            const usedCouponIds: string[] = _.compact(_.map(selectedCoupons, (n) => { return n.coupon_id }));
            // 参与折扣的菜品总价
            // let discountedOriginalTotalDiscountPrice = clonedStateForFns.originalTotalDiscountPrice;
            // 选择优惠券（分析优惠券的使用或领取规则）
            const onSelectCouponsResult = onSelectCoupons(
                clonedStateForFns.allCoupons,
                couponItem,
                usedCouponIds,
                originalTotalPrice,
                clonedStateForFns.integralValue,
                fulfillmentTime,
                clonedStateForFns.cartDishes,
                restaurantStringId
            );
            if (onSelectCouponsResult) {
                const { newUsedCouponIds, deducted, newCouponPackage, usedPoints } = onSelectCouponsResult;
                const newSelectedCoupons: CheckoutCoupon[] = [];
                // 更新已选择的优惠券数据结构
                newUsedCouponIds.forEach((n) => {
                    const couponItemTarget = _.find(allCoupons, (m) => { return m.coupon_id === n });
                    if (couponItemTarget) {
                        newSelectedCoupons.push(couponItemTarget);
                    }
                })
                clonedStateForFns.selectedCoupons = newSelectedCoupons;
                clonedStateForFns.allCoupons = newCouponPackage;
                clonedStateForFns.discount = deducted;
                clonedStateForFns.originalTotalPoints = usedPoints;

            }
            // 已选中的优惠券中，有“当前购物车菜品无法使用此优惠券”
            const hasDiscountDish = _.find(cartDishes, (n) => !n.dish.is_no_discount);
            clonedStateForFns.allCoupons = clonedStateForFns.allCoupons.map((allCouponsItem) => {
                // 没有参与折扣的菜品
                if (!hasDiscountDish) {
                    if (_.find(clonedStateForFns.selectedCoupons, (selectedCouponsItem) => selectedCouponsItem.coupon_id === allCouponsItem.coupon_id)) {
                        allCouponsItem.showCantUseText = true;
                    } else {
                        allCouponsItem.showCantUseText = false;
                    }
                }
                // 有参与折扣的菜品
                else {
                    // 选择的优惠券，生效目标不存在购物车
                    if (this.checkCouponEffectiveTarget(allCouponsItem, cartDishes) === false) {
                        if (_.find(clonedStateForFns.selectedCoupons, (selectedCouponsItem) => selectedCouponsItem.coupon_id === allCouponsItem.coupon_id)) {
                            allCouponsItem.showCantUseText = true;
                        } else {
                            allCouponsItem.showCantUseText = false;
                        }
                    } else {
                        allCouponsItem.showCantUseText = false;
                    }
                }
                return allCouponsItem;
            })
            // 购物车调整
            const adjustCartResult = this.adjustCart(clonedStateForFns);
            clonedStateForFns.cartDishes = adjustCartResult.cartDishes;
            clonedStateForFns.totalCartDishes = adjustCartResult.totalCartDishes;
            // 总价详情调整
            const adjustTotalResult = this.adjustTotal(clonedStateForFns);
            clonedStateForFns.discount = adjustTotalResult.discount;
            clonedStateForFns.discountedDeliveryFee = adjustTotalResult.discountedDeliveryFee;
            clonedStateForFns.finalOrderPrice = adjustTotalResult.finalOrderPrice;
            // 优惠券排序
            clonedStateForFns.allCoupons = clonedStateForFns.allCoupons.sort(this.compareCoupon);


            this.setState({ ...clonedStateForFns, newlyReceivedCouponCodes: [], isDisableCustomerAction: false, });
        } catch (error) {
            this.setState({ isDisableCustomerAction: false });
        }
    }

    /**
     * 底部“去付款”按钮状态
     * - 配送时，地址不能为空
     * - 配送时，购物车原始价格不能低于起送价钱
     * - 履约时间不在营业时间内
     * - 当前时间在临时停业时间内
     *  - 该用户是否被屏蔽
     * - 积分不足
     */
    public setIsPayable() {
        const { isBusinessTime, currentAddress, orderDescribeStatus, originalTotalPrice, fulfillmentTime } = this.state;
        const errorTips: CheckoutErrorTips[] = [];
        // 是否在临时停业时间内
        const restaurantCloseTime = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_CLOSED_STATUS);
        if (restaurantCloseTime) {
            errorTips.push('TEMPORARY_CLOSE_TIME');
        }
        // 不在营业时间内
        if (!isBusinessTime) {
            errorTips.push('BUSINESS_TIME');
        } else {
            // 履约时间不能早于现在前2分钟
            if (moment(fulfillmentTime, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
                .isBefore(moment().subtract(2, 'minutes'))) {
                errorTips.push('BUSINESS_TIME');
            }
        }
        // 选择配送时
        if (orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY) {
            // 履约地址不符合
            if (JSON.stringify(currentAddress) === '{}') {
                errorTips.push('ADDRESS');
            }
            // 未达到最低起送价
            const deliveryMinimumFee: number = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE), 'delivery_minimum_fee', 0)
            if (originalTotalPrice < deliveryMinimumFee) {
                errorTips.push('PRICE');
            }
        }
        // 积分不足
        if (PM.availablePoints < 0) {
            errorTips.push('POINTS');
        }

        // 购物车有非可售时间的菜品
        if (this.state.cartDishes.find((item) => { return item.dish.unavailableTime })) {
            errorTips.push('UNAVAILABLE_DISH');
        }

        return errorTips
    }

    /**
     * 检查最低配送费
     */
    public checkDeliveryMinimumFee() {
        const { currentAddress, orderDescribeStatus, originalTotalPrice } = this.state;
        let show = false;
        let deliveryMinimumFee = 0;
        if (orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY) {
            // 履约地址不符合
            if (JSON.stringify(currentAddress) === '{}') {
                show = false;
            }
            // 未达到最低起送价
            deliveryMinimumFee = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE), 'delivery_minimum_fee', 0)
            if (originalTotalPrice < deliveryMinimumFee) {
                show = true;
            }
        } else {
            show = false;
        }
        return { show, deliveryMinimumFee }
    }

    /**
     * 点击加减菜品数量
     */
    public updateDishNum(clonedStateForFns: ICheckoutOrderDetailClonendStateObjectForAnalysisAndAdjustFunctions, actions: CartActions[]) {

        // 总数量
        let totalCartDishes: number = 0;
        // 加号操作
        let cartDishes: CheckoutDetailCartDish[] = _.cloneDeep(_.get(clonedStateForFns, 'cartDishes', []));
        // 可能是批量操作
        if (_.isArray(actions)) {
            actions.forEach((actionItem) => {
                if (actionItem.type === 'ADD') {
                    if (actionItem.dishTotalNum >= 99) {
                        Notification.notification(`${i18n.t('checkout_page_text_the_number_of_dishes_cannot_be_greater_than', { number: 99 })}`);
                    } else {
                        cartDishes = cartDishes.map(item => {
                            if (actionItem.id === item.helpfulId && item.num && item.dish.buyNumber) {
                                item.dish.buyNumber += actionItem.num;
                                item.num += actionItem.num;
                            }
                            return item
                        })
                    }
                }
                // 减号操作 (又数量-1 当数量为0的时候过滤掉)
                else if (actionItem.type === 'REDUCE') {
                    cartDishes = cartDishes.map(item => {
                        if (actionItem.id === item.helpfulId && item.num && item.dish.buyNumber) {
                            item.dish.buyNumber -= actionItem.num;
                            item.num -= actionItem.num;
                        }
                        return item
                    }).filter(item => item.num !== 0)
                }
                // 刷新（暂无操作）
                else if (actionItem.type === 'REFRESH') { }
                // 菜品备注
                else if (actionItem.type === 'REMARK') {
                    cartDishes = cartDishes.map(item => {
                        if (actionItem.id === item.helpfulId && item.num && item.dish.buyNumber && isString(actionItem.remark)) {
                            item.dish.remark = actionItem.remark.trim();
                        }
                        return item
                    })
                }

                // 计算出总数量
                cartDishes.forEach((item) => {
                    if (item.num) {
                        totalCartDishes += item.num;
                    }
                })
            })
        }
        // 不参于折扣的菜品原始总价格
        let originalTotalNoDiscountPrice = 0;
        // 参于折扣的菜品原始总价格
        let originalTotalDiscountPrice = 0;
        // 兑换积分
        let dishUsedPoints = 0;
        if (_.isArray(cartDishes)) {
            cartDishes.forEach((item) => {
                // 总积分
                if (item.dish.is_points_redemption) {
                    dishUsedPoints += ((item.dish.buyNumber || 0) * (item.dish.price || 0));
                } else if (item.dish.is_no_discount && item.dish.is_no_discount === true) {
                    // 不参与折扣
                    originalTotalNoDiscountPrice += ((item.dish.buyNumber || 0) * (item.dish.price || 0));
                } else {
                    // 参与折扣
                    originalTotalDiscountPrice += ((item.dish.buyNumber || 0) * (item.dish.price || 0));
                }
            })
        }
        const originalTotalPrice = originalTotalNoDiscountPrice + originalTotalDiscountPrice;
        return { cartDishes, totalCartDishes, originalTotalPrice, originalTotalNoDiscountPrice, originalTotalDiscountPrice, dishUsedPoints };
    }

    /**
     * 更新缓存购物车数据
     */
    updateCartLocalStorage(cartDishes, totalPrice: number, totalPoints: number) {
        const restaurantStringId = this.state.restaurantStringId;
        const cacheShopCarlist = _.map(cartDishes, (n) => n.dish);
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.SHOP_CAR,
            value: {
                ...cacheShopCar,
                [restaurantStringId]: {
                    shopCarList: cacheShopCarlist,
                    totalPrice,
                    totalPoints
                }
            }
        });
    }

    /**
     * 根据优惠券的的可用状态排序
     * - status 可用状态
     * - disClickstatus 可点击状态 true
     * - received_status 免领取的领取状态
     *
     * @param coupon
     */
    compareCoupon(coupon: CheckoutCoupon, nextCoupon: CheckoutCoupon) {
        let position = 0;
        let nextPosition = 0;
        if (coupon.status === '0') position += 2;
        if (!coupon.disClickstatus) position += 2;
        if (coupon.received_status) position += 1;
        // 下个item
        if (nextCoupon.status === '0') nextPosition += 2;
        if (!nextCoupon.disClickstatus) nextPosition += 2;
        if (nextCoupon.received_status) nextPosition += 1;
        return (nextPosition < position) ? -1 : 1;
    }

    /**
     * 输入编码领取优惠券
     * @param insertCode 优惠券编码
     */
    async onInsertCodeCoupon(insertCode: string) {
        let customerInfo = this.state.customerInfo;
        const customerId = _.get(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT), '_id', '');
        if (customerId) {
            if (insertCode) {
                // 检查token是否失效
                const isTokenInvalid = checkToken()
                if (isTokenInvalid) {
                    return
                }
                // $ 领取优惠券自动加入会员
                // 是否是会员
                const isMember = (customerInfo?.restaurant_member_ids || []).includes(this.state.restaurantStringId);
                if (!isMember) {
                    await this.joinMember();
                }

                const result: { error: true, status: number, result: any } | { error: false } = await apiManage.onInsertCodeCoupon(customerId, insertCode);
                if (result.error) {
                    showApiMessage(result, 'coupon')
                } else {
                    // 领取成功提示
                    Notification.notification(i18n.t('coupon_text_successfully_received'));
                    // 新领取的优惠券，用于显示`New`
                    const newlyReceivedCouponCodes = _.concat(this.state.newlyReceivedCouponCodes, [insertCode]);
                    // 获取我的优惠券
                    if (customerId) {
                        const customerInfoResult = await apiManage.get_customer_info(customerId, undefined,
                            CustomerProjectionFixed(['pr_key_info', 'pr_basic_info', 'pr_coupon', 'pr_integral_info', 'pr_member', 'pr_messages_info', 'pr_key_info'])
                        );
                        if (!_.get(customerInfo, 'error')) {
                            customerInfo = customerInfoResult
                        }
                    }
                    this.setState({ customerInfo, selectedCoupons: [], newlyReceivedCouponCodes, isInitCoupon: false }, () => {
                        // 重新检验优惠券规则
                        const initCouponResult = this.initCoupon();
                        // 调整子组件
                        const {
                            selectedCoupons, discount, cartDishes, totalCartDishes, discountedDeliveryFee, finalOrderPrice,
                            originalTotalPrice, originalTotalDiscountPrice, originalTotalNoDiscountPrice
                        } = this.state;
                        const clonedStateForFns = {
                            selectedCoupons, discount, cartDishes, totalCartDishes, discountedDeliveryFee, finalOrderPrice,
                            originalTotalPrice, originalTotalDiscountPrice, originalTotalNoDiscountPrice, allCoupons: initCouponResult
                        };
                        clonedStateForFns.discount = 0;
                        // 购物车调整
                        const adjustCartResult = this.adjustCart(clonedStateForFns);
                        clonedStateForFns.cartDishes = adjustCartResult.cartDishes;
                        clonedStateForFns.totalCartDishes = adjustCartResult.totalCartDishes;
                        // 总价详情调整
                        const adjustTotalResult = this.adjustTotal(clonedStateForFns);
                        clonedStateForFns.discount = adjustTotalResult.discount;
                        clonedStateForFns.discountedDeliveryFee = adjustTotalResult.discountedDeliveryFee;
                        clonedStateForFns.finalOrderPrice = adjustTotalResult.finalOrderPrice;
                        // 优惠券排序
                        clonedStateForFns.allCoupons = clonedStateForFns.allCoupons.sort(this.compareCoupon);
                        // 应用阶段
                        this.setState({ ...clonedStateForFns, isInitCoupon: true })
                    })
                }
            }
        }
    }

    /**
     * 跳转到whatsapp
     */
    public async goToWhatsApp() {
        const { restaurantInfo, orderDescribeStatus, cartDishes, selectedCoupons, orderRemark,
            currentAddress, restaurantStringId, originalTotalPrice, fulfillmentTime, tablewareNumber } = this.state;
        let newCurrentAddress;
        if (orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY) {
            // 计算路径距离
            newCurrentAddress = await checkDistance(restaurantInfo, currentAddress)
        }
        const step = this.getTimeStep();

        // 托盘数据
        const checkoutData: CheckoutData = {
            orderDescribeStatus,
            cartDishes,
            selectedCoupons,
            orderRemark: `${orderRemark ?? ''}${tablewareNumber > 1 ? (orderRemark ? ';' : '') + i18n.t('payment_page_text_cutlery_x_sets', { x: tablewareNumber, lng: restaurantInfo.setting_printer_language || 'IT' }) : ''}`,
            fulfillmentTime,
            currentAddress,
            restaurantStringId,
            originalTotalPrice,
        };
        // _.set(checkoutData, 'currentAddress', newCurrentAddress);
        console.log('checkoutData', checkoutData)

        // 组装信息
        let message = '';

        // 集团，餐厅
        const restaurantName = restaurantInfo.nickname ?? '';
        const local = restaurantInfo.local ?? '';
        const city = restaurantInfo.city ?? '';
        message += `${restaurantName} \n`;
        message += `${city}, ${local} \n`;
        // 号码
        const tel = restaurantInfo.tel ?? '';
        const prefix = restaurantInfo.tel_prefix ?? '';
        message += `+${prefix} ${tel} \n`;
        // 加分割线
        message += '----------------\n';

        // 用户信息
        const customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        if (newCurrentAddress) {
            message += `${currentAddress.first_name ?? ''}${currentAddress.last_name}\n`;
            message += `${i18n.t('account_page_text_phone')}: ${currentAddress.phone_prefix ?? ''} ${currentAddress.phone ?? ''} \n`;
            message += `${i18n.t('account_page_text_delivery_address')}: ${currentAddress.country},${currentAddress.state},${currentAddress.street ?? ''} \n`
            message += `${i18n.t('delivery_address_page_text_client_home_number')}: ${currentAddress.home_number ?? ''} \n`
            message += `${i18n.t('delivery_address_page_text_client_door_number')}: ${currentAddress.door_number ?? ''} \n`
            message += `${i18n.t('text_client_zip_code')}: ${currentAddress.code ?? ''} \n`
            message += `${i18n.t('text_client_city')}: ${currentAddress.city ?? ''} \n`
        } else {
            message += `${customer.first_name ?? ''}${customer.last_name}\n`;
            message += `${i18n.t('account_page_text_phone')}: ${customer.phone_prefix ?? ''} ${customer.phone ?? ''} \n`;
        }

        // 加分割线
        message += '----------------\n';
        message += `${
            // 如果是外卖，显示外卖时间，如果是自取，显示自取时间
            orderDescribeStatus === ICheckoutOrderDetailOrderDescribeStatusNames.TAKEAWAY_ORDER_DELIVERY ?
                i18n.t('general_text_delivery_time') : i18n.t('payment_page_text_takeaway_time')
            }: ${moment(fulfillmentTime).format('HH:mm')} - ${moment(fulfillmentTime, 'HH:mm').add(step, 'minute').format('HH:mm')}\n`
        message += `${i18n.t('payment_page_text_date')}: ${moment(fulfillmentTime).format('DD/MM/YYYY')}\n`;
        message += `${i18n.t('payment_page_text_whole_order_notes')}: ${orderRemark ?? ''}\n`;
        message += '----------------\n';
        // 购物车
        for (const dishItem of cartDishes) {
            const dishType = dishItem.dish.dish_type;
            //  dishItem.dish.name
            //  dishItem.dish.price
            //  dishItem.dish.buyNumber
            //  dishItem.dish.remark
            message += `${dishItem.dish.name} \n`;
            const total_price = (dishItem.dish.price ?? 0) * (dishItem.dish?.buyNumber ?? 0);
            message += `${i18n.t('order_page_text_quantities')}: ${dishItem.dish.buyNumber}  ${i18n.t('general_amount')}: ${PM.showPointsOrPrice(dishItem.dish.is_points_redemption, total_price)}  ${dishItem.dish.remark ? `\n${i18n.t('restaurant_order_page_text_dish_remarks')}: ${dishItem.dish.remark}` : ''} \n`
            if (dishType === '1' || dishType === '2') {
                const setMealDishes = renderSetMenuDetail(dishItem);
                // dishItem.dish.dish_type：这是菜品的类型。如果它的值是 '1' 或 '2'，则显示套餐或包餐的详情。
                // setMealDishes：这是一个包含套餐或包餐中的菜品的数组。
                // item.name：这是套餐或包餐中的一个菜品的名称。
                // item.price：这是套餐或包餐中的一个菜品的价格。如果价格大于 0，则显示价格。
                // item.num：这是套餐或包餐中的一个菜品的数量。如果数量大于 1，则显示数量。
                message += (setMealDishes || []).map((item) => {
                    let itemMessage = `    ${item.name} `;
                    if (item?.price && item?.price > 0) {
                        itemMessage += `+${PM.showPointsOrPrice(dishItem.dish.is_points_redemption, item.price)}`;
                    }
                    if (item.num && item.num > 1) {
                        itemMessage += `×${item.num} `;
                    }
                    return itemMessage;
                }).join('\n').concat('\n');
            }
        }
        // 加分割线
        message += '----------------\n';

        if (this.state.discountedDeliveryFee) {
            message += `${i18n.t('payment_page_text_shipping_fee')}: €${(
                this.state.discountedDeliveryFee - this.state.originalDeliveryFee < 0 ?
                    0
                    : (this.state.originalDeliveryFee - this.state.discountedDeliveryFee)
            ).toFixed(2)}\n`;
        } else if (this.state.originalDeliveryFee) {
            message += `${i18n.t('payment_page_text_shipping_fee')}: €${this.state.originalDeliveryFee.toFixed(2)}\n`;
        }

        // 订单总价
        message += `${i18n.t('order_page_text_subtotal')} €${(originalTotalPrice || 0).toFixed(2)}\n`;
        message += `${i18n.t('my_fidelity_page_scores')} ${(PM.getUsedPoints() || 0)}\n`;

        console.log(message)
        const restaurantNumber = (restaurantInfo?.setting_whats_app_phone_prefix ?? '') + (restaurantInfo?.setting_whats_app_phone ?? '');
        const number = restaurantNumber.replace(/[^\w\s]/gi, '').replace(/ /g, '');
        let url = `https://${(isMobileOrTablet() ? 'api' : 'web')}.whatsapp.com/send?phone=${number}`;
        url += `&text=${encodeURI(message)}&type=phone_number&app_absent=0`;
        window.open(url);
        // open modal of whether to clear cart
        this.props.handleClearCartModal(true);
    }

    /**
     * 跳转选择付款页面
     * - 计算餐馆到所选地址的路径距离
     * - 缓存结算托盘数据
     */
    public async goToPayment() {
        try {
            // 子组件分析类方法
            const { fulfillmentTime, cartDishes, totalCartDishes } = this.state;
            const clonedStateForFns = { fulfillmentTime, cartDishes, totalCartDishes };
            const analysisFulfillmentTimeResult = this.analysisFulfillmentTime(clonedStateForFns);
            const analysisCartResult = this.analysisCart(clonedStateForFns);
            if (!analysisFulfillmentTimeResult || !analysisCartResult) {
                return
            }
            // 跳转选择付款页面
            this.setState({
                loadingStatus: true
            }, async () => {
                // let timer = setTimeout(async () => {
                const { restaurantInfo, orderDescribeStatus, cartDishes, selectedCoupons, orderRemark, fulfillmentTime,
                    currentAddress, restaurantStringId, originalTotalPrice, tablewareNumber } = this.state;
                // 计算路径距离
                const newCurrentAddress = await checkDistance(restaurantInfo, currentAddress)
                // 托盘数据
                const checkoutData: CheckoutData = {
                    orderDescribeStatus,
                    cartDishes,
                    selectedCoupons,
                    orderRemark: `${orderRemark ?? ''}${tablewareNumber > 1 ? (orderRemark ? ';' : '') + i18n.t('payment_page_text_cutlery_x_sets', { x: tablewareNumber, lng: restaurantInfo.setting_printer_language || 'IT' }) : ''}`,
                    fulfillmentTime,
                    currentAddress,
                    restaurantStringId,
                    originalTotalPrice
                };

                _.set(checkoutData, 'currentAddress', newCurrentAddress);
                console.log('checkoutData', checkoutData)
                // 缓存结算托盘数据
                LocalStorageManager.setLocalStorage(
                    {
                        path: LOCAL_STORAGE_KEYS.CHECKOUT_DATA,
                        value: checkoutData
                    }
                )
                const history = createHashHistory();
                history.push(`/paymentMethodPage?string_id=${this.state.restaurantStringId}`);
                this.setState({
                    loadingStatus: false
                })
            })
        } catch (error) {
            this.setState({
                loadingStatus: false
            })
        }
    }

    /**
     * 获取折扣后的参与折扣的菜品价格
     * @param selectedCoupons 选择的优惠券
     * @param cartDishes 购物车
     */
    getDiscountedOriginalTotalDiscountPrice(originalTotalDiscountPrice: number, selectedCoupons: CheckoutCoupon[], cartDishes: CheckoutDetailCartDish[]) {
        let discountedOriginalTotalDiscountPrice = 0;
        if (selectedCoupons.length > 0) {
            // 参与折扣的菜品
            let discountedDishes = _.filter(cartDishes, (n) => { return !n.dish.is_no_discount && !n.dish.is_points_redemption })
            // 每道菜，计算出每道菜的优惠后的价格
            discountedDishes = discountedDishes.map((dishItem) => {
                // 这道菜品优惠了多少
                let discount = 0;
                selectedCoupons.forEach((couponItem) => {
                    // 生效目标
                    const ETRes = couponItem.effectiveTargetsRestaurants;
                    // 且有对应餐馆
                    const ETResItem = _.find(ETRes, (n) => { return n.res_string_id.string_id === this.state.restaurantStringId });
                    // 有生效目标
                    if (ETRes && ETResItem) {
                        // 菜单
                        const menuDataItem = _.find(ETResItem.menuData, (n) => { return n._id === dishItem.dish.menuId });
                        // 菜品
                        const dishDataItem = _.find(ETResItem.dishData, (n) => { return n._id === dishItem.dish._id });
                        // 这道菜品是否是生效目标
                        if (menuDataItem || dishDataItem) {
                            // $ 折扣类型 (0: 百分比 1: 现金)
                            if (couponItem.discount_type === '0') {
                                discount += (dishItem.dish.price || 0) * dishItem.num * (Number(couponItem.discount_value) * 0.01);
                            } else if (couponItem.discount_type === '1') {
                                // 该优惠券符合购物车的菜品
                                const matchDiscountedDishes = _.filter(discountedDishes, (n) => {
                                    return (
                                        _.findIndex(ETResItem?.dishData || [], (m) => { return m._id === n.dish._id }) !== -1) ||
                                        (_.findIndex(ETResItem?.menuData || [], (m) => { return m._id === n.dish.menuId }) !== -1)
                                });
                                // 符合购物车的菜品的总金
                                const matchDishTotalPrice = _.sumBy(matchDiscountedDishes, (n) => ((n.dish.price || 0) * n.num)) || 1;
                                // 菜品单价*数量÷符合购物车的菜品的总金额*优惠金额
                                discount += (dishItem.dish.price || 0) * dishItem.num / matchDishTotalPrice * (couponItem.discount_value || 0);
                            }
                        }
                    }
                    // 对享受折扣的菜品生效
                    else {
                        // $ 折扣类型 (0: 百分比 1: 现金)
                        if (couponItem.discount_type === '0') {
                            discount += (dishItem.dish.price || 0) * dishItem.num * (Number(couponItem.discount_value) * 0.01);
                        } else if (couponItem.discount_type === '1') {
                            // 该优惠券符合购物车的菜品数量
                            const matchDishTotalPrice = _.sumBy(discountedDishes, (n) => ((n.dish.price || 0) * n.num)) || 1;
                            // 菜品单价*数量÷符合购物车的菜品的总金额*优惠金额
                            discount += (dishItem.dish.price || 0) * dishItem.num / matchDishTotalPrice * (couponItem.discount_value || 0);
                        }
                    }
                })
                dishItem.discount = discount
                return dishItem
            })

            // 计算参与折扣的菜品总价
            discountedDishes.forEach((dishItem) => {
                let dishItemTotalPrice = (dishItem.dish.price || 0) * dishItem.num - (dishItem.discount || 0);
                dishItemTotalPrice = dishItemTotalPrice < 0 ? 0 : dishItemTotalPrice;
                discountedOriginalTotalDiscountPrice += dishItemTotalPrice;
            });
        } else {
            discountedOriginalTotalDiscountPrice = originalTotalDiscountPrice;
        }
        discountedOriginalTotalDiscountPrice = Number(discountedOriginalTotalDiscountPrice.toFixed(2));
        return discountedOriginalTotalDiscountPrice
    }

    /**
     * 优惠券的生效目标是否有效
     *
     * @param coupon 单张优惠券
     * @param cartDished 购物车
     * @param type 'CHECK_HAS_ET':只校验生效目标的菜品
     * @returns true为有效；false为无效
     */
    public checkCouponEffectiveTarget(coupon: CheckoutCoupon, cartDishes: CheckoutDetailCartDish[], type?: 'CHECK_HAS_ET') {
        // 参与折扣的菜品
        const discountedDishes = _.filter(cartDishes, (n) => { return !n.dish.is_no_discount })
        let pass = false;
        if (discountedDishes) {
            // 生效目标
            const ETRes = coupon.effectiveTargetsRestaurants;
            // 且有对应餐馆
            const ETResItem = _.find(ETRes, (n) => { return n.res_string_id.string_id === this.state.restaurantStringId });
            if (ETRes && ETResItem) {
                const hasMenuTarget = _.find(discountedDishes, (dishItem) => { return _.findIndex(ETResItem?.menuData, (targetMenu) => { return targetMenu._id === dishItem.dish.menuId }) !== -1 });
                const hasDishTarget = _.find(discountedDishes, (dishItem) => { return _.findIndex(ETResItem?.dishData, (targetDish) => { return targetDish._id === dishItem.dish._id }) !== -1 });
                // 生效目标有效
                if (hasMenuTarget || hasDishTarget) {
                    pass = true;
                }
            }
            // 对参与折扣的所有菜品生效
            else if (!(type === 'CHECK_HAS_ET')) {
                pass = true;
            }
        }
        return pass;
    }

    /**
     * 检查履约时间是否可用
     * - 第一次进来，自动获取最近的可用履约时间
     * @param time 履约时间
     * @return 可用的履约时间
     */
    checkFulfillmentTimeRecentlyAvailableTime(time: Date, maxTime: Date) {
        const restaurantId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
        const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
        const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE) || {};
        // 配送区域时间限制 deliveryFee只有配送时才有值
        let canceledScheduleTimeRanges: SettingClosedSchedule[] = [];
        if (deliveryFee && Object.keys(deliveryFee).length > 0) {
            canceledScheduleTimeRanges = getTargetDateCanceledSchedule(deliveryFee.canceled_schedule_time_ranges || []);
        }
        // 判断时间是否在营业时间段内 setting_app_takeaway_service_schedule
        let serviceSchedule = _.get(res, `[${restaurantId}].restaurant.setting_app_takeaway_service_schedule`, []);
        // 停业日历
        let settingClosedSchedule = _.get(res, `[${restaurantId}].restaurant.setting_closed_schedule`, []);
        // 选择地址后的区域限制配送时间
        settingClosedSchedule = _.concat(settingClosedSchedule, canceledScheduleTimeRanges);

        // 结合休业时间，算出营业时间
        serviceSchedule = updateScheduleData(serviceSchedule, settingClosedSchedule);
        const restaurantsInfo = _.get(res, `[${restaurantId}].restaurant`);
        // $ 时间表里面的时区  为null就是默认值
        const scheduleTimeZoneName = _.get(restaurantsInfo, 'time_zone') || momentTimeZone.tz.guess();
        // 当前时区
        const originTimeZoneName = momentTimeZone.tz.guess();
        const formatTime = DT(time);
        const isBusiness = checkSchedule(serviceSchedule, formatTime, scheduleTimeZoneName, originTimeZoneName);

        // 是否可用，在营业时间内
        let isAvailable = true;
        // 判断自取还是配送
        const orderWay = getQueryString('orderway');
        const isPickUp = orderWay === '2' ? false : true;

        if (isBusiness.isSelftakeAvailableInCurrentTime && isPickUp) {
            // 可以自己取
            isAvailable = true;
        } else if (isBusiness.isTakeawayAvailableInCurrentTime && !isPickUp) {
            // 可以配送
            isAvailable = true;
        } else {
            // 不允许配送和自取
            isAvailable = false;
        }
        let nextOpenTime: string = moment(time).format('YYYY-MM-DD HH:mm');
        if (!isAvailable) {
            serviceSchedule = serviceSchedule.map((item) => {
                return {
                    ...item,
                    time_24_hours: isPickUp ? (item.selftake_status && item.time_24_hours) : (item.takeaway_status && item.time_24_hours),
                    time_ranges: _.filter(item.time_ranges, (n) => { return isPickUp ? n.selftake_status : n.takeaway_status })
                }
            })
            // 今天的营业时间
            const todyTimeRanges = (_.find(serviceSchedule, (item) => item.week === moment().isoWeekday()) || {}).time_ranges || [];
            if (todyTimeRanges && todyTimeRanges.length > 0) {
                const rangesItem = _.find(todyTimeRanges, (item) => { return moment().isBefore(moment(item.start_time, 'HH:mm')) && (isPickUp ? item.selftake_status : item.takeaway_status) });
                if (rangesItem) {
                    nextOpenTime = moment(rangesItem.start_time, 'HH:mm').format('YYYY-MM-DD HH:mm');
                } else {
                    // 下个营业时间
                    const nextRangeItem = getNextTimeRanges(1, serviceSchedule);
                    console.log(nextRangeItem);
                    if (nextRangeItem.nextOpenTimeisAlldayOpen) {
                        nextOpenTime = moment(nextRangeItem.nextDate, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00');
                    } else {
                        nextOpenTime = `${nextRangeItem.nextDate} ${nextRangeItem.nextOpenTime}`;
                    }
                }
            } else {
                const nextRangeItem = getNextTimeRanges(1, serviceSchedule);
                if (nextRangeItem && Object.keys(nextRangeItem).length > 0) {
                    if (nextRangeItem.nextOpenTimeisAlldayOpen) {
                        nextOpenTime = moment(nextRangeItem.nextDate).format('YYYY-MM-DD 00:00');
                    } else {
                        nextOpenTime = moment(`${nextRangeItem.nextDate} ${nextRangeItem.nextOpenTime}`).format('YYYY-MM-DD HH:mm');
                    }
                }
            }
        }
        // 下一个营业时间 大于 最大可下单时间
        if (moment(nextOpenTime, 'YYYY-MM-DD HH:mm').isAfter(maxTime)) {
            nextOpenTime = moment(time).format('YYYY-MM-DD HH:mm');
        }
        return nextOpenTime;
    }

    /**
     * 找出最贵的配送费区域规则
     * - 用户地址，在多个配送区域内
     * @param deliveryFeeArr 配送费区域数组
     * @param originalTotalPrice 菜品原始总价
     */
    getBestMatchDeliveryFee(deliveryFeeArr: any[], originalTotalPrice: number) {
        let deliveryFeeItem: LevelDeliveryFee = {};
        let deliveryFee = 0;
        try {
            deliveryFeeArr.forEach((item) => {
                let discounted = 0;
                _.get(item, 'delivery_amount_discount', []).forEach(element => {
                    if (originalTotalPrice >= element.amount_reached) {
                        if (element.discount_fee > discounted) {
                            discounted = element.discount_fee
                        }
                    }
                });
                // 折扣后的配送费
                const discounted_Delivery_Fee = (_.get(item, 'delivery_fee', 0) - discounted) < 0 ? 0 :
                    (_.get(item, 'delivery_fee', 0) - discounted);
                if (Object.keys(deliveryFeeItem).length === 0) {
                    deliveryFee = discounted_Delivery_Fee;
                    deliveryFeeItem = item;
                } else if (deliveryFee < discounted_Delivery_Fee) {
                    deliveryFee = discounted_Delivery_Fee;
                    deliveryFeeItem = item;
                }
            })
        } catch (error) { }
        // 更新缓存配送费
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.DELIVERY_FEE,
            value: deliveryFeeItem
        })
        return deliveryFeeItem
    }

    /**
     * 加入会员
     */
    async joinMember() {
        if (this.state.customerInfo?._id) {
            const res = await apiManage.join_member(this.state.restaurantStringId, this.state.customerInfo?._id);
            if (!res?.error) {
                Notification.notification(i18n.t('restaurant_info_page_text_you_have_joined_a_member'));
            } else {
                Notification.notification(i18n.t('request_failure_text_operation_failed'));
            }
        }
    }
    /**
     *  获取时间间隔
     */
    getTimeStep() {
        const isPickUp = getQueryString('orderway') === '1';
        return isPickUp ? 15 : 20;
    }
    // *********************
    // View
    // *********************

    render() {
        const {
            showedPage, orderDescribeStatus, serviceSchedules, closeSchedules, takeawayServiceStatus,
            orderIntervalTime, restaurantLogo, restaurantAddress, cartDishes, totalCartDishes, allCoupons, selectedCoupons,
            currentAddress, allAddresses, discount, originalDeliveryFee, discountedDeliveryFee, minTime,
            fulfillmentTime, originalTotalPrice, finalOrderPrice, newlyReceivedCouponCodes, isEditAddress,
            editAddress, isDisableCustomerAction, loadingStatus, orderRemark, isBlockUsers, maxTime, originalTotalPoints,
            tablewareNumber
        } = this.state;
        const { closeCheckoutComponent, editSetMeal } = this.props;
        const titleName: { [key in ICheckoutOrderDetailShowedPageNames]: { name: string, icon: JSX.Element, action: () => void } } = {
            [ICheckoutOrderDetailShowedPageNames.MAIN_PAGE]: {
                name: i18n.t('checkout_page_text'),
                icon: <MdClose />,
                action: () => { closeCheckoutComponent() }
            },
            [ICheckoutOrderDetailShowedPageNames.NEW_ADDRESS_PAGE]:
            {
                name: isEditAddress ? i18n.t('checkout_page_text_edit_address') : i18n.t('checkout_page_text_add_address'),
                icon: <MdChevronLeft />,
                action: () => {
                    this.setState({ isEditAddress: false, editAddress: {} }, () => {
                        this.updateShowedPage(ICheckoutOrderDetailShowedPageNames.MAIN_PAGE)
                    });
                }
            },
            [ICheckoutOrderDetailShowedPageNames.COUPON_LIST_PAGE]: {
                name: i18n.t('coupon_text_view_coupons'),
                icon: <MdChevronLeft />,
                action: () => {
                    this.updateShowedPage(ICheckoutOrderDetailShowedPageNames.MAIN_PAGE)
                }
            }
        }
        return (
            <div className={`checkoutOrderDetailPage`}>
                {loadingStatus && < div className='loadingView'>
                    <CircularProgress style={{ color: '#ffdc33' }} />
                </div>}
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => titleName[showedPage].action()}>
                        {titleName[showedPage].icon}
                    </span>
                    <span className='title'>
                        {titleName[showedPage].name}
                    </span>
                </div >
                {/* 订单详情-主页面 */}
                <div className={`showedPageItem mainPage ${this.setIsPayable().length > 0 ? 'hasErrorTip' : ''}`}
                    style={{ display: showedPage === ICheckoutOrderDetailShowedPageNames.MAIN_PAGE ? 'block' : 'none' }}>
                    {/* 选择时间 */}
                    <CheckoutFulfillmentTime
                        orderDescribeStatus={orderDescribeStatus}
                        orderIntervalTime={orderIntervalTime}
                        serviceSchedules={serviceSchedules}
                        closeSchedules={closeSchedules}
                        takeawayServiceStatus={takeawayServiceStatus}
                        // 更新履约的时间
                        updateFulfillmentTime={(val: Date) => { this.analysisAndAdjustFulfillmentTime(val) }}
                        // 履约的时间
                        fulfillmentTime={fulfillmentTime}
                        // 最小的时间
                        minTime={minTime}
                        // 最大的时间
                        maxTime={maxTime}
                        // 时间是否在营业范围
                        businessTime={(isActive: boolean) => {
                            this.setState({
                                isBusinessTime: isActive
                            })
                        }}
                        // 检查履约时间是否合法
                        checkFulfillMentTimeFlat={this.state.checkFulfillMentTimeFlat}
                    />
                    {/* 履约地址 */}
                    <CheckoutFulfillmentAddress
                        orderDescribeStatus={orderDescribeStatus}
                        restaurantLogo={restaurantLogo}
                        restaurantAddress={restaurantAddress}
                        currentAddress={currentAddress}
                        allAddresses={allAddresses}
                        updateShowedPage={(targetPage) => {
                            this.updateShowedPage(targetPage)
                        }}
                        onChangeSelectedAddress={async (address: Address, index: number) => {
                            if (!address.door_number) {
                                Notification.notification(i18n.t('payment_page_text_address_error'));
                            } else if (!address.isWithin) {
                                Notification.notification(i18n.t('payment_page_text_this_address_is_not_within_the_scope_of_shipping'));
                            } else {
                                if (this.state.currentAddress === address) return;
                                allAddresses.forEach((item, idx) => {
                                    if (index === idx) {
                                        item.default = true;
                                    } else {
                                        item.default = false;
                                    }
                                })
                                /**
                                 *  缓存配送费和最小起送价
                                 */
                                await CachedAddressDeliveryFee(address);
                                // 获取匹配的配送区域
                                const deliveryFeeArr = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR) || [];
                                this.getBestMatchDeliveryFee(deliveryFeeArr, this.state.originalTotalPrice);
                                this.analysisAndAdjustFulfillmentAddress(address, allAddresses);
                                // 检查履约时间是否合法
                                this.setState({
                                    checkFulfillMentTimeFlat: !this.state.checkFulfillMentTimeFlat
                                })
                            }
                        }}
                        onChangeAddress={(address: Address, all: Array<Address>, index: number) => {
                            const newAddress = all.map((item, itemIndex) => {
                                return {
                                    ...item,
                                    isEdit: itemIndex === index ? 'CHANGE' : undefined
                                }
                            })
                            this.setState({
                                editAddress: address,
                                isEditAddress: true,
                                allAddresses: newAddress,
                                showedPage: ICheckoutOrderDetailShowedPageNames.NEW_ADDRESS_PAGE
                            })
                        }}
                    />
                    {/* 购物车 */}
                    <CheckoutCart
                        closeCheckoutComponent={() => { closeCheckoutComponent() }}
                        cartDishes={cartDishes}
                        totalCartDishes={totalCartDishes}
                        analysisAndAdjustCart={(actions) => {
                            this.analysisAndAdjustCart(actions)
                        }}
                        showCartErrorText={this.checkDeliveryMinimumFee()}
                        editSetMeal={(CheckoutDetailCartDish: CheckoutDetailCartDish) => { editSetMeal?.(CheckoutDetailCartDish) }}
                        orderDescribeStatus={orderDescribeStatus}
                        takeawayServiceStatus={takeawayServiceStatus}
                    />
                    {/* 优惠券 */}
                    <CheckoutCouponComponent
                        updateShowedPage={(targetPage) => { this.updateShowedPage(targetPage) }}
                        allCoupons={allCoupons}
                        selectedCoupons={selectedCoupons}
                        analysisAndAdjustCoupon={(couponItem) => { this.analysisAndAdjustCoupon(couponItem) }}
                        onInsertCodeCoupon={(insertCode) => { this.onInsertCodeCoupon(insertCode) }}
                        newlyReceivedCouponCodes={newlyReceivedCouponCodes}
                    />
                    {/* 餐具数量 */}
                    <CheckoutOrderTableware
                        onChangeTableware={(value) => { this.setState({ tablewareNumber: value }) }}
                        tablewareNumber={tablewareNumber}
                    />
                    {/* 整单备注 */}
                    <CheckoutOrderRemarks
                        orderRemark={orderRemark}
                        onRderRemarks={(value) => this.setState({ orderRemark: value })}
                    />
                    {/* 总价详情 */}
                    <CheckoutTotalPrice
                        discount={discount}
                        selectedCouponNumber={selectedCoupons.length}
                        originalDeliveryFee={originalDeliveryFee}
                        discountedDeliveryFee={discountedDeliveryFee}
                        originalTotalPrice={originalTotalPrice}
                        orderDescribeStatus={orderDescribeStatus}
                        // 积分四舍五入保留整数
                        originalTotalPoints={Math.ceil(originalTotalPoints || 0)}
                    />
                    {/* 付款按钮 */}
                    <CheckoutPaymentButton
                        isBlockUsers={isBlockUsers}
                        finalOrderPrice={finalOrderPrice}
                        errorTips={this.setIsPayable()}
                        showCartErrorText={this.checkDeliveryMinimumFee()}
                        goToPayment={() => {
                            if (!isDisableCustomerAction && !isBlockUsers) {
                                if (this.state.restaurantInfo?.setting_whats_app_order) {
                                    this.goToWhatsApp()
                                } else {
                                    this.goToPayment()
                                }
                            }
                        }}
                        isSettingWhatsappOn={this.state.restaurantInfo?.setting_whats_app_order ?? false}
                        originalTotalPoints={Math.ceil(originalTotalPoints || 0)}
                    />
                </div>
                {/* 订单详情-新增地址 */}
                {
                    showedPage === ICheckoutOrderDetailShowedPageNames.NEW_ADDRESS_PAGE &&
                    <div className='showedPageItem'
                        style={{ display: showedPage === ICheckoutOrderDetailShowedPageNames.NEW_ADDRESS_PAGE ? 'block' : 'none' }}>
                        <CheckoutNewAddress
                            isEditAddress={isEditAddress}
                            editAddress={editAddress}
                            allAddresses={allAddresses}
                            addNewAddress={async (allAddress: Array<Address>) => {
                                this.setState({
                                    isEditAddress: false
                                }, async () => {
                                    const res_string_id = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
                                    const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
                                    const res_info = _.get(res, `[${res_string_id}].restaurant`);
                                    // 计算地址是否在配送范围内
                                    allAddress = await checkAddress(res_info, allAddress);
                                    // 判断地址是否被选中
                                    let defaultAddress = {};
                                    (allAddress || []).forEach(item => {
                                        if (item.default && item.isWithin) {
                                            defaultAddress = item
                                        }
                                    });
                                    // 排序
                                    allAddress.sort(item => {
                                        if (item.default) return -1;
                                        return 1;
                                    })
                                    // 缓存配送费和最小起送价
                                    await CachedAddressDeliveryFee(defaultAddress);
                                    // 获取匹配的配送区域
                                    const deliveryFeeArr = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE_ARR) || [];
                                    this.getBestMatchDeliveryFee(deliveryFeeArr, this.state.originalTotalPrice);
                                    this.analysisAndAdjustFulfillmentAddress(defaultAddress, allAddresses);

                                    this.setState({
                                        allAddresses: allAddress,
                                        showedPage: ICheckoutOrderDetailShowedPageNames.MAIN_PAGE,
                                        editAddress: {},
                                        currentAddress: defaultAddress,
                                        // 检查履约时间是否合法
                                        checkFulfillMentTimeFlat: !this.state.checkFulfillMentTimeFlat
                                    })
                                })
                            }}
                        />
                    </div>
                }
                {/* 订单详情-优惠券列表 */}
                <div className='showedPageItem'
                    style={{ display: showedPage === ICheckoutOrderDetailShowedPageNames.COUPON_LIST_PAGE ? 'block' : 'none' }}>
                    <CheckoutCouponListComponent
                        allCoupons={allCoupons}
                        selectedCoupons={selectedCoupons}
                        analysisAndAdjustCoupon={(couponItem) => { this.analysisAndAdjustCoupon(couponItem) }}
                        newlyReceivedCouponCodes={newlyReceivedCouponCodes}
                    />
                </div>
            </div >
        )
    }
}
export default CheckoutOrderDetailPage

// *********************
// Props & State
// *********************

interface State extends ICheckoutOrderDetailState {
    restaurantStringId: string;
    restaurantInfo: Restaurant;
    customerInfo: Customer;
    restaurantLogo: string;
    restaurantAddress: {
        nickname: PropType<Restaurant, 'nickname'>,
        address: PropType<Restaurant, 'google_map_formatted_address'>
    };
    originExemptCoupon: CheckoutCouponPackage[];
    originCoupon: CheckoutCoupon[];
    integralValue: number;
    originalTotalNoDiscountPrice: number;
    originalTotalDiscountPrice: number;
    fulfillmentTime: Date;
    minTime: Date;
    maxTime: Date;
    newlyReceivedCouponCodes: string[];
    isDisableCustomerAction: boolean;
    editAddress: Address;
    isEditAddress: boolean;
    isBusinessTime: boolean;
    loadingStatus: boolean;
    isInitCoupon: boolean;
    init: boolean;
    isBlockUsers: boolean;
    checkFulfillMentTimeFlat: boolean;
    tablewareNumber: number;
}

interface Props extends ICheckoutOrderDetailProps {
    updateOrderPageState: (action?: { fulfillmentTime: Date }) => void;
    // 编辑套餐、包餐
    editSetMeal: (CheckoutDetailCartDish: CheckoutDetailCartDish) => void;
    handleClearCartModal: (b: boolean) => void;
}

export interface CheckoutData {
    orderDescribeStatus?: ICheckoutOrderDetailOrderDescribeStatusNames;
    cartDishes?: Array<CheckoutDetailCartDish>;
    selectedCoupons?: Array<CheckoutCoupon>;
    orderRemark?: string;
    fulfillmentTime?: Date;
    currentAddress?: Address;
    restaurantStringId?: string;
    originalTotalPrice?: number;
}


export function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}