/**
 * @CE AKclown
 */
import './scan.style.scss';

import { createHashHistory } from 'history';
import React, { Component } from 'react';
import { MdClear } from 'react-icons/md';
import QrReader from 'react-qr-reader';
import i18n from '../../i18n';

const history = createHashHistory();
// 扫描二维码组件
class ScanCode extends Component<Props, State>  {
    public qrReaderRef: any

    constructor(props) {
        super(props)
        this.openImageDialog = this.openImageDialog.bind(this)
    }
    public state: State = {
        // 扫码结果
        result: 'No result',
        // 错误提示
        errorTip: ''
    }

    // *********************
    // 方法区
    // *********************

    handleScan = data => {
        /**
         * 1、验证
         * 2、跳转
         */
        if (data) {
            if (this.props.type === 'coupon') {

            } else {
                const uriParams = this.getQueryString(data, 'string_id');
                if (uriParams) {
                    history.push(`/restaurantPage?string_id=${uriParams}`)
                } else {
                    this.setState({
                        errorTip: i18n.t('scan_page_QR_code_does_not_match')
                    })
                }
            }

            this.setState({
                result: data
            })
            if (this.props.successfulCallback) {
                this.props.successfulCallback(data)
            }
        }
    }

    // 获取URL问号后的参数值
    public getQueryString(data, name) {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        const r = data.split('?').length > 1 && data.split('?')[1].match(reg);
        if (r) {
            return unescape(r[2]);
        }
        return null;
    }

    // 扫描错误处理
    handleError = err => {
        this.setState({
            errorTip: err
        })
    }

    // 加载
    handleLoad = obj => {
        console.log(obj)
    }

    // 选择二维码控件处理
    openImageDialog() {
        this.qrReaderRef.openImageDialog()
    }

    // *********************
    // 渲染函数
    // *********************

    render() {
        const { handleCloseScan } = this.props;
        return (
            <div className='scanCode'>
                <MdClear className='clearIco' onClick={handleCloseScan} />
                <QrReader
                    ref={(qrReaderRef) => { this.qrReaderRef = qrReaderRef }}
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '100%' }}
                    legacyMode={true}
                />
                <p className='tipTitle'>
                    {i18n.t('scan_page_if_your_phone_does_not_support_barcode_scanning')}
                    {','}
                    {i18n.t('scan_page_please_select_picture_recognition')}
                </p>
                <div className='selectBtn' onClick={this.openImageDialog}>
                    {i18n.t('scan_page_please_select_picture_recognition')}
                </div>
                <p className='result'>{this.state.result}</p>
                <p className='errorTip'>{this.state.errorTip}</p>
            </div>
        )
    }
}

export default ScanCode



// *********************
// Types
// *********************

type State = {
    result: string,
    errorTip: string
}

type Props = {
    type?: string;
    handleCloseScan: () => void;
    successfulCallback?: (result) => void;
}