/**
 * @author liweikang
 */
import '../../component/my-order/my-order.style.scss';

import { Button, Fab, InputBase } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import _ from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
// import ConfirmDialogComponent from '../../component/my-order/confirm-dialog.component';
import GridLayoutComponent from '../../component/my-order/grid-layout.component';
import OrderDetailPanelComponent from '../../component/my-order/order-detail-panel.component';
import StatusButtonComponent from '../../component/my-order/order-status-button.component';
import ScrollDialogComponent from '../../component/my-order/scroll-dialog.component';
import Scroll from '../../component/scroll/scroll.component';
import TopBar from '../../component/top-bar/top-bar.component';
import { Address, Bag, Customer, OrderDish, Restaurant } from '../../models';
import apiManage from '../../request';
import { AirBubbles } from '../../component/payment/payment.componet';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import i18n from '../../i18n';
import Notification from '../../component/snackbar/snackbar';
import { OrderSetMeal } from '../../models/abstract/order-set-meal.entity';
import { OrderPackageMeal } from '../../models/abstract/order-package-meal.entity';
import { OrderSetMealArea } from '../../models/abstract/order-set-meal-area.entity';
import { OrderPackageMealArea } from '../../models/abstract/order-package-meal-area.entity';
import { inject_unount } from '../../component/decorators/helper.decorator';
import { createHashHistory } from 'history';
import { getOrderAllDishSort } from '../../constants/dish-sort';
import { v4 as uuidv4 } from 'uuid';
import { ReorderDialog } from '../../component/reorder-dialog/reorder-dialog';
import { PM } from '../../component/order/tool-points';

@inject_unount
class MyOrderPage extends React.Component<Props, State> {
    // *********************
    // 构造函数
    // *********************
    constructor(props: Props) {
        super(props);
        this.state = {
            showOrderDetailDialog: false,
            confirmDialogData: {},
            showCancelOrderDialog: false,
            orderDetailData: {
                _id: '',
                address: {},
                bags: [],
                dishes: {
                    totalNum: 0,
                    data: []
                },
                belonged_customer: [],
                belonged_restaurant: {}
            },
            isLogin: false,
            // 气泡提示
            showAirBubbles: true,
            // 排序bag
            sortBag: [],
            openReorderDialog: {
                open: false
            }
        }
    }

    // *********************
    // 生命周期
    // *********************
    componentDidMount() {
        this.setState({
            isLogin: this.checkToken()
        }, () => {
            const params: any = this.props.match.params;
            if (params.string_id && params.id) this.getOrderDetail({
                string_id: params.string_id,
                id: params.id,
                range: params.status === '4' || params.status === '3' ? 'HISTORY_ORDER' : undefined
            })
        })
    }

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
    }

    // *********************
    // 方法区
    // *********************
    /**
     * 检查token
     */
    checkToken = () => {
        const token = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN);
        if (!_.get(token, 'access_token')) {
            // Store.dispatch({
            //     type: 'SHOW_MESSAGE',
            //     level: 'Warning',
            //     message: this.props.t('order_page_text_after_login_view')
            // });
            // setTimeout(() => {
            //     createHashHistory().replace('/')
            // }, 1000);
            return false;
        }
        return true;
    }
    /**
     * 获取订单详情
     */
    getOrderDetail = async (params?: any) => {
        try {
            const result: any = await apiManage.getRestaurantOrder(params);

            if (result.error) {
                const code = _.get(result, 'result.code');
                const statusCode = _.get(result, 'result.statusCode');
                if (code === 1003 && statusCode === 403) {
                    Notification.notification(i18n.t('order_page_text_after_login_view'));
                    setTimeout(() => {
                        createHashHistory().replace('/')
                    }, 1000);
                    return false;
                }
            }

            const dishes: OrderDish[] = [];
            // 总菜品数量
            let totalNum = 0;
            // 把每个口袋里的菜品都提取出来
            if (result && Array.isArray(result.bags)) {
                result.bags.forEach((bag) => {
                    if (Array.isArray(bag.dishs)) {
                        bag.dishs.forEach(dish => {
                            dishes.push(dish);
                            totalNum += dish.num ? dish.num : 0;
                        });
                    }
                    if (Array.isArray(bag.package_meals)) {
                        (bag.package_meals || []).forEach(package_meals => {
                            totalNum += package_meals.num ? package_meals.num : 0;
                        });
                    }
                    if (Array.isArray(bag.set_meals)) {
                        bag.set_meals.forEach(set_meals => {
                            totalNum += set_meals.num ? set_meals.num : 0;
                        });
                    }
                })

                result.dishes = {
                    totalNum,
                    data: dishes
                }

                const sortBag = getOrderAllDishSort(_.cloneDeep(result?.bags?.[0] || {}));

                this.setState({
                    orderDetailData: result,
                    sortBag
                })
            }
        } catch (error) {
        }
    }



    /**
     * 普通菜品渲染
     * @param dishes 菜品数组
     * @param bagIndex 菜品index
     * @param isShowDot 是否显示小点点
     * @param isShowMargin 是否显示左边距
     */
    dishRender = (dishes: OrderDish[], bagIndex: number, isShowDot?: boolean, isShowMargin?: boolean, is_points_redemption?: boolean) => {
        return (
            _.isArray(dishes) && dishes.map((dishItem, dishIndex) => (
                <div key={dishItem._id || '' + dishIndex + bagIndex} className='commonDish'
                >
                    <div className='menu'>
                        <GridLayoutComponent
                            isShowMargin={isShowMargin}
                            isShowDot={isShowDot}
                            columns={3}
                            gridClass={'dishGridShow-3'}
                            leftText={dishItem.origin_name}
                            centerText={`x ${dishItem.num}`}
                            rightText={(isShowDot ? `(` : '') + PM.showPointsOrPrice(is_points_redemption !== undefined ? is_points_redemption : dishItem.is_points_redemption, dishItem.origin_price || 0) + (isShowDot ? `)` : '')}
                        ></GridLayoutComponent>
                    </div>
                </div>
            ))

        )
    }

    /**
     * 套餐渲染
     * @param setMeals 套餐数组
     * @param bagIndex index
     * @param isShowDot 是否显示小点点
     * @param isShowMargin 是否显示左边距
     */
    setMealRender = (setMeals: OrderSetMeal[], bagIndex: number, isShowDot?: boolean, isShowMargin?: boolean, is_points_redemption?: boolean) => {

        return (
            _.isArray(setMeals) && setMeals.map((dishItem, dishIndex) => (
                <div
                    key={dishItem._id || '' + dishIndex + bagIndex}
                    className='commonSetMeal'
                >
                    <div className='menu'>
                        <GridLayoutComponent
                            isShowMargin={isShowMargin}
                            isShowLine={isShowDot}
                            columns={3}
                            gridClass={'dishGridShow-3'}
                            leftText={dishItem.origin_name}
                            centerText={`x ${dishItem.num}`}
                            rightText={(isShowDot ? `(` : '') + PM.showPointsOrPrice(is_points_redemption !== undefined ? is_points_redemption : !!dishItem.is_points_redemption, dishItem.origin_price || 0) + (isShowDot ? `)` : '')}
                        >
                        </GridLayoutComponent>
                        {
                            this.areaRender(dishItem.origin_set_meal_areas || [], dishIndex, isShowDot, is_points_redemption !== undefined ? is_points_redemption : !!dishItem.is_points_redemption)
                        }

                    </div>
                </div >
            ))

        )
    }

    // 区域渲染
    areaRender = (areas: Array<OrderPackageMealArea | OrderSetMealArea>, dishIndex: number, isShowDot?: boolean, is_points_redemption?: boolean) => {
        return (
            _.isArray(areas) && areas.map((area, areaIndex) => (
                <div
                    key={area._id || '' + areaIndex + dishIndex}
                    className='commonArea'>
                    {
                        _.get(area, 'origin_additional_fees', 0) > 0 &&
                        <GridLayoutComponent
                            columns={2}
                            gridClass={'additionalFees'}
                            leftText={`${area.origin_name} ${i18n.t('package_page_additional_fees')}`}
                            centerText=''
                            rightText={PM.showPointsOrPrice(is_points_redemption, area.origin_additional_fees || 0)}
                        >
                        </GridLayoutComponent>
                    }
                    {this.dishRender(area.dishes || [], areaIndex, true, isShowDot, is_points_redemption)}

                    {
                        this.setMealRender(
                            (area as OrderPackageMealArea).set_meals || [],
                            areaIndex + (area.dishes || []).length, true, isShowDot, is_points_redemption)
                    }
                </div>

            ))

        )
    }

    /**
     * 包餐渲染
     * @param packageMeals 包餐数组
     * @param bagIndex 包餐index
     */
    packMealRender = (packageMeals: OrderPackageMeal[], bagIndex: number) => {
        return (
            _.isArray(packageMeals) && packageMeals.map((dishItem, dishIndex) => (
                <div
                    key={dishItem._id || '' + dishIndex + bagIndex}
                    className='commonPackMeal'>
                    <div className='menu'>
                        <GridLayoutComponent
                            columns={3}
                            gridClass={'dishGridShow-3'}
                            leftClass={'serMealArea'}
                            leftText={`${dishItem.origin_name}`}
                            centerText={`x ${dishItem.num}`}
                            rightText={PM.showPointsOrPrice(dishItem.is_points_redemption, dishItem.origin_price || 0)}
                        >
                        </GridLayoutComponent>
                        {
                            this.areaRender(dishItem.origin_package_meal_areas || [], dishIndex, false, !!dishItem.is_points_redemption)
                        }
                    </div>

                </div>
            ))

        )
    }

    /**
     * 再次订购
     * - 重复菜品加入购物车
     * - 覆盖原购物车
     */
    public async reorder() {
        const { orderDetailData } = this.state;
        const stringId = orderDetailData.belonged_restaurant.string_id || '';
        // 只有一个bag
        const bag = orderDetailData.bags?.[0];

        // 更新缓存购物车数据
        const cacheShopCar = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.SHOP_CAR) || {};

        const dishBaseAttr = (dishesItem: OrderDish) => {
            return {
                _id: dishesItem.origin_id,
                name: dishesItem.origin_name,
                price: dishesItem.origin_price,
                num: dishesItem.num,
                dish_type: dishesItem.dish_type,
                menuId: dishesItem.menu_id,
                menuName: dishesItem.menu_name,
                buyNumber: dishesItem.num,
                remark: dishesItem.remark,
                delivery_type: dishesItem.origin_delivery_type,
                is_points_redemption: dishesItem.is_points_redemption,
            }
        }

        // $ 普通菜品
        const dishes = bag?.dishs?.map((item) => {
            return {
                ...dishBaseAttr(item),
                firstId: `${item.origin_id}${item.menu_id}`,
                is_no_discount: item.origin_is_no_discount,
            }
        }) || [];
        // $ 套餐
        const setMeals = bag?.set_meals?.map((item) => {
            return {
                ...dishBaseAttr(item),
                firstId: `${item.origin_id}${item.menu_id}`,
                is_no_discount: item.origin_is_no_discount,
                set_meal_areas: item.origin_set_meal_areas?.map((iitem) => {
                    return {
                        _id: iitem.origin_id,
                        name: iitem.origin_name,
                        additional_fees: iitem.origin_additional_fees,
                        dishes: (iitem.dishes || [])?.map((dishesItem) => {
                            return dishBaseAttr(dishesItem)
                        })
                    }
                })
            }
        }) || [];
        // $ 包餐
        const packageMeals = bag?.package_meals?.map((item) => {
            return {
                ...dishBaseAttr(item),
                inCartId: uuidv4(),
                firstId: `${item.origin_id}${item.menu_id}`,
                is_no_discount: item.origin_is_no_discount,
                package_meal_areas: item.origin_package_meal_areas?.map((iitem) => {
                    // 区域已点数量 菜品总价
                    const areaObj = ([...(iitem?.dishes || []), ...(iitem.set_meals || [])])?.reduce((pre, cur) => {
                        return {
                            totalDishes: pre.totalDishes + (cur?.num || 0), totalDishesPrice: pre.totalDishesPrice + ((((cur?.origin_price || 0) + ((cur as OrderSetMeal)?.total_additional_fees || 0))) * (cur?.num || 0))
                        }
                    }, { totalDishes: 0, totalDishesPrice: 0 });
                    return {
                        _id: iitem.origin_id,
                        name: iitem.origin_name,
                        totalDishes: areaObj?.totalDishes,
                        totalDishesPrice: areaObj?.totalDishesPrice,
                        additional_fees: iitem.origin_additional_fees,
                        dishes: (iitem.dishes || [])?.map((dishesItem) => {
                            return dishBaseAttr(dishesItem)
                        }),
                        set_meals: iitem.set_meals?.map((setMealsItem) => {
                            return {
                                _id: setMealsItem.origin_id,
                                name: setMealsItem.origin_name,
                                num: setMealsItem.num,
                                set_meal_areas: setMealsItem.origin_set_meal_areas?.map((setMealsItemAreas) => {
                                    return {
                                        _id: setMealsItemAreas.origin_id,
                                        name: setMealsItemAreas.origin_name,
                                        additional_fees: setMealsItemAreas.origin_additional_fees,
                                        dishes: (setMealsItemAreas.dishes || [])?.map((setMealsItemAreasDishesItem) => {
                                            return dishBaseAttr(setMealsItemAreasDishesItem)
                                        })
                                    }
                                }),
                                // 包餐里的套餐的  套餐价格 + 套餐内的额外价格
                                CollectiveDishTotalPrice: (setMealsItem.origin_price || 0) + (setMealsItem.total_additional_fees || 0),
                                price: setMealsItem.origin_price,
                                total_additional_fees: setMealsItem.total_additional_fees
                            }
                        })
                    }
                }) || []
            }
        }) || [];
        let shopCarList = [...dishes, ...setMeals, ...packageMeals];
        const res = await apiManage.checkDishStatus(stringId, shopCarList, orderDetailData.type);
        // 变动的菜品 【下架/估清 /价格变动】
        let failDishes: string[] = [];
        if (!res?.error) {
            failDishes = res?.invalidMenuData?.map((item) => item._id) || [];
        }
        // 订单菜品变动，从数组去掉
        shopCarList = shopCarList.filter((item) => !failDishes.includes(item._id || ''));
        // 计算出总价格
        const totalPrice = shopCarList.reduce((pre, cur) => {
            if (cur.buyNumber && cur.price) {
                return pre + cur.buyNumber * cur.price;
            }
            return pre;
        }, 0);

        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.SHOP_CAR,
            value: {
                ...cacheShopCar,
                [stringId]: {
                    shopCarList: shopCarList,
                    totalPrice
                }
            }
        });
        if (failDishes?.length) {
            this.setState({
                openReorderDialog: {
                    open: true,
                    confrim: () => {
                        this.goOrderPage(stringId, orderDetailData.type === '1' ? '1' : '2')
                    },
                    dishes: res?.invalidMenuData
                }
            })
        } else {
            this.goOrderPage(stringId, orderDetailData.type === '1' ? '1' : '2')
        }
    }

    goOrderPage(stringId: string, orderway: string) {
        // 点餐页
        createHashHistory().push(`/orderpage?string_id=${stringId}&orderway=${orderway}`);

    }

    // *********************
    // 视图区
    // *********************

    render() {
        const { t } = this.props;
        const { /*confirmDialogData,*/ orderDetailData, showAirBubbles, sortBag, openReorderDialog } = this.state;
        const restaurantPhone = orderDetailData && orderDetailData.belonged_restaurant
            && orderDetailData.belonged_restaurant.tel;
        const telPrefix = _.get(orderDetailData, 'belonged_restaurant.tel_prefix') ? `+${_.get(orderDetailData, 'belonged_restaurant.tel_prefix')} ` : '';
        /**
         * 0：线上付款
         * 1：线下付款
         */
        const paymentType = (orderDetailData && orderDetailData.payment_type) || null;
        /**
         * 0: 未付款
         * 1: 已付款
         * 2: 已申请退款
         * 3: 已退款
         */
        const paymentStatus = (orderDetailData && orderDetailData.payment_status) || null;
        const status = (orderDetailData && orderDetailData.status) || null;
        // 菜品原价 100
        const total_price = _.get(orderDetailData, 'total_price', 0) || 0;
        // 付款金额 1.23
        const total_price_with_tax = _.get(orderDetailData, 'total_price_with_tax', 0) || 0;
        // 配送费折后价 0.45
        const total_delivery_fee_with_tax: number = _.get(orderDetailData, 'total_delivery_fee_with_tax', 0) || 0;
        // 不含税价格
        const total_price_without_tax = _.get(orderDetailData, 'total_price_without_tax', 0);
        // 菜品折后价 0.78
        // const total_price_new = total_price_with_tax - total_delivery_fee_with_tax;
        // 菜品折扣 99.22
        // const total_price_discount = total_price_new - total_price;
        // 配送费折扣
        const total_delivery_amount_discount = _.get(orderDetailData, 'total_delivery_amount_discount', 0) || 0;
        // 配送费原价 5
        const total_delivery_fee_with_tax_old: number = total_delivery_amount_discount + total_delivery_fee_with_tax;
        // 折扣总额
        const total_use_discount_price = Math.abs(_.get(orderDetailData, 'total_use_discount_price', 0));
        // 订单创建来源 OD_WEB OD_APP OD_POS
        const orderCreationOrigin = _.get(orderDetailData, 'order_creation_origin');
        // 税费 含税价格 -  不含税价格
        const taxTotal = total_price_with_tax - total_price_without_tax;
        // 小费
        const tip: number = _.get(orderDetailData, 'tip', 0);
        // 打包费
        const bagFee = _.get(orderDetailData, 'bagFee', 0);
        // 最终的服务费服务费 = 服务费 + 打包费
        const serviceCharge: number = _.get(orderDetailData, 'serviceCharge', 0) + bagFee;
        // 优惠券总额
        const total_coupon_price = _.get(orderDetailData, 'total_coupon_price', 0);
        // 抹零总额
        const ignore_money = _.get(orderDetailData, 'ignore_money', 0);
        // 订单信息 订单折扣金额 = 折扣总额 + 优惠券总额 + 抹零总额；值是正数；文本显示 负数
        // const orderDiscountTotal = total_use_discount_price + total_coupon_price + ignore_money;
        const conutList = () => {
            let conutArr: { text: string, count: number | JSX.Element }[] = [
                { text: i18n.t('order_page_text_quantities'), count: `x ${orderDetailData.dishes.totalNum}` },
                { text: i18n.t('order_page_text_subtotal'), count: total_price }
            ]
            if (orderDetailData.total_points) {
                conutArr = conutArr.concat([
                    { text: i18n.t('my_fidelity_page_scores'), count: <span>{orderDetailData.total_points} {i18n.t('my_fidelity_page_scores')}</span> }
                ])
            }
            if (total_use_discount_price > 0) {
                conutArr = conutArr.concat([
                    { text: i18n.t('payment_page_text_discount'), count: -total_use_discount_price }
                ])
            }
            if (total_coupon_price > 0) {
                conutArr = conutArr.concat([
                    { text: i18n.t('coupon_text'), count: -total_coupon_price }
                ])
            }
            if (ignore_money > 0) {
                conutArr = conutArr.concat([
                    { text: i18n.t('order_page_ignore_money_type'), count: -ignore_money }
                ])
            }
            // 订单来源为Deliverect平台时 才展示这些费用数据
            if (orderDetailData?.order_origin_program === 'Deliverect') {
                conutArr = conutArr.concat([
                    { text: i18n.t('order_page_taxes'), count: taxTotal },
                    { text: i18n.t('order_page_tip'), count: tip },
                    { text: i18n.t('order_page_service_charges'), count: serviceCharge }
                ])
            }
            // 外送
            if (orderDetailData?.type === '0') {
                conutArr = conutArr.concat([
                    {
                        text: i18n.t('order_page_text_delivery_fee'), count: <>
                            €{total_delivery_fee_with_tax.toFixed(2)}
                            {
                                total_delivery_amount_discount > 0 &&
                                <span className='delLine'>
                                    €{total_delivery_fee_with_tax_old.toFixed(2)}
                                </span>
                            }</>
                    }]
                )
            }

            const fixedCount = (count: number) => `€${(count || 0).toFixed(2)}`;
            return conutArr.map((item, index) => {
                return <GridLayoutComponent
                    key={`${index}`}
                    columns={2}
                    leftText={item.text}
                    rightText={typeof item.count === 'number' ? fixedCount(item.count) : item.count}
                ></GridLayoutComponent>;
            })
        }
        return (
            <div className='myOrderDetailPage' >
                <TopBar
                    title={t('order_page_text_my_orders')}
                    isGoBack={() => {
                        createHashHistory().replace({
                            pathname: '/my-order-page'
                        })
                    }}
                ></TopBar>
                <div className='myOrderDetailPageContainer'>
                    <div className='orderDetail'>
                        <Scroll data={[orderDetailData]}>
                            <OrderDetailPanelComponent
                                orderDetailData={orderDetailData}
                            ></OrderDetailPanelComponent>
                            {/* 订单详情 */}
                            <div className='orderDishDetail'>
                                <div className='dish'>
                                    {sortBag.map((item, index_bags: number) => (
                                        <div key={`${index_bags}-bags`}>
                                            {
                                                item.dish_type === '0' ?
                                                    this.dishRender([item as OrderDish], index_bags) :
                                                    item.dish_type === '1' ?
                                                        this.packMealRender([item as OrderPackageMeal], index_bags) :
                                                        item.dish_type === '2' ?
                                                            this.setMealRender([item as OrderSetMeal], index_bags) :
                                                            null
                                            }
                                        </div>
                                    ))}
                                    <div className='summary'>
                                        {conutList()}
                                    </div>
                                </div>
                            </div>
                        </Scroll>
                    </div>
                    <div className='footer'>
                        <div className='amount'>
                            <span>{t('general_text_total')}</span>
                            <span>{`€${orderDetailData.total_price_with_tax ?
                                orderDetailData.total_price_with_tax.toFixed(2) : '0.00'}`}{orderDetailData.total_points ? `+${Math.ceil(orderDetailData.total_points)} ${i18n.t('my_fidelity_page_scores')}` : null}</span>
                        </div>

                        <div className='footerBtn'>
                            {/* 气泡，若需修改或取消订单，请联系商家 */}
                            {showAirBubbles &&
                                <AirBubbles
                                    title={t('general_text_please_contact_the_merchant_If_you_want_to_modify_or_cancel_the_order')}
                                    attachClass='phoneAirBubbles'
                                    onClick={() => { this.setState({ showAirBubbles: false }) }}
                                />}
                            <div className='btnDetail'>
                                <Button
                                    variant='contained'
                                    style={{ background: '#ffdc33' }}
                                    onClick={async () => {
                                        await this.reorder()
                                    }}
                                >{t('order_page_text_order_again')}</Button>
                                {
                                    // $ 线下付款，不能再切换付款方式（POS端下的订单也不能）
                                    orderCreationOrigin !== 'OD_POS' && paymentType === '0' && paymentStatus === '0' && (status === '0' || status === '1') ?
                                        <Button
                                            variant='contained'
                                            style={{ background: '#ffdc33' }}
                                            onClick={() => {
                                                createHashHistory().push(`/paymentMethodPage?string_id=${orderDetailData.belonged_restaurant.string_id}&order_id=${orderDetailData._id}`);
                                            }}
                                        >{t('payment_page_text_checkout')}</Button>
                                        : null
                                }
                            </div>
                            <a href={`tel:${telPrefix}${restaurantPhone}`}>
                                <Fab className='call'>
                                    <CallIcon />
                                </Fab>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <ConfirmDialogComponent
                    title={confirmDialogData.title || ''}
                    visible={confirmDialogData.visible || false}
                    confirmButtonText='Ok'
                    cancelButtonText='Cancel'
                    content={confirmDialogData.content}
                    onCancelFn={() => this.setState({ confirmDialogData: {} })}
                    onConfirmFn={() => this.setState({ showCancelOrderDialog: true, confirmDialogData: {} })}
                ></ConfirmDialogComponent> */}
                {/* 取消订单 */}
                < ScrollDialogComponent
                    title={t('order_page_text_cancel_order')}
                    visible={this.state.showCancelOrderDialog}
                    confirmButtonText='Ok'
                    cancelButtonText='Cancel'
                    onCancelFn={() => {
                        this.setState({
                            showCancelOrderDialog: false
                        })
                    }}
                    contentFunction={() => {
                        return (
                            <div className='dialogCancelOrder'>
                                <div className='chooseReasons'>
                                    <h3 className='title'>{'Choose reason（Multiple choice）'}</h3>
                                    <div className='choose'>
                                        <StatusButtonComponent isNormalBtn={true}
                                            btnText={'Waiting time is too long'} />
                                        <StatusButtonComponent isNormalBtn={true} btnText={'Too bad'} />
                                        <StatusButtonComponent isNormalBtn={true}
                                            btnText={'If the goods are not delivered, it will be unpalatable'} />
                                    </div>
                                </div>
                                <div className='otherReasons'>
                                    <h3 className='title'>{'Other reasons（Optional）'}</h3>
                                    <div className='input'>
                                        <InputBase multiline={true} rows={5} fullWidth={true}></InputBase>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                ></ScrollDialogComponent >
                {/* <RequestDialogComponent></RequestDialogComponent> */}
                {/* 再次订单弹窗 */}
                {openReorderDialog?.open && <ReorderDialog
                    handleClose={() => { this.setState({ openReorderDialog: { open: false } }) }}
                    dishes={openReorderDialog.dishes || []}
                    onConfirm={openReorderDialog?.confrim}
                />}
            </div >
        );
    }

}

export default withTranslation()<Props>(MyOrderPage as any);

// *********************
// Types
// *********************

type State = {
    // 显示订单详情弹框
    showOrderDetailDialog: boolean;
    // 显示确认框内容
    confirmDialogData: {
        title?: string;
        visible?: boolean;
        content?: string;
        onCancelFn?: () => void;
    }
    // 取消订单弹框
    showCancelOrderDialog: boolean;
    orderDetailData: {
        _id: string;
        address: Address;
        bags: Bag[];
        belonged_customer: Array<Customer>;
        belonged_restaurant: Restaurant;
        dishes: {
            totalNum: number;
            data: OrderDish[]
        };

        [key: string]: any
    },
    isLogin: boolean;
    showAirBubbles: boolean;
    sortBag: (OrderDish | OrderSetMeal | OrderPackageMeal)[];
    openReorderDialog: {
        open: boolean;
        confrim?: () => void;
        dishes?: any[];
    };
}

type Props = {
} & WithTranslation & RouteComponentProps

/**
 * 重新下单菜品报告
 */
export type ReOrderReport = {
    validMenuData: ReOrderDish[];
    invalidMenuData: ReOrderDish[];
}
type ReOrderDish = {
    dish_type: string;
    name: string;
    _id: string;
    set_meal?: {
        set_meal_area_id: string;
        set_meal_area_name: string;
    }
}
