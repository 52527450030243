import './index.css';
import './static/css/reset.css';
import './static/css/snackbar.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import i18n from './i18n';
import Store from './rudex/store';
import * as serviceWorker from './serviceWorker';
import { LOCAL_STORAGE_KEYS } from './constants/local-storage.constant';
import { LocalStorageManager } from './constants/tool-local-storage';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './component/snackbar/snackbar';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAP_KEY, GOOGLE_MAP_LIBRARIES } from './gconfig';

i18n.changeLanguage(LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE) || 'EN');

ReactDOM.render(
	<Provider store={Store} >
		<I18nextProvider i18n={i18n}>
			<SnackbarProvider
				style={{ top: '-14px' }}
				className='snackbar-com'
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				autoHideDuration={1500}
				maxSnack={1}
			>
				<SnackbarUtilsConfigurator />

				<App />
				<div style={{ opacity: 0 }}>
					<LoadScript
						loadingElement={'  '}
						language={LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE) || 'en'}
						libraries={GOOGLE_MAP_LIBRARIES}
						googleMapsApiKey={GOOGLE_MAP_KEY}
					></LoadScript>
				</div>
			</SnackbarProvider>
		</I18nextProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
