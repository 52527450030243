import React from 'react';
import { Typography } from '@material-ui/core';
import { ICheckoutShowedPageNames, ICheckoutSignInInputVcodeProps } from '../../interfaces';
import { MdChevronLeft } from 'react-icons/md';
import { IS_PASSWORD, IS_VCODE } from '../../constants/base.constant';
import i18n from '../../i18n';
import apiManage from '../../request';
import _ from 'lodash';
import ButtonComponent from '../../component/button.component';
import { signIn_param } from '../../component/checkout/checkout-sign-in-password-page';
import VerificationCode from '../../component/checkout/verification-code.component';
import PasswordInput from '../../component/password-input.component';
import { showApiMessage } from '../../messenger/messageManager';
import Notification from '../../component/snackbar/snackbar';

import { GOOGLE_ROBOT_SCORE } from '../../gconfig';
import { VCodeDialog } from '../../component/v-code-dialog/v-code-dialog';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';

class ForgetPassword extends React.Component<Props, State> {
    public state: State = {
        // 文本框 - 验证码
        vcode: '',
        // 文本框 - 密码框
        password: '',
        // 谷歌人机校验获取token
        showGoogleReCaptcha: false,
        // 谷歌人机校验图像弹窗
        showReCaptChaDialog: false,
        // 是否通过人机校验
        isPassReCaptcha: false,
        vcodeType: 'SMS',
        showVCodeDialog: {
            show: false
        }
    }

    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount(): void {
        this.setState({
            vcodeType: LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE) === 'WHATSAPP' ? 'WHATSAPP' : 'SMS'
        })
    }
    // *********************
    // Default Function
    // *********************

    /**
     * 通过reCaptchaToken解析分值
     */
    public async checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        this.setState({
                            showReCaptChaDialog: true
                        })
                    } else {
                        // 评分高，已验证不是机器人
                        this.setState({
                            isPassReCaptcha: true
                        })
                        // 发送验证码
                        this.props.sendPhoneGetVerificationCode();
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    public handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            this.setState({
                isPassReCaptcha: true
            })
            // 发送验证码
            this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE);
            this.props.sendPhoneGetVerificationCode();
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        // 提示
        Notification.notification(i18n.t(message));
    }

    /**
     * #### 密码登录 获取顾客头像和顾客信息,
     * 判断顾客信息是否完善, 不完善跳入信息补全页面
     */
    public async signIn() {
        const { password } = this.state;
        const { phone, phonePrefix } = this.props;
        const params = {
            phone,
            phone_prefix: phonePrefix,
            password
        }
        this.props.signIn(params);
    }

    /**
     * 重置密码
     */
    public async confirm() {
        const { phone, phonePrefix } = this.props;
        const { vcode, password, vcodeType } = this.state;
        // 验证码格式错误
        if (vcode === '' || !IS_VCODE.test(vcode)) {
            Notification.notification(i18n.t('reset_password_page_text_vcode_mismatched'));
            return;
        }
        // 密码格式错误
        else if (!(password && IS_PASSWORD.test(password))) {
            Notification.notification(i18n.t('signup_page_text_invalid_password_format'));
            return;
        }
        // 表单正确
        else {
            // 修改密码
            const params = {
                vcode: {
                    value: vcode,
                    type: vcodeType === 'SMS' ? 'RESET_PASSWORD_BY_PHONE' : 'INIT_BY_WHATS'
                },
                password,
                phone,
                phone_prefix: phonePrefix
            }
            try {
                const result = await apiManage.patch_customer_password(params, {
                    headers: {
                        Authorization: undefined
                    }
                });
                if (result.error) {
                    showApiMessage(result, 'forgetPassword')
                    return;
                }
                Notification.notification(i18n.t('delivery_address_page_text_success'));
                this.signIn();
            } catch (error) {
                //
            }
        }

    }
    reRenderText() {
        const { phonePrefix, phone } = this.props;
        const { vcodeType } = this.state;

        // 根据 vcodeType 选择合适的翻译键
        const translationKey = vcodeType === 'SMS'
            ? 'delivery_address_page_please_your_verification_code'
            : 'delivery_address_page_please_your_verification_code_with_Whatsapp';

        // 获取完整的文本
        const fullText = i18n.t(translationKey, { phone: `${phonePrefix} ${phone}` });

        // 提取文本中的数字
        const numberReg = /\d+/g;
        const numbers = fullText.match(numberReg) || [];

        // 将数字部分用 span 包裹
        const highlightedNumbers = <span style={{ color: '#5E6899' }}>{numbers.join(' ')}</span>;

        // 替换文本中的 'WhatsApp' 部分为高亮样式
        const highlightWhatsApp = (text: string) => {
            const index = text.indexOf('WhatsApp');
            if (index === -1) return text;
            return (
                <>
                    {text.slice(0, index)}
                    <span style={{ color: '#5E6899' }}>WhatsApp</span>
                    {text.slice(index + 8)}
                </>
            );
        };

        // 分割文本并应用高亮
        const startIndex = fullText.indexOf(numbers.join(' '));
        const textBeforeNumbers = fullText.slice(0, startIndex);
        const textAfterNumbers = fullText.slice(startIndex + numbers.join(' ').length);

        const highlightedTextBefore = vcodeType === 'SMS' ? textBeforeNumbers : highlightWhatsApp(textBeforeNumbers);
        const highlightedTextAfter = vcodeType === 'SMS' ? textAfterNumbers : highlightWhatsApp(textAfterNumbers);

        return (
            <>
                {highlightedTextBefore}
                {highlightedNumbers}
                {highlightedTextAfter}
            </>
        );
    }

    /**
     * 切换验证码类型
     * 显示确认弹窗，发送验证码
     */
    switchCodeType() {
        this.setState({
            showVCodeDialog: {
                show: true,
                confirm: async () => {
                    this.setState({
                        showVCodeDialog: {
                            show: false
                        },
                        vcodeType: this.state.vcodeType === 'SMS' ? 'WHATSAPP' : 'SMS'
                    }, () => {
                        // 全局缓存一下对于whatsapp填写验证码的状态
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE,
                            value: this.state.vcodeType
                        });
                        this.props.sendPhoneGetVerificationCode(this.state.vcodeType === 'WHATSAPP')
                    })
                }
            }
        })
    }
    // *********************
    // View
    // *********************
    render() {
        const { vcode, password, vcodeType, } = this.state;
        const { phonePrefix, vcodeTimeMS, whatsappVcodeTimeMS } = this.props;
        return (
            <div className='checkout-sign-in-phone'>
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE)}>
                        <MdChevronLeft />
                    </span>
                    <span className='title'>
                        {i18n.t('signin_page_text_forget_password')}
                    </span>
                </div >
                <VerificationCode
                    defaultValue={vcode}
                    onChange={(vCode) => this.setState({
                        vcode: vCode
                    })}
                    isCountDown={(vcodeType === 'SMS' ? vcodeTimeMS : whatsappVcodeTimeMS) !== 90}
                    onChangeSecond={() => {
                        this.props.sendPhoneGetVerificationCode(vcodeType === 'WHATSAPP');
                    }}
                    codeSecond={vcodeType === 'SMS' ? vcodeTimeMS : whatsappVcodeTimeMS}
                />
                <div className='code-password-box'>
                    <Typography className='tip-text'>
                        {this.reRenderText()}
                    </Typography>
                    {phonePrefix === '39' && <div className='switch-container' onClick={() => { this.switchCodeType() }}>
                        {i18n.t('login_page_Switch_to_x_verification_code', { x: vcodeType === 'SMS' ? 'WhatsApp' : 'SMS' })}
                    </div>}
                </div>
                <div className=''>
                    <Typography className='tip-text'>
                        {i18n.t('reset_password_page_text_password_mismatched_rule')}
                    </Typography>
                    <PasswordInput
                        className='sign-up-password mt30'
                        defaultValue={password}
                        isSame={true}
                        onChange={(val: string) => {
                            this.setState({
                                password: val
                            });
                        }}
                        placeholder={i18n.t('delivery_address_page_please_enter_your_password')}
                    />
                </div>
                <div className='mt60'></div>
                <ButtonComponent
                    className={!(IS_VCODE.test(vcode) && IS_PASSWORD.test(password)) ? 'signup-button-not-phone ' : 'signup-button-div'}
                    value={i18n.t('payment_page_text_next')}
                    onClick={() => {
                        if ((IS_VCODE.test(vcode) && IS_PASSWORD.test(password))) {
                            this.confirm()
                        }
                    }}
                />
                <VCodeDialog
                    show={this.state.showVCodeDialog.show}
                    confirm={() => {
                        this.state.showVCodeDialog.confirm?.()
                    }}
                    vcodeType={vcodeType === 'SMS' ? 'WhatsApp' : 'SMS'}
                    onClose={() => {
                        this.setState({ showVCodeDialog: { show: false } })
                    }} />
            </div>
        )
    }
}

export default ForgetPassword


// *********************
// Props & State
// *********************

type State = {
    vcode: string;
    password: string;
    showGoogleReCaptcha: boolean;
    showReCaptChaDialog: boolean;
    isPassReCaptcha: boolean;
    vcodeType: 'WHATSAPP' | 'SMS';
    showVCodeDialog: {
        show: boolean;
        confirm?: () => void;
    }
}

interface Props extends ICheckoutSignInInputVcodeProps {
    sendPhoneGetVerificationCode: (toWhatsapp?: boolean) => void;
    // 登录接口
    signIn: (param: signIn_param) => void;
    whatsappVcodeTimeMS: number;
}
