import React from 'react';
import moment from 'moment';
import i18n from '../../i18n';
import { Schedule, ScheduleTimeRange, SettingClosedSchedule } from '../../models';
import { Chip, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { CanceledScheduleTimeRanges } from '../../models/abstract/canceled-schedule-time-ranges.entity';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { DT, checkSchedule } from '../../constants/tool-constant';
import { getQueryString } from '../../constants/tool-get-parms';
import { updateScheduleData } from '../../constants/tool-update-schedule-data';
import { getNextTimeRanges } from './analyzeRestaurantSchedule.tool';
import momentTimeZone from 'moment-timezone';


// 
/**
 * 餐厅营业时间表弹窗
 * - componentType:dish 菜品可售时间表
 */
export function ScheduleDialog(
    props: {
        className?: string;
        open: boolean;
        handleClose: () => void;
        scheduledata: Array<Schedule>;
        isPickUp?: boolean;
        serviceAllBtn: boolean;
        settingClosedSchedule?: SettingClosedSchedule[];
        canceledScheduleTimeRanges?: CanceledScheduleTimeRanges[];
        componentType?: 'dish' | 'default';
    }
) {
    const { open, handleClose, className, scheduledata, isPickUp, serviceAllBtn, settingClosedSchedule, canceledScheduleTimeRanges, componentType } = props
    function changeString(value?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | number) {
        let text = ''
        switch (value) {
            case 1:
                text = `${i18n.t('restaurant_info_page_text_monday')}`
                break;
            case 2:
                text = `${i18n.t('restaurant_info_page_text_tuesday')}`
                break;
            case 3:
                text = `${i18n.t('restaurant_info_page_text_wednesday')}`
                break;
            case 4:
                text = `${i18n.t('restaurant_info_page_text_thursday')}`
                break;
            case 5:
                text = `${i18n.t('restaurant_info_page_text_friday')}`
                break;
            case 6:
                text = `${i18n.t('restaurant_info_page_text_saturday')}`
                break;
            case 7:
                text = `${i18n.t('restaurant_info_page_text_sunday')}`
                break;
            default:
                break;
        }
        return text
    }
    // 没有传值，默认选择配送
    const [isShowPickup, onShowPickup] = React.useState(isPickUp ?? false);
    React.useEffect(() => {
        if (open) {
            onShowPickup(isPickUp ?? false);
        }
    }, [isPickUp, open])
    return (
        <Dialog className={className} maxWidth='md' fullWidth={true}
            aria-labelledby='simple-dialog-title'
            open={open} onClose={handleClose}>
            <div className='closeIcon'>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div >
            <div className='head'>{componentType === 'dish' ? i18n.t('order_page_text_menu_available_for_sale_schedule') : i18n.t('restaurant_info_page_text_schedule')}</div>
            <div className='orderWayBtn'>
                <Chip
                    onClick={() => { isShowPickup && onShowPickup(false) }}
                    className={`chipItem ${!isShowPickup ? 'activeBg' : ''}`}
                    size='small' label={i18n.t('payment_page_button_delivery')} />
                <Chip
                    onClick={() => { !isShowPickup && onShowPickup(true) }}
                    className={`chipItem ${isShowPickup ? 'activeBg' : ''}`}
                    size='small' label={i18n.t('payment_page_button_takeaway')} />
            </div>
            <div className='dialogbody'>
                <div>
                    {serviceAllBtn ? scheduledata.map((item: Schedule, index: number) => {
                        // 今天星期几
                        const todyIsoWeekday = moment(new Date()).isoWeekday();
                        // 之后星期几
                        const isoWeekday = moment(new Date()).add(index, 'day').isoWeekday();
                        // 目标日期的营业时间段
                        const dateTimeRanges: Schedule = _.find(scheduledata, (n) => { return n.week === isoWeekday }) || {};
                        // 目标日期
                        const targetDate = moment(new Date()).add(index, 'day').format('YYYY-MM-DD')
                        // 目标日期的营业时间段（是否是全体营业）
                        const timeRanges = dateTimeRanges.time_24_hours ? [{
                            start_time: '00:00:00',
                            end_time: '23:59:00',
                            selftake_status: dateTimeRanges.selftake_status,
                            takeaway_status: dateTimeRanges.takeaway_status
                        }] : (dateTimeRanges.time_ranges || [])
                        // 最终显示的营业时间表（对应的周几）
                        // console.log(settingClosedSchedule, '====', canceledScheduleTimeRanges);
                        // 只有配送限制
                        const newCanceledScheduleTimeRanges = isShowPickup === false ? canceledScheduleTimeRanges || [] : []
                        const finalTimeRanges = componentType === 'dish' ? timeRanges : businessSchedule(timeRanges, getTargetDateClosedSchedule(targetDate, [...(settingClosedSchedule || []), ...newCanceledScheduleTimeRanges]))
                        // 在付款页面需要区分自取或配送
                        const filterFinalTimeRanges = _.filter(finalTimeRanges, (n) => {
                            return isShowPickup === true ? n.selftake_status : n.takeaway_status
                        })

                        return (
                            <div className='scheduleBox' key={index}>
                                <div className='date'>
                                    {todyIsoWeekday > isoWeekday ? i18n.t('restaurant_info_page_text_next_week') : ''}
                                    {` `}
                                    {changeString(isoWeekday)}
                                </div>
                                <div className='time'>
                                    {/* 先判断判断是否可以配送和自取 */}
                                    {
                                        /**
                                         * 1、先判断是否可配送或者可以自取
                                         * 2、判断是否全体营业
                                         * 3、分时间段
                                         */
                                        // 如果设置一周不营业开关打开，那么 显示停业  最高级
                                        (!serviceAllBtn
                                            ? <div>
                                                <span className='showTip'>
                                                    {/* 没有营业时间显示休息 */}
                                                    {i18n.t('request_failure_text_service_not_available')}
                                                </span>
                                            </div>
                                            :
                                            finalTimeRanges.length > 0
                                                ?
                                                // 找出对应外卖方式（自取或配送）
                                                (filterFinalTimeRanges.length > 0 ?
                                                    // 拆分成两两一组，便于处理显示
                                                    _.chunk(filterFinalTimeRanges, 2).map(
                                                        (timeItem, timeIndex) => {
                                                            return (
                                                                <div key={timeIndex}>
                                                                    {timeItem.map((iitem: any, I) => {
                                                                        // 满足一整天的显示24小时营业
                                                                        if (iitem.start_time === '00:00:00' && iitem.end_time === '23:59:00') {
                                                                            return (
                                                                                <span key={I} className='showTip'>
                                                                                    {i18n.t('general_text_all_day_business')}
                                                                                </span>
                                                                            )
                                                                        }
                                                                        // 否则，显示设置的时间
                                                                        else {
                                                                            return (
                                                                                <span key={I} className='showTip'>
                                                                                    {`${moment(iitem.start_time, 'HH:mm').format('HH:mm')}-${moment(iitem.end_time, 'HH:mm').format('HH:mm')}`}
                                                                                </span>
                                                                            )
                                                                        }

                                                                    })}
                                                                </div>
                                                            )
                                                        })
                                                    : <div>
                                                        <span className='showTip'>
                                                            {/* 要区分自取或配送，显示不可配送或不可自取 */}
                                                            {isShowPickup === true
                                                                ? i18n.t('payment_page_text_takeaway_unavailable')
                                                                : i18n.t('payment_page_text_delivery_unavailable')}
                                                        </span>
                                                    </div>)
                                                : <div>
                                                    <span className='showTip'>
                                                        {/* 没有营业时间显示休息 */}
                                                        {i18n.t('payment_page_text_close_down')}
                                                    </span>
                                                </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }) :
                        <div className='scheduleBox' >
                            <div className='time'>
                                <div>
                                    <span className='showTip'>
                                        {/* 没有营业时间显示休息 */}
                                        {i18n.t('request_failure_text_service_not_available')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </Dialog>
    )
}


/**
 * 根据目标日期匹配对应的休业时间表
 * @param targetDate 日期
 * @param closedSchedule 休业时间表
 * @returns 目标日期的休业时间段
 */
export function getTargetDateClosedSchedule(
    targetDate: string,
    closedSchedule: SettingClosedSchedule[]
) {
    let findTargetClosedSchedule: Array<{ start_time: string, end_time: string }> = [];
    try {
        // 目标日期出现在中间的，24小时休业
        const isAllDayClose = _.find(closedSchedule, (n) => {
            return moment(targetDate, 'YYYY-MM-DD').isBetween(
                moment(n.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS),
                moment(n.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS),
                'day',
                '()'
            )
        })
        if (isAllDayClose) {
            findTargetClosedSchedule = [{ start_time: '00:00:00', end_time: '23:59:00' }]
        } else {
            // 匹配的休业时间
            const matchingSchedule = _.filter(closedSchedule, (n) => {
                return (moment(targetDate, 'YYYY-MM-DD').isSame(
                    moment(n.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS), 'day'
                ) || moment(targetDate, 'YYYY-MM-DD').isSame(
                    moment(n.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS), 'day'
                ));
            });
            // 没有匹配上的休业时间
            if (matchingSchedule.length === 0) {
                findTargetClosedSchedule = [];
            }
            // 有匹配上的休业时间
            else if (matchingSchedule.length > 0) {
                matchingSchedule.forEach((closeTime) => {
                    let startTime: string = '';
                    let endTime: string = '';
                    if (moment(closeTime.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS).isSame(
                        moment(closeTime.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS), 'day'
                    )) {
                        startTime = closeTime.start_time_interval || '';
                        endTime = closeTime.end_time_interval || '';
                    } else if (moment(targetDate, 'YYYY-MM-DD').isSame(
                        moment(closeTime.start_date, moment.HTML5_FMT.DATETIME_LOCAL_MS), 'day'
                    )) {
                        startTime = closeTime.start_time_interval || '';
                        endTime = '23:59:00';
                    } else if (moment(targetDate, 'YYYY-MM-DD').isSame(
                        moment(closeTime.end_date, moment.HTML5_FMT.DATETIME_LOCAL_MS), 'day'
                    )) {
                        startTime = '00:00:00';
                        endTime = closeTime.end_time_interval || '';
                    }
                    findTargetClosedSchedule.push({
                        start_time: startTime,
                        end_time: endTime
                    })
                })
            }
            // console.log(matchingSchedule, '匹配上的休业时间表');
        }
    } catch (error) {
    }
    // console.log(isAllDayClose, '出现在中间的，24小时休业');
    // console.log(findTargetClosedSchedule, '=====目标日期休业时间表');
    return findTargetClosedSchedule
}

/**
 * 营业时间终极版，排除休业时间
 * @param openTimeRanges 某一天的营业时间
 * @param closeTimeRanges 某一天的休业时间
 * @returns 某一天的营业时间
 */
export function businessSchedule(
    openTimeRanges: Array<ScheduleTimeRange> | undefined,
    closeTimeRanges: Array<{ start_time: string, end_time: string }>
) {
    // 转化时间
    function TFMoment(time) {
        return moment(time, 'HH:mm:ss')
    }
    // 设置原始时间段的开始时间结束时间
    function setNewOpenItem(
        originTime: ScheduleTimeRange, start_time?: string, end_time?: string
    ) {
        originTime = _.cloneDeep(originTime);
        _.set(originTime, 'start_time', start_time)
        _.set(originTime, 'end_time', end_time)
        return originTime
    }
    // 输出匹配后的营业时间
    const newOpenTimeRanges: ScheduleTimeRange[] = [];
    try {
        if (openTimeRanges) {
            openTimeRanges.forEach((openItem) => {
                let newOpenItem: ScheduleTimeRange | Array<ScheduleTimeRange> | undefined = openItem;
                closeTimeRanges.forEach((closeItem) => {
                    // 如果其中一次遍历产生两段时间，再次进入循环
                    if (newOpenItem && _.isArray(newOpenItem)) {
                        newOpenItem = businessSchedule(newOpenItem, [closeItem]);
                        return
                    }
                    const startTime = newOpenItem?.start_time;
                    const endTime = newOpenItem?.end_time;
                    if (newOpenItem) {
                        // 营业开始和结束时间都在休业时间内
                        if (moment(newOpenItem.start_time, 'HH:mm:ss').isBetween(TFMoment(closeItem.start_time), TFMoment(closeItem.end_time), 'minutes', '[]') &&
                            moment(newOpenItem.end_time, 'HH:mm:ss').isBetween(TFMoment(closeItem.start_time), TFMoment(closeItem.end_time), 'minutes', '[]')
                        ) {
                            newOpenItem = undefined;
                        }
                        // 休业开始和结束时间都在营业时间内
                        else if (moment(closeItem.start_time, 'HH:mm:ss').isBetween(TFMoment(newOpenItem.start_time), TFMoment(newOpenItem.end_time), 'minutes', '[]') &&
                            moment(closeItem.end_time, 'HH:mm:ss').isBetween(TFMoment(newOpenItem.start_time), TFMoment(newOpenItem.end_time), 'minutes', '[]')) {
                            // 休业结束时间等于营业结束时间
                            if (moment(newOpenItem.end_time, 'HH:mm:ss').isSame(TFMoment(closeItem.end_time))) {
                                newOpenItem = setNewOpenItem(newOpenItem, newOpenItem.start_time, closeItem.start_time);
                            }
                            // 休业开始时间等于营业开始时间
                            else if (moment(newOpenItem.start_time, 'HH:mm:ss').isSame(TFMoment(closeItem.start_time))) {
                                newOpenItem = setNewOpenItem(newOpenItem, closeItem.end_time, newOpenItem.end_time)
                            } else {
                                newOpenItem = [
                                    setNewOpenItem(newOpenItem, startTime, closeItem.start_time),
                                    setNewOpenItem(newOpenItem, closeItem.end_time, endTime)
                                ];
                            }
                        }
                        // 营业开始时间在休业时间内
                        else if (moment(newOpenItem.start_time, 'HH:mm:ss').isBetween(TFMoment(closeItem.start_time), TFMoment(closeItem.end_time), 'minutes', '[]')) {
                            newOpenItem = setNewOpenItem(newOpenItem, closeItem.end_time, newOpenItem.end_time);
                        }
                        // 营业结束时间在休业时间内
                        else if (moment(newOpenItem.end_time, 'HH:mm:ss').isBetween(TFMoment(closeItem.start_time), TFMoment(closeItem.end_time), 'minutes', '[]')) {
                            newOpenItem = setNewOpenItem(newOpenItem, newOpenItem.start_time, closeItem.start_time);
                        }
                    }

                })
                if (newOpenItem) {
                    if (_.isArray(newOpenItem)) {
                        newOpenTimeRanges.push(...newOpenItem);
                    } else {
                        newOpenTimeRanges.push(newOpenItem);
                    }
                }
                // console.log(newOpenItem, '单个结果');
            })
            // console.log(newOpenTimeRanges, '最终结果');
        }
    } catch (error) {
    }
    return newOpenTimeRanges
}

/**
 * 初始化 区域限制配送时间
 * - 组装成停业时间表格式
 * @param canceledScheduleTimeRanges
 */
export function getTargetDateCanceledSchedule(canceledScheduleTimeRanges: CanceledScheduleTimeRanges[]) {
    const newCanceledScheduleTimeRanges = (canceledScheduleTimeRanges || []).map((item) => {
        const weekOfday = Number(moment().format('E'));
        // 获取这周几的日期
        const days = Number(item.belonged_schedule_id?.week) - weekOfday;
        // 如果是过去的日期，则获取下周的日期
        const date = moment().add(days < 0 ? days + 7 : days, 'days').format('YYYY-MM-DD')
        // 是否是24小时
        const startTimeInterval = item.belonged_schedule_id?.time_24_hours === true ? '00:00' : undefined;
        const endTimeInterval = item.belonged_schedule_id?.time_24_hours === true ? '23:59' : undefined;
        return {
            start_date: date,
            end_date: date,
            start_time_interval: startTimeInterval ?? moment(item.belonged_schedule_time_range_id?.start_time, 'HH:mm').format('HH:mm'),
            end_time_interval: endTimeInterval ?? moment(item.belonged_schedule_time_range_id?.end_time, 'HH:mm').format('HH:mm'),
            week: item.belonged_schedule_id?.week
        }
    })
    return newCanceledScheduleTimeRanges
}

/**
 * 获取菜单菜单可用时间表
 */
export function getMenuDishAvailableSchedule(
    canceledScheduleTimeRanges: { belonged_schedule_id: string, belonged_schedule_time_range_id: string, time_24_hours: boolean }[],
    schedule: Schedule[],
    date: string,
    orderType: string
) {
    // 1~7
    const weekOfDay = Number(moment(date).format('E'));
    const time = moment(date)?.format('HH:mm');
    let available = false;
    const cloneSchedule = _.cloneDeep(schedule)
    cloneSchedule.map((item) => {

        // 移除无效时间范围
        item.time_ranges = _.compact(item.time_ranges?.map((range) => {
            return canceledScheduleTimeRanges.find((cancelTimeItem) => {
                return range._id === cancelTimeItem.belonged_schedule_time_range_id
            }) ? undefined : range;
        }) || []) || [];
        // 24小时
        if (canceledScheduleTimeRanges.find((cancelTimeItem) => cancelTimeItem.time_24_hours && (cancelTimeItem.belonged_schedule_id === item._id))) {
            item.time_24_hours = false;
            item.time_ranges = [];
        }

        // 是否是有效时间内
        if (item.week === weekOfDay) {
            available = getAvailable(orderType, item, time)
        }
        return item;
    })
    return { schedule: cloneSchedule, available }
}

/**
 * 可售菜品
 * - schedule 可用时间表
 */
export function isAvailableDish(schedule: Schedule[], fulfillmentTime: Date, orderType: string): boolean {
    const weekOfDay = Number(moment(fulfillmentTime).format('E'));
    const time = moment(fulfillmentTime)?.format('HH:mm');
    let available: boolean = false;
    if (schedule?.length) {
        schedule?.find((item) => {
            // 是否是有效时间内
            if (item.week === weekOfDay) {
                available = getAvailable(orderType, item, time)
            }
        })
    } else {
        available = true;
    }

    return available
}

function getAvailable(orderType: string, scheduleItem: Schedule, time: string) {
    let available = false;
    // 是否是有效时间内
    if (scheduleItem.time_24_hours) {
        available = (orderType === '1' && scheduleItem.selftake_status) || (orderType === '2' && scheduleItem.takeaway_status) || false;
    } else {
        available = !!scheduleItem.time_ranges?.find((range) => {
            if ((orderType === '1' && range.selftake_status) || (orderType === '2' && range.takeaway_status)) {
                return moment(time, 'HH:mm').isBetween(moment(range.start_time, 'HH:mm'), moment(range.end_time, 'HH:mm'), 'm', '[]')
            }
        })
    }
    return available;
}

/**
 * 获取到下个可配送时间
 */
export function getFulfillmentTime() {
    // 设置最大可选日期
    let maxTime = new Date(moment(new Date()).add(6, 'day').set({ 'hour': 23, 'minute': 59, 'second': 0 }).valueOf())


    const restaurantId = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
    const res = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.RESTAURANTS);
    const deliveryFee = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.DELIVERY_FEE) || {};
    const time = initFulfillmentTime(_.get(res, 'setting_order_interval_time'));

    if (res.setting_max_takeout_day) {
        maxTime = new Date(moment().add(Number(res.setting_max_takeout_day) - 1, 'day').set({ 'hour': 23, 'minute': 59, 'second': 0 }).valueOf());
    }
    // 配送区域时间限制 deliveryFee只有配送时才有值
    let canceledScheduleTimeRanges: SettingClosedSchedule[] = [];
    if (deliveryFee && Object.keys(deliveryFee).length > 0) {
        canceledScheduleTimeRanges = getTargetDateCanceledSchedule(deliveryFee.canceled_schedule_time_ranges || []);
    }
    // 判断时间是否在营业时间段内 setting_app_takeaway_service_schedule
    let serviceSchedule = _.get(res, `[${restaurantId}].restaurant.setting_app_takeaway_service_schedule`, []);
    // 停业日历
    let settingClosedSchedule = _.get(res, `[${restaurantId}].restaurant.setting_closed_schedule`, []);
    // 选择地址后的区域限制配送时间
    settingClosedSchedule = _.concat(settingClosedSchedule, canceledScheduleTimeRanges);

    // 结合休业时间，算出营业时间
    serviceSchedule = updateScheduleData(serviceSchedule, settingClosedSchedule);
    const restaurantsInfo = _.get(res, `[${restaurantId}].restaurant`);
    // $ 时间表里面的时区  为null就是默认值
    const scheduleTimeZoneName = _.get(restaurantsInfo, 'time_zone') || momentTimeZone.tz.guess();
    // 当前时区
    const originTimeZoneName = momentTimeZone.tz.guess();
    const formatTime = DT(time);
    const isBusiness = checkSchedule(serviceSchedule, formatTime, scheduleTimeZoneName, originTimeZoneName);

    // 是否可用，在营业时间内
    let isAvailable = true;
    // 判断自取还是配送
    const orderWay = getQueryString('orderway');
    const isPickUp = orderWay === '2' ? false : true;

    if (isBusiness.isSelftakeAvailableInCurrentTime && isPickUp) {
        // 可以自己取
        isAvailable = true;
    } else if (isBusiness.isTakeawayAvailableInCurrentTime && !isPickUp) {
        // 可以配送
        isAvailable = true;
    } else {
        // 不允许配送和自取
        isAvailable = false;
    }
    let nextOpenTime: string = moment(time).format('YYYY-MM-DD HH:mm');
    if (!isAvailable) {
        serviceSchedule = serviceSchedule.map((item) => {
            return {
                ...item,
                time_24_hours: isPickUp ? (item.selftake_status && item.time_24_hours) : (item.takeaway_status && item.time_24_hours),
                time_ranges: _.filter(item.time_ranges, (n) => { return isPickUp ? n.selftake_status : n.takeaway_status })
            }
        })
        // 今天的营业时间
        const todyTimeRanges = (_.find(serviceSchedule, (item) => item.week === moment().isoWeekday()) || {}).time_ranges || [];
        if (todyTimeRanges && todyTimeRanges.length > 0) {
            const rangesItem = _.find(todyTimeRanges, (item) => { return moment().isBefore(moment(item.start_time, 'HH:mm')) && (isPickUp ? item.selftake_status : item.takeaway_status) });
            if (rangesItem) {
                nextOpenTime = moment(rangesItem.start_time, 'HH:mm').format('YYYY-MM-DD HH:mm');
            } else {
                // 下个营业时间
                const nextRangeItem = getNextTimeRanges(1, serviceSchedule);
                console.log(nextRangeItem);
                if (nextRangeItem.nextOpenTimeisAlldayOpen) {
                    nextOpenTime = moment(nextRangeItem.nextDate, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00');
                } else {
                    nextOpenTime = `${nextRangeItem.nextDate} ${nextRangeItem.nextOpenTime}`;
                }
            }
        } else {
            const nextRangeItem = getNextTimeRanges(1, serviceSchedule);
            if (nextRangeItem && Object.keys(nextRangeItem).length > 0) {
                if (nextRangeItem.nextOpenTimeisAlldayOpen) {
                    nextOpenTime = moment(nextRangeItem.nextDate).format('YYYY-MM-DD 00:00');
                } else {
                    nextOpenTime = moment(`${nextRangeItem.nextDate} ${nextRangeItem.nextOpenTime}`).format('YYYY-MM-DD HH:mm');
                }
            }
        }
    }
    // 下一个营业时间 大于 最大可下单时间
    if (moment(nextOpenTime, 'YYYY-MM-DD HH:mm').isAfter(maxTime)) {
        nextOpenTime = moment(time).format('YYYY-MM-DD HH:mm');
    }
    return nextOpenTime ? initFulfillmentTime(0, nextOpenTime) : time;
}


function initFulfillmentTime(orderIntervalTime?: number, date?: string): Date {
    const step = getQueryString('orderway') === '1' ? 15 : 20;
    // 获取当餐馆配送最低时间,初始时间要加上餐厅最低配送时间
    // 在这里排除0的情况  null和undefined都设置为30分钟
    orderIntervalTime = orderIntervalTime ?? 30;
    // 当分钟数不大于20的时候，要当前的%20 加上餐馆定的事件的分钟数
    // 这里有一种情况就是当前分钟+餐厅分钟 === 20 或者 0 . 下面除法依旧时1 ，应该改为2才对
    // 20 - 40 这个范围就不对
    orderIntervalTime = orderIntervalTime > 40 ?
        orderIntervalTime : (moment(date ?? new Date()).minute() % step) + orderIntervalTime;

    let minTime = new Date(moment(date ?? new Date()).valueOf());
    // 补充的时间差，补充到20 40 60
    const timeEquation = moment(date ?? new Date()).minute() % step;
    // 刚好等于则不需要补
    if (timeEquation !== 0 || !date) {
        minTime = new Date(moment(date ?? new Date())
            .add(
                ((Math.floor((orderIntervalTime) / step) + 1) * step) - (moment(date ?? new Date()).minute() % step),
                'minutes'
            )
            .valueOf()
        );
    }
    return minTime;
}


/**
 * 时间表排序从小到打
 */
export function sortTimeRanges(Schedule: Schedule[]): Schedule[] {
    return Schedule.map((item) => {
        return {
            ...item,
            time_ranges: item.time_ranges?.sort((a, b) => {
                return moment(a.start_time, 'HH:mm').valueOf() - moment(b.start_time, 'HH:mm').valueOf();
            })
        }
    });

}